import React, { Component } from "react";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import GridContainer from "components/Grid/GridContainer.js";
import FormControl from "@material-ui/core/FormControl";
import GridItem from "components/Grid/GridItem.js";
import Datetime from "react-datetime";
import Assignment from "@material-ui/icons/Assignment";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
class SummaryReport extends Component {
  state = {
    download: false,
    view: false,
    expense: [],
    monthly: [],
    income: [],
    status: "",
    frmDate: "",
    toDate: "",
    active: 1,
    endTotal: 0,
    startTotal: 0,
    incomeTotal: 0,
    expenseTotal: 0,
    openingBalance: 0,
    closingBalance: 0,
    btnIdArray: []
  };
  searchReport = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/getsummery?frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate
      )
      .then(resp => {
        // console.log(resp.data);
        let incomeTotal = 0;
        let expenseTotal = 0;
        let openingBalance = 0;
        let closingBalance = 0;
        resp.data.monthly.forEach(v => {
          openingBalance += parseFloat(v.start_date);
          closingBalance += parseFloat(v.end_date);
        });
        resp.data.expense.forEach(v => {
          expenseTotal += parseFloat(v.total);
        });
        resp.data.income.forEach(v => {
          incomeTotal += parseFloat(v.amount);
        });
        this.setState({
          expense: resp.data.expense,
          income: resp.data.income,
          monthly: resp.data.monthly,
          endTotal: resp.data.endTotal,
          startTotal: resp.data.startTotal,
          incomeTotal,
          closingBalance,
          openingBalance,
          expenseTotal,
          download: true,
          view: true
        });
      });
  };

  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h5>Filters</h5>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <br />
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                onChange={e => {
                  let year = e._d.getFullYear().toString();
                  let month = (e._d.getMonth() + 101).toString().substring(1);
                  let day = (e._d.getDate() + 100).toString().substring(1);
                  this.setState(
                    {
                      frmDate: year + "/" + month + "/" + day
                    },
                    () => {
                      //console.log(this.state.frmDate);
                    }
                  );
                }}
                inputProps={{
                  placeholder: "From Date"
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <br />
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                onChange={e => {
                  let year = e._d.getFullYear().toString();
                  let month = (e._d.getMonth() + 101).toString().substring(1);
                  let day = (e._d.getDate() + 100).toString().substring(1);
                  this.setState(
                    {
                      toDate: year + "/" + month + "/" + day
                    },
                    () => {
                      //console.log(this.state.toDate);
                    }
                  );
                }}
                inputProps={{
                  placeholder: "To Date"
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={1}>
            <Button
              style={{ position: "relative", top: "13px", right: "20px" }}
              onClick={this.searchReport}
              color="success"
            >
              Search
            </Button>
          </GridItem>
          {this.state.download ? (
            <GridItem xs={12} sm={12} md={1}>
              <a
                href={
                  "https://admin.akclasses.in/api/summery?frmdate=" +
                  this.state.frmDate +
                  "&todate=" +
                  this.state.toDate
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  style={{ position: "relative", top: "13px" }}
                  onClick={() => {
                    this.setState({ download: false });
                  }}
                  color="danger"
                >
                  Download Report
                </Button>
              </a>
            </GridItem>
          ) : null}

          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody>
                <h3>Summary Report</h3>
                <div
                  style={{
                    width: "50%",
                    display: "inline-block",
                    padding: "10px"
                  }}
                >
                  <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
                    Monthly
                  </h4>
                  <table
                    style={{
                      width: "100%",
                      border: "1px grey solid",
                      borderCollapse: "collapse"
                    }}
                  >
                    <tr
                      style={{
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        Sr No.
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        Mode
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        Opening Balance
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        Closing Balance
                      </th>
                    </tr>
                    {this.state.monthly.map((sec, index) => (
                      <tr
                        style={{
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                        key={index}
                      >
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        >
                          {sec.mode}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        >
                          {parseFloat(sec.start_date).toFixed(2)}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        >
                          {parseFloat(sec.end_date).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                    {this.state.monthly.length > 0 ? (
                      <tr
                        style={{
                          backgroundColor: "lightgreen",
                          color: "white"
                        }}
                      >
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        ></td>
                        <th
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        >
                          Totals
                        </th>
                        <th
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        >
                          ₹{parseFloat(this.state.openingBalance).toFixed(2)}
                        </th>
                        <th
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        >
                          ₹{parseFloat(this.state.closingBalance).toFixed(2)}
                        </th>
                      </tr>
                    ) : null}
                  </table>
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "inline-block",
                    padding: "10px",
                    position: "relative",
                    top: this.state.download ? "100px" : "40px"
                  }}
                >
                  {this.state.view ? (
                    <h6
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "red"
                      }}
                    >
                      {new Date(this.state.frmDate).toDateString()} -
                      {new Date(this.state.toDate).toDateString()}
                    </h6>
                  ) : null}
                  <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
                    Monthly Closing
                  </h4>
                  <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
                    ₹
                    {parseFloat(this.state.endTotal).toFixed(2) -
                      parseFloat(this.state.startTotal).toFixed(2)}
                  </h4>
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "inline-block",
                    padding: "10px"
                  }}
                >
                  <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
                    Income
                  </h4>
                  <table
                    style={{
                      width: "100%",
                      border: "1px grey solid",
                      borderCollapse: "collapse"
                    }}
                  >
                    <tr
                      style={{
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        Sr No.
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        Mode
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        Start Date
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        End Date
                      </th>

                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        Amount
                      </th>
                    </tr>
                    {this.state.income.map((sec, index) => (
                      <tr
                        style={{
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                        key={index}
                      >
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        >
                          {sec.mode}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        >
                          {sec.start_date}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        >
                          {sec.end_date}
                        </td>

                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        >
                          {parseFloat(sec.amount).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                    {this.state.income.length > 0 ? (
                      <tr
                        style={{
                          backgroundColor: "lightgreen",
                          color: "white"
                        }}
                      >
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        ></td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        ></td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        ></td>
                        <th
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        >
                          Totals
                        </th>
                        <th
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        >
                          ₹{parseFloat(this.state.incomeTotal).toFixed(2)}
                        </th>
                      </tr>
                    ) : null}
                  </table>
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "inline-block",
                    padding: "10px"
                  }}
                >
                  <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
                    Expense
                  </h4>
                  <table
                    style={{
                      width: "100%",
                      border: "1px grey solid",
                      borderCollapse: "collapse"
                    }}
                  >
                    <tr
                      style={{
                        border: "1px grey solid",
                        borderCollapse: "collapse"
                      }}
                    >
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        Sr No.
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        Category
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        By Order
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                      >
                        Total
                      </th>
                    </tr>
                    {this.state.expense.map((sec, index) => (
                      <tr
                        style={{
                          border: "1px grey solid",
                          borderCollapse: "collapse"
                        }}
                        key={index}
                      >
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        >
                          {sec.expenses_category.CATEGORY}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        >
                          {sec.expenses_category.BYORDER}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        >
                          {parseFloat(sec.total).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                    {this.state.expense.length > 0 ? (
                      <tr
                        style={{
                          backgroundColor: "lightgreen",
                          color: "white"
                        }}
                      >
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        ></td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        ></td>
                        <th
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        >
                          Totals
                        </th>
                        <th
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse"
                          }}
                        >
                          ₹{parseFloat(this.state.expenseTotal).toFixed(2)}
                        </th>
                      </tr>
                    ) : null}
                  </table>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default SummaryReport;
