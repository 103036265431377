import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CardHeader from "components/Card/CardHeader.js";
import Datetime from "react-datetime";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CardIcon from "components/Card/CardIcon.js";
import { makeStyles } from "@material-ui/core/styles";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
const classes = makeStyles(styles);
class SingleVendorOrders extends Component {
  state = {
    secondaryCategories: [],
    type: "",
    set: false,
    tableArray: []
  };
  searchReport = () => {
    axios
      .get(
        this.state.set
          ? "https://admin.akclasses.in/api/login_vender_order_settlement?frmdate=" +
              this.state.frmDate +
              "&todate=" +
              this.state.toDate
          : "https://admin.akclasses.in/api/login_vender_order_payment?frmdate=" +
              this.state.frmDate +
              "&todate=" +
              this.state.toDate
      )
      .then(resp => {
        // console.log(resp.data);
        if (resp.data.status === "fail") {
          this.setState({ warn: true });
          setTimeout(() => {
            this.setState({ warn: false });
          }, 2000);
        } else {
          this.setState(
            {
              secondaryCategories: resp.data.ordData,
              download: true
            },
            () => {
              let tableArray = this.state.secondaryCategories.map(
                (sec, index) => [
                  [index + 1],
                  [sec.ORDER_ID],
                  [
                    sec.ORDER_DATE.slice(8, 10) +
                      "-" +
                      sec.ORDER_DATE.slice(5, 7) +
                      "-" +
                      sec.ORDER_DATE.slice(0, 4)
                  ],
                  [sec.course ? sec.course.TITLE : "NA"],
                  [
                    <div key={index}>
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold"
                        }}
                      >
                        {sec.NAME
                          ? sec.NAME
                          : sec.user
                          ? (sec.user.FIRST_NAME ? sec.user.FIRST_NAME : "") +
                            " " +
                            (sec.user.LAST_NAME ? sec.user.LAST_NAME : "")
                          : "NA"}
                        ,
                      </span>
                      <br />
                      <span
                        style={{
                          fontSize: "15px",
                          // fontWeight:'bold',
                          color: "blue"
                        }}
                      >
                        {sec.EMAIL
                          ? sec.EMAIL
                          : sec.user
                          ? sec.user.email
                          : "NA"}
                      </span>
                      <br />
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold"
                        }}
                      >
                        {sec.CONTACT_NO
                          ? sec.CONTACT_NO
                          : sec.user
                          ? sec.user.MOBILE
                          : "NA"}
                        ,
                      </span>
                      <br />
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold"
                        }}
                      >
                        {sec.ADDRESS
                          ? sec.ADDRESS
                          : sec.user
                          ? sec.user.ADDRESS_1
                          : "NA"}
                      </span>
                      ,
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold"
                        }}
                      >
                        {sec.CITY ? sec.CITY : sec.user ? sec.user.CITY : "NA"}
                      </span>
                      <br />
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold"
                        }}
                      >
                        {sec.PINCODE
                          ? sec.PINCODE
                          : sec.user
                          ? sec.user.PINCODE
                          : "NA"}
                      </span>
                      {/* <br />
                       <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "red"
                        }}
                      >
                        PW :{" "}
                        {sec.user
                          ? sec.user.DE_PASSWORD
                            ? sec.user.DE_PASSWORD
                            : "NA"
                          : "NA"}
                      </span> */}
                    </div>
                  ],
                  [sec.GRAND_TOTAL ? sec.GRAND_TOTAL : "NA"]
                ]
              );
              tableArray.push({
                total: true,
                colspan: "4",
                amount: (
                  <div>
                    <div>₹{resp.data.grand_total}</div>
                    {/* <div>₹{resp.data.ak_share + "(AK)"}</div> */}
                  </div>
                )
              });
              this.setState({ tableArray });
            }
          );
        }
      });
  };
  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={2}>
            <FormControl
              style={{ marginTop: "12px" }}
              fullWidth
              className={classes.selectFormControl}
            >
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Type
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={this.state.type}
                inputProps={{
                  name: "type",
                  id: "simple-select",
                  onChange: e => {
                    this.setState({ type: e.target.value }, () => {
                      if (this.state.type === "settled") {
                        this.setState({ set: true });
                      } else {
                        this.setState({ set: false });
                      }
                    });
                  }
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value="settled"
                >
                  Settled
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value="pending"
                >
                  Pending
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <h5 style={{ marginTop: "20px", marginBottom: "0px" }}>Filters</h5>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <br />
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                onChange={e => {
                  let year = e._d.getFullYear().toString();
                  let month = (e._d.getMonth() + 101).toString().substring(1);
                  let day = (e._d.getDate() + 100).toString().substring(1);
                  this.setState(
                    {
                      frmDate: year + "/" + month + "/" + day
                    },
                    () => {
                      //console.log(this.state.frmDate);
                    }
                  );
                }}
                inputProps={{
                  placeholder: "From Date"
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <br />
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                onChange={e => {
                  let year = e._d.getFullYear().toString();
                  let month = (e._d.getMonth() + 101).toString().substring(1);
                  let day = (e._d.getDate() + 100).toString().substring(1);
                  this.setState(
                    {
                      toDate: year + "/" + month + "/" + day
                    },
                    () => {
                      //console.log(this.state.toDate);
                    }
                  );
                }}
                inputProps={{
                  placeholder: "To Date"
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <Button
              style={{ position: "relative", top: "13px", right: "20px" }}
              onClick={this.searchReport}
              color="warning"
            >
              Search
            </Button>
            {/* {this.state.download ? (
              <a
                href={
                  "https://admin.akclasses.in/api/income_report_excel?frmdate=" +
                  this.state.frmDate +
                  "&todate=" +
                  this.state.toDate
                }
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button
                  style={{
                    position: "relative",
                    bottom: "37px",
                    left: "70%"
                  }}
                  color="success"
                  onClick={this.handleDownload}
                >
                  Download Report
                </Button>
              </a>
            ) : null} */}
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <h3>Vendors</h3>
                <Table
                  tableHead={[
                    "Sr No.",
                    "Order No",
                    <div key={1} style={{ width: "70px" }}>
                      Order Date
                    </div>,
                    <div key={1} style={{ width: "150px" }}>
                      Product Details
                    </div>,
                    "Student Details",
                    "Grand Total"
                  ]}
                  tableData={this.state.tableArray}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : null}
      </div>
    );
  }
}

export default SingleVendorOrders;
