/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
// import editorConfiguration from "../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AddAlert from "@material-ui/icons/AddAlert";
import Constants from "variables/AppConstants";
let ProgressBar = (props) => {
  return (
    <div className="progress-bar">
      <Filler percentage={props.percentage} />
    </div>
  );
};
let Filler = (props) => {
  return (
    <div className="filler" style={{ width: `${props.percentage}%` }}></div>
  );
};
let fd = new FormData();
class AddAd extends Component {
  state = {
    title: "",
    imgUrl: [],
    subtitle: "",
    description: "",
    redirect: false,
    selectedImage: "",
    fields: {},
    errors: {},
    show: false,
    loading: false,
  };
  // handleValidation() {
  //   let fields = this.state.fields;
  //   let errors = {};
  //   let formIsValid = true;

  //   //Name
  //   if (!fields["name"]) {
  //     formIsValid = false;
  //     errors["name"] = "Cannot be empty";
  //   }

  //   if (typeof fields["name"] !== "undefined") {
  //     if (!fields["name"].match(/^[a-zA-Z]+$/)) {
  //       formIsValid = false;
  //       errors["name"] = "Only letters";
  //     }
  //   }
  //   this.setState({ errors: errors });
  //   return formIsValid;
  // }
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    fd.append("title", this.state.title);
    fd.append("file", this.state.imgUrl);
    fd.append("subtitle", this.state.subtitle);
    fd.append("description", this.state.description);
    fd.append("type", "public");
    axios.post(Constants.postUrls.postAds, fd).then(() => {
      // console.log(resp);
      setTimeout(() => {
        this.setState({
          redirect: true,
        });
      }, 1000);
      this.setState({
        show: true,
        loading: false,
      });
    });
  };
  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/ads"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.show ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Advertisement Added Successfully!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Add Ad</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Title"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ title: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Sub Title"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ subtitle: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <label>Image</label>
                        <br />
                        <input
                          name="imgUrl"
                          onChange={this.handleFile}
                          type="file"
                          required={true}
                          accept="image/*"
                        />
                      </GridItem>
                      <br />
                      <GridItem xs={12} sm={12} md={12}>
                        <label>Description</label>
                        <br />
                        <CKEditor
                          editor={ClassicEditor}
                          // config={editorConfiguration}
                          data={this.state.description}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            //console.log({ data });
                            this.setState({
                              description: data,
                              specificationAlert: false,
                            });
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <br />
                        {this.state.mediaProgress ? (
                          <ProgressBar percentage={this.state.progress} />
                        ) : null}
                      </GridItem>
                      <br />
                      <GridItem xs={12} sm={12} md={4}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Ad"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/ads",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddAd;
