/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Constants from "../variables/Constants";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
// import editorConfiguration from "../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Modal from "react-awesome-modal";
let ProgressBar = (props) => {
  return (
    <div className="progress-bar">
      <Filler percentage={props.percentage} />
    </div>
  );
};
let Filler = (props) => {
  return (
    <div className="filler" style={{ width: `${props.percentage}%` }}></div>
  );
};
let fd = new FormData();
class UpdateFaculty extends Component {
  state = {
    name: "",
    email: "",
    mobile: "",
    img: "",
    frontSide: null,
    visible: false,
    frontSideCrop: {
      unit: "%",
      x: 0,
      y: 0,
      width: 50,
      height: 50,
    },
    city: "",
    state: "",
    pincode: "",
    address1: "",
    address2: "",
    officeNo: "",
    about: "",
    redirect: false,
    max: false,
    notification: false,
    loading: false,
  };
  componentDidMount() {
    let categoryData = this.props.location.state.categoryData;
    //console.log(categoryData);
    this.setState({
      name: categoryData.name,
      email: categoryData.email,
      city: categoryData.city,
      state: categoryData.state,
      pincode: categoryData.pincode,
      address1: categoryData.address_1,
      address2: categoryData.address_2,
      officeNo: categoryData.office_no,
      img: categoryData.image,
      mobile: categoryData.mobile,
      message: "",
    });
  }
  handleFile = (e) => {
    if (e.target.files[0]) {
      this.setState(
        {
          [e.target.name]: e.target.files[0],
        },
        () => {
          this.refForImages(e);
        }
      );
    }
  };
  refForImages = (e) => {
    if (e.target.name === "img") {
      this.setState({
        visible: true,
        frontSide: URL.createObjectURL(this.state.img),
      });
    }
  };
  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };
  onFrontsideCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const frontsideCroppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ frontsideCroppedImageUrl });
    }
  }
  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ frontSideCrop: crop });
  };
  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        // window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }
  handleSubmit = async (e) => {
    console.log(this.state.img);
    console.log(this.state.croppedImageUrl);
    e.preventDefault();
    if (this.state.max === true) {
      this.setState({ max: true });
    } else {
      this.setState({
        loading: true,
      });
      fd.append("name", this.state.name);
      fd.append("email", this.state.email);
      // fd.append("image", this.state.img);
      fd.append("city", this.state.city);
      fd.append("state", this.state.state);
      fd.append("pincode", this.state.pincode);
      fd.append("office_no", this.state.officeNo);
      fd.append("address_1", this.state.address1);
      fd.append("address_2", this.state.address2);
      fd.append("about_us", this.state.about);
      fd.append("mobile", this.state.mobile);
      let categoryData = this.props.location.state.categoryData;
      let categoryId = categoryData.id;
      const frontSideFile = await fetch(this.state.frontsideCroppedImageUrl)
        .then((r) => r.blob())
        .then(
          (blobFile) =>
            new File([blobFile], this.state.frontsideCroppedImageUrl, {
              type: blobFile.type,
            })
        );

      if (this.state.frontsideCroppedImageUrl) {
        fd.append("image", frontSideFile);
      } else {
        fd.append("image", this.state.img);
      }
      axios
        .post(
          "https://admin.akclasses.in/api/faculty/update/" + categoryId,
          fd,
          {
            onUploadProgress: (progressEvent) => {
              this.setState({
                progress: Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                ),
                mediaProgress: true,
              });
              if (this.state.progress === 100) {
                this.setState({ mediaProgress: false });
              }
            },
          }
        )
        .then((resp) => {
          //console.log(resp);
          if (resp.data.status === "success") {
            this.setState({
              notification: true,
              message: resp.data.message,
            });
            setTimeout(() => {
              this.setState({
                notification: false,
                loading: false,
                redirect: true,
              });
            }, 1000);
          }
          if (resp.data.status === "failed") {
            this.setState({
              notification: true,
              message: resp.data.message,
            });
            setTimeout(() => {
              this.setState({
                notification: false,
                loading: false,
              });
            }, 3000);
          }
        });
    }
  };
  // handleFile = (e) => {
  //   this.setState({ [e.target.name]: e.target.files[0] }, () => {
  //     //console.log(this.state.img);
  //   });
  // };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/faculty"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={this.state.message}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Faculty</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Name"
                          id="float"
                          inputProps={{
                            value: this.state.name,
                            required: true,
                            onChange: (e) =>
                              this.setState({ name: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Email"
                          id="float"
                          inputProps={{
                            value: this.state.email,
                            type: "email",
                            onChange: (e) =>
                              this.setState({ email: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Mobile"
                          id="float"
                          inputProps={{
                            value: this.state.mobile,
                            type: "number",
                            required: true,
                            onChange: (e) =>
                              this.setState({ mobile: e.target.value }, () => {
                                if (this.state.mobile.length > 10) {
                                  this.setState({ max: true });
                                } else {
                                  this.setState({ max: false });
                                }
                              }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        {this.state.max ? (
                          <div>
                            <span style={{ color: "red" }}>
                              Max Allowed Characters : 10
                            </span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="City"
                          id="float"
                          inputProps={{
                            type: "text",
                            value: this.state.city,
                            required: true,
                            onChange: (e) =>
                              this.setState({ city: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="State"
                          id="float"
                          inputProps={{
                            type: "text",
                            value: this.state.state,
                            required: true,
                            onChange: (e) =>
                              this.setState({ state: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Pincode"
                          id="float"
                          inputProps={{
                            type: "text",
                            value: this.state.pincode,
                            required: true,
                            onChange: (e) =>
                              this.setState({ pincode: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Address 1"
                          id="float"
                          inputProps={{
                            type: "text",
                            value: this.state.address1,
                            required: true,
                            onChange: (e) =>
                              this.setState({ address1: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Address 2"
                          id="float"
                          inputProps={{
                            type: "text",
                            value: this.state.address2,
                            required: true,
                            onChange: (e) =>
                              this.setState({ address2: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Office No"
                          id="float"
                          inputProps={{
                            type: "number",
                            value: this.state.officeNo,
                            required: true,
                            onChange: (e) =>
                              this.setState({ officeNo: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <label>Image</label>
                        <br />
                        <input
                          id="i1"
                          name="img"
                          size="medium"
                          onChange={this.handleFile}
                          style={{ width: "50%" }}
                          accept="image/*"
                          type="file"
                          helperText="image"
                        />
                        <br />
                        <img
                          width="200px"
                          src={Constants.imgUrl + this.state.img}
                          alt=""
                        />
                        <br />
                      </GridItem>
                      <Modal
                        visible={this.state.visible}
                        width="800"
                        height="800"
                        effect="fadeInRight"
                        // onClose={() => this.closeModal()}

                        onClickAway={() => this.setState({ visible: false })}
                      >
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <Card>
                              <CardBody>
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "150%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: "40%",
                                    height: "40%",
                                    bgcolor: "background.paper",
                                    boxShadow: 24,
                                  }}
                                >
                                  <div style={{}}>
                                    {this.state.frontSide && (
                                      <div>
                                        <ReactCrop
                                          src={this.state.frontSide}
                                          crop={this.state.frontSideCrop}
                                          ruleOfThirds
                                          onImageLoaded={this.onImageLoaded}
                                          onComplete={
                                            this.onFrontsideCropComplete
                                          }
                                          onChange={this.onCropChange}
                                          crossorigin="anonymous"
                                        />
                                      </div>
                                    )}

                                    {this.state.frontsideCroppedImageUrl && (
                                      <div>
                                        <img
                                          alt="Crop"
                                          style={{
                                            maxWidth: "100%",
                                            marginLeft: "1rem",
                                          }}
                                          src={
                                            this.state.frontsideCroppedImageUrl
                                          }
                                        />
                                      </div>
                                    )}
                                  </div>
                                  <div>
                                    {/* <a
                                      href="javascript:void(0);"
                                      onClick={() => this.setState({ visible: false })}
                                    >
                                      Close
                                    </a> */}
                                    <Button
                                      variant="contained"
                                      type="button"
                                      size="small"
                                      color="primary"
                                      style={{ marginLeft: "50px" }}
                                      onClick={() =>
                                        this.setState({ visible: false })
                                      }
                                    >
                                      Send
                                    </Button>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </Modal>

                      <div>
                        <img
                          style={{
                            maxWidth: "100%",
                            marginLeft: "1rem",
                          }}
                          src={this.state.frontsideCroppedImageUrl}
                        />
                      </div>
                      <GridItem xs={12} sm={12} md={12}>
                        <label>About Us</label>
                        <br />
                        <CKEditor
                          editor={ClassicEditor}
                          // config={editorConfiguration}
                          data={this.props.location.state.categoryData.about_us}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            //console.log({ data });
                            this.setState({
                              about: data,
                              specificationAlert: false,
                            });
                          }}
                        />
                        <br />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <br />
                        {this.state.mediaProgress ? (
                          <ProgressBar percentage={this.state.progress} />
                        ) : null}
                      </GridItem>
                    </GridContainer>
                    <br />
                    <GridItem xs={12} sm={12} md={12}>
                      <Button className="" color="warning" type="submit">
                        {this.state.loading ? <Loader /> : "Update Faculty"}
                      </Button>
                      <Link
                        to={{
                          pathname: "/admin/faculty",
                        }}
                      >
                        <Button className="btn-round" color="danger">
                          Cancel
                        </Button>
                      </Link>
                    </GridItem>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateFaculty;
