/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
// import editorConfiguration from "../variables/EditorConfiguration";
// import {CKEditor} from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import katex from "katex";
import "katex/dist/katex.min.css";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Constants from "variables/Constants";
const classes = makeStyles(styles);
export default class UpdateQuestionBank extends Component {
  state = {
    option_value_1: "",
    option_value_2: "",
    option_value_3: "",
    option_value_4: "",
    question: "",
    right_answer: "",
    solution: "",
    redirect: false,
    notification: false,
    loading: false,
    levels: [],
    level: "",
    subjects: [],
    subject: "",
    chapters: [],
    sources: [],
    source: "",
    chapter: "",
    aAlert: false,
    bAlert: false,
    cAlert: false,
    dAlert: false,
    eAlert: false,
    fAlert: false,
    gAlert: false,
    hAlert: false,
    iAlert: false,
    jAlert: false,
    kAlert: false,
    options: ["Option 1", "Option 2", "Option 3", "Option 4"],
  };
  componentDidMount() {
    axios.get(Constants.getUrls.getQuestionBankLevelData).then((resp) => {
      this.setState({
        levels: resp.data,
      });
    });
    axios.get(Constants.getUrls.getQuestionBankSubjectData).then((resp) => {
      this.setState({
        subjects: resp.data,
      });
    });
    axios.get(Constants.getUrls.getQuestionBankChapterData).then((resp) => {
      this.setState({
        chapters: resp.data,
      });
    });

    axios.get(Constants.getUrls.getActivateSources).then((resp) => {
      // console.log(resp.data);
      this.setState({
        sources: resp.data,
      });
    });

    let levelData = this.props.location.state.levelData;
    axios.get(Constants.getUrls.getQuestionBankData).then((resp) => {
      this.setState({
        level: levelData.level_id,
        subject: levelData.subject_id,
        chapter: levelData.chapter_id,
        source: levelData.source_id,
        question: levelData.question,
        option_value_1: levelData.option_value_1,
        option_value_2: levelData.option_value_2,
        option_value_3: levelData.option_value_3,
        option_value_4: levelData.option_value_4,
        right_answer: levelData.right_answer,
        solution: levelData.solution,
      });
    });
  }

  handleSubject = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        axios
          .get(Constants.getUrls.showLevel_with_subject + this.state.level)
          .then((resp) => {
            // console.log(resp.data);
            this.setState({
              subjects: resp.data.subject,
              subject: "",
              chapters: [],
              chapter: "",
            });
          });
      }
    );
  };

  handleChapter = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // console.log(this.state.subject);
        axios
          .get(Constants.getUrls.show_subject + this.state.subject)
          .then((resp) => {
            // console.log(resp.data);
            this.setState({
              chapters: resp.data.chapter,
              chapter: "",
            });
          });
      }
    );
  };

  handleLast = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      cAlert: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.level === "") {
      this.setState({
        aAlert: true,
      });
    } else if (this.state.subject === "") {
      this.setState({
        bAlert: true,
      });
    } else if (this.state.chapter === "") {
      this.setState({
        cAlert: true,
      });
    } else if (this.state.source === "") {
      this.setState({
        kAlert: true,
      });
    } else if (this.state.question === "") {
      this.setState({
        dAlert: true,
      });
    } else if (this.state.option_value_1 === "") {
      this.setState({
        eAlert: true,
      });
    } else if (this.state.option_value_2 === "") {
      this.setState({
        fAlert: true,
      });
    } else if (this.state.option_value_3 === "") {
      this.setState({
        gAlert: true,
      });
    } else if (this.state.option_value_4 === "") {
      this.setState({
        hAlert: true,
      });
    } else if (this.state.right_answer === "") {
      this.setState({
        iAlert: true,
      });
    } else if (this.state.solution === "") {
      this.setState({
        jAlert: true,
      });
    } else {
      this.setState({
        loading: true,
      });
      let payLoad = {
        level_id: this.state.level,
        subject_id: this.state.subject,
        chapter_id: this.state.chapter,
        source_id: this.state.source,
        question: this.state.question,
        option_value_1: this.state.option_value_1,
        option_value_2: this.state.option_value_2,
        option_value_3: this.state.option_value_3,
        option_value_4: this.state.option_value_4,
        right_answer: this.state.right_answer,
        solution: this.state.solution,
      };
      let levelData = this.props.location.state.levelData;
      // console.log(levelData);
      let questionBankId = levelData.id;
      axios
        .post(Constants.postUrls.updateQuestionBank + questionBankId, payLoad)
        .then(() => {
          //console.log(resp);
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        });
    }
  };

  handleChange(content) {
    // console.log(content); //Get Content Inside Editor
    this.setState(
      {
        question: content,
        dAlert: false,
      },
      () => {
        console.log(this.state.question);
      }
    );
  }
  handleChange1(content) {
    // console.log(content); //Get Content Inside Editor
    this.setState(
      {
        option_value_1: content,
        eAlert: false,
      },
      () => {
        console.log(this.state.option_value_1);
      }
    );
  }
  handleChange2(content) {
    // console.log(content); //Get Content Inside Editor
    this.setState(
      {
        option_value_2: content,
        fAlert: false,
      },
      () => {
        console.log(this.state.option_value_2);
      }
    );
  }
  handleChange3(content) {
    // console.log(content); //Get Content Inside Editor
    this.setState(
      {
        option_value_3: content,
        gAlert: false,
      },
      () => {
        console.log(this.state.option_value_3);
      }
    );
  }
  handleChange4(content) {
    // console.log(content); //Get Content Inside Editor
    this.setState(
      {
        option_value_4: content,
        hAlert: false,
      },
      () => {
        console.log(this.state.option_value_4);
      }
    );
  }
  handleChange5(content) {
    // console.log(content); //Get Content Inside Editor
    this.setState(
      {
        solution: content,
        jAlert: false,
      },
      () => {
        console.log(this.state.option_value_4);
      }
    );
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/questionBank"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notifyStatus ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Status Changed!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Question</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Level
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.level}
                            inputProps={{
                              required: true,
                              name: "level",
                              id: "simple-select",
                              onChange: this.handleSubject,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Level
                            </MenuItem>
                            {this.state.levels.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Subject
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.subject}
                            inputProps={{
                              required: true,
                              name: "subject",
                              id: "simple-select",
                              onChange: this.handleChapter,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Level First
                            </MenuItem>
                            {this.state.subjects.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Chapter
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.chapter}
                            inputProps={{
                              required: true,
                              name: "chapter",
                              id: "simple-select",
                              onChange: this.handleLast,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Subject First
                            </MenuItem>
                            {this.state.chapters.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Source
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.source}
                            inputProps={{
                              required: true,
                              name: "source",
                              id: "simple-select",
                              onChange: this.handleLast,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Source
                            </MenuItem>
                            {this.state.sources.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.kAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <br />
                        <label>Question</label>
                        <br />
                        {/* <CKEditor
                                                    required={true}
                                                    editor={ClassicEditor}
                                                    data={this.state.question}
                                                    // config={editorConfiguration}
                                                    //   data={this.props.location.state.levelData.QUESTION}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        //console.log({ data });
                                                        this.setState({
                                                            question: data,
                                                            dAlert: false
                                                        });
                                                    }}
                                                /> */}
                        <div style={{ color: "black" }}>
                          <SunEditor
                            // width="50%"
                            setOptions={{
                              katex: katex,
                              buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize"],
                                // ['paragraphStyle', 'blockquote'],
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "subscript",
                                  "superscript",
                                ],
                                // ["fontColor", "hiliteColor"],
                                ["align", "list", "lineHeight"],
                                ["outdent", "indent"],

                                [
                                  "table",
                                  "horizontalRule",
                                  "link",
                                  "image",
                                  "video",
                                ],
                                ["math"], //You must add the 'katex' library at options to use the 'math' plugin.
                                ["imageGallery"], // You must add the "imageGalleryUrl".
                                ["fullScreen", "showBlocks", "codeView"],
                                ["preview", "print"],
                                ["removeFormat"],

                                ["save", "template"],
                                // '/', Line break
                              ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                              defaultTag: "div",
                              minHeight: "300px",
                              showPathLabel: false,
                              // font: sortedFontOptions
                            }}
                            setContents={this.state.question}
                            onChange={this.handleChange.bind(this)}
                            required={true}
                          />
                        </div>
                        {this.state.dAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <label>Option 1</label>
                        <br />
                        {/* <CKEditor
                                                    required={true}
                                                    editor={ClassicEditor}
                                                    // config={editorConfiguration}
                                                    data={this.state.option_value_1}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        //console.log({ data });
                                                        this.setState({
                                                            option_value_1: data,
                                                            eAlert: false
                                                        });
                                                    }}
                                                /> */}
                        <div style={{ color: "black" }}>
                          <SunEditor
                            // width="50%"
                            setOptions={{
                              katex: katex,
                              buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize"],
                                // ['paragraphStyle', 'blockquote'],
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "subscript",
                                  "superscript",
                                ],
                                // ["fontColor", "hiliteColor"],
                                ["align", "list", "lineHeight"],
                                ["outdent", "indent"],

                                [
                                  "table",
                                  "horizontalRule",
                                  "link",
                                  "image",
                                  "video",
                                ],
                                ["math"], //You must add the 'katex' library at options to use the 'math' plugin.
                                ["imageGallery"], // You must add the "imageGalleryUrl".
                                ["fullScreen", "showBlocks", "codeView"],
                                ["preview", "print"],
                                ["removeFormat"],

                                ["save", "template"],
                                // '/', Line break
                              ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                              defaultTag: "div",
                              minHeight: "300px",
                              showPathLabel: false,
                              // font: sortedFontOptions
                            }}
                            setContents={this.state.option_value_1}
                            onChange={this.handleChange1.bind(this)}
                            required={true}
                          />
                        </div>
                        {this.state.eAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <label>Option 2</label>
                        <br />
                        {/* <CKEditor
                                                    required={true}
                                                    editor={ClassicEditor}
                                                    // config={editorConfiguration}
                                                    data={this.state.option_value_2}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        //console.log({ data });
                                                        this.setState({
                                                            option_value_2: data,
                                                            fAlert: false
                                                        });
                                                    }}
                                                /> */}
                        <div style={{ color: "black" }}>
                          <SunEditor
                            // width="50%"
                            setOptions={{
                              katex: katex,
                              buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize"],
                                // ['paragraphStyle', 'blockquote'],
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "subscript",
                                  "superscript",
                                ],
                                // ["fontColor", "hiliteColor"],
                                ["align", "list", "lineHeight"],
                                ["outdent", "indent"],

                                [
                                  "table",
                                  "horizontalRule",
                                  "link",
                                  "image",
                                  "video",
                                ],
                                ["math"], //You must add the 'katex' library at options to use the 'math' plugin.
                                ["imageGallery"], // You must add the "imageGalleryUrl".
                                ["fullScreen", "showBlocks", "codeView"],
                                ["preview", "print"],
                                ["removeFormat"],

                                ["save", "template"],
                                // '/', Line break
                              ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                              defaultTag: "div",
                              minHeight: "300px",
                              showPathLabel: false,
                              // font: sortedFontOptions
                            }}
                            setContents={this.state.option_value_2}
                            onChange={this.handleChange2.bind(this)}
                            required={true}
                          />
                        </div>
                        {this.state.fAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <label>Option 3</label>
                        <br />
                        {/* <CKEditor
                                                    required={true}
                                                    editor={ClassicEditor}
                                                    // config={editorConfiguration}
                                                    data={this.state.option_value_3}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        //console.log({ data });
                                                        this.setState({
                                                            option_value_3: data,
                                                            gAlert: false
                                                        });
                                                    }}
                                                /> */}
                        <div style={{ color: "black" }}>
                          <SunEditor
                            // width="50%"
                            setOptions={{
                              katex: katex,
                              buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize"],
                                // ['paragraphStyle', 'blockquote'],
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "subscript",
                                  "superscript",
                                ],
                                // ["fontColor", "hiliteColor"],
                                ["align", "list", "lineHeight"],
                                ["outdent", "indent"],

                                [
                                  "table",
                                  "horizontalRule",
                                  "link",
                                  "image",
                                  "video",
                                ],
                                ["math"], //You must add the 'katex' library at options to use the 'math' plugin.
                                ["imageGallery"], // You must add the "imageGalleryUrl".
                                ["fullScreen", "showBlocks", "codeView"],
                                ["preview", "print"],
                                ["removeFormat"],

                                ["save", "template"],
                                // '/', Line break
                              ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                              defaultTag: "div",
                              minHeight: "300px",
                              showPathLabel: false,
                              // font: sortedFontOptions
                            }}
                            setContents={this.state.option_value_3}
                            onChange={this.handleChange3.bind(this)}
                            required={true}
                          />
                        </div>
                        {this.state.gAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <label>Option 4</label>
                        <br />
                        {/* <CKEditor
                                                    required={true}
                                                    editor={ClassicEditor}
                                                    // config={editorConfiguration}
                                                    data={this.state.option_value_4}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        //console.log({ data });
                                                        this.setState({
                                                            option_value_4: data,
                                                            hAlert: false
                                                        });
                                                    }}
                                                /> */}
                        <div style={{ color: "black" }}>
                          <SunEditor
                            // width="50%"
                            setOptions={{
                              katex: katex,
                              buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize"],
                                // ['paragraphStyle', 'blockquote'],
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "subscript",
                                  "superscript",
                                ],
                                // ["fontColor", "hiliteColor"],
                                ["align", "list", "lineHeight"],
                                ["outdent", "indent"],

                                [
                                  "table",
                                  "horizontalRule",
                                  "link",
                                  "image",
                                  "video",
                                ],
                                ["math"], //You must add the 'katex' library at options to use the 'math' plugin.
                                ["imageGallery"], // You must add the "imageGalleryUrl".
                                ["fullScreen", "showBlocks", "codeView"],
                                ["preview", "print"],
                                ["removeFormat"],

                                ["save", "template"],
                                // '/', Line break
                              ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                              defaultTag: "div",
                              minHeight: "300px",
                              showPathLabel: false,
                              // font: sortedFontOptions
                            }}
                            setContents={this.state.option_value_4}
                            onChange={this.handleChange4.bind(this)}
                            required={true}
                          />
                        </div>
                        {this.state.hAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Right Answer
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.right_answer}
                            inputProps={{
                              name: "right_answer",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  right_answer: e.target.value,
                                });
                              },
                            }}
                          >
                            {this.state.options.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.iAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <label>Solution</label>
                        <br />
                        {/* <CKEditor
                                                    editor={ClassicEditor}
                                                    // config={editorConfiguration}
                                                    data={this.state.solution}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        //console.log({ data });
                                                        this.setState({
                                                            solution: data,
                                                            jAlert: false
                                                        });
                                                    }}
                                                /> */}
                        <div style={{ color: "black" }}>
                          <SunEditor
                            // width="50%"
                            setOptions={{
                              katex: katex,
                              buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize"],
                                // ['paragraphStyle', 'blockquote'],
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "subscript",
                                  "superscript",
                                ],
                                // ["fontColor", "hiliteColor"],
                                ["align", "list", "lineHeight"],
                                ["outdent", "indent"],

                                [
                                  "table",
                                  "horizontalRule",
                                  "link",
                                  "image",
                                  "video",
                                ],
                                ["math"], //You must add the 'katex' library at options to use the 'math' plugin.
                                ["imageGallery"], // You must add the "imageGalleryUrl".
                                ["fullScreen", "showBlocks", "codeView"],
                                ["preview", "print"],
                                ["removeFormat"],

                                ["save", "template"],
                                // '/', Line break
                              ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                              defaultTag: "div",
                              minHeight: "300px",
                              showPathLabel: false,
                              // font: sortedFontOptions
                            }}
                            setContents={this.state.solution}
                            onChange={this.handleChange5.bind(this)}
                            required={true}
                          />
                        </div>
                        {this.state.jAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Update Questions"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/questionbank",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>

                      {this.state.notification ? (
                        <div style={{ position: "relative", left: "600px" }}>
                          <SnackbarContent
                            message={"Question Updated!"}
                            icon={AddAlert}
                            color="success"
                          />
                        </div>
                      ) : null}
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}
