/* eslint-disable react/prop-types */
import React, { Component } from "react";
// import { MultiSelect } from "react-multi-select-component";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Modal from "react-awesome-modal";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
// import editorConfiguration from "../variables/EditorConfiguration";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Datetime from "react-datetime";

import Constants from "variables/Constants";
import { error } from "assets/img/clint-mckoy.jpg";
import { TestSeries } from "views/TestSeries";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { MultiSelect } from "react-multi-select-component";
// import "react-multi-select-component/style.css";
let ProgressBar = (props) => {
  return (
    <div className="progress-bar">
      <Filler percentage={props.percentage} />
    </div>
  );
};
let Filler = (props) => {
  return (
    <div className="filler" style={{ width: `${props.percentage}%` }}></div>
  );
};
const classes = makeStyles(styles);
let fd = new FormData();
const facultyIds = [];
class AddCoursesMaster extends Component {
  state = {
    category: "",
    title: "",
    sku: "",
    twoxprice: "",
    threexprice: "",
    duration: "",
    faculty: [],
    facultyWithName: "",
    vendorCommission: 0,
    facultyCommission: 0,
    facultyId: "",
    facultyArr: [],
    levels: [],
    streams: [],
    level: "",
    stream: "",
    cod: "",
    codPdf: [],
    akshare: 100,
    facultyshare: "",
    proDesc: "",
    courseDetails: "",
    specification: "",
    productId: "",
    googlesheetdetails: "",
    index: [],
    img: [],
    confirmation: ["Yes", "No"],
    confirm: "No",
    testSeries: [],
    test: [],
    thumb: [],
    cashback: "",
    discount: "",
    gcLink: "",
    gwLink: "",
    gaLink: "",
    demoLink1: "",
    demoLink2: "",
    demoLink3: "",
    demoLink4: "",
    offValue: "",
    subject: "",
    offType: "",
    payment_gateway: "",
    offTypeArray: ["percent", "amount"],
    paymentGatewayArray: ["razorpay", "phonepe"],
    courses: [],
    attempts: [],
    batches: [],
    subjects: [],
    redirect: false,
    atLeast: false,
    notification: false,
    displayVariant: false,
    gatewayAlert: false,
    aAlert: false,
    bAlert: false,
    cAlert: false,
    dAlert: false,
    visible: false,
    eAlert: false,
    fAlert: false,
    langAlert: false,
    loading: false,
    batchAlert: false,
    languages: ["Hindi", "English"],
    language: "",
    dropShow: false,
    type: ["Normal", "Combo"],
    combo_type: "",
    batch: [],
    batches: [],
    batchwings: "",
    batch_wings: [],
    batch_id: [],
    attempt: "",
    getSubjectFilter: [],
    subject_filter_id: 0,
    test_seriesId: [],
    amenities: [],
    selected_amenites: [],
    amenity_id_array: [],
    ordermailtemplate: "",
    shippingmailtemplate: "",
    orderTemplates: [],
    shippingTemplates: [],
    expiry_date: "",
  };
  componentDidMount() {
    this.setState({
      orderTemplates: [],
      shippingTemplates: [],
    },() => {
        axios.get(Constants.getUrls.getmailTemplate + "?type=Order").then((respOrd) => {
        console.log(respOrd.data.data);
        this.setState(
          {
            orderTemplates: respOrd.data.data,
          },
          () => {
            console.log(this.state.orderTemplates);
          }
        );
      });

      axios.get(Constants.getUrls.getmailTemplate + "?type=Shipping").then((respShip) => {
        console.log(respShip.data.data);
        this.setState(
          {
            shippingTemplates: respShip.data.data,
          },
          () => {
            console.log(this.state.shippingTemplates);
          }
        );
      });
    });
    
    axios
      .get("https://admin.akclasses.in/api/courseCategoriesR")
      .then((resp) => {
        // console.log(resp);
        this.setState(
          {
            courses: resp.data,
          },
          () => {
            console.log(this.state.courses);
          }
        );
      });
    
    axios.get(Constants.getUrls.getAmenities + "?per_page=150").then((res) => {
      console.log(res);
      res.data.data.map((val) => {
        // console.log(val);
        this.state.amenities.push({
          label: val?.title,
          value: val?.id,
        });
        this.setState({
          amenities: this.state.amenities,
        });
      });
    });
    axios
      .get("https://admin.akclasses.in/api/activeAttempts")
      .then((respattempt) => {
        console.log(respattempt);
        this.setState(
          {
            attempts: respattempt.data,
          },
          () => {
            console.log(this.state.attempts);
          }
        );
      });
    axios
      .get(
        "https://admin.akclasses.in/api/courses?type=Test_Series&per_page=10"
      )
      .then((resp) => {
        // console.log(resp);
        resp.data.data.map((val) => {
          // console.log(val);
          this.state.testSeries.push({
            label: val?.TITLE,
            value: val?.ID,
          });
          this.setState({
            testSeries: this.state.testSeries,
          });
        });
      });
    axios.get("https://admin.akclasses.in/api/facultyR").then((resp) => {
      //console.log(resp.data);
      this.setState({
        facultyArr: resp.data,
      });
    });
    // axios.get("https://admin.akclasses.in/api/batchWings").then((resp)=>{
    //   console.log(resp)
    //   this.setState({
    //     batchwings: resp.data
    //   }, ()=>{ console.log(this.state.batchwings)})
    // })
  }

  handleBatch = (e) => {
    this.setState({
      batch: e.target.value,
    });
  };

  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] }, () => {
      //console.log(this.state.img);
    });
  };
  openModal() {
    this.setState({
      visible: true,
    });
  }

  closeModal() {
    this.setState({
      visible: false,
    });
  }

  handleAttempt = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleLevel = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        aAlert: false,
      },
      () => {
        //console.log(this.state.category);
        axios
          .get(
            "https://admin.akclasses.in/api/showCategory_with_level/" +
              this.state.category
          )
          .then((resp) => {
            console.log(resp.data);
            this.setState({
              levels: resp.data.level,
            });
          });
      }
    );
  };

  handleStream = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        cAlert: false,
      },
      () => {
        //console.log(this.state.level);
        axios
          .get("https://admin.akclasses.in/api/level/" + this.state.level)
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              streams: resp.data.stream,
            });
          });
      }
    );
  };
  handleSubject = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        dAlert: false,
      },
      () => {
        // console.log(this.state.stream);
        axios
          .get("https://admin.akclasses.in/api/stream/" + this.state.stream)
          .then((resp) => {
            // console.log(resp.data);
            this.setState({
              subjects: resp.data.stream.subject_masters,
            });
          });
      }
    );
  };
  handleWings = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fAlert: false,
      },
      () => {
        // console.log(this.state.subject);
        axios
          .get(
            Constants.getUrls.subjectFilter +
              "?subject_id=" +
              this.state.subject
          )
          .then((res) => {
            // console.log("subjectfilter", res);
            this.setState({ getSubjectFilter: res.data[0] });
          });
      }
    );
  };
  handlesubjectFilter = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fAlert: false,
      },
      () => {
        // console.log(this.state.subject);
        axios
          .get(
            Constants.getUrls.subjectFilter +
              "?subject_id=" +
              this.state.subject
          )
          .then((res) => {
            console.log("subjectfilter", res);
            this.setState({ getSubjectFilter: res.data[0] }, () => {
              console.log(this.state.getSubjectFilter);
            });
          });
      }
    );
  };
  attemptWiseWings = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        axios
          .get(
            "https://admin.akclasses.in/api/subjectWings/" + this.state.subject
          )
          .then((resp) => {
            this.setState({
              batch_wings: resp.data.wings,
            });
          });
      }
    );
  };
  handleBatch = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fAlert: false,
      },
      () => {
        console.log(this.state.batchwings);
        axios
          .get(
            "https://admin.akclasses.in/api/wingBatch/" + this.state.batchwings
          )
          .then((resp) => {
            console.log(resp);
            this.setState({
              batches: [],
            });
            resp.data.data.map((v) => {
              if (v.batch) {
                this.state.batches.push({
                  label: v.batch
                    ? v.batch.batch_name +
                      " " +
                      "(" +
                      v.batch.attempt?.title +
                      ")"
                    : "",
                  value: v.batch ? v.batch.id : "",
                });
              }
            });
            this.setState(
              {
                batches: this.state.batches,
              },
              () => {
                console.log(this.state.batches);
              }
            );
          });
      }
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();

    //  this.state.batch.map((v)=>{
    //    this.state.batch_id.push({ "value": v.value})
    //  })
    //  this.setState({
    //    batch_id: this.state.batch_id
    //  }, ()=>{ console.log(this.state.batch_id )})
    let facultyDetails = [];
    this.state.faculty.forEach((v) => {
      facultyDetails.push({ fid: v.fid, fshare: v.fshare });
    });
    if (facultyDetails.length === 0) {
      this.setState({ atLeast: true });
    } else if (this.state.category === "") {
      this.setState({
        aAlert: true,
      });
    } else if (this.state.faculty === "") {
      this.setState({
        bAlert: true,
      });
    } else if (this.state.level === "") {
      this.setState({
        cAlert: true,
      });
    } else if (this.state.stream === "") {
      this.setState({
        dAlert: true,
      });
    } else if (this.state.offType === "") {
      this.setState({
        eAlert: true,
      });
    } else if (this.state.payment_gateway === "") {
      this.setState({
        gatewayAlert: true,
      });
    } else if (this.state.language === "") {
      this.setState({
        langAlert: true,
      });
    } else if (this.state.batch === "") {
      this.setState({
        batchAlert: true,
      });
    } else if (this.state.subject === "") {
      this.setState({
        fAlert: true,
      });
    } else {
      this.setState({
        loading: true,
      });
      this.state.selected_amenites.map((item) => {
        this.state.amenity_id_array.push(item.value);
      });
      this.setState({
        amenity_id_array: this.state.amenity_id_array,
      });
      // return;
      this.state.batch.map((v) => {
        this.state.batch_id.push(v.value);
      });
      this.setState({
        batch_id: this.state.batch_id,
      });

      // if (this.state.confirm === "Yes") {
      //   this.state.test.map(
      //     (v) => {
      //       this.state.test_seriesId.push(v.value);
      //     },
      //     () => {
      //       // console.log(this.state.test_seriesId);
      //     }
      //   );
      // }

      fd.append("faculty", facultyDetails[0].fid);
      fd.append("ak_share", this.state.akshare);
      fd.append("discount_amount", this.state.discount);
      fd.append("cash_amount", this.state.cashback);
      fd.append("category", this.state.category);
      fd.append("sku", this.state.sku);
      fd.append("title", this.state.title);
      fd.append("short_description", this.state.courseDetails);
      fd.append("description", this.state.proDesc);
      fd.append("duration", this.state.duration);
      fd.append("price", "");
      // if (this.state.confirm === "Yes") {
      //   fd.append("multi_include_test_series", this.state.test_seriesId);
      // }
      // fd.append("combo_type", facultyDetails.length > 1 ? "Combo" : "Normal");
      fd.append("combo_type", this.state.combo_type);
      fd.append("price_1", "");
      fd.append("image_path", this.state.img);
      fd.append("thumb_image", this.state.thumb);
      fd.append("what_learn", this.state.specification);
      fd.append("syllabus_pdf", this.state.index);
      fd.append("common_link", this.state.gcLink);
      fd.append("window_link", this.state.gwLink);
      fd.append("android_link", this.state.gaLink);
      fd.append("demo_link", this.state.demoLink1);
      fd.append("demo_link1", this.state.demoLink2);
      fd.append("demo_link2", this.state.demoLink3);
      fd.append("demo_link3", this.state.demoLink4);
      fd.append("google_sheet_details", this.state.googlesheetdetails);
      fd.append("level", this.state.level);
      fd.append("stream", this.state.stream);
      fd.append("offer_type", this.state.offType);
      fd.append("payment_gateway", this.state.payment_gateway);
      fd.append("ordermailtemplate", this.state.ordermailtemplate);
      fd.append("shippingmailtemplate", this.state.shippingmailtemplate);
      fd.append("language", this.state.language);
      // fd.append("batch", this.state.batch);
      fd.append("subject_master_id", this.state.subject);
      fd.append("subject_filter_id", this.state.subject_filter_id);
      fd.append("value", this.state.offValue);
      fd.append("curriculum_doubts", this.state.cod);
      fd.append("curriculum_doubts_pdf", this.state.codPdf);
      fd.append("type", "COURSES");
      fd.append("faculty_detail", JSON.stringify(facultyDetails));
      fd.append("batch_wings_id", this.state.batchwings);
      fd.append("batch_id", JSON.stringify(this.state.batch_id));
      fd.append("attempt", this.state.attempt);
      fd.append("include_test_series", this.state.test_seriesId);
      fd.append("amenity_ids", JSON.stringify(this.state.amenity_id_array));
      fd.append("expiry_date", this.state.expiry_date);
      console.log("fd", fd);
      // if (this.state.confirm === "Yes") {
      //   axios
      //     .get("https://admin.akclasses.in/api/course/create-multiple", fd, {
      //       onUploadProgress: (progressEvent) => {
      //         this.setState({
      //           progress: Math.round(
      //             (progressEvent.loaded / progressEvent.total) * 100
      //           ),
      //           mediaProgress: true,
      //         });
      //       },
      //     })
      //     .then((resp) => {
      //       console.log(resp);
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
      // }
      // return
      axios
        .post(Constants.postUrls.addCourse, fd, {
          onUploadProgress: (progressEvent) => {
            this.setState({
              progress: Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              ),
              mediaProgress: true,
            });
          },
        })
        .then((resp) => {
          console.log("addcourse", resp);
          this.setState({
            productId: resp.data.data.ID,
            displayVariant: true,
          });
        });
    }
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/coursesmaster"} />;
    } else {
      if (this.state.displayVariant) {
        return (
          <Redirect
            to={{
              pathname: "/admin/coursevariants/" + this.state.productId,
            }}
          />
        );
      } else {
        return (
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                    <h4 style={{ color: "black" }}>Course Master</h4>
                  </CardHeader>
                  <CardBody>
                    <form onSubmit={this.handleSubmit}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Course Type
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.combo_type}
                              inputProps={{
                                name: "combo_type",
                                id: "simple-select",
                                onChange: (e) => {
                                  this.setState(
                                    {
                                      combo_type: e.target.value,
                                      aAlert: false,
                                    },
                                    () => {
                                      this.state.combo_type === "Combo"
                                        ? this.setState({
                                            dropShow: true,
                                          })
                                        : this.setState({ dropShow: false });
                                    }
                                  );
                                },
                              }}
                            >
                              {this.state.type.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v}
                                >
                                  {v}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.aAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            required={true}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Category
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.category}
                              inputProps={{
                                name: "category",
                                id: "simple-select",
                                onChange: this.handleLevel,
                              }}
                            >
                              {this.state.courses.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v.ID}
                                >
                                  {v.CATEGORY}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.aAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Level
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.level}
                              inputProps={{
                                required: true,
                                name: "level",
                                id: "simple-select",
                                onChange: this.handleStream,
                              }}
                            >
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value=""
                              >
                                Select Category First
                              </MenuItem>
                              {this.state.levels.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v.id}
                                >
                                  {v.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.cAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Stream
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.stream}
                              inputProps={{
                                required: true,
                                name: "stream",
                                id: "simple-select",
                                onChange: this.handleSubject,
                                // //console.log(e.target.value);
                              }}
                            >
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value=""
                              >
                                Select Level First
                              </MenuItem>
                              {this.state.streams.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v.id}
                                >
                                  {v.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.dAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Subject
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.subject}
                              inputProps={{
                                required: true,
                                name: "subject",
                                id: "simple-select",
                                onChange: this.handleWings,
                                // e => {
                                //   this.setState({
                                //     fAlert: false,
                                //     subject: e.target.value
                                //   });
                                // }
                                // //console.log(e.target.value);
                              }}
                            >
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value=""
                              >
                                Select Stream First
                              </MenuItem>
                              {this.state.subjects.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v.subject_master.id}
                                >
                                  {v.subject_master.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.fAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Subject Filter
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.subject_filter_id}
                              inputProps={{
                                required: true,
                                name: "subject_filter_id",
                                id: "simple-select",
                                onChange: this.handlesubjectFilter,
                              }}
                            >
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value=""
                              >
                                Select Subject First
                              </MenuItem>
                              {this.state.getSubjectFilter.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v.id}
                                >
                                  {v.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.fAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            required={true}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Attempt
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.attempt}
                              inputProps={{
                                name: "attempt",
                                id: "simple-select",
                                onChange: this.attemptWiseWings,
                              }}
                            >
                              {this.state.attempts.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v.id}
                                >
                                  {v.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.aAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Batch Wings
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.batchwings}
                              inputProps={{
                                required: true,
                                name: "batchwings",
                                id: "simple-select",
                                onChange: this.handleBatch,

                                // e => {
                                //   this.setState({
                                //     fAlert: false,
                                //     selected_wing: e.target.value
                                //   });
                                // }
                                // //console.log(e.target.value);
                              }}
                            >
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value=""
                              >
                                Select Attempt First
                              </MenuItem>
                              {this.state.batch_wings.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v?.batch_wing?.id}
                                >
                                  {v?.batch_wing?.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.fAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "20px", zIndex: "100" }}
                            required={true}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Select Batch
                            </InputLabel>

                            <span> Select Batches </span>
                            <MultiSelect
                              disableSearch
                              options={this.state.batches}
                              name="batch"
                              value={this.state.batch}
                              onChange={(data) => {
                                this.setState(
                                  {
                                    batch: data,
                                  },
                                  () => {
                                    console.log(this.state.batch);
                                  }
                                );
                              }}
                            />
                          </FormControl>

                          {this.state.batchAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "20px", zIndex: "100" }}
                            required={true}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Select Amenities
                            </InputLabel>

                            <span> Select Amenities </span>
                            <MultiSelect
                              disableSearch
                              options={this.state.amenities}
                              name="amenities"
                              value={this.state.selected_amenites}
                              onChange={(data) => {
                                this.setState({
                                  selected_amenites: data,
                                });
                              }}
                            />
                          </FormControl>

                          {this.state.batchAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="SKU"
                            id="float"
                            inputProps={{
                              required: true,
                              onChange: (e) =>
                                this.setState({ sku: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Title"
                            id="float"
                            inputProps={{
                              required: true,
                              onChange: (e) =>
                                this.setState({ title: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Duration"
                            id="float"
                            inputProps={{
                              required: true,
                              onChange: (e) =>
                                this.setState({ duration: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        {/* <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Price"
                            id="float"
                            inputProps={{
                              type: "number",
                              onChange: e =>
                                this.setState({ twoxprice: e.target.value })
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem> */}
                        {/* <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="3 Times Price"
                            id="float"
                            inputProps={{
                              type: "number",
                              onChange: e =>
                                this.setState({ threexprice: e.target.value })
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem> */}

                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Ak Classes Share"
                            id="float"
                            inputProps={{
                              type: "float",
                              disabled: true,
                              value: this.state.akshare,
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Offer Value"
                            id="float"
                            inputProps={{
                              type: "number",
                              required: true,
                              onChange: (e) =>
                                this.setState({ offValue: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Offer Type
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.offType}
                              inputProps={{
                                required: true,
                                name: "offType",
                                id: "simple-select",
                                onChange: (e) => {
                                  this.setState({
                                    offType: e.target.value,
                                    eAlert: false,
                                  });
                                  // //console.log(e.target.value);
                                },
                              }}
                            >
                              {this.state.offTypeArray.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v}
                                >
                                  {v}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.eAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Payment Gateway
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.payment_gateway}
                              inputProps={{
                                required: true,
                                name: "payment_gateway",
                                id: "simple-select",
                                onChange: (e) => {
                                  this.setState({
                                    payment_gateway: e.target.value,
                                    gatewayAlert: false,
                                  });
                                  // //console.log(e.target.value);
                                },
                              }}
                            >
                              {this.state.paymentGatewayArray.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v}
                                >
                                  {v}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.gatewayAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Order Mail Template
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.ordermailtemplate}
                              inputProps={{
                                name: "ordermailtemplate",
                                id: "simple-select",
                                onChange: (e) => {
                                  this.setState({
                                    ordermailtemplate: e.target.value,
                                  });
                                },
                              }}
                            >
                              {
                                this.state.orderTemplates.length > 0 ? (
                                this.state.orderTemplates.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v.id}
                                >
                                  {v.title}
                                </MenuItem>
                                )
                                )
                                )
                                : ""
                              }
                            </Select>
                          </FormControl>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Shipping Mail Template
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.shippingmailtemplate}
                              inputProps={{
                                name: "shippingmailtemplate",
                                id: "simple-select",
                                onChange: (e) => {
                                  this.setState({
                                    shippingmailtemplate: e.target.value,
                                  });
                                },
                              }}
                            >
                              {
                                this.state.shippingTemplates.length > 0 ? (
                                this.state.shippingTemplates.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v.id}
                                >
                                  {v.title}
                                </MenuItem>
                              ))
                            ) : "" }
                            </Select>
                          </FormControl>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Featured Courses
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.featured_course}
                              inputProps={{
                                required: true,
                                name: "featured_course",
                                id: "simple-select",
                                onChange: (e) => {
                                  this.setState({
                                    featured_course: e.target.value,
                                  });
                                  // //console.log(e.target.value);
                                },
                              }}
                            >
                              <MenuItem value="no">NO</MenuItem>
                              <MenuItem value="yes">YES </MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Want to Add Test Series?
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.confirm}
                              inputProps={{
                                required: true,
                                name: "confirm",
                                id: "simple-select",
                                onChange: (e) => {
                                  this.setState(
                                    {
                                      confirm: e.target.value,
                                    },
                                    () => {
                                      if (this.state.confirm === "No") {
                                        this.setState({ test: "" });
                                      }
                                    }
                                  );
                                  // //console.log(e.target.value);
                                },
                              }}
                            >
                              {this.state.confirmation.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v}
                                >
                                  {v}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Select Language
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.language}
                              inputProps={{
                                required: true,
                                name: "language",
                                id: "simple-select",
                                onChange: (e) => {
                                  this.setState({
                                    language: e.target.value,
                                  });
                                  // //console.log(e.target.value);
                                },
                              }}
                            >
                              {this.state.languages.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v}
                                >
                                  {v}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.langAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>

                        {this.state.confirm === "Yes" ? (
                          <GridItem xs={12} sm={12} md={4}>
                            <FormControl
                              style={{ marginTop: "20px", zIndex: "100" }}
                              required={true}
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                              >
                                Select Batch
                              </InputLabel>

                              <span> Select TestSeries </span>
                              {/* <MultiSelect
                                disableSearch
                                options={this.state.testSeries}
                                name="batch"
                                value={this.state.test}
                                onChange={(e) => {
                                  this.setState({
                                    test: e.target.value,
                                  });
                                }}
                              /> */}
                              <MultiSelect
                                disableSearch
                                options={this.state.testSeries}
                                name="test"
                                value={this.state.test}
                                onChange={(data) => {
                                  // console.log(data);
                                  this.setState(
                                    {
                                      test: data,
                                    },
                                    () => {
                                      this.state.test.map((v) => {
                                        this.state.test_seriesId.push(v.value);
                                      });
                                      this.setState({
                                        test_seriesId: this.state.test_seriesId,
                                      });
                                    }
                                  );
                                }}
                              />
                            </FormControl>

                            {/* <FormControl
                              style={{ marginTop: "12px" }}
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                              >
                                Test Series
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={this.state.test}
                                inputProps={{
                                  required: true,
                                  name: "test",
                                  id: "simple-select",
                                  onChange: (e) => {
                                    this.setState({
                                      test: e.target.value,
                                    });
                                    // //console.log(e.target.value);
                                  },
                                }}
                              >
                                {this.state.testSeries.map((v, i) => (
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    key={i}
                                    value={v.ID}
                                  >
                                    {v.TITLE}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl> */}
                          </GridItem>
                        ) : null}
                        {this.state.faculty.length >= 1 ? (
                          <GridItem xs={12} sm={12} md={12}>
                            <table
                              style={{
                                width: "100%",
                                border: "1px black solid",
                                borderCollapse: "collapse",
                              }}
                            >
                              <tr
                                style={{
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                              >
                                <th
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  Sr.No
                                </th>
                                <th
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  Faculty
                                </th>
                                <th
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  Faculty Share
                                </th>
                                <th
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  Action
                                </th>
                              </tr>
                              {this.state.faculty.map((v, i) => (
                                <tr
                                  style={{
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                  key={i}
                                >
                                  <td
                                    style={{
                                      padding: "10px",
                                      textAlign: "center",
                                      border: "1px black solid",
                                      borderCollapse: "collapse",
                                    }}
                                  >
                                    {i + 1}
                                  </td>
                                  <td
                                    style={{
                                      padding: "4px 10px 4px 10px",
                                      textAlign: "center",
                                      border: "1px black solid",
                                      borderCollapse: "collapse",
                                    }}
                                  >
                                    {v.fName}
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      textAlign: "center",
                                      border: "1px black solid",
                                      borderCollapse: "collapse",
                                    }}
                                  >
                                    {v.fshare}
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      textAlign: "center",
                                      border: "1px black solid",
                                      borderCollapse: "collapse",
                                    }}
                                  >
                                    <input
                                      style={{
                                        backgroundColor: "red",
                                        borderRadius: "10%",
                                        border: "none",
                                        padding: "10px",
                                        color: "white",
                                      }}
                                      onClick={() => {
                                        this.state.faculty.splice(i, 1);
                                        facultyIds.splice(i, 1);
                                        this.setState(
                                          { faculty: this.state.faculty },
                                          () => {
                                            if (
                                              this.state.faculty.length === 0
                                            ) {
                                              this.setState({
                                                atLeast: true,
                                                akshare: 100,
                                                facultyCommission: 0,
                                              });
                                            } else {
                                              let facultyCommission = 0;
                                              this.state.faculty.forEach(
                                                (v) => {
                                                  facultyCommission += v.fshare;
                                                }
                                              );
                                              let akshare =
                                                100 - facultyCommission;
                                              this.setState({
                                                atLeast: false,
                                                akshare,
                                                facultyCommission,
                                              });
                                            }
                                          }
                                        );
                                      }}
                                      type="button"
                                      value="Delete"
                                    />
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </GridItem>
                        ) : null}
                        <GridItem xs={12} sm={12} md={12}>
                          <Button
                            onClick={() => {
                              this.setState({
                                visible: true,
                              });
                            }}
                            className="btn-round"
                            color="success"
                          >
                            Add Faculty
                          </Button>
                        </GridItem>
                        {/* <GridItem xs={12} sm={12} md={12}>
                          <label>Product Description</label>
                          <br />
                          <CKEditor
                            editor={ClassicEditor}
                            // config={editorConfiguration}
                            data={this.state.proDesc}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                proDesc: data
                              });
                            }}
                          />
                        </GridItem> */}
                        <GridItem xs={12} sm={12} md={12}>
                          <label>Course Details</label>
                          <br />
                          <CKEditor
                            editor={ClassicEditor}
                            // config={editorConfiguration}
                            data={this.state.courseDetails}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                courseDetails: data,
                              });
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <label>Index of Video Lecture</label>
                          <br />
                          <CKEditor
                            editor={ClassicEditor}
                            // config={editorConfiguration}
                            data={this.state.specification}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                specification: data,
                              });
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <label>Upload Index of Video Lecture PDF :</label>
                          <br />
                          <input
                            name="index"
                            onChange={this.handleFile}
                            type="file"
                            accept="application/pdf"
                            // required={true}
                          />
                        </GridItem>
                        <br />
                        <GridItem xs={12} sm={12} md={4}>
                          <label>Upload Image</label>
                          <br />
                          <input
                            id="img"
                            name="img"
                            onChange={this.handleFile}
                            accept="image/*"
                            type="file"
                            required={true}
                          />
                          {/* <span style={{ color: "red" }}>
                            Image should be 750px X 180px.
                          </span> */}
                        </GridItem>
                        <br />
                        <GridItem xs={12} sm={12} md={4}>
                          <label>Thumb Image</label>
                          <br />
                          <input
                            // required={true}
                            accept="image/*"
                            name="thumb"
                            onChange={this.handleFile}
                            type="file"
                          />
                        </GridItem>
                        {/* <GridItem xs={12} sm={12} md={12}>
                          <label>Curriculum of Doubt File</label>
                          <br />
                          <input
                            name="codPdf"
                            onChange={this.handleFile}
                            type="file"
                          />
                        </GridItem> */}
                        {/* <GridItem xs={12} sm={12} md={12}>
                          <label>Curriculum of Doubt</label>
                          <br />
                          <CKEditor
                            editor={ClassicEditor}
                            data={this.state.cod}
                            // config={editorConfiguration}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                cod: data
                              });
                            }}
                          />
                        </GridItem> */}
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Cashback Amount"
                            id="float"
                            inputProps={{
                              type: "number",
                              required: true,
                              onChange: (e) =>
                                this.setState({ cashback: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Discount Amount"
                            id="float"
                            inputProps={{
                              type: "number",
                              required: true,
                              onChange: (e) =>
                                this.setState({ discount: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        {/* <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Update Google Drive Link"
                            id="float"
                            inputProps={{
                              onChange: e =>
                                this.setState({ gcLink: e.target.value })
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem> */}
                        {/* <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Windows Google Drive Link"
                            id="float"
                            inputProps={{
                              onChange: e =>
                                this.setState({ gwLink: e.target.value })
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem> */}
                        {/* <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Android Google Drive Link"
                            id="float"
                            inputProps={{
                              onChange: e =>
                                this.setState({ gaLink: e.target.value })
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem> */}
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Demo Link 1"
                            id="float"
                            inputProps={{
                              onChange: (e) =>
                                this.setState({ demoLink1: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Demo Link 2"
                            id="float"
                            inputProps={{
                              onChange: (e) =>
                                this.setState({ demoLink2: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Google Sheet Details"
                            id="float"
                            inputProps={{
                              onChange: (e) =>
                                this.setState({
                                  googlesheetdetails: e.target.value,
                                }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        <span
                          style={{
                            marginLeft: "0px",
                            color: "green",
                            fontSize: "12px",
                            fontWeight: "400",
                          }}
                        >
                          {
                            'Sample Link Details: {"sheet_id":"14Tnrl4tTxgVTD-QEKIKsOIgSeAIC0OE1V9-6J37xkUA","sheet_name":"ak"}'
                          }
                        </span>

                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                        <label>Expiry Date</label>
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={(e) => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  expiry_date: year + "/" + month + "/" + day,
                                },
                                () => {
                                  //console.log(this.state.expiry_date);
                                }
                              );
                            }}
                            inputProps={{
                              required: true,
                              placeholder: "Expiry Date",
                            }}
                          />
                        </FormControl>
                      </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                        <span
                          style={{
                            marginLeft: "0px",
                            color: "blue",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          <a href="https://docs.google.com/spreadsheets/d/1HqNeRiHzV05r1ThDksuM4_nynLnLUh0kHJv5pgKRf1k/edit#gid=0" target="_blank" rel="noopener noreferrer">Click To Download Sample Spreadsheet</a>
                        </span>
                        </GridItem>

                        

                        {/* <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Demo Link 3"
                            id="float"
                            inputProps={{
                              onChange: e =>
                                this.setState({ demoLink3: e.target.value })
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem> */}
                        {/* <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Demo Link 4"
                            id="float"
                            inputProps={{
                              onChange: e =>
                                this.setState({ demoLink4: e.target.value })
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem> */}
                        <GridItem xs={12} sm={12} md={12}>
                          <br />
                          {this.state.mediaProgress ? (
                            <ProgressBar percentage={this.state.progress} />
                          ) : null}
                        </GridItem>
                        <br />
                        <GridItem xs={12} sm={12} md={6}>
                          {this.state.atLeast ? (
                            <div>
                              <span style={{ color: "red" }}>
                                *Add Atleast One Faculty
                              </span>
                            </div>
                          ) : null}
                          <Button className="" color="warning" type="submit">
                            {this.state.loading ? (
                              <Loader />
                            ) : (
                              "Add Course Master"
                            )}
                          </Button>
                          <Link
                            to={{
                              pathname: "/admin/coursesmaster",
                            }}
                          >
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <Modal
              visible={this.state.visible}
              width="700"
              height="175"
              effect="fadeInUp"
              onClickAway={() => this.closeModal()}
            >
              <GridContainer style={{ paddingLeft: "5px", paddingTop: "20px" }}>
                <GridItem xs={12} sm={12} md={5}>
                  <FormControl fullWidth>
                    <label>Faculty</label>
                    <select
                      style={{ padding: "11px" }}
                      name="facultyId"
                      value={this.state.facultyId}
                      onChange={(e) => {
                        this.setState({ facultyId: e.target.value }, () => {
                          this.state.facultyArr.forEach((v) => {
                            if (parseInt(this.state.facultyId) === v.id) {
                              this.setState({ facultyWithName: v.name });
                            }
                          });
                        });
                      }}
                    >
                      <option value="">Select</option>
                      {this.state.facultyArr.map((v, i) => (
                        <option key={i} value={v.id}>
                          {v.name}
                        </option>
                      ))}
                    </select>
                  </FormControl>
                  {this.state.bAlert ? (
                    <div>
                      <span style={{ color: "red" }}>*required</span>
                    </div>
                  ) : null}
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <CustomInput
                    labelText="Faculty Share"
                    id="float"
                    inputProps={{
                      type: "float",
                      required: true,
                      value: this.state.facultyshare,
                      onChange: (e) =>
                        this.setState({ facultyshare: e.target.value }),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <Button
                    style={{ marginTop: "20px" }}
                    onClick={() => {
                      if (facultyIds.includes(this.state.facultyId) === false) {
                        facultyIds.push(this.state.facultyId);
                        this.state.faculty.push({
                          fid: this.state.facultyId,
                          fName: this.state.facultyWithName,
                          fshare: parseFloat(this.state.facultyshare),
                        });
                        this.setState(
                          {
                            faculty: this.state.faculty,
                            facultyshare: "",
                            facultyId: "",
                          },
                          () => {
                            if (this.state.faculty.length === 0) {
                              this.setState({
                                akshare: 100,
                                atLeast: true,
                                facultyCommission: 0,
                              });
                            } else {
                              let facultyCommission = 0;
                              this.state.faculty.forEach((v) => {
                                facultyCommission += v.fshare;
                              });
                              let akshare = 100 - facultyCommission;
                              this.setState({
                                atLeast: false,
                                akshare,
                                facultyCommission,
                              });
                            }
                          }
                        );
                      }
                      this.setState({
                        visible: false,
                      });
                    }}
                    className="btn-round"
                    color="success"
                  >
                    Add
                  </Button>
                </GridItem>
              </GridContainer>
            </Modal>
          </div>
        );
      }
    }
  }
}

export default AddCoursesMaster;
