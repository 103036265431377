import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import { TextField } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import editorConfiguration from "variables/EditorConfiguration";
import Datetime from "react-datetime";
import Constants from "variables/Constants";
import { MultiSelect } from "react-multi-select-component";
import init from "../Helper/WindowToken";
const classes = makeStyles(styles);

export default class Update_FaceToFace_Batch extends Component {
  state = {
    loading: false,
    notification: false,
    message: "",
    batch_name: "",
    display_name: "",
    batch_start_date: "",
    batch_end_date: "",
    total_batch_days: "",
    rowId: "",
    category_id: "",
    level_id: "",
    stream_id: "",
    allCategories: [],
    allStreams: [],
    allLevels: [],
    allFaceSubjects: [],
    seletedSubjects: [],
    seletedTestSeries: [],
    allTestSeries: [],
    cAlert: false,
    bAlert: false,
    aAlert: false,
    redirect: false,
    id: "",
    selectedTestSeries: [],
  };
  componentDidMount() {
    // All Categories API
    // if (init() === "success") {
    axios
      .get(
        "https://admin.akclasses.in/api/facetofaceSubject?per_page=50" +
          "&type=COURSE"
      )
      .then((resp) => {
        // console.log(resp);
        resp.data.data.map((item) => {
          this.state.allFaceSubjects.push({
            label: item?.title,
            value: item?.id,
          });
        });
        this.setState(
          {
            allFaceSubjects: this.state.allFaceSubjects,
          },
          () => {
            console.log(this.state.allFaceSubjects);
            const categoryData = this.props.location.state;
            console.log(categoryData);
            // this.state.
            console.log(categoryData.categoryData.face_to_face_subject_id);
            if (categoryData.categoryData.face_to_face_subject_id !== null) {
              let subIds = JSON.parse(
                categoryData.categoryData.face_to_face_subject_id
              );
              this.state.allFaceSubjects.map((val) => {
                subIds.map((item) => {
                  if (item === val?.id)
                    this.state.seletedSubjects.push({
                      label: val?.title,
                      value: val?.id,
                    });
                });
              });
            }
            this.setState(
              {
                id: categoryData.categoryData?.id,
                category_id: categoryData.categoryData?.category_id,
                level_id: categoryData.categoryData?.level_id,
                stream_id: categoryData.categoryData?.stream_id,
                batch_name: categoryData.categoryData?.batch_name,
                display_name: categoryData.categoryData?.display_name,
                batch_start_date: categoryData.categoryData?.batch_start_date,
                batch_end_date: categoryData.categoryData?.batch_end_date,
                total_batch_days: categoryData.categoryData?.total_batch_days,
                seletedSubjects: this.state.seletedSubjects,
              },
              () => {
                console.log(this.state.seletedSubjects);
                axios
                  .get("https://admin.akclasses.in/api/faceCat?per_page=100")
                  .then((resp) => {
                    this.setState({
                      allCategories: resp.data.data,
                    });
                  });
                // All Level API

                axios
                  .get(
                    "https://admin.akclasses.in/api/faceToFaceLevel?per_page=100&type=COURSE"
                  )
                  .then((resp) => {
                    this.setState({
                      allLevels: resp.data.data,
                    });
                  });

                // All Streams API

                axios
                  .get(
                    "https://admin.akclasses.in/api/facetofaceStream?type=COURSE&per_page=100"
                  )
                  .then((resp) => {
                    this.setState({
                      allStreams: resp.data.data,
                    });
                  });

                if (init() === "success") {
                  axios
                    .get(
                      "https://admin.akclasses.in/api/facetofaceSubject?per_page=50" +
                        "&type=COURSE"
                    )
                    .then((resp) => {
                      // console.log(resp);
                      resp.data.data.map((item) => {
                        this.state.allFaceSubjects.push({
                          label: item?.title,
                          value: item?.id,
                        });
                      });
                      this.setState({
                        allFaceSubjects: this.state.allFaceSubjects,
                      });
                    });
                }

                axios
                  .get(
                    Constants.getUrls.getCourses +
                      "?type=Test_Series&per_page=50"
                  )
                  .then((resp) => {
                    console.log(resp);
                    const allTestSeries = resp.data.data.map((item) => ({
                      label: item?.TITLE,
                      value: item?.ID,
                    }));

                    this.setState(
                      {
                        allTestSeries,
                      },
                      () => {
                        console.log(this.state.allTestSeries);
                        if (categoryData.categoryData.test_series_id !== null) {
                          const testIds = JSON.parse(
                            categoryData.categoryData.test_series_id
                          );
                          const selectedTestSeries = allTestSeries.filter(
                            (val) => testIds.includes(val.value)
                          );

                          this.setState({
                            selectedTestSeries,
                          });
                        }
                      }
                    );
                  });
              }
            );
          }
        );
      });
  }
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    this.setState({ loading: true });
    let subject_ids = [];
    this.state.seletedSubjects.map((item) => {
      subject_ids.push(item.value);
    });
    let testseries_ids = [];
    this.state.selectedTestSeries.map((item) => {
      testseries_ids.push(item.value);
    });
    let payload = {
      category_id: this.state.category_id,
      level_id: this.state.level_id,
      stream_id: this.state.stream_id,
      batch_name: this.state.batch_name,
      display_name: this.state.display_name,
      batch_start_date: this.state.batch_start_date,
      batch_end_date: this.state.batch_end_date,
      total_batch_days: this.state.total_batch_days,
      face_to_face_subject_id: subject_ids,
      test_series_id: testseries_ids,
    };

    if (this.state.category_id === "") {
      return this.setState({ cAlert: true });
    }
    if (this.state.level_id === "") {
      return this.setState({ aAlert: true });
    }
    if (this.state.stream_id === "") {
      return this.setState({ bAlert: true });
    }

    axios
      .post(Constants.postUrls.updateFtoFBatch + "/" + this.state.id, payload)
      .then((resp) => {
        // window.location.reload()
        console.log(resp);
        if (resp.data.status === "success") {
          this.setState({
            loading: false,
            notification: true,
            message: "Batch created Successfuly!",
          });
          setTimeout(() => {
            this.setState({
              redirect: true,
              notification: false,
            });
          }, 3000);
        }
      });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/facetofacebatch"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Batch Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Batch</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Category
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.category_id}
                            inputProps={{
                              name: "category",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  category_id: e.target.value,
                                  cAlert: false,
                                });
                                // //console.log(e.target.value);
                              },
                            }}
                          >
                            {this.state.allCategories.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.category}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Level
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.level_id}
                            inputProps={{
                              name: "level_id",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  level_id: e.target.value,
                                  aAlert: false,
                                });
                                // //console.log(e.target.value);
                              },
                            }}
                          >
                            {this.state.allLevels.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Stream
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.stream_id}
                            inputProps={{
                              name: "stream_id",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  stream_id: e.target.value,
                                  bAlert: false,
                                });
                                // //console.log(e.target.value);
                              },
                            }}
                          >
                            {this.state.allStreams.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>{" "}
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Batch"
                          id="float"
                          inputProps={{
                            name: "batch_name",
                            value: this.state.batch_name,
                            required: true,
                            onChange: this.handleInput,
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Display Name"
                          id="float"
                          inputProps={{
                            name: "display_name",
                            value: this.state.display_name,
                            required: true,
                            onChange: this.handleInput,
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      {/* <GridItem xs={12} sm={4} md={6}>
                        <FormControl
                          style={{ marginTop: "20px", zIndex: "100" }}
                          required={true}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Select Subject
                          </InputLabel>

                          <span> Select Subject </span>
                          <MultiSelect
                            disableSearch
                            options={this.state.allFaceSubjects}
                            name="batch"
                            value={this.state.seletedSubjects}
                            onChange={(data) => {
                              this.setState(
                                {
                                  seletedSubjects: data,
                                },
                                () => {
                                  console.log(this.state.seletedSubjects);
                                }
                              );
                            }}
                          />
                        </FormControl>
                      </GridItem> */}
                      <GridItem xs={12} sm={4} md={6}>
                        <FormControl
                          style={{ marginTop: "20px", zIndex: "100" }}
                          required={true}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Select Test Series
                          </InputLabel>

                          <span> Select Test Series </span>
                          <MultiSelect
                            disableSearch
                            options={this.state.allTestSeries}
                            name="batch"
                            value={this.state.selectedTestSeries}
                            onChange={(data) => {
                              this.setState(
                                {
                                  selectedTestSeries: data,
                                },
                                () => {
                                  console.log(this.state.selectedTestSeries);
                                }
                              );
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={4} md={6}>
                        Start Date
                        <br />
                        <TextField
                          type="date"
                          labelText="Date-Time"
                          name="batch_start_date"
                          value={this.state.batch_start_date}
                          id="float"
                          inputProps={{
                            // value: this.state.batch_start_date,
                            name: "batch_start_date",
                            required: true,
                            onChange: (e) => {
                              this.setState({
                                batch_start_date: e.target.value,
                              });
                            },
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={6}>
                        End Date
                        <br />
                        <TextField
                          type="date"
                          labelText="Date-Time"
                          name="batch_end_date"
                          value={this.state.batch_end_date}
                          id="float"
                          inputProps={{
                            // value: this.state.batch_end_date,
                            required: true,
                            onChange: (e) => {
                              this.setState({
                                batch_end_date: e.target.value,
                              });
                            },
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Total Batch Days"
                          id="float"
                          inputProps={{
                            name: "total_batch_days",
                            value: this.state.total_batch_days,
                            required: true,
                            onChange: this.handleInput,
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <Button
                        type="submit"
                        className="btn-round"
                        color="warning"
                      >
                        {this.state.loading ? <Loader /> : "Update Batch"}
                      </Button>
                      <Link
                        to={{
                          pathname: "/admin/facetofacebatch",
                        }}
                      >
                        <Button className="btn-round" color="danger">
                          Cancel
                        </Button>
                      </Link>
                    </GridItem>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}
