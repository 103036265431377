import axios from "axios";
import React, { Component } from "react";
import Constants from "variables/Constants";
import GridContainer from "components/Grid/GridContainer.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Card from "components/Card/Card.js";
import AddAlert from "@material-ui/icons/AddAlert";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import { Assignment } from "@material-ui/icons";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Modal from "react-awesome-modal";
import Loader from "components/Loader";

import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const classes = makeStyles(styles);
const perPages = ["10", "25", "50", "100"];

export default class SubjectFilter extends Component {
  state = {
    getSubjectFilter: [],
  };
  componentDidMount() {
    this.getSubjectsFilter();
  }
  getSubjectsFilter = () => {
    axios.get(Constants.getUrls.subjectFilter).then((res) => {
      console.log("subjectfilter", res);
      this.setState({ getSubjectFilter: res.data[0] }, () => {
        console.log(this.state.getSubjectFilter);
      });
    });
  };
  handlePage = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        axios
          .get(
            "https://admin.akclasses.in/api/subject_master/paginate_index?per_page=" +
              this.state.per_page +
              "&type=Course"
          )
          .then((resp) => {
            //console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                secondaryCategories: resp.data.data,
                total: resp.data.total,
                from: resp.data.from,
                to: resp.data.to,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };
  handleSelect = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const payload = {
          status: this.state.status,
        };
        axios
          .post(
            "https://admin.akclasses.in/api/subject_master/status/" + id,
            payload
          )
          .then(() => {
            //console.log(res);
            this.setState({
              notifyStatus: true,
            });
            setTimeout(() => {
              this.setState(
                {
                  notifyStatus: false,
                  fromInc: 1,
                  active: 1,
                  toInc: 5,
                },
                () => {
                  this.getSubjects();
                }
              );
            }, 1000);
          });
      }
    );
  };
  handleModal = (sec) => {
    this.setState({
      rowId: sec.id,
      visibleDelete: true,
    });
  };
  handleDelete = (id) => {
    console.log(id);
    this.setState({ delLoading: true });
    axios.get(Constants.postUrls.deleteSubjectFilter + id).then(() => {
      this.setState({
        notifyDelete: true,
        delLoading: false,
      });
      setTimeout(() => {
        this.setState({
          visibleDelete: false,
        });
        this.getSubjectsFilter();
      }, 2000);
    });
  };
  render() {
    return (
      <div>
        {" "}
        <GridContainer>
          {this.state.notifyStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Status Changed!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <FormControl
                  style={{
                    position: "absolute",
                    right: "10%",
                    width: "85px",
                  }}
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    s
                    key={1}
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={this.state.per_page}
                    inputProps={{
                      name: "per_page",
                      id: "simple-select",
                      onChange: (e) => {
                        this.handlePage(e);
                      },
                    }}
                  >
                    {perPages.map((v, i) => (
                      <MenuItem
                        key={i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={v}
                      >
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <h3>Subejct Filter</h3>
                <Link to="/admin/addSubjectFilter">
                  <Button className="" color="warning">
                    Add Subejct Filter
                  </Button>
                </Link>
                <Table
                  tableHead={["Sr No.", "Title", "Status", "Action", "Delete"]}
                  tableData={this.state.getSubjectFilter.map((sec, index) => [
                    [index + 1],
                    [sec.title],

                    [
                      <Select
                        key={index}
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={sec.status}
                        inputProps={{
                          name: "status",
                          id: "simple-select",
                          onChange: (e) => {
                            this.handleSelect(e, sec.id);
                          },
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="active"
                        >
                          ACTIVE
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="inactive"
                        >
                          INACTIVE
                        </MenuItem>
                      </Select>,
                    ],
                    [
                      <Link
                        key={index}
                        to={{
                          pathname: "/admin/updateSubjectFilter/" + sec.id,
                          state: { subjectData: sec },
                        }}
                      >
                        <Button className="btn-round" color="info">
                          Update
                        </Button>
                      </Link>,
                    ],
                    [
                      <Button
                        key={index}
                        onClick={() => {
                          this.handleModal(sec);
                        }}
                        className="btn-round"
                        color="danger"
                      >
                        Delete
                      </Button>,
                    ],
                  ])}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Modal
          visible={this.state.visibleDelete}
          width="600"
          height="100"
          effect="fadeInUp"
          onClickAway={() => this.setState({ visibleDelete: false })}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  <div>
                    <h3>Are You Sure You Want to Delete It?</h3>
                    {this.state.notifyDelete ? (
                      <div>
                        <span style={{ color: "green" }}>
                          Subject Deleted Successfuly!
                        </span>
                      </div>
                    ) : null}
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <br />
                        <Button
                          className=""
                          color="warning"
                          onClick={() => {
                            this.handleDelete(this.state.rowId);
                          }}
                        >
                          {this.state.delLoading ? <Loader /> : "Yes"}
                        </Button>
                        <Button
                          className=""
                          color="danger"
                          onClick={() => {
                            this.setState({ visibleDelete: false });
                          }}
                        >
                          No
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Modal>
      </div>
    );
  }
}
