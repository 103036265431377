/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Card from "components/Card/Card.js";
import EditIcon from "@material-ui/icons/Edit";
import CardHeader from "components/Card/CardHeader.js";
import axios from "axios";
import Loader from "components/Loader";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
// import editorConfiguration from "../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
const classes = makeStyles(styles);
class UpdateSubject extends Component {
  state = {
    heading: "",
    defGoal: "",
    time: "",
    image: "",
    totalMarks: "",
    correctMarks: "",
    deductedMarks: "",
    category: "",
    testCategory: [],
    redirect: false,
    imgUpdated: false,
    notification: false,
    loading: false,
    examtype: "",
    files: "",
  };
  componentDidMount() {
    axios
      .get("https://admin.akclasses.in/api/coursesForFilter?type=Test_Series")
      .then((resp) => {
        //console.log(resp.data);
        this.setState({
          testCategory: resp.data,
        });
      });
    let categoryData = this.props.location.state.categoryData;
    console.log(categoryData);

    this.setState({
      heading: categoryData.TITLE,
      image: categoryData.image,
      defGoal: categoryData.DESCRIPTION,
      time: categoryData.NO_OF_MINUTES,
      totalMarks: categoryData.total_marks,
      correctMarks: categoryData.currect_ans,
      deductedMarks: categoryData.wrong_ans,
      category: categoryData.REF_COURSE_ID,
      examtype: categoryData.exam_type,
    });
  }
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files[0], imgUpdated: true });
  };
  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    let categoryData = this.props.location.state.categoryData;
    let categoryId = categoryData.ID;
    let fd = new FormData();
    fd.append("ref_course_id", this.state.category);
    fd.append("title", this.state.heading);
    fd.append("description", this.state.defGoal);
    fd.append("total_marks", this.state.totalMarks);
    fd.append("currect_ans", this.state.correctMarks);
    fd.append("wrong_ans", this.state.deductedMarks);
    fd.append("no_of_minutes", this.state.time);
    fd.append("exam_type", this.state.examtype);
    fd.append("file", this.state.files);

    if (this.state.imgUpdated) {
      fd.append("image", this.state.image);
    }
    axios
      .post("https://admin.akclasses.in/api/subject/update/" + categoryId, fd)
      .then(() => {
        //console.log(resp);
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/subjects"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Subject Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Subject</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Test Series
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.category}
                            inputProps={{
                              name: "category",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  category: e.target.value,
                                });
                                // //console.log(e.target.value);
                              },
                            }}
                          >
                            {this.state.testCategory.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.ID}
                              >
                                {v.TITLE}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Subject Title"
                          inputProps={{
                            value: this.state.heading,
                            required: true,
                            onChange: (e) =>
                              this.setState({ heading: e.target.value }, () => {
                                //console.log(this.state.heading);
                              }),
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Paper Timing(in mins)"
                          inputProps={{
                            value: this.state.time,
                            type: "number",
                            required: true,
                            onChange: (e) =>
                              this.setState({ time: e.target.value }, () => {
                                //console.log(this.state.heading);
                              }),
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Total Marks"
                          inputProps={{
                            value: this.state.totalMarks,
                            type: "number",
                            required: true,
                            onChange: (e) =>
                              this.setState({ totalMarks: e.target.value }),
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Correct Answer Marks"
                          inputProps={{
                            value: this.state.correctMarks,
                            type: "number",
                            required: true,
                            onChange: (e) =>
                              this.setState({ correctMarks: e.target.value }),
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Deducted Marks"
                          inputProps={{
                            value: this.state.deductedMarks,
                            type: "number",
                            required: true,
                            onChange: (e) =>
                              this.setState({ deductedMarks: e.target.value }),
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Paper Type
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.examtype}
                            inputProps={{
                              name: "examtype",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState(
                                  {
                                    examtype: e.target.value,
                                    // aAlert: false
                                  },
                                  () => {
                                    console.log(this.state.examtype);
                                  }
                                );
                                // //console.log(e.target.value);
                              },
                            }}
                          >
                            {/* {this.state.testCategory.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.ID}
                              >
                                {v.TITLE}
                              </MenuItem>
                            ))} */}
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value="subjective"
                            >
                              Subjective
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value="objective"
                            >
                              Objective
                            </MenuItem>
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      {this.state.examtype === "subjective" ? (
                        <GridItem>
                          <div>
                            <label> Select File </label>
                            <br />
                            <input
                              name="files"
                              onChange={this.handleFile}
                              type="file"
                              accept=".pdf"
                            />
                          </div>
                        </GridItem>
                      ) : null}

                      <GridItem xs={12} sm={12} md={12}>
                        <label>Image</label>
                        <br />
                        <input
                          name="image"
                          onChange={this.handleFile}
                          type="file"
                          accept="image/*"
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <label>Short Description</label>
                        <br />
                        <CKEditor
                          required={true}
                          editor={ClassicEditor}
                          // config={editorConfiguration}
                          data={
                            this.props.location.state.categoryData.DESCRIPTION
                          }
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            //console.log({ data });
                            this.setState({
                              defGoal: data,
                              specificationAlert: false,
                            });
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" type="submit" color="info">
                          {this.state.loading ? <Loader /> : "Update Subject"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/subjects",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateSubject;
