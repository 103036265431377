/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Constants from "../variables/Constants";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Modal from "react-awesome-modal";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
// import editorConfiguration from "../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { MultiSelect } from "react-multi-select-component";
import Datetime from "react-datetime";
import { error } from "assets/img/clint-mckoy.jpg";
import Geocode from "geocode";

const classes = makeStyles(styles);
let facultyIds = [];
const selectId = window.location.pathname.split("/")[3];
// //console.log(selectId);
let ProgressBar = (props) => {
  return (
    <div className="progress-bar">
      <Filler percentage={props.percentage} />
    </div>
  );
};
let Filler = (props) => {
  return (
    <div className="filler" style={{ width: `${props.percentage}%` }}></div>
  );
};
let fd = new FormData();
class UpdateCoursesMaster extends Component {
  state = {
    category: "",
    title: "",
    sku: "",
    confirmation: ["Yes", "No"],
    confirm: "No",
    testSeries: [],
    test_filter: [],
    test: [],
    vendorCommission: 0,
    facultyCommission: 0,
    displayVariant: false,
    atLeast: false,
    twoxprice: "",
    threexprice: "",
    duration: "",
    faculty: [],
    facultyWithName: "",
    facultyId: "",
    akshare: 100,
    subject: "",
    productId: "",
    facultyArr: [],
    facultyshare: "",
    proDesc: "",
    courseDetails: "",
    specification: "",
    index: "",
    img: "",
    thumb: "",
    cashback: "",
    discount: "",
    gcLink: "",
    gwLink: "",
    gaLink: "",
    demoLink1: "",
    demoLink2: "",
    demoLink3: "",
    demoLink4: "",
    subjects: [],
    levels: [],
    streams: [],
    offValue: "",
    offType: "",
    payment_gateway: "",
    offTypeArray: ["percent", "amount"],
    paymentGatewayArray: ["razorpay", "phonepe"],
    attempts: [],
    attempt: "",
    level: "",
    stream: "",
    cod: "",
    codPdf: "",
    googlesheetdetails: "",
    courses: [],
    redirect: false,
    notification: false,
    loading: false,
    languages: ["Hindi", "English"],
    language: "",
    type: ["Normal", "Combo"],
    combo_type: "",
    batch: [],
    batches: [],
    batchwings: "",
    batch_wing: [],
    batch_id: [],
    getSubjectFilter: [],
    subject_filter: "",
    subject_filter_id: "",
    // test_series: ,
    amenities: [],
    selected_amenites: [],
    amenity_id_array: [],
    test_seriesId: [],
    deviceName: "",
    ipAddress: "",
    address: "",
    ordermailtemplate: "",
    shippingmailtemplate: "",
    templates: [],
    expiry_date: "",
    orderTemplates: [],
    shippingTemplates: [],
  };

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     deviceName: '',
  //     ipAddress: '',
  //   };
  // }

  componentDidMount() {
    const selectId = window.location.pathname.split("/")[3];
    // //console.log(selectId);
    if (this.props.location.state.directVariant === true) {
      this.setState({
        productId: this.props.location.state.categoryData.ID,
        displayVariant: true,
      });
    } else {
      // axios.get(Constants.getUrls.getBatches).then(resp => {
      //   // //console.log(resp);

      //   resp.data.data.forEach(v=>{
      //     this.state.batches.push({
      //       label: v.batch_name,
      //       value: v.id
      //     })
      //     this.setState({
      //       batches: this.state.batches
      //     })
      //   })
      // });
      axios.get(Constants.getUrls.getmailTemplate + "?type=Order").then((respOrd) => {
        console.log(respOrd.data.data);
        this.setState(
          {
            orderTemplates: respOrd.data.data,
          },
          () => {
            console.log(this.state.orderTemplates);
          }
        );
      });

      axios.get(Constants.getUrls.getmailTemplate + "?type=Shipping").then((respShip) => {
        console.log(respShip.data.data);
        this.setState(
          {
            shippingTemplates: respShip.data.data,
          },
          () => {
            console.log(this.state.shippingTemplates);
          }
        );
      });

      facultyIds = [];
      axios
        .get("https://admin.akclasses.in/api/activeAttempts")
        .then((respattempt) => {
          // //console.log(respattempt);
          this.setState(
            {
              attempts: respattempt.data,
            },
            () => {
              // //console.log(this.state.attempts);
            }
          );
        });
      axios
        .get("https://admin.akclasses.in/api/courseCategoriesR")
        .then((resp) => {
          //console.log(resp.data);
          this.setState({
            courses: resp.data,
          });
        });
      axios
        .get(Constants.getUrls.getAmenities + "?per_page=150")
        .then((res) => {
          //console.log(res);
          res.data.data.map((val) => {
            // //console.log(val);
            this.state.amenities.push({
              label: val?.title,
              value: val?.id,
            });
            this.setState({
              amenities: this.state.amenities,
            });
          });
        });
      axios
        .get(
          "https://admin.akclasses.in/api/courses?type=Test_Series&per_page=10"
        )
        .then((resp) => {
          // //console.log(resp);
          resp.data.data.map((v) => {
            // //console.log(v);
            this.state.testSeries.push({
              label: v?.TITLE,
              value: v?.ID,
            });
          });
          this.setState(
            {
              testSeries: this.state.testSeries,
              // testSeries: resp.data.data,
            },
            () => {
              //console.log(this.state.testSeries);
            }
          );
        });

      axios.get("https://admin.akclasses.in/api/facultyR").then((resp) => {
        // //console.log(resp.data);
        this.setState({
          facultyArr: resp.data,
        });
      });

      // axios.get("https://admin.akclasses.in/api/batchwings" + id)
      // axios
      //   .get("https://admin.akclasses.in/api/index_without_paginate?type=Course")
      //   .then(resp => {
      //     ////console.log(resp.data);
      //     this.setState({
      //       levels: resp.data
      //     });
      //   });
      // axios
      //   .get("https://admin.akclasses.in/api/stream_without_paginate?type=Course")
      //   .then(resp => {
      //     ////console.log(resp.data);
      //     this.setState({
      //       streams: resp.data
      //     });
      //   });
      // axios
      //   .get(
      //     "https://admin.akclasses.in/api/subject_master/without_paginate_index?type=Course"
      //   )
      //   .then(resp => {
      //     ////console.log(resp.data);
      //     this.setState({
      //       subjects: resp.data
      //     });
      //   });
      let categoryData = this.props.location.state.categoryData;
      console.log(categoryData);

      if (
        categoryData.include_test_series !== null ||
        categoryData.multi_include_test_series !== null
      ) {
        this.setState({
          confirm: "Yes",
        });
      } else {
        this.setState({
          confirm: "No",
        });
      }

      let productId = categoryData.ID;
      axios
        .get(
          "https://admin.akclasses.in/api/facultydetail?product_id=" + productId
        )
        .then((resp) => {
          // //console.log(resp.data);

          this.setState({ faculty: resp.data }, () => {
            if (this.state.faculty.length === 0) {
              facultyIds = [];
              this.setState({
                akshare: 100,
                // atLeast: true,
                facultyCommission: 0,
              });
            } else {
              let facultyCommission = 0;
              this.state.faculty.forEach((v) => {
                facultyIds.push(parseInt(v.id));
                facultyCommission += parseInt(v.faculty_share);
              });
              let akshare = 100 - facultyCommission;
              this.setState({
                // atLeast: false,
                akshare,
                facultyCommission,
              });
            }

            // //console.log(facultyIds);
          });
        });
      // //console.log(categoryData);

      this.setState(
        {
          category: categoryData.course_category.ID,
          offType: categoryData.OFFER_TYPE,
          payment_gateway: categoryData.payment_gateway,
          ordermailtemplate: categoryData.ordermailtemplate,
          shippingmailtemplate: categoryData.shippingmailtemplate,

          offValue: categoryData.VALUE,
          stream: categoryData.STREAM_ID,
          level: categoryData.LEVEL_ID,
          productId: categoryData.ID,
          title: categoryData.TITLE,
          sku: categoryData.SKU,
          twoxprice: categoryData.PRICE,
          threexprice: categoryData.PRICE_1,
          duration: categoryData.DURATION,
          akshare: categoryData.AKCLASSES_SHARE
            ? categoryData.AKCLASSES_SHARE
            : 100,
          facultyshare: categoryData.FACULTY_SHARE,
          proDesc: categoryData.DESCRIPTION,
          courseDetails: categoryData.SHORT_DESCRIPTION,
          specification: categoryData.WHAT_LEARN,
          index: categoryData.SYLLABUS_PDF,
          img: categoryData.IMAGE_PATH,
          thumb: categoryData.THUMB_IMAGE_PATH,
          cashback: categoryData.CASHBACK_AMOUNT,
          discount: categoryData.DISCOUNT_AMOUNT,
          gcLink: categoryData.COMMON_GD_LINK,
          gwLink: categoryData.WINDOW_GD_LINK,
          subject: categoryData.subject_master_id,
          demoLink1: categoryData.DEMO_LINK,
          demoLink2: categoryData.DEMO_LINK1,
          googlesheetdetails: categoryData.google_sheet,
          demoLink3: categoryData.DEMO_LINK2,
          demoLink4: categoryData.DEMO_LINK3,
          cod: categoryData.curriculum_doubts,
          codPdf: categoryData.curriculum_doubts_pdf,
          gaLink: categoryData.ANDROID_GD_LINK,
          language: categoryData.language,
          combo_type: categoryData.COMBO_TYPE,
          batchwings: categoryData.batch_wings_id,
          attempt: categoryData.attempt?.id,
          expiry_date: categoryData.expiry_date,
          subject_filter_id: categoryData.subject_filter_id,
          test_seriesId:
            categoryData.include_test_series !== null
              ? categoryData.include_test_series
              : categoryData.multi_include_test_series !== null
              ? categoryData.multi_include_test_series
              : 0,
        },

        () => {
          categoryData.multi_test_series_details.length > 0 &&
            categoryData.multi_test_series_details.map((item) => {
              this.state.test.push({
                label: item?.TITLE,
                value: item?.ID,
              });
            });
          this.setState({ test: this.state.test });
          axios
            .get(
              "https://admin.akclasses.in/api/showCategory_with_level/" +
                this.state.category
            )
            .then((resp) => {
              ////console.log(resp.data);
              this.setState({
                levels: resp.data.level,
              });
            });
          axios
            .get("https://admin.akclasses.in/api/level/" + this.state.level)
            .then((resp) => {
              ////console.log(resp.data);
              this.setState({
                streams: resp.data.stream,
              });
            });
          // axios
          //   .get("https://admin.akclasses.in/api/stream/" + this.state.stream)
          //   .then(resp => {
          //     //console.log(resp.data);
          //     this.setState({
          //       subjects: resp.data.stream.subject_masters
          //     });
          //   });
          axios
            .get("https://admin.akclasses.in/api/stream/" + this.state.stream)
            .then((resp) => {
              // //console.log(resp.data);
              this.setState({
                subjects: resp.data.stream.subject_masters,
              });
            });

          // ///////////SELECT BATCH WINGS ACCORDING TO THE SUBJECT

          axios
            .get(
              "https://admin.akclasses.in/api/subjectWings/" +
                this.state.subject
            )
            .then((resp) => {
              // //console.log(resp);

              resp.data.wings.map((v) => {
                this.state.batch_wing.push({
                  label: v.batch_wing === null ? "" : v.batch_wing.title,
                  value: v.batch_wing === null ? "" : v.batch_wing.id,
                });
              });
              this.setState({
                batch_wing: this.state.batch_wing,
              });
            });

          // axios
          //   .get(
          //     "https://admin.akclasses.in/api/show_alloted_batchwings?subject_id=" +
          //       this.state.subject
          //   )
          //   .then((resp) => {
          //     //console.log(resp);
          //     resp.data.addedData.map((v) => {
          //       this.state.batch_wing.push({
          //         label: v.title,
          //         value: v.id,
          //       });
          //     });
          //   });

          ////////// SELECT BATCHES ACCORDING TO WINGS AND COMAPRING WITh ALL THE BATCHES

          axios
            .get(
              "https://admin.akclasses.in/api/show_alloted_batches?course_id=" +
                selectId
            )
            .then((resp) => {
              // //console.log(resp);
              resp.data.addedData.map(
                (v) => {
                  this.state.batch.push({
                    label: v.batch_name,
                    value: v.id,
                  });
                },
                () => this.state.batch
              );
              // resp.data.notAddedData.map((val)=>{
              //   this.state.batches.push({
              //     label: val.batch_name,
              //     value: val.id
              //   })
              // })
              axios
                .get(
                  "https://admin.akclasses.in/api/wingBatch/" +
                    this.state.batchwings
                )
                .then((resp) => {
                  // //console.log(resp);
                  resp.data.data.map((v) => {
                    if (v.batch) {
                      this.state.batches.push({
                        label: v.batch
                          ? v.batch.batch_name +
                            " " +
                            "(" +
                            v.batch.attempt?.title +
                            ")"
                          : "",
                        value: v.batch ? v.batch.id : "",
                      });
                    }
                  });
                });
              this.setState(
                {
                  batch: this.state.batch,
                },
                () => this.state.batch
              );
            });

          // ssssssssssss

          axios
            .get(
              Constants.getUrls.subjectFilter +
                "?subject_id=" +
                this.state.subject
            )
            .then((res) => {
              // //console.log("subjectfilter", res);
              this.setState({ getSubjectFilter: res.data[0] }, () => {
                // //console.log(this.state.getSubjectFilter);
              });
            });

          // axios.get(Constants.getUrls.getBatches)
          //   .then((resp)=>{
          //     //console.log(resp)
          //     resp.data.data.map((v)=>{
          //       this.state.batches.push({
          //         label: v.batch_name,
          //         value: v.id,
          //       })
          //     })
          //   })

          // ALREADY SELECTED AMENITIES

          axios.get(Constants.getUrls.getAmenities).then((res) => {
            // //console.log(res);
            let array_id = JSON.parse(categoryData.amenity_ids);
            res.data.data.map((val) => {
              array_id.map((item) => {
                if (item === val?.id) {
                  this.state.selected_amenites.push({
                    label: val?.title,
                    value: val?.id,
                  });
                  this.setState({
                    amenities: this.state.amenities,
                  });
                }
              });
            });
          });

          axios
            .get(
              "https://admin.akclasses.in/api/courses?type=Test_Series&per_page=10"
            )
            .then((resp) => {
              if (categoryData.include_test_series != null) {
                resp.data.data.map((item) => {
                  if (item.ID === categoryData.include_test_series) {
                    this.state.test.push({
                      label: item.TITLE,
                      value: item.ID,
                    });
                    this.setState({
                      test: this.state.test,
                      confirm: "Yes",
                    });
                  }
                });
              }
            });
        }
      );
    }
    axios.get("https://admin.akclasses.in/api/mailTemplate").then((resp) => {
      console.log(resp.data);
      this.setState(
        {
          templates: resp.data,
        },
        () => {
          console.log(this.state.templates);
        }
      );
    });
  }
  // handleBatch = e => {
  //   this.setState(
  //     {
  //       batch: e.target.value,
  //     }
  //   )
  // }
  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] }, () => {
      ////console.log(this.state.img);
    });
  };

  handleAttempt = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleLevel = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        streams: [],
        subjects: [],
        aAlert: false,
      },
      () => {
        ////console.log(this.state.category);
        axios
          .get(
            "https://admin.akclasses.in/api/showCategory_with_level/" +
              this.state.category
          )
          .then((resp) => {
            ////console.log(resp.data);
            this.setState({
              levels: resp.data.level,
              level: "",
            });
          });
      }
    );
  };

  handleStream = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        subjects: [],
        cAlert: false,
      },
      () => {
        axios
          .get("https://admin.akclasses.in/api/level/" + this.state.level)
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              streams: resp.data.stream,
              stream: "",
            });
          });
      }
    );
  };
  handleSubject = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        dAlert: false,
      },
      () => {
        axios
          .get("https://admin.akclasses.in/api/stream/" + this.state.stream)
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              subjects: resp.data.stream.subject_masters,
            });
          });
        //   ////console.log(this.state.stream);
        //  axios.get("https://admin.akclasses.in/api/subjectWings/" + this.state.subject)
        // .then((resp)=>{
        //   //console.log(resp)
        //   resp.data.wings.map((v)=>{
        //     this.state.batch_wing.push({
        //       label: v.batch_wing.title,
        //       value: v.batch_wing.id,
        //     })
        //   })
        //   this.setState({
        //     batch_wing: this.state.batch_wing
        //   })
        // })
      }
    );
  };
  handleWings = (e) => {
    this.setState(
      {
        batch_wing: [],
        [e.target.name]: e.target.value,
        fAlert: false,
      },
      () => {
        //console.log(this.state.subject);
        axios
          .get(
            Constants.getUrls.subjectFilter +
              "?subject_id=" +
              this.state.subject
          )
          .then((res) => {
            // //console.log("subjectfilter", res);
            this.setState({ getSubjectFilter: res.data[0] }, () => {
              //console.log(this.state.getSubjectFilter);
            });
          });
        axios
          .get(
            "https://admin.akclasses.in/api/show_alloted_batchwings?subject_id=" +
              this.state.subject
          )
          .then((resp) => {
            //console.log(resp);

            resp.data.addedData.map((v) => {
              this.state.batch_wing.push({
                label: v.title,
                value: v.id,
              });
            });
            this.setState({
              batch_wing: this.state.batch_wing,
            });
          });
      }
    );
  };
  handlesubjectFilter = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        subject_filter_id: e.target.value,
        fAlert: false,
      },
      () => {
        //console.log(this.state.subject_filter_id);
        axios
          .get(
            Constants.getUrls.subjectFilter +
              "?subject_id=" +
              this.state.subject
          )
          .then((res) => {
            // //console.log("subjectfilter", res);
            this.setState({ getSubjectFilter: res.data[0] }, () => {
              //console.log(this.state.getSubjectFilter);
            });
          });
      }
    );
  };
  attemptWiseWings = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        axios
          .get(
            "https://admin.akclasses.in/api/subjectWings/" + this.state.subject
          )
          .then((resp) => {
            this.setState({
              batch_wings: resp.data.wings,
            });
          });
      }
    );
  };
  handleBatch = (e) => {
    this.setState(
      {
        batches: [],
        [e.target.name]: e.target.value,
        fAlert: false,
      },
      () => {
        // //console.log(this.state.batchwings);
        this.setState({
          batch: [],
        });
        axios
          .get(
            "https://admin.akclasses.in/api/wingBatch/" + this.state.batchwings
          )
          .then((resp) => {
            //console.log(resp);
            resp.data.data.map((v) => {
              if (v.batch) {
                this.state.batches.push({
                  label: v.batch
                    ? v.batch.batch_name +
                      " " +
                      "(" +
                      v.batch.attempt?.title +
                      ")"
                    : "",
                  value: v.batch ? v.batch.id : "",
                });
              }
            });
          });
      }
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.state.test_seriesId);
    this.state.selected_amenites.map((item) => {
      this.state.amenity_id_array.push(item.value);
    });
    this.setState(
      {
        amenity_id_array: this.state.amenity_id_array,
      },
      () => {
        //console.log(this.state.amenity_id_array);
      }
    );
    this.state.batch.map((v) => {
      this.state.batch_id.push(v.value);
    });
    this.setState({
      batch_id: this.state.batch_id,
    });

    let facultyDetails = [];
    this.state.faculty.forEach((v) => {
      facultyDetails.push({ fid: v.id, fshare: v.faculty_share });
    });
    // //console.log(facultyDetails[0].fid);
    if (facultyDetails.length === 0) {
      this.setState({ atLeast: true });
    } else {
      this.setState({
        loading: true,
      });
      fd.append("faculty", facultyDetails[0].fid);
      fd.append("ak_share", this.state.akshare);
      fd.append("discount_amount", this.state.discount);
      fd.append("cash_amount", this.state.cashback);
      fd.append("category", this.state.category);
      fd.append("sku", this.state.sku);
      fd.append("title", this.state.title);
      fd.append("short_description", this.state.courseDetails);
      fd.append("description", this.state.proDesc);
      fd.append("duration", this.state.duration);
      fd.append("faculty_detail", JSON.stringify(facultyDetails));
      // fd.append("combo_type", facultyDetails.length > 1 ? "Combo" : "Normal");
      fd.append("combo_type", this.state.combo_type);
      fd.append("price", "");
      fd.append("price_1", "");
      fd.append("image_path", this.state.img);
      fd.append("thumb_image", this.state.thumb);
      fd.append("what_learn", this.state.specification);
      fd.append("syllabus_pdf", this.state.index);
      fd.append("common_link", this.state.gcLink);
      fd.append("window_link", this.state.gwLink);
      fd.append("android_link", this.state.gaLink);
      fd.append("subject_master_id", this.state.subject);
      fd.append("type", "COURSES");
      fd.append("android_link", this.state.gaLink);
      fd.append("level", this.state.level);
      fd.append("stream", this.state.stream);
      fd.append("offer_type", this.state.offType);
      fd.append("payment_gateway", this.state.payment_gateway);
      fd.append("ordermailtemplate", this.state.ordermailtemplate);
      fd.append("shippingmailtemplate", this.state.shippingmailtemplate);
      fd.append("language", this.state.language);
      fd.append("batch_id", JSON.stringify(this.state.batch_id));
      fd.append("value", this.state.offValue);
      fd.append("demo_link", this.state.demoLink1);
      fd.append("demo_link1", this.state.demoLink2);
      fd.append("demo_link2", this.state.demoLink3);
      fd.append("google_sheet_details", this.state.googlesheetdetails);
      // if (this.state.confirm === "Yes") {
      // fd.append("include_test_series", this.state.test);
      // }
      fd.append("demo_link3", this.state.demoLink4);
      fd.append("curriculum_doubts", this.state.cod);
      fd.append("curriculum_doubts_pdf", this.state.codPdf);
      fd.append("batch_wings_id", this.state.batchwings);
      fd.append("attempt", this.state.attempt);
      fd.append("subject_filter_id", this.state.subject_filter_id);
      fd.append("include_test_series", this.state.test_seriesId);
      fd.append("multi_include_test_series", this.state.test_seriesId);
      fd.append("amenity_ids", JSON.stringify(this.state.amenity_id_array));
      fd.append("expiry_date", this.state.expiry_date);
      // fd.append("_method", "PUT")
      // fd.append("batch_id", this.state.batch_id)

      let categoryData = this.props.location.state.categoryData;
      let categoryId = categoryData.ID;
      // if (this.state.confirm === "Yes") {
      //   axios
      //     .get("https://admin.akclasses.in/api/course/create-multiple", fd, {
      //       onUploadProgress: (progressEvent) => {
      //         this.setState({
      //           progress: Math.round(
      //             (progressEvent.loaded / progressEvent.total) * 100
      //           ),
      //           mediaProgress: true,
      //         });
      //       },
      //     })
      //     .then((resp) => {
      //       //console.log(resp);
      //     })
      //     .catch((error) => {
      //       //console.log(error);
      //     });
      // }

      axios
        .post(
          "https://admin.akclasses.in/api/course/update/" + categoryId,
          // "https://admin.akclasses.in/api/course/update/" + categoryId,
          fd,
          {
            onUploadProgress: (progressEvent) => {
              this.setState({
                progress: Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                ),
                mediaProgress: true,
              });
            },
          }
        )
        .then((resp) => {
          fd.delete("image_path");
          fd.delete("thumb_image");
          //console.log(resp);
          this.setState({
            displayVariant: true,
          });
        });

      // Get device name
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            //console.log('Latitude:', latitude);
            //console.log('Longitude:', longitude);

            //Get Operating System
            const userAgent = window.navigator.userAgent;
            let operatingSystem = "Unknown";

            // if (/Windows/.test(userAgent)) {
            //   operatingSystem = "Windows";
            // } else if (/Mac/.test(userAgent)) {
            //   operatingSystem = "Mac OS";
            // } else if (/Linux/.test(userAgent)) {
            //   operatingSystem = "Linux";
            // } else if (/Android/.test(userAgent)) {
            //   operatingSystem = "Android";
            // } else if (/iOS/.test(userAgent)) {
            //   operatingSystem = "iOS";
            // }

            if (userAgent.match(/Android/i)) {
              operatingSystem = "Android";
            } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
              operatingSystem = "iOS";
            } else if (userAgent.match(/Windows/i)) {
              operatingSystem = "Windows";
            } else if (userAgent.match(/Mac OS/i)) {
              operatingSystem = "Mac OS";
            } else if (userAgent.match(/Linux/i)) {
              operatingSystem = "Linux";
            }

            //console.log(operatingSystem);
            // Make a request to Nominatim for reverse geocoding
            axios
              .get(
                `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
              )
              .then((response) => {
                const address = response.data.display_name;
                //console.log('Address:', address);
                this.setState({ address });

                // Get IP Address
                fetch("https://api.ipify.org?format=json")
                  .then((response) => response.json())
                  .then((data) => {
                    this.setState({ ipAddress: data.ip });
                    //console.log('IP Address:', data.ip);

                    // Get approximate location using IP geolocation service
                    axios
                      .get("https://ipapi.co/json/")
                      .then((response) => {
                        const { city, region } = response.data;
                        this.setState({ city, state: region });
                        //console.log(city);
                        //console.log(region);

                        // Concatenate operatingSystem and data.ip
                        const concatenatedOs = `${operatingSystem} : ${data.ip}`;

                        // Concatenate city and region
                        const concatenatedAddress = `${city}, ${region}`;

                        //Save Device Data
                        fd.append("course_id", categoryId);
                        fd.append("device_name", concatenatedAddress);
                        fd.append("ip_address", concatenatedOs);
                        fd.append("latitude", latitude);
                        fd.append("longitude", longitude);
                        axios
                          .post(Constants.postUrls.saveDeviceInfo, fd)
                          .then((respSave) => {
                            //console.log(respSave);
                          });
                      })
                      .catch((error) => {
                        console.error("Error fetching location:", error);
                      });
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              });
          },
          (error) => {
            console.error(error);
          }
        );
      }
    }
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    if (this.state.redirect) {
      //console.log("saved");
      // return <Redirect to={"/admin/coursesmaster"} />;
    } else {
      if (this.state.displayVariant) {
        return (
          <Redirect
            to={{
              pathname: "/admin/coursevariants/" + this.state.productId,
            }}
          />
        );
      } else {
        return (
          <div>
            <GridContainer>
              {this.state.notification ? (
                <div style={{ position: "relative", left: "600px" }}>
                  <SnackbarContent
                    message={"Course Updated!"}
                    icon={AddAlert}
                    color="success"
                  />
                </div>
              ) : null}
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                    <h4 style={{ color: "black" }}>Course Master</h4>
                  </CardHeader>
                  <CardBody>
                    <form onSubmit={this.handleSubmit}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Course Type
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.combo_type}
                              inputProps={{
                                name: "combo_type",
                                id: "simple-select",
                                onChange: (e) => {
                                  this.setState(
                                    {
                                      combo_type: e.target.value,
                                      aAlert: false,
                                    },
                                    () => {
                                      this.state.combo_type === "Combo"
                                        ? this.setState({
                                            dropShow: true,
                                          })
                                        : this.setState({ dropShow: false });
                                    }
                                  );
                                },
                              }}
                            >
                              {this.state.type.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v}
                                >
                                  {v}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.aAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Category
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.category}
                              inputProps={{
                                name: "category",
                                id: "simple-select",
                                onChange: this.handleLevel,
                              }}
                            >
                              {this.state.courses.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v.ID}
                                >
                                  {v.CATEGORY}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Level
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.level}
                              inputProps={{
                                required: true,
                                name: "level",
                                id: "simple-select",
                                onChange: this.handleStream,
                              }}
                            >
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value=""
                              >
                                Select Category First
                              </MenuItem>
                              {this.state.levels.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v.id}
                                >
                                  {v.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Stream
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.stream}
                              inputProps={{
                                required: true,
                                name: "stream",
                                id: "simple-select",
                                onChange: this.handleSubject,
                                // ////console.log(e.target.value);
                              }}
                            >
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value=""
                              >
                                Select Level First
                              </MenuItem>
                              {this.state.streams.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v.id}
                                >
                                  {v.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Subject
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.subject}
                              onChange={this.handleWings}
                              inputProps={{
                                required: true,
                                name: "subject",
                                id: "simple-select",

                                // {
                                //   this.setState({
                                //     fAlert: false,
                                //     subject: e.target.value
                                //   });
                                // }
                                // // ////console.log(e.target.value);
                              }}
                            >
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value=""
                              >
                                Select Stream First
                              </MenuItem>
                              {this.state.subjects.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v.subject_master.id}
                                >
                                  {v.subject_master.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.fAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Subject Filter
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.subject_filter_id}
                              onChange={this.handlesubjectFilter}
                              inputProps={{
                                required: true,
                                name: "subject_filter",
                                id: "simple-select",

                                // {
                                //   this.setState({
                                //     fAlert: false,
                                //     subject: e.target.value
                                //   });
                                // }
                                // // ////console.log(e.target.value);
                              }}
                            >
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value=""
                              >
                                Select Subject First
                              </MenuItem>
                              {this.state.getSubjectFilter.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v.id}
                                >
                                  {v.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.fAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>
                        {/* select Batch Wings */}

                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Attempt
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.attempt}
                              inputProps={{
                                name: "attempt",
                                id: "simple-select",
                                onChange: this.attemptWiseWings,
                              }}
                            >
                              {this.state.attempts.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v.id}
                                >
                                  {v.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Batch Wings
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.batchwings}
                              onChange={this.handleBatch}
                              inputProps={{
                                required: true,
                                name: "batchwings",
                                id: "simple-select",

                                // {
                                //   this.setState({
                                //     fAlert: false,
                                //     selected_wing: e.target.value
                                //   });
                                // }
                                // ////console.log(e.target.value);
                              }}
                            >
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value=""
                              >
                                Select Subject Filter First
                              </MenuItem>
                              {this.state.batch_wing.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v.value}
                                >
                                  {v.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.fAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>

                        {/* select multiple batch */}
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "20px", zIndex: "100" }}
                            required={true}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <span> Select Batches </span>
                            <MultiSelect
                              disableSearch
                              options={this.state.batches}
                              name="batch"
                              value={this.state.batch}
                              onChange={(data) => {
                                this.setState(
                                  {
                                    batch: data,
                                  },
                                  () => {
                                    // //console.log(this.state.batch);
                                  }
                                );
                              }}
                            />
                          </FormControl>

                          {this.state.batchAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "20px", zIndex: "100" }}
                            required={true}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Select Amenities
                            </InputLabel>

                            <span> Select Amenities </span>
                            <MultiSelect
                              disableSearch
                              options={this.state.amenities}
                              name="amenities"
                              value={this.state.selected_amenites}
                              onChange={(data) => {
                                this.setState(
                                  {
                                    selected_amenites: data,
                                  },
                                  () => {
                                    //console.log(this.state.selected_amenites);
                                  }
                                );
                              }}
                            />
                          </FormControl>

                          {this.state.batchAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="SKU"
                            id="float"
                            inputProps={{
                              value: this.state.sku,
                              required: true,
                              onChange: (e) =>
                                this.setState({ sku: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Title"
                            id="float"
                            inputProps={{
                              value: this.state.title,
                              required: true,
                              onChange: (e) =>
                                this.setState({ title: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Duration"
                            id="float"
                            inputProps={{
                              value: this.state.duration,
                              required: true,
                              onChange: (e) =>
                                this.setState({ duration: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        {/* <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Price"
                            id="float"
                            inputProps={{
                              value: this.state.twoxprice,
                              type: "number",
                              onChange: e =>
                                this.setState({ twoxprice: e.target.value })
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem> */}
                        {/* <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="3 Times Price"
                            id="float"
                            inputProps={{
                              value: this.state.threexprice,
                              type: "number",
                              onChange: e =>
                                this.setState({ threexprice: e.target.value })
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem> */}
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Ak Classes Share"
                            id="float"
                            inputProps={{
                              type: "float",
                              disabled: true,
                              value: this.state.akshare,
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Offer Value"
                            id="float"
                            inputProps={{
                              type: "number",
                              required: true,
                              value: this.state.offValue,
                              onChange: (e) =>
                                this.setState({ offValue: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Offer Type
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.offType}
                              inputProps={{
                                required: true,
                                name: "offType",
                                id: "simple-select",
                                onChange: (e) => {
                                  this.setState({
                                    offType: e.target.value,
                                    eAlert: false,
                                  });
                                  // ////console.log(e.target.value);
                                },
                              }}
                            >
                              {this.state.offTypeArray.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v}
                                >
                                  {v}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Payment Gateway
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.payment_gateway}
                              inputProps={{
                                required: true,
                                name: "payment_gateway",
                                id: "simple-select",
                                onChange: (e) => {
                                  this.setState({
                                    payment_gateway: e.target.value,
                                    eAlert: false,
                                  });
                                  // ////console.log(e.target.value);
                                },
                              }}
                            >
                              {this.state.paymentGatewayArray.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v}
                                >
                                  {v}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Order Mail Template
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.ordermailtemplate}
                              inputProps={{
                                required: true,
                                name: "ordermailtemplate",
                                id: "simple-select",
                                onChange: (e) => {
                                  this.setState({
                                    ordermailtemplate: e.target.value,
                                    eAlert: false,
                                  });
                                  // ////console.log(e.target.value);
                                },
                              }}
                            >
                              {this.state.orderTemplates.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v.id}
                                >
                                  {v.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Shipping Mail Template
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.shippingmailtemplate}
                              inputProps={{
                                required: true,
                                name: "shippingmailtemplate",
                                id: "simple-select",
                                onChange: (e) => {
                                  this.setState({
                                    shippingmailtemplate: e.target.value,
                                    eAlert: false,
                                  });
                                  // ////console.log(e.target.value);
                                },
                              }}
                            >
                              {this.state.shippingTemplates.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v.id}
                                >
                                  {v.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Select Language
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.language}
                              inputProps={{
                                required: true,
                                name: "language",
                                id: "simple-select",
                                onChange: (e) => {
                                  this.setState({
                                    language: e.target.value,
                                  });
                                },
                              }}
                            >
                              {this.state.languages.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v}
                                >
                                  {v}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>

                        {/* <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            required={true}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Batch
                            </InputLabel>
                                <MultiSelect
                                  disableSearch
                                  options={this.state.batches}
                                  value={this.state.batch}
                                  onChange={data=>{
                                    this.setState({ batch: data})
                                  }}
                                />
                          </FormControl>


                          {this.state.batchAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}


                        </GridItem> */}

                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Want to Add Test Series?
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.confirm}
                              inputProps={{
                                required: true,
                                name: "confirm",
                                id: "simple-select",
                                onChange: (e) => {
                                  this.setState(
                                    {
                                      confirm: e.target.value,
                                    },
                                    () => {
                                      if (this.state.confirm === "No") {
                                        this.setState({ test_series: "" });
                                      }
                                    }
                                  );
                                  // ////console.log(e.target.value);
                                },
                              }}
                            >
                              {this.state.confirmation.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v}
                                >
                                  {v}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        {this.state.confirm === "Yes" ? (
                          <GridItem xs={12} sm={12} md={4}>
                            <FormControl
                              style={{ marginTop: "12px" }}
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                              >
                                Test Series
                              </InputLabel>

                              <MultiSelect
                                disableSearch
                                options={this.state.testSeries}
                                name="test"
                                value={this.state.test}
                                onChange={(data) => {
                                  //console.log(data);
                                  this.setState(
                                    {
                                      test: data,
                                      test_seriesId: [],
                                    },
                                    () => {
                                      this.state.test.map((v) => {
                                        this.state.test_seriesId.push(v.value);
                                      });
                                      this.setState(
                                        {
                                          test_seriesId: this.state
                                            .test_seriesId,
                                        },
                                        () => {
                                          //console.log(this.state.test_seriesId);
                                        }
                                      );
                                    }
                                  );
                                }}
                              />
                            </FormControl>
                          </GridItem>
                        ) : null}
                        {this.state.faculty.length >= 1 ? (
                          <GridItem xs={12} sm={12} md={12}>
                            <br />
                            <table
                              style={{
                                width: "100%",
                                border: "1px black solid",
                                borderCollapse: "collapse",
                              }}
                            >
                              <tr
                                style={{
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                              >
                                <th
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  Sr.No
                                </th>
                                <th
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  Faculty
                                </th>
                                <th
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  Faculty Share
                                </th>
                                <th
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  Action
                                </th>
                              </tr>
                              {this.state.faculty.map((v, i) => (
                                <tr
                                  style={{
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                  key={i}
                                >
                                  <td
                                    style={{
                                      padding: "10px",
                                      textAlign: "center",
                                      border: "1px black solid",
                                      borderCollapse: "collapse",
                                    }}
                                  >
                                    {i + 1}
                                  </td>
                                  <td
                                    style={{
                                      padding: "4px 10px 4px 10px",
                                      textAlign: "center",
                                      border: "1px black solid",
                                      borderCollapse: "collapse",
                                    }}
                                  >
                                    {v.name}
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      textAlign: "center",
                                      border: "1px black solid",
                                      borderCollapse: "collapse",
                                    }}
                                  >
                                    {v.faculty_share}
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      textAlign: "center",
                                      border: "1px black solid",
                                      borderCollapse: "collapse",
                                    }}
                                  >
                                    <input
                                      style={{
                                        backgroundColor: "red",
                                        borderRadius: "10%",
                                        border: "none",
                                        padding: "10px",
                                        color: "white",
                                      }}
                                      onClick={() => {
                                        this.state.faculty.splice(i, 1);
                                        facultyIds.splice(i, 1);
                                        this.setState(
                                          { faculty: this.state.faculty },
                                          () => {
                                            if (
                                              this.state.faculty.length === 0
                                            ) {
                                              this.setState({
                                                akshare: 100,
                                                atLeast: true,
                                                facultyCommission: 0,
                                              });
                                            } else {
                                              let facultyCommission = 0;
                                              this.state.faculty.forEach(
                                                (v) => {
                                                  facultyCommission +=
                                                    v.faculty_share;
                                                }
                                              );
                                              let akshare =
                                                100 - facultyCommission;
                                              this.setState({
                                                atLeast: false,
                                                akshare,
                                                facultyCommission,
                                              });
                                            }
                                          }
                                        );
                                      }}
                                      type="button"
                                      value="Delete"
                                    />
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </GridItem>
                        ) : null}
                        <GridItem xs={12} sm={12} md={12}>
                          <Button
                            onClick={() => {
                              this.setState({
                                visible: true,
                              });
                            }}
                            className="btn-round"
                            color="success"
                          >
                            Add Faculty
                          </Button>
                        </GridItem>
                        {/* <GridItem xs={12} sm={12} md={12}>
                          <label>Product Description</label>
                          <br />
                          <CKEditor
                            editor={ClassicEditor}
                            // config={editorConfiguration}
                            data={
                              this.props.location.state.categoryData.DESCRIPTION
                            }
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              ////console.log({ data });
                              this.setState({
                                proDesc: data
                              });
                            }}
                          />
                        </GridItem> */}
                        <GridItem xs={12} sm={12} md={12}>
                          <label>Course Details</label>
                          <br />
                          <CKEditor
                            editor={ClassicEditor}
                            // config={editorConfiguration}
                            data={
                              this.props.location.state.categoryData
                                .SHORT_DESCRIPTION
                            }
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              ////console.log({ data });
                              this.setState({
                                courseDetails: data,
                              });
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <label>Index of Video Lecture</label>
                          <br />
                          <CKEditor
                            editor={ClassicEditor}
                            // config={editorConfiguration}
                            data={
                              this.props.location.state.categoryData.WHAT_LEARN
                            }
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              ////console.log({ data });
                              this.setState({
                                specification: data,
                                specificationAlert: false,
                              });
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <label>Upload Index of Video Lecture PDF :</label>
                          <br />
                          <input
                            name="index"
                            onChange={this.handleFile}
                            type="file"
                            accept="application/pdf"
                          />
                        </GridItem>
                        <br />
                        <GridItem xs={12} sm={12} md={4}>
                          <label>Upload Image</label>
                          <br />
                          <input
                            name="img"
                            onChange={this.handleFile}
                            accept="image/*"
                            type="file"
                          />
                          {/* <span
                            style={{
                              color: "red",
                              fontWeight: this.state.error ? "bolder" : "none",
                            }}
                          >
                            Image should be 750px X 180px.
                          </span> */}
                          <img
                            width="200px"
                            src={Constants.imgUrl + this.state.img}
                            alt=""
                          />
                        </GridItem>
                        <br />
                        <GridItem xs={12} sm={12} md={4}>
                          <label>Thumb Image</label>
                          <br />
                          <input
                            accept="image/*"
                            name="thumb"
                            onChange={this.handleFile}
                            type="file"
                          />
                          <img
                            width="200px"
                            src={Constants.imgUrl + this.state.thumb}
                            alt=""
                          />
                        </GridItem>
                        {/* <GridItem xs={12} sm={12} md={12}>
                          <label>Curriculum of Doubt File</label>
                          <br />
                          <input
                            name="codPdf"
                            onChange={this.handleFile}
                            type="file"
                          />
                        </GridItem> */}
                        {/* <GridItem xs={12} sm={12} md={12}>
                          <label>Curriculum of Doubt</label>
                          <br />
                          <CKEditor
                            editor={ClassicEditor}
                            // config={editorConfiguration}
                            data={
                              this.props.location.state.categoryData
                                .curriculum_doubts
                            }
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              ////console.log({ data });
                              this.setState({
                                cod: data
                              });
                            }}
                          />
                        </GridItem> */}
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Cashback Amount"
                            id="float"
                            inputProps={{
                              type: "number",
                              value: this.state.cashback,
                              required: true,
                              onChange: (e) =>
                                this.setState({ cashback: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Discount Amount"
                            id="float"
                            inputProps={{
                              type: "number",
                              required: true,
                              value: this.state.discount,
                              onChange: (e) =>
                                this.setState({ discount: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        {/* <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Update Google Drive Link"
                            id="float"
                            inputProps={{
                              value: this.state.gcLink,
                              onChange: e =>
                                this.setState({ gcLink: e.target.value })
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem> */}
                        {/* <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Windows Google Drive Link"
                            id="float"
                            inputProps={{
                              value: this.state.gwLink,
                              onChange: e =>
                                this.setState({ gwLink: e.target.value })
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem> */}
                        {/* <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Android Google Drive Link"
                            id="float"
                            inputProps={{
                              value: this.state.gaLink,
                              onChange: e =>
                                this.setState({ gaLink: e.target.value })
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem> */}
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Demo Link 1"
                            id="float"
                            inputProps={{
                              value: this.state.demoLink1,
                              onChange: (e) =>
                                this.setState({ demoLink1: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Demo Link 2"
                            id="float"
                            inputProps={{
                              value: this.state.demoLink2,
                              onChange: (e) =>
                                this.setState({ demoLink2: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Google Sheet Details"
                            id="float"
                            inputProps={{
                              value: this.state.googlesheetdetails,
                              onChange: (e) =>
                                this.setState({
                                  googlesheetdetails: e.target.value,
                                }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        <span
                          style={{
                            marginLeft: "0px",
                            color: "green",
                            fontSize: "12px",
                            fontWeight: "400",
                          }}
                        >
                          {
                            'Sample Link Details: {"sheet_id":"14Tnrl4tTxgVTD-QEKIKsOIgSeAIC0OE1V9-6J37xkUA","sheet_name":"ak"}'
                          }
                        </span>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                        <label>Expiry Date</label>
                        <FormControl fullWidth>
                          <Datetime
                            value={this.state.expiry_date}
                            timeFormat={false}
                            onChange={(e) => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  expiry_date: year + "/" + month + "/" + day,
                                },
                                () => {
                                  //console.log(this.state.expiry_date);
                                }
                              );
                            }}
                            inputProps={{
                              value: this.state.expiry_date,
                              required: true,
                              placeholder: "Expiry Date",
                            }}
                          />
                        </FormControl>
                      </GridItem>

                        {/* <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Demo Link 3"
                            id="float"
                            inputProps={{
                              value: this.state.demoLink3,
                              onChange: e =>
                                this.setState({ demoLink3: e.target.value })
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Demo Link 4"
                            id="float"
                            inputProps={{
                              value: this.state.demoLink4,
                              onChange: e =>
                                this.setState({ demoLink4: e.target.value })
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem> */}

                        <GridItem xs={12} sm={12} md={12}>
                          <br />
                          {this.state.mediaProgress ? (
                            <ProgressBar percentage={this.state.progress} />
                          ) : null}
                        </GridItem>
                        <br />
                        <GridItem xs={12} sm={12} md={6}>
                          {this.state.atLeast ? (
                            <div>
                              <span style={{ color: "red" }}>
                                *Add Atleast One Faculty
                              </span>
                            </div>
                          ) : null}
                          <Button className="" color="warning" type="submit">
                            {this.state.loading ? (
                              <Loader />
                            ) : (
                              "Update Course Master"
                            )}
                          </Button>
                          <Link
                            to={{
                              pathname: "/admin/coursesmaster",
                            }}
                          >
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <Modal
              visible={this.state.visible}
              width="700"
              height="175"
              effect="fadeInUp"
              onClickAway={() => {
                this.setState({ visible: false });
              }}
            >
              <GridContainer style={{ paddingLeft: "5px", paddingTop: "20px" }}>
                <GridItem xs={12} sm={12} md={5}>
                  <FormControl fullWidth>
                    <label>Faculty</label>
                    <select
                      style={{ padding: "11px" }}
                      name="facultyId"
                      value={this.state.facultyId}
                      onChange={(e) => {
                        this.setState({ facultyId: e.target.value }, () => {
                          this.state.facultyArr.forEach((v) => {
                            if (parseInt(this.state.facultyId) === v.id) {
                              this.setState({ facultyWithName: v.name });
                            }
                          });
                        });
                      }}
                    >
                      <option value="">Select</option>
                      {this.state.facultyArr.map((v, i) => (
                        <option key={i} value={v.id}>
                          {v.name}
                        </option>
                      ))}
                    </select>
                  </FormControl>
                  {this.state.bAlert ? (
                    <div>
                      <span style={{ color: "red" }}>*required</span>
                    </div>
                  ) : null}
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <CustomInput
                    labelText="Faculty Share"
                    id="float"
                    inputProps={{
                      type: "float",
                      value: this.state.facultyshare,
                      required: true,
                      onChange: (e) =>
                        this.setState({ facultyshare: e.target.value }),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <Button
                    style={{ marginTop: "20px" }}
                    onClick={() => {
                      if (
                        facultyIds.includes(parseInt(this.state.facultyId)) ===
                        false
                      ) {
                        facultyIds.push(parseInt(this.state.facultyId));
                        this.state.faculty.push({
                          id: this.state.facultyId,
                          name: this.state.facultyWithName,
                          faculty_share: parseFloat(this.state.facultyshare),
                        });
                        this.setState(
                          {
                            faculty: this.state.faculty,
                            facultyshare: "",
                            facultyId: "",
                          },
                          () => {
                            if (this.state.faculty.length === 0) {
                              this.setState({
                                akshare: 100,
                                atLeast: true,
                                facultyCommission: 0,
                              });
                            } else {
                              let facultyCommission = 0;
                              this.state.faculty.forEach((v) => {
                                facultyCommission += v.faculty_share;
                              });
                              let akshare = 100 - facultyCommission;
                              this.setState({
                                atLeast: false,
                                akshare,
                                facultyCommission,
                              });
                            }
                          }
                        );
                      }
                      this.setState({
                        visible: false,
                      });
                    }}
                    className="btn-round"
                    color="success"
                  >
                    Add
                  </Button>
                </GridItem>
              </GridContainer>
            </Modal>
          </div>
        );
      }
    }
  }
}

export default UpdateCoursesMaster;
