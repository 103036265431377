/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Card from "components/Card/Card.js";
import EditIcon from "@material-ui/icons/Edit";
import CardHeader from "components/Card/CardHeader.js";
import axios from "axios";
import Loader from "components/Loader";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Constants from "variables/Constants";
// import editorConfiguration from "variables/EditorConfiguration";

class UpdateScannerSource extends Component {
  state = {
    source: "",
    redirect: false,
    notification: false,
    loading: false,
    aAlert: false,
    bAlert: false,
    title: "",
    // description: "",
  };

  componentDidMount() {
    let sourceData = this.props.location.state.sourceData;

    this.setState({
      title: sourceData.title,
      //   description: sourceData.description,
      status: sourceData.status,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.title === "") {
      this.setState({
        aAlert: true,
      });
    }
    // else if (this.state.description === "") {
    //   this.setState({
    //     bAlert: true,
    //   });
    // }
    else {
      this.setState({
        loading: true,
      });
      let payLoad = {
        title: this.state.title,
        // description: this.state.description,
        status: this.state.status,
      };

      let sourceData = this.props.location.state.sourceData;
      let sourceId = sourceData.id;
      axios
        .post(Constants.postUrls.updateScannerSource + sourceId, payLoad)
        .then((resp) => {
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        });
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/scannersource"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Source Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Scanner Source</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Source"
                          inputProps={{
                            value: this.state.title,
                            required: true,
                            onChange: (e) =>
                              this.setState(
                                { title: e.target.value, aAlert: false },
                                () => {
                                  //console.log(this.state.title);
                                }
                              ),
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      {/* <GridItem xs={12} sm={12} md={12}>
                        <br />
                        <label>Description</label>
                        <br />
                        <SunEditor
                          setOptions={editorConfiguration}
                          onChange={(content) =>
                            this.setState({
                              description: content,
                              bAlert: false,
                            })
                          }
                          setContents={this.state.description}
                        />
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem> */}

                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" type="submit" color="info">
                          {this.state.loading ? <Loader /> : "Update Source"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/scannersource",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateScannerSource;
