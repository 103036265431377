import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Loader from "components/Loader";
import Modal from "react-awesome-modal";
const classes = makeStyles(styles);
const perPages = ["10", "25", "50", "100"];
class Topics extends Component {
  state = {
    notifyStatus: false,
    secondaryCategories: [],
    status: "",
    active: 1,
    per_page: "10",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    rowId: "",
    visible: false,
    notifyDelete: false,
    delLoading: false,
  };
  componentDidMount() {
    this.getTopics();
  }
  getTopics = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/topic?per_page=" + this.state.per_page
      )
      .then((resp) => {
        console.log(resp);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            btnIdArray,
            secondaryCategories: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };
  handlePage = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        axios
          .get(
            "https://admin.akclasses.in/api/topic?per_page=" +
              this.state.per_page
          )
          .then((resp) => {
            //console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                secondaryCategories: resp.data.data,
                total: resp.data.total,
                from: resp.data.from,
                to: resp.data.to,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };
  currentPage = (v) => {
    let commonUrl =
      "https://admin.akclasses.in/api/topic?per_page=" +
      this.state.per_page +
      "&page=";
    axios.get(commonUrl + v).then((resp) => {
      // console.log(resp.data.data);
      this.setState({
        total: resp.data.total,
        from: resp.data.from,
        to: resp.data.to,
        secondaryCategories: resp.data.data,
        active: v,
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };
  handleSelect = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const payload = {
          status: this.state.status,
        };
        axios
          .post("https://admin.akclasses.in/api/topic/status/" + id, payload)
          .then(() => {
            //console.log(res);
            this.setState({
              notifyStatus: true,
            });
            setTimeout(() => {
              this.setState(
                {
                  notifyStatus: false,
                  fromInc: 1,
                  active: 1,
                  toInc: 5,
                },
                () => {
                  this.getTopics();
                }
              );
            }, 1000);
          });
      }
    );
  };

  handleModal = (sec) => {
    this.setState(
      {
        rowId: sec.topic.id,
        visible: true,
      },
      () => {
        console.log(this.state.rowId);
      }
    );
  };

  handleDelete = (id) => {
    this.setState({ delLoading: true });
    axios.delete("https://admin.akclasses.in/api/topic/" + id).then((resp) => {
      console.log(resp)
      this.setState({
        notifyDelete: true,
        delLoading: false,
      });
      setTimeout(() => {
        // window.location.reload();
        this.setState(
          {
            notifyDelete: false,
            visible: false,
          },
          () => {
            this.getTopics();
          }
        );
      }, 3000);
    });
  };

  render() {
    return (
      <div>
        <GridContainer>
          {this.state.notifyStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Status Changed!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <FormControl
                  style={{
                    position: "absolute",
                    right: "10%",
                    width: "85px",
                  }}
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    s
                    key={1}
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={this.state.per_page}
                    inputProps={{
                      name: "per_page",
                      id: "simple-select",
                      onChange: (e) => {
                        this.handlePage(e);
                      },
                    }}
                  >
                    {perPages.map((v, i) => (
                      <MenuItem
                        key={i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={v}
                      >
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <h3>Topics</h3>
                <Link to="/admin/addtopic">
                  <Button className="" color="warning">
                    Add Topic
                  </Button>
                </Link>
                <Table
                  tableHead={[
                    "Sr No.",
                    "Topic",
                    "Lectures",
                    "Status",
                    "Actions",
                  ]}
                  tableData={this.state.secondaryCategories.map(
                    (sec, index) => [
                      [index + 1],
                      [sec.topic === null ? "NA" : sec.topic.name],
                      [sec.topic === null ? "NA" : sec.topic.no_of_lecture],
                      [
                        <Select
                          key={index}
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={sec.topic === null ? "" : sec.topic.status}
                          inputProps={{
                            name: "status",
                            id: "simple-select",
                            onChange: (e) => {
                              this.handleSelect(e, sec.id);
                            },
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="ACTIVE"
                          >
                            ACTIVE
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="INACTIVE"
                          >
                            INACTIVE
                          </MenuItem>
                        </Select>,
                      ],
                      [
                        <Link
                          key={index}
                          to={{
                            pathname: "/admin/updatetopic/" + sec.id,
                            state: { categoryData: sec },
                          }}
                        >
                          <Button className="btn-round" color="info">
                            Update
                          </Button>
                        </Link>,

                        <Button
                          key={index}
                          onClick={() => {
                            this.handleModal(sec);
                          }}
                          className="btn-round"
                          color="#F94A29"
                        >
                          Delete
                        </Button>,
                      ],
                    ]
                  )}
                />
              </CardBody>
            </Card>
          </GridItem>
          <Modal
            visible={this.state.visible}
            width="600"
            height="100"
            effect="fadeInUp"
            onClickAway={() => this.setState({ visible: false })}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  {/* <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                  <h4>Stacked Form</h4> 
                  </CardHeader> */}
                  <CardBody>
                    <div>
                      <h3>Are You Sure You Want to Delete It?</h3>
                      {this.state.notifyDelete ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Topic Deleted Successfuly!
                          </span>
                        </div>
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={() => {
                              this.handleDelete(this.state.rowId);
                            }}
                          >
                            {this.state.delLoading ? <Loader /> : "Yes"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            No
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Modal>
        </GridContainer>
        {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : (
          <div>
            <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div>
            <div style={{ float: "right", border: "1px solid grey" }}>
              <div
                onClick={this.prevPages}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div>
              {this.state.filteredBtnIdArray.map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(v);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === v ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === v ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black",
                  }}
                >
                  {v}
                </div>
              ))}
              <div
                onClick={this.nextPages}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.filteredBtnIdArray.length === 2 ||
                    this.state.filteredBtnIdArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c8;
                </i>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Topics;
