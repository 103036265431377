import React, { Component } from "react";

class Notification extends Component {
  state = {
    show: true
  };
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        show: false
      });
    }, parseInt(this.props.duration));
  }

  render() {
    if (this.state.show) {
      return (
        <div className="notDiv">
          <span className="iconDiv">
            <i className="fa fa-check"></i>
          </span>
          <span className="contentDiv">
            <span className="title">{this.props.title}</span>
          </span>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default Notification;
