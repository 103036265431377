import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Loader from "components/Loader";
import Modal from "react-awesome-modal";
const classes = makeStyles(styles);
const orders = ["UnUsed", "Used"];

class ProductKeys extends Component {
  state = {
    visible: false,
    notifyDelete: false,
    delLoading: false,
    rowId: 0,
    notifyStatus: false,
    secondaryCategories: [],
    operatinalCategories: [],
    filteredSecondaryCategories: [],
    courses: [],
    catData: [],
    status: "",
    category: "",
    course: "",
    showButtons: false,
    delNot: false,
    loading: false,
    showBoth: false,
    showSearch: false,
    active: 0,
    btnIdArray: []
  };
  componentDidMount() {
    axios
      // .get("https://admin.akclasses.in/api/coursesR?type=Courses")
      .get("https://admin.akclasses.in/api/coursesForFilter")
      .then(resp => {
        console.log(resp);
        this.setState({
          courses: resp.data
        });
      });
  }
  handleSelect = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        const payload = {
          status: this.state.status
        };
        axios
          .post(
            "https://admin.akclasses.in/api/courseKey/status/" + id,
            payload
          )
          .then(() => {
            //console.log(res);
            this.setState({
              notifyStatus: true
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
      }
    );
  };
  handleDelete = id => {
    this.setState({ delLoading: true });
    axios
      .post("https://admin.akclasses.in/api/courseKey/delete/" + id)
      .then(() => {
        this.setState({
          notifyDelete: true,
          delLoading: false
        });
        setTimeout(() => {
          // window.location.reload();
          this.searchUser();
        }, 2000);
      });
  };
  handleModal = sec => {
    this.setState({
      rowId: sec.ID,
      visible: true
    });
  };
  // handleModal = id => {
  //   this.setState({ active: id });
  //   axios
  //     .post("https://admin.akclasses.in/api/courseKey/delete/" + id)
  //     .then(() => {
  //       // console.log(res.data);
  //       this.setState({
  //         delNot: true,
  //         active: 0
  //       });
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 2000);
  //     });
  // };
  handleDrop = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        showBoth: false
      },
      () => {
        console.log(this.state.course)
        // this.setState({
        //   courses: []
        // })
        axios
          .get("https://admin.akclasses.in/api/course/" + this.state.course)
          .then(resp => {
            console.log(resp.data);
            this.setState({
              catData: resp.data.product.variant
            });
            let viewIds = [];
            let views = [];
            this.state.catData.forEach(v => {
              if (viewIds.includes(v.view_and_validity.id) === false) {
                viewIds.push(v.view_and_validity.id);
                views.push(v.view_and_validity);
              }
            });
            //console.log(views);
            this.setState({ catData: views }, () => {
              this.setState({
                showBoth: this.state.catData.length >= 1 ? true : false
              });
            });
          });
      }
    );
  };
  searchUser = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/course_with_key/" +
          this.state.course +
          "?view_validity_id=" +
          this.state.category
      )
      .then(resp => {
        // console.log(resp.data);
        this.setState({
          operatinalCategories: resp.data.courses_key,
          secondaryCategories: resp.data.courses_key,
          showButtons: true
        });
      });
  };
  handleOrders = index => {
    let filteredSecondaryCategories = [];
    this.state.operatinalCategories.forEach(v => {
      if (v.IS_USED === index) {
        filteredSecondaryCategories.push(v);
      }
    });
    // console.log(filteredSecondaryCategories);
    this.setState({
      secondaryCategories: filteredSecondaryCategories,
      act: index
    });
  };
  render() {
    return (
      <div>
        <GridContainer>
          {this.state.notifyStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Status Changed!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          {this.state.delNot ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Product Key Deleted!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody>
                <div
                  style={{
                    position: "relative",
                    top: "40px"
                  }}
                >
                  {this.state.showButtons ? (
                    <div>
                      {orders.map((btn, index) => (
                        <Button
                          key={index}
                          id={btn}
                          color={this.state.act === index ? "danger " : "info"}
                          onClick={() => {
                            this.handleOrders(index);
                          }}
                        >
                          {btn}
                        </Button>
                      ))}
                    </div>
                  ) : null}
                  <GridItem
                    style={{
                      position: "relative",
                      left: "68%",
                      bottom: "40px"
                    }}
                    xs={12}
                    sm={12}
                    md={3}
                  >
                    <FormControl
                      style={{ marginTop: "12px" }}
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        Search By Course
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={this.state.course}
                        inputProps={{
                          name: "course",
                          id: "simple-select",
                          onChange: e => {
                            this.handleDrop(e);
                          }
                        }}
                      >
                        {this.state.courses.map((v, i) => (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            key={i}
                            value={v.ID}
                          >
                            <span>
                            {v.TITLE}(
                            {v.faculties === null
                              ? "AK Team"
                              : (v.faculties.name)}
                            )
                            </span>
                            
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  {this.state.showBoth ? (
                    <div>
                      <GridItem
                        style={{
                          position: "relative",
                          left: "68%",
                          bottom: "40px"
                        }}
                        xs={12}
                        sm={12}
                        md={3}
                      >
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Views Validity
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.category}
                            inputProps={{
                              name: "category",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  category: e.target.value,
                                  showSearch: true
                                });
                                // //console.log(e.target.value);
                              }
                            }}
                          >
                            {this.state.catData.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.view_and_validity}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.showSearch ? (
                          <Button
                            onClick={this.searchUser}
                            style={{
                              position: "relative",
                              left: "45%"
                            }}
                            color="success"
                          >
                            Search
                          </Button>
                        ) : null}
                      </GridItem>
                    </div>
                  ) : null}
                </div>

                <h3>Product Keys</h3>
                <Link to="/admin/addproductkey">
                  <Button className="" color="warning">
                    Add Product Key
                  </Button>
                </Link>
                <Table
                  tableHead={[
                    "Sr No.",
                    "Views Validity",
                    "Product Key",
                    "Used By",
                    "Extended",
                    "Delete",
                    "Update"
                  ]}
                  tableData={this.state.secondaryCategories.map(
                    (sec, index) => [
                      [index + 1],
                      [
                        sec.view_validity
                          ? sec.view_validity.view_and_validity
                          : ""
                      ],
                      [sec.PRODUCT_KEY],
                      [
                        <div key={index}>
                          <div style={{ color: "blue", fontWeight: "600" }}>
                            {sec.user
                              ? (sec.user.FIRST_NAME
                                  ? sec.user.FIRST_NAME
                                  : "") +
                                " " +
                                (sec.user.LAST_NAME ? sec.user.LAST_NAME : "")
                              : ""}
                          </div>
                          <div> {sec.ORDER_NO ? sec.ORDER_NO : ""}</div>
                        </div>
                      ],
                      [sec.PRODUCT_KEY_EXTENDED],
                      // [
                      //   <Select
                      //     key={index}
                      //     MenuProps={{
                      //       className: classes.selectMenu
                      //     }}
                      //     classes={{
                      //       select: classes.select
                      //     }}
                      //     value={sec.STATUS}
                      //     inputProps={{
                      //       name: "status",
                      //       id: "simple-select",
                      //       onChange: e => {
                      //         this.handleSelect(e, sec.ID);
                      //       }
                      //     }}
                      //   >
                      //     <MenuItem
                      //       classes={{
                      //         root: classes.selectMenuItem,
                      //         selected: classes.selectMenuItemSelected
                      //       }}
                      //       value="ACTIVE"
                      //     >
                      //       ACTIVE
                      //     </MenuItem>
                      //     <MenuItem
                      //       classes={{
                      //         root: classes.selectMenuItem,
                      //         selected: classes.selectMenuItemSelected
                      //       }}
                      //       value="INACTIVE"
                      //     >
                      //       INACTIVE
                      //     </MenuItem>
                      //   </Select>
                      // ],
                      [
                        <Button
                          key={index}
                          onClick={() => {
                            this.handleModal(sec);
                          }}
                          className="btn-round"
                          color="danger"
                        >
                          Delete
                        </Button>
                      ],
                      [
                        <Link
                          key={index}
                          to={{
                            pathname: "/admin/updateproductkey/" + sec.ID,
                            state: { categoryData: sec }
                          }}
                        >
                          <Button className="btn-round" color="info">
                            Update
                          </Button>
                        </Link>
                      ]
                    ]
                  )}
                />
              </CardBody>
            </Card>
          </GridItem>
          <Modal
            visible={this.state.visible}
            width="600"
            height="100"
            effect="fadeInUp"
            onClickAway={() => this.setState({ visible: false })}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  {/* <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                  <h4>Stacked Form</h4> 
                  </CardHeader> */}
                  <CardBody>
                    <div>
                      <h3>Are You Sure You Want to Delete It?</h3>
                      {this.state.notifyDelete ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Product Key Deleted Successfuly!
                          </span>
                        </div>
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={() => {
                              this.handleDelete(this.state.rowId);
                            }}
                          >
                            {this.state.delLoading ? <Loader /> : "Yes"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            No
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Modal>
        </GridContainer>
        {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : null}
      </div>
    );
  }
}

export default ProductKeys;
