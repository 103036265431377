import React, { Component } from "react";
import "../../scss/Checkout.scss";
// import Constants from './common/Constants';
import { Redirect, Link } from "react-router-dom";
import Lottie from "lottie-react-web";
import loader from "../../images/loader.json";
import Modal from "react-awesome-modal";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
// import Constants from "../../components/common/Constants";`
import webAkLogo from "../../images/AK-logo1.png";

import Datetime from "react-datetime";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
const classes = makeStyles(styles);

window.telCode = "+91";
window.mobile = "";
window.fName = "";
window.name = "";
window.email = "";
window.social_token = "";
window.otpView = false;
window.otpVerified = false;
window.payLoad = {
  FIRST_NAME: "",
  lname: "",
  city: "",
  state: "",
  visible: false,
  warn1: false,
  warn2: false,
  mobile: "",
  address: 0,
  discount: 0,
  taxAmount: 0,
  coupon: 0,
  pincode: "",
  email: "",
  subTotal: 0,
  couponId: 0,
  payMode: "",
  DeliveryCharge: "",
  cartData: [],
  pincodeList: [],
  shippingAddress: [],
  cartId: 0,
  grand_total: 0,
  extendproductPrice: "",
  last_name: "",
  coupon: 0,
  attempt: 0,
  product_detail_for_order: {},
  openEye: false,
  sku: "",
  send_sku: "",
};
const courses = ["CA Foundation", "CA Inter", "CA Final"];
export default class Checkout extends Component {
  state = {
    showLogin: true,
    regForm: true,
    FIRST_NAME: "",
    contact_no: 0,
    email: "",
    address1: "",
    address_2: "",
    city: "",
    state: "",
    pincode: 0,

    orderDate: "",
    delivery: ["Order Placed", "Shipped", "Cancelled"],
    payment: ["Collected", "Not_Collected"],
    vendors: [],
    delStatus: "",
    payStatus: "",
    akShare: 100,
    total: "",
    shipping_date: "",
    productId: "",
    reference: "",
    orderTime: "",
    mediaId: "",
    osId: "",
    attempt: "",
    exMode: "",
    variantId: "",
    viewId: "",
    send_mail_to_user: "",
    send_key: "",
    staticCourses: "",
    batchwings: "",
    batch: "",
    sku: "",
    product: {},
    exModes: [],
    staticCourses: "",
    btnLoading: false,
    redirect: false,
    products: [],
    variants: [],
    mediaTypes: [],
    osTypes: [],
    views: [],
    message: "",
    notification: false,
  };
  // }

  componentDidMount() {
    // console.log(this.props.location.state);
    const props = this.props;
    this.setState(
      {
        productId: props?.product_details?.product_id,
        variantId: props?.product_details?.variant_id,
        batch: props?.batchId,
        batchwings: props?.batchWingId,
        attempt: props?.product_details?.attempt,
        mediaId: props?.product_details?.media_type_id,
        osId: props?.product_details?.os_type_id,
        send_sku: props?.sku,
        total: props?.grand_total,
        product: props?.product_details,
        viewId: props?.product_details?.view_and_validity_id,
      },
      () => {
        this.handleVariants();
      }
    );

    axios.get("https://admin.akclasses.in/api/venderR").then((resp) => {
      // console.log(resp.data);
      this.setState({
        vendors: resp.data,
      });
    });
    axios
      .get("https://admin.akclasses.in/api/generate_orderId")
      .then((resp) => {
        //console.log(resp.data);
        this.setState({
          orderId: resp.data,
        });
      });
    axios
      .get("https://admin.akclasses.in/api/expenseCategoriesR")
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          exModes: resp.data,
        });
      });
    if (localStorage.token) {
      this.setState({
        login: true,
      });
    }
  }
  handleVariants = (e) => {
    // this.setState(
    //   {
    //     [e.target.name]: e.target.value,
    //     cAlert: false,
    //   },
    //   () => {
    axios
      // .get("https://admin.akclasses.in/api/getCoursesActiveVarient/" + this.state.productId)
      .get(
        "https://admin.akclasses.in/api/courseActiveVarients/" +
          this.state.productId
      )
      .then((resp) => {
        console.log(resp);
        this.setState(
          {
            variants: resp.data.product.variant,
            product_sku: resp.data?.product.SKU,
          },
          () => {
            this.setState(
              {
                mediaId: this.state.variants[0]
                  ? this.state.variants[0].media_type_id
                  : "",
                osId: this.state.variants[0]
                  ? this.state.variants[0].os_type_id
                  : "",
                viewId: this.state.variants[0]
                  ? this.state.variants[0].view_and_validity_id
                  : "",
                variantId: this.state.variants[0]
                  ? this.state.variants[0].id
                  : "",
                total: this.state.variants[0]
                  ? this.state.variants[0].offer_price
                  : 0,
              },
              () => {
                let osIds = [];
                let osTypes = [];
                this.state.variants.forEach((v) => {
                  if (osIds.includes(v.os_type.id) === false) {
                    osIds.push(v.os_type.id);
                    osTypes.push(v.os_type);
                  }
                });
                //console.log(osTypes);
                this.setState({ osTypes });
                let mediaTypes = [];
                let mediaIds = [];
                this.state.variants.forEach((v) => {
                  if (mediaIds.includes(v.media_type.id) === false) {
                    if (v.os_type_id === this.state.osId) {
                      mediaIds.push(v.media_type.id);
                      mediaTypes.push(v.media_type);
                    }
                  }
                });
                //console.log(mediaTypes);
                this.setState({ mediaTypes });
                let viewIds = [];
                let views = [];
                this.state.variants.forEach((v) => {
                  if (v.view_and_validity) {
                    if (viewIds.includes(v.view_and_validity.id) === false) {
                      if (
                        v.os_type_id === this.state.osId &&
                        v.media_type_id === this.state.mediaId
                      ) {
                        viewIds.push(v.view_and_validity.id);
                        views.push(v.view_and_validity);
                      }
                    }
                  }
                });
                //console.log(views);
                this.setState({ views });
              }
            );
          }
        );
        let facultyCommission = 0;
        resp.data.product.faculty.forEach((v) => {
          facultyCommission += v.faulty_share;
        });
        if (this.state.vendorSelected) {
          axios
            .get(
              "https://admin.akclasses.in/api/get_vendor_product/" +
                this.state.reference +
                "?product_id=" +
                this.state.productId
            )
            .then((resp) => {
              console.log(resp.data);
              if (resp.data.length > 0) {
                let vendorCommission = resp.data.find(
                  (v) => v.product_id === parseInt(this.state.productId)
                ).commission_percent;
                this.setState(
                  {
                    vendorCommission,
                    warning: false,
                  },
                  () => {
                    // console.log(vendorCommission);
                    let total =
                      facultyCommission + parseInt(this.state.vendorCommission);
                    this.setState({
                      akShare: 100 - total,
                    });
                  }
                );
              } else {
                this.setState({
                  warning: true,
                  akShare: 100 - facultyCommission,
                });
              }
            });
        } else {
          this.setState({
            akShare: 100 - facultyCommission,
            facultyCommission,
          });
        }
      });
    //   }
    // );
    // //console.log(e.target.value);
  };

  validateName = (text) => {
    console.log(text);
    var reg = /^[A-Za-z ]+$/;
    if (reg.test(text) === false) {
      console.log("Name is Not Correct");
      this.setState({
        name: text,
        showCorrectName: false,
        showNameError: true,
      });
      return false;
    } else {
      this.setState({
        name: text,
        showCorrectName: true,
        showNameError: false,
      });
      console.log("Name is Correct");
    }
  };
  validateEmail = (text) => {
    console.log(text);
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      console.log("Email is Not Correct");
      this.setState({
        email: text,
        showCorrectEmail: false,
        showEmailError: true,
      });
      return false;
    } else {
      this.setState({
        email: text,
        showCorrectEmail: true,
        showEmailError: false,
      });
      console.log("Email is Correct");
    }
  };
  validateEmail1 = (text) => {
    console.log(text);
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      console.log("Email is Not Correct");
      this.setState({
        EMAIL1: text,
        showCorrectEmail1: false,
        showEmailError1: true,
      });
      return false;
    } else {
      this.setState({
        EMAIL1: text,
        showCorrectEmail1: true,
        showEmailError1: false,
      });
      console.log("Email is Correct");
    }
  };

  validateMobile(text) {
    const reg = /^[0]?[6789]\d{9}$/;
    if (reg.test(text) === false) {
      this.setState({
        mobilevalidate: false,
        mobileNumber: text,
        showmobileError: true,
      });
      return false;
    } else {
      this.setState({
        mobilevalidate: true,
        mobileNumber: text,
        message: "",
        showmobileError: false,
      });
      return true;
    }
  }
  validateCity = (text) => {
    console.log(text);
    var reg = /^[A-Za-z ]+$/;
    if (reg.test(text) === false) {
      console.log("Name is Not Correct");
      this.setState({
        city: text,
        showCorrectCity: false,
        showCityError: true,
      });
      return false;
    } else {
      this.setState({
        city: text,
        showCorrectCity: true,
        showCityError: false,
      });
      console.log("Name is Correct");
    }
  };
  validateState = (text) => {
    console.log(text);
    var reg = /^[A-Za-z ]+$/;
    if (reg.test(text) === false) {
      console.log("Name is Not Correct");
      this.setState({
        stateM: text,
        showCorrectState: false,
        showStateError: true,
      });
      return false;
    } else {
      this.setState({
        stateM: text,
        showCorrectState: true,
        showStateError: false,
      });
      console.log("Name is Correct");
    }
  };
  validatePincode(text) {
    const reg = /^[0]?[789]\d{5}$/;
    if (reg.test(text) === false) {
      this.setState(
        {
          showCorrectPincode: false,
          pincode: text,
          showPincodeError: true,
        },
        () => {
          console.log(this.state.showCorrectPincode);
        }
      );
      return false;
    } else {
      this.setState(
        {
          showCorrectPincode: true,
          pincode: text,
          showPincodeError: false,
        },
        () => {
          console.log(this.state.showCorrectPincode);
        }
      );
      return true;
    }
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  placeOrder = (e) => {
    e.preventDefault();
    this.setState({
      btnLoading: true,
      loading: true,
    });
    let fd = new FormData();
    fd.append("name", this.state.FIRST_NAME);
    fd.append("contact_no", this.state.contact_no);
    fd.append("email", this.state.email);
    fd.append("address", this.state.address1);
    fd.append("address_2", "");
    fd.append("city", this.state.city);
    fd.append("state", this.state.state);
    fd.append("country", "");
    fd.append("pincode", this.state.pincode);
    fd.append("delivery_status", this.state.delStatus);
    fd.append("payment_status", this.state.payStatus);
    fd.append("shipping_date", "");
    fd.append("product_details", this.state.productId);
    fd.append("vender", this.state.reference);
    fd.append("shipping", "");
    fd.append("grand_total", this.state.total);
    fd.append("ordID", this.state.orderId);
    fd.append("order_type", "COURSE");
    fd.append("exam_given", "no");
    fd.append("order_date", this.state.orderDate);
    fd.append("order_time", this.state.orderTime);
    fd.append("media_type_id", this.state.mediaId);
    fd.append("os_type_id", this.state.osId);
    fd.append("ak_share", this.state.akShare);
    fd.append("attempt", this.state.attempt);
    fd.append("mode", this.state.exMode);
    fd.append("variant_id", this.state.variantId);
    fd.append("view_and_validity_id", this.state.viewId);
    fd.append("send_mail_to_user", this.state.send_mail_to_user);
    fd.append("send_key", this.state.send_key);
    fd.append("course", this.state.staticCourses);
    fd.append("batch_wings_id", this.state.batchwings);
    fd.append("batch_id", this.state.batch);
    fd.append("sku", this.state.sku);

    // console.log(fd);
    // return;
    axios
      .post("https://admin.akclasses.in/api/storeOfflineOrder", fd)
      .then((resp) => {
        console.log(resp);
        if (resp.data.status === "success") {
          this.setState({
            loading: false,
            btnLoading: false,
            notification: true,
            message: resp.data.message,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
              redirect: true,
            });
          }, 4000);
        }
        if (resp.data.status === "failed") {
          this.setState({
            loading: false,
            btnLoading: false,
            notification: true,
            message: resp.data.message,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
            });
          }, 4000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/offlineorders"} />;
    } else {
      return (
        <>
          {this.state.notification ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={this.state.message}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <section className="section1">
            <h1 className="fonts2">Checkout</h1>
            {/* <h1 className="fonts">fill your details and place your order</h1> */}
          </section>
          <section className="section-b-space" style={{ paddingTop: "16px" }}>
            <div className="container contain2 padding-cls">
              <div className="checkout-page">
                <div className="checkout-form">
                  <div className="checkout">
                    <div>
                      <div
                        className="row"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "50% 50%",
                        }}
                      >
                        {this.state.showLogin ? (
                          <div className="col-sm-6 col-md-6">
                            {this.state.regForm ? (
                              <div
                                className=""
                                style={{
                                  // background: "#f9f9f9",
                                  background: "#fff",

                                  padding: "25px",
                                  boxShadow:
                                    "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                                }}
                              >
                                <div className="checkout-title">
                                  <div
                                    className="row"
                                    style={{
                                      display: "grid",
                                      gridTemplateColumns: "50%",
                                    }}
                                  >
                                    <div className="col-md-7">
                                      <h3>Billing and Shipping</h3>
                                    </div>
                                  </div>
                                </div>
                                <form
                                  onSubmit={(e) => {
                                    this.placeOrder(e);
                                  }}
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "50%",
                                  }}
                                >
                                  <div className="">
                                    {/* <div className="checkout-title">
                                       <h3>Add Address</h3>
                                     </div> */}
                                    <div className="row check-out">
                                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                        <div className="field-label">
                                          Order Id{" "}
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="orderId"
                                          value={this.state.orderId}
                                          required
                                          // onChange={(e) => {
                                          //   this.setState({
                                          //     orderId: e.target.value,
                                          //   });
                                          // }}
                                          style={{
                                            borderRadius: "5px",
                                            fontSize: "14px",
                                          }}
                                        />

                                        <div>
                                          <Datetime
                                            timeFormat={false}
                                            onChange={(e) => {
                                              let year = e._d
                                                .getFullYear()
                                                .toString();
                                              let month = (
                                                e._d.getMonth() + 101
                                              )
                                                .toString()
                                                .substring(1);
                                              let day = (e._d.getDate() + 100)
                                                .toString()
                                                .substring(1);
                                              let time = new Date(); // for now
                                              let hour = time.getHours();
                                              let minute = time.getMinutes();
                                              let second = time.getSeconds();

                                              this.setState(
                                                {
                                                  orderDate:
                                                    year +
                                                    "/" +
                                                    month +
                                                    "/" +
                                                    day,
                                                  orderTime:
                                                    hour +
                                                    ":" +
                                                    minute +
                                                    ":" +
                                                    second,
                                                },
                                                () => {
                                                  // console.log(this.state.orderDate);
                                                  // console.log(this.state.orderTime);
                                                }
                                              );
                                            }}
                                            inputProps={{
                                              required: true,
                                              placeholder: "Order Date",
                                            }}
                                          />
                                        </div>

                                        {/* NAME OF USER */}

                                        <div className="field-label">
                                          Name{" "}
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="FIRST_NAME"
                                          value={this.state.FIRST_NAME}
                                          required
                                          onChange={(e) => {
                                            this.setState(
                                              { FIRST_NAME: e.target.value },
                                              () => {
                                                this.validateName(
                                                  this.state.FIRST_NAME
                                                );
                                              }
                                            );
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            fontSize: "14px",
                                          }}
                                        />
                                        {this.state.showNameError &&
                                        this.state.FIRST_NAME.length > 0 ? (
                                          <p
                                            style={{
                                              color: "red",
                                              fontSize: 10,
                                            }}
                                          >
                                            Enter valid name
                                          </p>
                                        ) : null}
                                      </div>

                                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                        <div className="field-label">
                                          Email address{" "}
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        </div>
                                        <input
                                          type="email"
                                          name="EMAIL"
                                          className="form-control"
                                          value={this.state.email}
                                          required
                                          onChange={(e) => {
                                            this.setState(
                                              { email: e.target.value },
                                              () => {
                                                this.validateEmail(
                                                  this.state.email
                                                );
                                              }
                                            );
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            fontSize: "14px",
                                          }}
                                        />
                                        {this.state.showEmailError &&
                                        this.state.email.length > 0 ? (
                                          <p
                                            style={{
                                              color: "red",
                                              fontSize: 10,
                                            }}
                                          >
                                            Enter valid e-mail address
                                          </p>
                                        ) : null}
                                      </div>

                                      {/* MOBILE NUMBER */}

                                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                        <label className="field-label">
                                          Phone{" "}
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="contact_no"
                                          value={this.state.contact_no}
                                          required
                                          maxLength={10}
                                          onChange={(e) => {
                                            this.setState(
                                              { contact_no: e.target.value },
                                              () => {
                                                this.validateMobile(
                                                  this.state.contact_no
                                                );
                                              }
                                            );
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            fontSize: "14px",
                                          }}
                                        />
                                        {this.state.showmobileError &&
                                        this.state.contact_no.length > 0 ? (
                                          <p
                                            style={{
                                              color: "red",
                                              fontSize: 10,
                                            }}
                                          >
                                            Enter valid mobile
                                          </p>
                                        ) : null}
                                      </div>

                                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                        <label className="field-label">
                                          {" "}
                                          Street address{" "}
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="address1"
                                          value={this.state.address1}
                                          required
                                          onChange={(e) => {
                                            this.handleInput(e);
                                          }}
                                          placeholder="House number and street name"
                                          style={{
                                            borderRadius: "5px",
                                            fontSize: "14px",
                                          }}
                                        />
                                      </div>

                                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                        <label className="field-label">
                                          Town / City{" "}
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="city"
                                          value={this.state.city}
                                          required
                                          onChange={(e) => {
                                            this.setState(
                                              { city: e.target.value },
                                              () => {
                                                this.validateCity(
                                                  this.state.city
                                                );
                                              }
                                            );
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            fontSize: "14px",
                                          }}
                                        />
                                        {this.state.showCityError &&
                                        this.state.city.length > 0 ? (
                                          <p
                                            style={{
                                              color: "red",
                                              fontSize: 10,
                                            }}
                                          >
                                            Enter valid City Name
                                          </p>
                                        ) : null}
                                      </div>

                                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                        <label className="field-label">
                                          State{" "}
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="state"
                                          value={this.state.state}
                                          required
                                          onChange={(e) => {
                                            this.setState(
                                              { state: e.target.value },
                                              () => {
                                                this.validateState(
                                                  this.state.state
                                                );
                                              }
                                            );
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            fontSize: "14px",
                                          }}
                                        />
                                        {this.state.showStateError &&
                                        this.state.state.length > 0 ? (
                                          <p
                                            style={{
                                              color: "red",
                                              fontSize: 10,
                                            }}
                                          >
                                            Enter valid State
                                          </p>
                                        ) : null}
                                      </div>

                                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                        <label className="field-label">
                                          Pincode{" "}
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="pincode"
                                          maxLength={6}
                                          value={this.state.pincode}
                                          required
                                          onChange={(e) => {
                                            this.setState(
                                              { pincode: e.target.value },
                                              () => {
                                                // this.validatePincode(
                                                //   this.state.pincode
                                                // );
                                              }
                                            );
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            fontSize: "14px",
                                          }}
                                        />
                                        {/* {this.state.showPincodeError ? (
                                            <p
                                              style={{
                                                color: "red",
                                                fontSize: 10,
                                              }}
                                            >
                                              Enter valid Pincode
                                            </p>
                                          ) : null} */}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row check-out">
                                    {/* <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                       <div className="field-label">Name</div>
                                       <input
                                         type="text"
                                         name="FIRST_NAME"
                                         value={this.state.FIRST_NAME}
                                         required
                                         onChange={e => {
                                           this.handleInput(e);
                                         }}
                                       />
                                     </div> */}
                                  </div>
                                  <button
                                    to=""
                                    type="submit"
                                    className="btn btn-solid"
                                    style={{ textTransform: "capitalize" }}
                                    // onClick={() => {
                                    //   this.registerUser();
                                    // }}
                                  >
                                    {this.state.btnLoading ? (
                                      <Lottie
                                        options={{
                                          animationData: loader,
                                        }}
                                        style={{
                                          width: "50px",
                                          height: "30px",
                                        }}
                                      />
                                    ) : (
                                      " Place Order"
                                    )}
                                  </button>
                                </form>
                              </div>
                            ) : null}
                          </div>
                        ) : null}

                        <div
                          className={
                            window.innerWidth > 400
                              ? "col-lg-6 col-md-6 col-sm-6 col-xs-6"
                              : "col-lg-6 col-md-6 col-sm-12 col-xs-12"
                          }
                        >
                          <div className="checkoutOverlay" />
                          <div className="checkout-details">
                            <div className="order-box">
                              <div className="title-box">
                                <div
                                  className="row"
                                  style={{ marginBottom: "24px" }}
                                >
                                  <div className="col-md-7 col-xs-6">
                                    Product
                                  </div>
                                  <div className="col-md-5 col-xs-6">
                                    <span>Details</span>
                                  </div>
                                </div>
                                <ul className="sub-total row">
                                  <FormControl
                                    style={{ marginTop: "12px" }}
                                    fullWidth
                                    className={classes.selectFormControl}
                                  >
                                    <InputLabel
                                      htmlFor="simple-select"
                                      className={classes.selectLabel}
                                    >
                                      Course
                                    </InputLabel>
                                    <Select
                                      MenuProps={{
                                        className: classes.selectMenu,
                                      }}
                                      classes={{
                                        select: classes.select,
                                      }}
                                      style={{
                                        width: "50%",
                                      }}
                                      value={this.state.staticCourses}
                                      inputProps={{
                                        name: "staticCourses",
                                        id: "simple-select",
                                        onChange: (e) => {
                                          this.setState({
                                            staticCourses: e.target.value,
                                            // aAlert: false
                                          });
                                          // //console.log(e.target.value);
                                        },
                                      }}
                                    >
                                      {courses.map((v, i) => (
                                        <MenuItem
                                          classes={{
                                            root: classes.selectMenuItem,
                                            selected:
                                              classes.selectMenuItemSelected,
                                          }}
                                          key={i}
                                          value={v}
                                        >
                                          {v}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>

                                  <div
                                    className="col-md-7 col-xs-7"
                                    style={{
                                      marginTop: "50px",
                                    }}
                                  >
                                    <li>
                                      <span>{this.state.product?.TITLE}</span>
                                    </li>
                                  </div>
                                  <div className="col-md-5 col-xs-5">
                                    <li>
                                      <span className="count">
                                        <div className="field-label">
                                          ₹{" "}
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="otal"
                                          value={this.state.total}
                                          required
                                          onChange={(e) => {
                                            this.setState({
                                              total: e.target.value,
                                            });
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            fontSize: "14px",
                                            width: "50%",
                                          }}
                                        />
                                      </span>
                                    </li>

                                    {/* <li>
                                      <span className="count">
                                        <div className="field-label">
                                          AK Share
                                        </div>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="otal"
                                          value={this.state.akShare}
                                          required
                                          // onChange={(e) => {
                                          //   this.setState({
                                          //     total: e.target.value,
                                          //   });
                                          // }}
                                          style={{
                                            borderRadius: "5px",
                                            fontSize: "14px",
                                            width: "50%",
                                          }}
                                        />
                                      </span>
                                    </li> */}

                                    <FormControl
                                      style={{ marginTop: "12px" }}
                                      fullWidth
                                      className={classes.selectFormControl}
                                    >
                                      <InputLabel
                                        htmlFor="simple-select"
                                        className={classes.selectLabel}
                                      >
                                        Reference By
                                      </InputLabel>
                                      <Select
                                        MenuProps={{
                                          className: classes.selectMenu,
                                        }}
                                        classes={{
                                          select: classes.select,
                                        }}
                                        style={{
                                          width: "50%",
                                        }}
                                        value={this.state.reference}
                                        inputProps={{
                                          name: "reference",
                                          id: "simple-select",
                                          onChange: (e) => {
                                            this.setState(
                                              {
                                                reference: e.target.value,
                                                vendorSelected: true,
                                                gAlert: false,
                                              },
                                              () => {
                                                axios
                                                  .get(
                                                    "https://admin.akclasses.in/api/get_vendor_product/" +
                                                      this.state.reference +
                                                      "?product_id=" +
                                                      this.state.productId
                                                  )
                                                  .then((resp) => {
                                                    console.log(resp.data);
                                                    if (resp.data.length > 0) {
                                                      let vendorCommission = resp.data.find(
                                                        (v) =>
                                                          v.product_id ===
                                                          parseInt(
                                                            this.state.productId
                                                          )
                                                      ).commission_percent;
                                                      this.setState(
                                                        {
                                                          vendorCommission,
                                                          warning: false,
                                                        },
                                                        () => {
                                                          let total =
                                                            this.state
                                                              .facultyCommission +
                                                            parseInt(
                                                              this.state
                                                                .vendorCommission
                                                            );
                                                          this.setState({
                                                            akShare:
                                                              100 - total,
                                                          });
                                                        }
                                                      );
                                                    } else {
                                                      this.setState({
                                                        warning: true,
                                                      });
                                                    }
                                                  });
                                              }
                                            );
                                          },
                                        }}
                                      >
                                        {this.state.vendors.map((v, i) => (
                                          <MenuItem
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected:
                                                classes.selectMenuItemSelected,
                                            }}
                                            key={i}
                                            value={v?.id}
                                          >
                                            {v?.institute_name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                    {this.state.gAlert ? (
                                      <div>
                                        <span style={{ color: "red" }}>
                                          *required
                                        </span>
                                      </div>
                                    ) : null}
                                    {this.state.warning ? (
                                      <div>
                                        <span style={{ color: "red" }}>
                                          This Vendor is Not Available for
                                          Selected Product!!!
                                        </span>
                                      </div>
                                    ) : null}
                                    {/* INCOME MODE SELECT */}
                                    <FormControl
                                      style={{ marginTop: "12px" }}
                                      fullWidth
                                      className={classes.selectFormControl}
                                    >
                                      <InputLabel
                                        htmlFor="simple-select"
                                        className={classes.selectLabel}
                                      >
                                        Income Mode
                                      </InputLabel>
                                      <Select
                                        MenuProps={{
                                          className: classes.selectMenu,
                                        }}
                                        classes={{
                                          select: classes.select,
                                        }}
                                        style={{
                                          width: "50%",
                                        }}
                                        value={this.state.exMode}
                                        inputProps={{
                                          name: "exMode",
                                          id: "simple-select",
                                          onChange: (e) => {
                                            this.setState({
                                              exMode: e.target.value,
                                              hAlert: false,
                                            });
                                            // //console.log(e.target.value);
                                          },
                                        }}
                                      >
                                        {this.state.exModes.map((v, i) => (
                                          <MenuItem
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected:
                                                classes.selectMenuItemSelected,
                                            }}
                                            key={i}
                                            value={v.ID}
                                          >
                                            {v.CATEGORY}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                    {this.state.hAlert ? (
                                      <div>
                                        <span style={{ color: "red" }}>
                                          *required
                                        </span>
                                      </div>
                                    ) : null}
                                    {/* SEND MAIL TO USER */}
                                    <FormControl
                                      style={{ marginTop: "12px" }}
                                      fullWidth
                                      className={classes.selectFormControl}
                                    >
                                      <InputLabel
                                        htmlFor="simple-select"
                                        className={classes.selectLabel}
                                      >
                                        Send Mail To User
                                      </InputLabel>
                                      <Select
                                        MenuProps={{
                                          className: classes.selectMenu,
                                        }}
                                        classes={{
                                          select: classes.select,
                                        }}
                                        style={{
                                          width: "50%",
                                        }}
                                        required
                                        value={this.state.send_mail_to_user}
                                        inputProps={{
                                          name: "send_mail_to_user",
                                          id: "simple-select",
                                          onChange: (e) => {
                                            this.setState({
                                              send_mail_to_user: e.target.value,
                                              jAlert: false,
                                            });
                                            // //console.log(e.target.value);
                                          },
                                        }}
                                      >
                                        {/* {this.state.attempts.map((v, i) => ( */}
                                        <MenuItem
                                          classes={{
                                            root: classes.selectMenuItem,
                                            selected:
                                              classes.selectMenuItemSelected,
                                          }}
                                          // key={i}
                                          value="Yes"
                                        >
                                          Yes
                                        </MenuItem>
                                        <MenuItem
                                          classes={{
                                            root: classes.selectMenuItem,
                                            selected:
                                              classes.selectMenuItemSelected,
                                          }}
                                          // key={i}
                                          value="No"
                                        >
                                          No
                                        </MenuItem>
                                        {/* ))} */}
                                      </Select>
                                    </FormControl>
                                    {/* ADD PRODUCT KEY TO USER */}
                                    <FormControl
                                      style={{ marginTop: "12px" }}
                                      fullWidth
                                      className={classes.selectFormControl}
                                    >
                                      <InputLabel
                                        htmlFor="simple-select"
                                        className={classes.selectLabel}
                                      >
                                        Send Product Key
                                      </InputLabel>
                                      <Select
                                        MenuProps={{
                                          className: classes.selectMenu,
                                        }}
                                        classes={{
                                          select: classes.select,
                                        }}
                                        style={{
                                          width: "50%",
                                        }}
                                        value={this.state.send_key}
                                        required
                                        inputProps={{
                                          name: "send_key",
                                          id: "simple-select",
                                          onChange: (e) => {
                                            this.setState({
                                              send_key: e.target.value,
                                              kAlert: false,
                                            });
                                            if (e.target.value === "Yes") {
                                              this.setState({
                                                sku: this.state.send_sku,
                                              });
                                            }
                                            // //console.log(e.target.value);
                                          },
                                        }}
                                      >
                                        {/* {this.state.attempts.map((v, i) => ( */}
                                        <MenuItem
                                          classes={{
                                            root: classes.selectMenuItem,
                                            selected:
                                              classes.selectMenuItemSelected,
                                          }}
                                          // key={i}
                                          value="Yes"
                                        >
                                          Yes
                                        </MenuItem>
                                        <MenuItem
                                          classes={{
                                            root: classes.selectMenuItem,
                                            selected:
                                              classes.selectMenuItemSelected,
                                          }}
                                          // key={i}
                                          value="No"
                                        >
                                          No
                                        </MenuItem>
                                        {/* ))} */}
                                      </Select>
                                    </FormControl>

                                    <div
                                      className="form-group col-md-12 col-sm-12 col-xs-12"
                                      style={{
                                        marginTop: "50px",
                                      }}
                                    >
                                      <FormControl
                                        style={{ marginTop: "12px" }}
                                        fullWidth
                                        className={classes.selectFormControl}
                                      >
                                        <InputLabel
                                          htmlFor="simple-select"
                                          className={classes.selectLabel}
                                        >
                                          Delivery Status
                                        </InputLabel>
                                        <Select
                                          MenuProps={{
                                            className: classes.selectMenu,
                                          }}
                                          classes={{
                                            select: classes.select,
                                          }}
                                          style={{
                                            width: "50%",
                                          }}
                                          value={this.state.delStatus}
                                          inputProps={{
                                            name: "delStatus",
                                            id: "simple-select",
                                            onChange: (e) => {
                                              this.setState({
                                                delStatus: e.target.value,
                                                aAlert: false,
                                              });
                                              // //console.log(e.target.value);
                                            },
                                          }}
                                        >
                                          {this.state.delivery.map((v, i) => (
                                            <MenuItem
                                              classes={{
                                                root: classes.selectMenuItem,
                                                selected:
                                                  classes.selectMenuItemSelected,
                                              }}
                                              key={i}
                                              value={v}
                                            >
                                              {v}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      {/* {this.validator.message('phone', this.state.phone, 'required|phone')} */}
                                    </div>
                                    <div
                                      className="form-group col-md-12 col-sm-12 col-xs-12"
                                      style={{ marginBottom: 15 }}
                                    >
                                      <FormControl
                                        style={{ marginTop: "12px" }}
                                        fullWidth
                                        className={classes.selectFormControl}
                                      >
                                        <InputLabel
                                          htmlFor="simple-select"
                                          className={classes.selectLabel}
                                        >
                                          Payment Status
                                        </InputLabel>
                                        <Select
                                          MenuProps={{
                                            className: classes.selectMenu,
                                          }}
                                          classes={{
                                            select: classes.select,
                                          }}
                                          style={{
                                            width: "50%",
                                          }}
                                          value={this.state.payStatus}
                                          inputProps={{
                                            name: "payStatus",
                                            id: "simple-select",
                                            onChange: (e) => {
                                              this.setState({
                                                payStatus: e.target.value,
                                                bAlert: false,
                                              });
                                              // //console.log(e.target.value);
                                            },
                                          }}
                                        >
                                          {this.state.payment.map((v, i) => (
                                            <MenuItem
                                              classes={{
                                                root: classes.selectMenuItem,
                                                selected:
                                                  classes.selectMenuItemSelected,
                                              }}
                                              key={i}
                                              value={v}
                                            >
                                              {v}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </div>
                                    {this.state.passError ? (
                                      <div>
                                        <span
                                          style={{
                                            color: "red",
                                            marginLeft: "20px",
                                            marginBottom: "12px",
                                            display: "inline-flex",
                                          }}
                                        >
                                          Select Payment status
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Modal
                  visible={this.state.paymentError}
                  width="350"
                  height="300"
                  effect="fadeInUp"
                >
                  <div>
                    <h4> Something went wrong .</h4>
                    <span onClick={this.placeOrder}>Try Again</span>
                    <span onClick={this.closeModal}>Cancel</span>
                  </div>
                </Modal>
              </div>
            </div>
          </section>
        </>
      );
    }
  }
}
