/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import FormControl from "@material-ui/core/FormControl";
// import editorConfiguration from "../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Modal from "react-awesome-modal";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Constants from "variables/Constants";
const classes = makeStyles(styles);

let ProgressBar = (props) => {
  return (
    <div className="progress-bar">
      <Filler percentage={props.percentage} />
    </div>
  );
};
let Filler = (props) => {
  return (
    <div className="filler" style={{ width: `${props.percentage}%` }}></div>
  );
};
const facultyIds = [];
let fd = new FormData();
class AddTestSeries extends Component {
  state = {
    category: "",
    test_series_type: "",
    combo_test_id: "",
    threexprice: "",
    twoxprice: "",
    title: "",
    akshare: 100,
    duration: "",
    faculty: [],
    index: [],
    thumb: [],
    vendorCommission: 0,
    atLeast: false,
    facultyCommission: 0,
    facultyArr: [],
    img: [],
    sku: "",
    levels: [],
    streams: [],
    testTypess: ["Free", "Paid"],
    testTypes: "",
    level: "",
    stream: "",
    facultyshare: "",
    proDesc: "",
    courseDetails: "",
    offValue: "",
    demoLink1: "",
    demoLink2: "",
    demoLink3: "",
    demoLink4: "",
    offType: "",
    offTypeArray: ["percent", "amount"],
    specification: "",
    testType: ["Optional", "Upload"],
    cashback: "",
    discount: "",
    gcLink: "",
    gwLink: "",
    gaLink: "",
    courses: [],
    productId: "",
    facultyWithName: "",
    facultyId: "",
    courseCategories: [],
    type: ["Normal", "Combo"],
    subject: "",
    subjects: [],
    combo_type: "",
    redirect: false,
    notification: false,
    dropShow: false,
    aAlert: false,
    bAlert: false,
    cAlert: false,
    eAlert: false,
    fAlert: false,
    gAlert: false,
    iAlert: false,
    hAlert: false,
    dAlert: false,
    loading: false,
    question: "",
    last_insert_id: "",
    questionsLimit: "",
    max: false,
  };
  componentDidMount() {
    axios.get("https://admin.akclasses.in/api/courses_combo").then((resp) => {
      // console.log(resp.data);
      this.setState({
        courses: resp.data,
      });
    });
    axios.get("https://admin.akclasses.in/api/facultyR").then((resp) => {
      //console.log(resp.data);
      this.setState({
        facultyArr: resp.data,
      });
    });
    axios.get("https://admin.akclasses.in/api/testCategoriesR").then((resp) => {
      //console.log(resp.data);
      this.setState({
        courseCategories: resp.data,
      });
    });

    axios
      .get(Constants.getUrls.getQuestionBank + "?per_page=10")
      .then((resp) => {
        // console.log(resp);
        this.setState({
          questionsLimit: resp.data.total,
        });
      });
  }

  handleLevel = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        bAlert: false,
      },
      () => {
        //console.log(this.state.category);
        axios
          .get(
            "https://admin.akclasses.in/api/showCategory_with_level/" +
              this.state.category
          )
          .then((resp) => {
            // console.log(resp.data);
            this.setState({
              levels: resp.data.level,
            });
          });
      }
    );
  };

  handleStream = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        eAlert: false,
      },
      () => {
        //console.log(this.state.level);
        axios
          .get("https://admin.akclasses.in/api/level/" + this.state.level)
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              streams: resp.data.stream,
            });
          });
      }
    );
  };
  handleSubject = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fAlert: false,
      },
      () => {
        // console.log(this.state.stream);
        axios
          .get("https://admin.akclasses.in/api/stream/" + this.state.stream)
          .then((resp) => {
            // console.log(resp.data);
            this.setState({
              subjects: resp.data.stream.subject_masters,
            });
          });
      }
    );
  };
  handleSubmit = (e) => {
    e.preventDefault();
    let facultyDetails = [];
    this.state.faculty.forEach((v) => {
      facultyDetails.push({ fid: v.fid, fshare: v.fshare });
    });
    if (facultyDetails.length === 0) {
      this.setState({ atLeast: true });
    } else if (this.state.combo_type === "") {
      this.setState({
        aAlert: true,
      });
    } else if (this.state.category === "") {
      this.setState({
        bAlert: true,
      });
    } else if (this.state.test_series_type === "") {
      this.setState({
        cAlert: true,
      });
    } else if (this.state.faculty === "") {
      this.setState({
        dAlert: true,
      });
    } else if (this.state.level === "") {
      this.setState({
        eAlert: true,
      });
    } else if (this.state.stream === "") {
      this.setState({
        fAlert: true,
      });
    } else if (this.state.offType === "") {
      this.setState({
        gAlert: true,
      });
    } else if (this.state.testTypes === "") {
      this.setState({
        hAlert: true,
      });
    } else if (this.state.subject === "") {
      this.setState({
        iAlert: true,
      });
    } else {
      this.setState({
        loading: true,
      });
      fd.append("ak_share", this.state.akshare);
      fd.append("discount_amount", this.state.discount);
      fd.append("cash_amount", this.state.cashback);
      fd.append("category", this.state.category);
      fd.append("combo_type", facultyDetails.length > 1 ? "Combo" : "Normal");
      fd.append("faculty_detail", JSON.stringify(facultyDetails));
      fd.append("sku", this.state.sku);
      fd.append("title", this.state.title);
      fd.append("short_description", this.state.courseDetails);
      fd.append("description", this.state.proDesc);
      fd.append("duration", this.state.duration);
      fd.append("price", this.state.twoxprice);
      fd.append("price_1", "");
      fd.append("image_path", this.state.img);
      fd.append("thumb_image", this.state.thumb);
      fd.append("what_learn", this.state.specification);
      fd.append("demo_link", this.state.demoLink1);
      fd.append("demo_link1", this.state.demoLink2);
      fd.append("demo_link2", this.state.demoLink3);
      fd.append("demo_link3", this.state.demoLink4);
      fd.append("syllabus_pdf", this.state.index);
      fd.append("common_link", this.state.gcLink);
      fd.append("window_link", this.state.gwLink);
      fd.append("subject_master_id", this.state.subject);
      fd.append("android_link", this.state.gaLink);
      fd.append("test_series_type", this.state.test_series_type);
      fd.append("test_type", this.state.testTypes);
      fd.append("combo_type", this.state.combo_type);
      fd.append("combo_test_id", this.state.combo_test_id);
      fd.append("level", this.state.level);
      fd.append("stream", this.state.stream);
      fd.append("offer_type", this.state.offType);
      fd.append("value", this.state.offValue);
      fd.append("type", "TEST_SERIES");
      fd.append("no_of_questions", parseFloat(this.state.question));
      fd.append("language", "Hindi");
      axios
        .post(
          // "https://admin.akclasses.in/api/course/create",
          Constants.postUrls.createCourse,
          fd,
          {
            onUploadProgress: (progressEvent) => {
              this.setState({
                progress: Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                ),
                mediaProgress: true,
              });
            },
          }
        )
        .then((resp) => {
          console.log(resp);
          this.setState({
            notification: true,
            loading: false,
            last_insert_id: resp.data.data.ID,
          });
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
        });
    }
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleFile = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.files[0],
      },
      () => {
        //console.log(this.state.img);
      }
    );
  };

  // handleQuestion = e => {
  //   console.log("hii");
  //   this.setState({
  //     question: e.target.value,
  //   });
  //   if(this.state.question > this.state.questionsLimit)
  //   {
  //     console.log("greater");
  //   }
  // }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={"/admin/addtestseriesquestion/" + btoa(this.state.last_insert_id)}
        />
      );
      // return <Redirect to={"/admin/testseries"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Test Series Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Test Series Master</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Test Series Type
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.combo_type}
                            inputProps={{
                              name: "combo_type",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState(
                                  {
                                    combo_type: e.target.value,
                                    aAlert: false,
                                  },
                                  () => {
                                    this.state.combo_type === "Combo"
                                      ? this.setState({
                                          dropShow: true,
                                        })
                                      : this.setState({ dropShow: false });
                                  }
                                );
                              },
                            }}
                          >
                            {this.state.type.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      {this.state.dropShow ? (
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Combo Test Series
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.combo_test_id}
                              inputProps={{
                                name: "combo_test_id",
                                id: "simple-select",
                                onChange: (e) => {
                                  this.setState({
                                    combo_test_id: e.target.value,
                                  });
                                },
                              }}
                            >
                              {this.state.courses.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v.ID}
                                >
                                  {v.TITLE}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                      ) : null}
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Category
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.category}
                            inputProps={{
                              name: "category",
                              id: "simple-select",
                              onChange: this.handleLevel,
                            }}
                          >
                            {this.state.courseCategories.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.ID}
                              >
                                {v.CATEGORY}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Level
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.level}
                            inputProps={{
                              required: true,
                              name: "level",
                              id: "simple-select",
                              onChange: this.handleStream,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Category First
                            </MenuItem>
                            {this.state.levels.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.eAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Stream
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.stream}
                            inputProps={{
                              required: true,
                              name: "stream",
                              id: "simple-select",
                              onChange: this.handleSubject,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Level First
                            </MenuItem>
                            {this.state.streams.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.fAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Subject
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.subject}
                            inputProps={{
                              required: true,
                              name: "subject",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  iAlert: false,
                                  subject: e.target.value,
                                });
                              },
                              // //console.log(e.target.value);
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Stream First
                            </MenuItem>
                            {this.state.subjects.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.subject_master.id}
                              >
                                {v.subject_master.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.iAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="SKU"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ sku: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Title"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ title: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Duration"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ duration: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Price"
                          id="float"
                          inputProps={{
                            type: "number",
                            required: true,
                            onChange: (e) =>
                              this.setState({ twoxprice: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Three Times Price"
                          id="float"
                          inputProps={{
                            type: "number",
                            onChange: (e) =>
                              this.setState({ threexprice: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Test Series Type 1
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.test_series_type}
                            inputProps={{
                              name: "test_series_type",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  test_series_type: e.target.value,
                                  cAlert: false,
                                });
                              },
                            }}
                          >
                            {this.state.testType.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Test Series Type 2
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.testTypes}
                            inputProps={{
                              name: "testTypes",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  testTypes: e.target.value,
                                  hAlert: false,
                                });
                              },
                            }}
                          >
                            {this.state.testTypess.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.hAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Ak Classes Share"
                          id="float"
                          inputProps={{
                            type: "float",
                            disabled: true,
                            value: this.state.akshare,
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Offer Value"
                          id="float"
                          inputProps={{
                            type: "number",
                            required: true,
                            onChange: (e) =>
                              this.setState({ offValue: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Offer Type
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.offType}
                            inputProps={{
                              required: true,
                              name: "offType",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  offType: e.target.value,
                                  gAlert: false,
                                });
                                // //console.log(e.target.value);
                              },
                            }}
                          >
                            {this.state.offTypeArray.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.gAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="No. Of Questions"
                          id="float"
                          inputProps={{
                            required: true,
                            type: "number",
                            onChange: (e) =>
                              this.setState(
                                { question: e.target.value },
                                () => {
                                  if (
                                    this.state.question >
                                    this.state.questionsLimit
                                  ) {
                                    this.setState({ max: true });
                                  } else {
                                    this.setState({ max: false });
                                  }
                                }
                              ),
                            // onChange: (e) =>
                            //   this.setState({
                            //     question: e.target.value,
                            //   }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        {this.state.max ? (
                          <div>
                            <span style={{ color: "red" }}>
                              You can add only {this.state.questionsLimit}{" "}
                              questions.
                            </span>
                          </div>
                        ) : null}
                      </GridItem>
                      {this.state.faculty.length >= 1 ? (
                        <GridItem xs={12} sm={12} md={12}>
                          <table
                            style={{
                              width: "100%",
                              border: "1px black solid",
                              borderCollapse: "collapse",
                            }}
                          >
                            <tr
                              style={{
                                border: "1px black solid",
                                borderCollapse: "collapse",
                              }}
                            >
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                              >
                                Sr.No
                              </th>
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                              >
                                Faculty
                              </th>
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                              >
                                Faculty Share
                              </th>
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "center",
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                              >
                                Action
                              </th>
                            </tr>
                            {this.state.faculty.map((v, i) => (
                              <tr
                                style={{
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                                key={i}
                              >
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  {i + 1}
                                </td>
                                <td
                                  style={{
                                    padding: "4px 10px 4px 10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  {v.fName}
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  {v.fshare}
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  <input
                                    style={{
                                      backgroundColor: "red",
                                      borderRadius: "10%",
                                      border: "none",
                                      padding: "10px",
                                      color: "white",
                                    }}
                                    onClick={() => {
                                      this.state.faculty.splice(i, 1);
                                      facultyIds.splice(i, 1);
                                      this.setState(
                                        { faculty: this.state.faculty },
                                        () => {
                                          if (this.state.faculty.length === 0) {
                                            this.setState({
                                              atLeast: true,
                                              akshare: 100,
                                              facultyCommission: 0,
                                            });
                                          } else {
                                            let facultyCommission = 0;
                                            this.state.faculty.forEach((v) => {
                                              facultyCommission += v.fshare;
                                            });
                                            let akshare =
                                              100 - facultyCommission;
                                            this.setState({
                                              atLeast: false,
                                              akshare,
                                              facultyCommission,
                                            });
                                          }
                                        }
                                      );
                                    }}
                                    type="button"
                                    value="Delete"
                                  />
                                </td>
                              </tr>
                            ))}
                          </table>
                        </GridItem>
                      ) : null}
                      <GridItem xs={12} sm={12} md={12}>
                        <Button
                          onClick={() => {
                            this.setState({
                              visible: true,
                            });
                          }}
                          className="btn-round"
                          color="success"
                        >
                          Add Faculty
                        </Button>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <label>Product Description</label>
                        <br />
                        <CKEditor
                          editor={ClassicEditor}
                          // config={editorConfiguration}
                          data={this.state.proDesc}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            //console.log({ data });
                            this.setState({
                              proDesc: data,
                            });
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <label htmlFor="">Course Details</label>
                        <br />
                        <textarea
                          name="courseDetails"
                          onChange={this.handleInput}
                          className="form-control"
                          cols="119"
                          rows="6"
                        ></textarea>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <label>Index of Video Lecture</label>
                        <br />
                        <CKEditor
                          editor={ClassicEditor}
                          // config={editorConfiguration}
                          data={this.state.specification}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            //console.log({ data });
                            this.setState({
                              specification: data,
                              specificationAlert: false,
                            });
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <label>Upload Index of Video Lecture PDF :</label>
                        <br />
                        <input
                          name="index"
                          onChange={this.handleFile}
                          type="file"
                          // required={true}
                          accept="application/pdf"
                        />
                      </GridItem>
                      <br />
                      <GridItem xs={12} sm={12} md={4}>
                        <label>Upload Image</label>
                        <br />
                        <input
                          name="img"
                          onChange={this.handleFile}
                          accept="image/*"
                          type="file"
                          required={true}
                        />
                      </GridItem>
                      <br />
                      <GridItem xs={12} sm={12} md={4}>
                        <label>Thumb Image</label>
                        <br />
                        <input
                          name="thumb"
                          onChange={this.handleFile}
                          type="file"
                          accept="image/*"
                          // required={true}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Cashback Amount"
                          id="float"
                          inputProps={{
                            type: "number",
                            required: true,
                            onChange: (e) =>
                              this.setState({ cashback: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Discount Amount"
                          id="float"
                          inputProps={{
                            type: "number",
                            required: true,
                            onChange: (e) =>
                              this.setState({ discount: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Common Google Drive Link"
                          id="float"
                          inputProps={{
                            onChange: (e) =>
                              this.setState({ gcLink: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Windows Google Drive Link"
                          id="float"
                          inputProps={{
                            onChange: (e) =>
                              this.setState({ gwLink: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Android Google Drive Link"
                          id="float"
                          inputProps={{
                            onChange: (e) =>
                              this.setState({ gaLink: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Demo Link 1"
                          id="float"
                          inputProps={{
                            onChange: (e) =>
                              this.setState({ demoLink1: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Demo Link 2"
                          id="float"
                          inputProps={{
                            onChange: (e) =>
                              this.setState({ demoLink2: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Demo Link 3"
                          id="float"
                          inputProps={{
                            onChange: (e) =>
                              this.setState({ demoLink3: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Demo Link 4"
                          id="float"
                          inputProps={{
                            onChange: (e) =>
                              this.setState({ demoLink4: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <br />
                        {this.state.mediaProgress ? (
                          <ProgressBar percentage={this.state.progress} />
                        ) : null}
                      </GridItem>
                      <br />
                      <GridItem xs={12} sm={12} md={6}>
                        {this.state.atLeast ? (
                          <div>
                            <span style={{ color: "red" }}>
                              *Add Atleast One Faculty
                            </span>
                          </div>
                        ) : null}
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? (
                            <Loader />
                          ) : (
                            "Add Test Series Master"
                          )}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/testseries",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <Modal
            visible={this.state.visible}
            width="700"
            height="175"
            effect="fadeInUp"
            onClickAway={() => {
              this.setState({ visible: false });
            }}
          >
            <GridContainer style={{ paddingLeft: "5px", paddingTop: "20px" }}>
              <GridItem xs={12} sm={12} md={5}>
                <FormControl fullWidth>
                  <label>Faculty</label>
                  <select
                    style={{ padding: "11px" }}
                    name="facultyId"
                    value={this.state.facultyId}
                    onChange={(e) => {
                      this.setState({ facultyId: e.target.value }, () => {
                        this.state.facultyArr.forEach((v) => {
                          if (parseInt(this.state.facultyId) === v.id) {
                            this.setState({ facultyWithName: v.name });
                          }
                        });
                      });
                    }}
                  >
                    <option value="">Select</option>
                    {this.state.facultyArr.map((v, i) => (
                      <option key={i} value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </select>
                </FormControl>
                {this.state.bAlert ? (
                  <div>
                    <span style={{ color: "red" }}>*required</span>
                  </div>
                ) : null}
              </GridItem>
              <GridItem xs={12} sm={12} md={5}>
                <CustomInput
                  labelText="Faculty Share"
                  id="float"
                  inputProps={{
                    type: "float",
                    value: this.state.facultyshare,
                    required: true,
                    onChange: (e) =>
                      this.setState({ facultyshare: e.target.value }),
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <Button
                  style={{ marginTop: "20px" }}
                  onClick={() => {
                    if (facultyIds.includes(this.state.facultyId) === false) {
                      facultyIds.push(this.state.facultyId);
                      this.state.faculty.push({
                        fid: this.state.facultyId,
                        fName: this.state.facultyWithName,
                        fshare: parseFloat(this.state.facultyshare),
                      });
                      this.setState(
                        {
                          faculty: this.state.faculty,
                          facultyshare: "",
                          facultyId: "",
                        },
                        () => {
                          if (this.state.faculty.length === 0) {
                            this.setState({
                              akshare: 100,
                              atLeast: true,
                              facultyCommission: 0,
                            });
                          } else {
                            let facultyCommission = 0;
                            this.state.faculty.forEach((v) => {
                              facultyCommission += v.fshare;
                            });
                            let akshare = 100 - facultyCommission;
                            this.setState({
                              atLeast: false,
                              akshare,
                              facultyCommission,
                            });
                          }
                        }
                      );
                    }
                    this.setState({
                      visible: false,
                    });
                  }}
                  className="btn-round"
                  color="success"
                >
                  Add
                </Button>
              </GridItem>
            </GridContainer>
          </Modal>
        </div>
      );
    }
  }
}

export default AddTestSeries;
