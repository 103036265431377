import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Constants from 'variables/Constants';
import Modal from "react-awesome-modal";
import Loader from "components/Loader";
import MathJax from "react-mathjax-preview";
import renderHTML from "react-render-html";
const classes = makeStyles(styles);

export default class TestseriesQuestion extends Component {
    state = {
        data: [],
        notifyStatus: false,
        notifyDelete: false,
        visible: false,
        delLoading: false,
        rowId: 0,
        questionBank: [],
        quizId:"",
    }
    componentDidMount() {
        console.log(this.props.match.params.id);
        this.getquestion();
    }
    getquestion = () => {
        const quizId = window.location.pathname.split('/')[3];
        axios.get(Constants.getUrls.getSeriesQuestions + quizId).then(resp => {
            console.log(resp);
            if(resp)
            {
                this.setState({
                    questionBank: resp.data,
                    quizId: quizId
                },
                );
            }
            else
            {
                this.setState({
                    questionBank: resp.data,
                    quizId: quizId
                },
                );
            }
        });
    };

    handleDelete = id => {
        // console.log(id);
        this.setState({ delLoading: true });
        axios.post(Constants.postUrls.DeleteSeriesQuestions + id).then(resp => {
            this.setState({
                notifyDelete: true,
                delLoading: false
            });
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        });
    };
    handleModal = question => {
        console.log(question);
        this.setState({
            rowId: question.id,
            visible: true
        });
    };

    render() {
        return (
            <div>
                <GridContainer>
                    {
                        this.state.notifyStatus ? (
                            <div style={{ position: "relative", left: "600px" }}>
                                <SnackbarContent
                                    message={"Status Changed!"}
                                    icon={AddAlert}
                                    color="success"
                                />
                            </div>
                        ) : null
                    }
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <CardIcon color="danger">
                                    <Assignment />
                                </CardIcon>
                                <h4>Simple Table</h4>
                            </CardHeader>
                            <CardBody style={{ position: "relative" }}>
                                <h3>Question</h3>
                                {
                                    <Link
                                        to={{
                                        pathname: "/admin/addtestseriesquestion/" + btoa(this.state.quizId),
                                        }}
                                    >
                                        <Button className="" color="warning">
                                            Add Questions
                                        </Button>
                                    </Link>
                                }
                                <Table
                                    tableHead={[
                                        "Sr.No.",
                                        // "Question",
                                        // "Option1",
                                        // "Option2",
                                        // "Option3",
                                        // "Option4",
                                        // "Solution",
                                        // "Right Answer",
                                        "Level",
                                        "Subject",
                                        "Chapter",
                                        "Question",
                                        "Delete"
                                    ]}
                                    tableData={this.state.questionBank && this.state.questionBank.map(
                                        (question, index) => [
                                            [index + 1],
                                            // [question.question],
                                            // [question.option_value_1],
                                            // [question.option_value_2],
                                            // [question.option_value_3],
                                            // [question.option_value_4],
                                            // [question.solution],
                                            // [question.right_answer],
                                            [question.level.title],
                                            [question.subject.title],
                                            [question.chapter.title],
                                            [<MathJax math={question.questionbank ? question.questionbank.question : ""}/>],
                                            [
                                                <Button
                                                    key={index}
                                                    onClick={() => {
                                                        this.handleModal(question);
                                                    }}
                                                    className="btn-round"
                                                    color="danger"
                                                >
                                                    {this.state.act === parseInt(question.id) ? (
                                                        <Loader />
                                                    ) : (
                                                        "Delete"
                                                    )}
                                                </Button>
                                            ]
                                        ]
                                    )}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                    <Modal
                        visible={this.state.visible}
                        width="600"
                        height="100"
                        effect="fadeInUp"
                        onClickAway={() => this.setState({ visible: false })}
                    >
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardBody>
                                        <div>
                                            <h3>Are You Sure You Want to Delete It?</h3>
                                            {this.state.notifyDelete ? (
                                                <div>
                                                    <span style={{ color: "green" }}>
                                                        Question Deleted Successfully!
                                                    </span>
                                                </div>
                                            ) : null}
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={4}>
                                                    <br />
                                                    <Button
                                                        className=""
                                                        color="warning"
                                                        onClick={() => {
                                                            this.handleDelete(this.state.rowId);
                                                        }}
                                                    >
                                                        {this.state.delLoading ? <Loader /> : "Yes"}
                                                    </Button>
                                                    <Button
                                                        className=""
                                                        color="danger"
                                                        onClick={() => {
                                                            this.setState({ visible: false });
                                                        }}
                                                    >
                                                        No
                                                    </Button>
                                                </GridItem>
                                            </GridContainer>
                                        </div>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </Modal>
                </GridContainer>
      </div>
    );
  }
}