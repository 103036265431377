import PieChart, {
  // Series,
  Label,
  Connector,
  Size,
  Export,
  Legend,
  Format,
} from "devextreme-react/pie-chart";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import searchicon from "../assets/img/Search.png";
import {
  Chart,
  Series,
  CommonSeriesSettings,
  SeriesTemplate,
  Annotation,
  CommonAnnotationSettings,
} from "devextreme-react/chart";
import AnnotationTemplate from "./customannotationTemplate";
import React, { Component } from "react";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Tables from "components/Table/Table.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import Modal from "react-awesome-modal";
import { MultiSelect } from "react-multi-select-component";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Loader from "components/Loader";
import Constants from "variables/Constants";
import { touchRippleClasses } from "@mui/material";
const classes = makeStyles(styles);

export default class Monitoring_System_Dashboard extends Component {
  state = {
    students: 0,
    attempt_id: "",
    strategy_id: "",
    data: [],
    vendors: 0,
    batches: [],
    batch_count: 0,
    attempts: [],
    strategy_data: [],
    user_list: [],
    batches: [],
    batch_id: "",
    batch_name: "",
    delayed_by: "",
    loading: false,
    next_page_url: "",
    prev_page_url: "",
    mobile_number: "",
    overall_progress: "NA",
    current_page: 0,
    last_page: 0,
    user_delayed_by: [],
    is_active: "",
    list_load: false,
    total_stu_in_batch: 0,
    search_by: "",
    full_name: "",
    email: "",
    mobile_no: "",
    user: "",
    from: 0,
    to: 0,
    delayed_from: "",
    delayed_to: "",
    subjects: [],
    subject: "",
    visible: false,
    addedStrategies: [],
    user_details: {},
    strategies: [],
    search: "",
    stat_Loading: false,
    meetings: [],
    meeting_id: "",
    meeting_users: [],
    showSheetMessage: false,
    sheetMessage: "",
    sheetLoader: false,
  };
  componentDidMount() {
    // Attempts

    axios
      .get("https://admin.akclasses.in/api/activeAttempts")
      .then((respattempt) => {
        console.log(respattempt);
        this.setState(
          {
            attempts: respattempt.data,
            attempt_id: respattempt.data[0].id,
          },
          () => {
            this.getMonitoringData();
            this.getStrategies();
          }
        );
      });

    // Meetings
    axios
      .get(
        "https://admin.akclasses.in/api/portal/index_without_type?per_page=100"
      )
      .then((resp) => {
        console.log(resp.data);
        this.setState({
          meetings: resp.data.data,
        });
      });

    // STRAEGIES
    //  Subjects

    axios
      .get(
        "https://admin.akclasses.in/api/subjects?per_page=" +
          this.state.per_page
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          subjects: resp.data.data,
        });
      });

    // Courses

    // Batches

    axios.get(Constants.getUrls.getBatches).then((res) => {
      console.log(res);
      // // this.setState({ catchNull: res.data.data})
      this.setState({
        batches: res.data.data,
      });
    });
    const data = [
      { heading: "Present", area: 90 },
      { heading: "Absent", area: 20 },
    ];
    this.setState({
      data: data,
    });
  }

  updateSpreadSheet = () => {
    this.setState({
      sheetLoader: true,
    });
    const payload = {
      attempt_id: this.state.attempt_id,
      strategy_id: this.state.strategy_id,
    };
    axios.post(Constants.postUrls.deleteSheetData, payload).then((resp) => {
      console.log(resp);
      if (resp.data.status === "success") {
        setTimeout(() => {
          axios
            .post(Constants.postUrls.updateSpreadsheet, payload)
            .then((res) => {
              console.log(res);
              if (res.data.status === "success") {
                this.setState(
                  {
                    showSheetMessage: true,
                    sheetMessage: res.data.message,
                    sheetLoader: false,
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({
                        showSheetMessage: false,
                      });
                    }, 2000);
                  }
                );
              }
            });
        }, 2000);
      }
    });
  };

  resetFilter = () => {
    // console.log("retset called")
    this.setState(
      {
        batch_id: "",
        delayed_by: "",
        is_active: "",
      },
      () => {
        this.getMonitoringData();
      }
    );
  };
  getMonitoringData = () => {
    this.setState({
      loading: true,
    });
    let payload = {
      attempt_id: this.state.attempt_id,
      strategy_id: this.state.strategy_id,
      batch_id: this.state.batch_id,
      delayed: this.state.delayed_by,
    };
    axios
      .post(
        "https://admin.akclasses.in/api/monitoring/smart-classroom-dashboard",
        payload
      )
      .then((resp) => {
        console.log(resp);
        let obj_to_array = Object.entries(resp.data?.data?.data).map((obj) => ({
          key_name: obj[1],
        }));
        this.setState({ user_list: [] });
        obj_to_array.map((sec, idx) => {
          this.state.user_list.push(sec.key_name);
        });
        this.setState(
          {
            loading: false,
            students: resp.data?.students,
            active_users: resp.data?.active_users,
            inactive_users: resp.data?.inactive_users,
            vendors: resp.data?.vendors,
            // batch_count: resp.data?.batches,
            // user_list: resp.data.data?.data,
            user_list: this.state.user_list,
            // batches: resp.data.batches,
            current_page: resp.data.data?.current_page,
            last_page: resp.data.data?.last_page,
            total_stu_in_batch: resp.data?.data?.total,
            from: resp.data?.data?.from,
            to: resp.data?.data?.to,
          },
          () => {
            let user_delayed = [];
            user_delayed.push({
              heading: "0_Days",
              area: resp.data?.zero_delay,
              delayed: "0to0",
            });
            user_delayed.push({
              heading: "1-5_Days",
              area: resp.data?.one_to_fve,
              delayed: "1to5",
            });
            user_delayed.push({
              heading: "6-10_Days",
              area: resp.data?.six_to_ten,
              delayed: "6to10",
            });
            user_delayed.push({
              heading: "11-15_Days",
              area: resp.data?.elv_to_fift,
              delayed: "11to15",
            });
            user_delayed.push({
              heading: "16-20_Days",
              area: resp.data?.sixt_to_twnty,
              delayed: "16to20",
            });
            user_delayed.push({
              heading: "21-25_Days",
              area: resp.data?.twntone_to_twntyfive,
              delayed: "21to25",
            });
            // user_delayed.push({
            //   heading: "other Delay",
            //   area: resp.data?.other_delay,
            //   delayed: "",
            // });

            let batch_data = [];
            resp.data.batches.map((item, idx) => {
              batch_data.push({
                batch_name: item?.batch_name,
                count: item?.user_count,
                id: item?.id,
              });
            });
            const data = [
              {
                heading: "inactive",
                count: resp.data?.inactive_users,
              },
              {
                heading: "active",
                count: resp.data?.active_users,
              },
            ];
            this.setState({
              strategy_data: data,
              batches: batch_data,
              user_delayed_by: user_delayed,
            });
          }
        );
      });
  };

  getMonitoringData2 = () => {
    this.setState({
      loading: true,
    });
    let payload = {
      attempt_id: this.state.attempt_id,
      strategy_id: this.state.strategy_id,
      batch_id: this.state.batch_id,
      delayed: this.state.delayed_by,
    };
    axios
      .post(
        "https://admin.akclasses.in/api/monitoring/smart-classroom-dashboard",
        payload
      )
      .then((resp) => {
        console.log(resp);
        let obj_to_array = Object.entries(resp.data?.data?.data).map((obj) => ({
          key_name: obj[1],
        }));
        this.setState({ user_list: [] });
        obj_to_array.map((sec, idx) => {
          this.state.user_list.push(sec.key_name);
        });
        this.setState(
          {
            loading: false,
            students: resp.data?.students,
            active_users: resp.data?.active_users,
            inactive_users: resp.data?.inactive_users,
            vendors: resp.data?.vendors,
            // batch_count: resp.data?.batches,
            // user_list: resp.data.data?.data,
            user_list: this.state.user_list,
            // batches: resp.data.batches,
            current_page: resp.data.data?.current_page,
            last_page: resp.data.data?.last_page,
            total_stu_in_batch: resp.data?.data?.total,
            from: resp.data?.data?.from,
            to: resp.data?.data?.to,
          },
          () => {
            let user_delayed = [];
            user_delayed.push({
              heading: "0_Days",
              area: resp.data?.zero_delay,
              delayed: "0to0",
            });
            user_delayed.push({
              heading: "1-5_Days",
              area: resp.data?.one_to_fve,
              delayed: "1to5",
            });
            user_delayed.push({
              heading: "6-10_Days",
              area: resp.data?.six_to_ten,
              delayed: "6to10",
            });
            user_delayed.push({
              heading: "11-15_Days",
              area: resp.data?.elv_to_fift,
              delayed: "11to15",
            });
            user_delayed.push({
              heading: "16-20_Days",
              area: resp.data?.sixt_to_twnty,
              delayed: "16to20",
            });
            user_delayed.push({
              heading: "21-25_Days",
              area: resp.data?.twntone_to_twntyfive,
              delayed: "21to25",
            });
            // user_delayed.push({
            //   heading: "other Delay",
            //   area: resp.data?.other_delay,
            //   delayed: "",
            // });

            let batch_data = [];
            resp.data.batches.map((item, idx) => {
              batch_data.push({
                batch_name: item?.batch_name,
                count: item?.user_count,
                id: item?.id,
              });
            });
            const data = [
              {
                heading: "inactive",
                count: resp.data?.inactive_users,
              },
              {
                heading: "active",
                count: resp.data?.active_users,
              },
            ];
            this.setState({
              strategy_data: data,
              // batches: batch_data,
              user_delayed_by: user_delayed,
            });
          }
        );
      });
  };
  getStrategies = () => {
    axios
      .get(
        // "https://admin.akclasses.in/api/strategy?per_page=" +
        Constants.getUrls.strategy + "?per_page=100"
      )
      .then((resp) => {
        // console.log(resp);
        this.setState(
          {
            strategy_id: resp.data.data[0]?.id,
            strategies: resp.data.data,
          },
          () => {
            this.getMonitoringData();
          }
        );
      });
  };
  pointClickHandler = (e) => {
    this.setState(
      {
        batch_id: e.target.data.id,
        batch_name: e.target.data.batch_name,
        loading: true,
      },
      () => {
        let payload = {
          attempt_id: this.state.attempt_id,
          batch_id: this.state.batch_id,
          strategy_id: this.state.strategy_id,
          is_active: this.state.is_active,
          delayed: this.state.delayed_by,
        };
        axios
          .post(
            "https://admin.akclasses.in/api/monitoring/smart-classroom-dashboard",
            payload
          )
          .then((resp) => {
            console.log(resp);
            let obj_to_array = Object.entries(resp.data?.data?.data).map(
              (obj) => ({
                key_name: obj[1],
              })
            );
            this.setState({ user_list: [] });
            obj_to_array.map((sec, idx) => {
              this.state.user_list.push(sec.key_name);
            });
            // console.log("this", resp);
            let user_delayed = [];
            user_delayed.push({
              heading: "0_Days",
              area: resp.data?.zero_delay,
              delayed: "0to0",
            });
            user_delayed.push({
              heading: "1-5_Days",
              area: resp.data?.one_to_fve,
              delayed: "1to5",
            });
            user_delayed.push({
              heading: "6-10_Days",
              area: resp.data?.six_to_ten,
              delayed: "6to10",
            });
            user_delayed.push({
              heading: "11-15_Days",
              area: resp.data?.elv_to_fift,
              delayed: "11to15",
            });
            user_delayed.push({
              heading: "16-20_Days",
              area: resp.data?.sixt_to_twnty,
              delayed: "16to20",
            });
            user_delayed.push({
              heading: "21-25_Days",
              area: resp.data?.twntone_to_twntyfive,
              delayed: "21to25",
            });
            user_delayed.push({
              heading: "other Delay",
              area: resp.data?.other_delay,
              delayed: "26to150",
            });
            const data = [
              {
                heading: "inactive",
                count: resp.data?.inactive_users,
              },
              {
                heading: "active",
                count: resp.data?.active_users,
              },
            ];
            this.setState(
              {
                loading: false,
                list_load: false,
                strategy_data: data,
                // user_list: resp.data.data.data,
                next_page_url: resp.data.data?.next_page_url,
                user_delayed_by: user_delayed,
                total_stu_in_batch: resp.data?.data?.total,
                from: resp.data?.data?.from,
                to: resp.data?.data?.to,
              },
              () => {
                // console.log(this.state.strategy_data);
              }
            );
          });
      }
    );
  };
  handleUserActiveStatus = (e) => {
    console.log(e.target.data);
    this.setState(
      {
        is_active: e.target.data?.heading,
        list_load: true,
      },
      () => {
        let payload = {
          batch_id: this.state?.batch_id,
          attempt_id: this.state?.attempt_id,
          delayed: this.state.delayed_by,
          is_active: this.state.is_active,
          strategy_id: this.state.strategy_id,
        };

        axios
          .post(
            "https://admin.akclasses.in/api/monitoring/smart-classroom-dashboard",
            payload
          )
          .then((resp) => {
            // console.log(resp);
            let obj_to_array = Object.entries(resp.data?.data?.data).map(
              (obj) => ({
                key_name: obj[1],
              })
            );
            this.setState({ user_list: [] });
            obj_to_array.map((sec, idx) => {
              this.state.user_list.push(sec.key_name);
            });
            this.setState({
              list_load: false,
              // user_list: resp.data?.data?.data,
              from: resp.data?.data?.from,
              to: resp.data?.data?.to,
            });
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    );
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleDelayedBy = (e) => {
    this.setState(
      {
        delayed_by: this.state.delayed_from + "to" + this.state.delayed_to,
        list_load: true,
      },
      () => {
        let payload = {
          attempt_id: this.state.attempt_id,
          batch_id: this.state.batch_id,
          delayed: this.state.delayed_by,
          is_active: this.state.is_active,
          strategy_id: this.state.strategy_id,
        };
        axios
          .post(
            "https://admin.akclasses.in/api/monitoring/smart-classroom-dashboard",
            payload
          )
          .then((resp) => {
            console.log(resp);
            let obj_to_array = Object.entries(resp.data?.data?.data).map(
              (obj) => ({
                key_name: obj[1],
              })
            );
            console.log(obj_to_array);
            this.setState({ user_list: [] });
            obj_to_array.map((sec, idx) => {
              this.state.user_list.push(sec.key_name);

              if (idx === obj_to_array.length - 1) {
                this.setState(
                  {
                    user_list: this.state.user_list,
                  },
                  () => {
                    console.log(this.state.user_list);
                  }
                );
              }
            });
            this.setState({
              // user_list: resp.data.data?.data,
              total_stu_in_batch: resp.data?.data.total,
              list_load: false,
              from: resp.data?.data?.from,
              to: resp.data?.data?.to,
            });
          });
      }
    );
  };
  handleDelayedBar = (e) => {
    // console.log(e.target.data);
    this.setState(
      {
        list_load: true,
      },
      () => {
        let payload = {
          delayed: e.target?.data?.delayed,
          batch_id: this.state.batch_id,
          attempt_id: this.state.attempt_id,
          strategy_id: this.state.strategy_id,
          is_active: this.state.is_active,
        };
        axios
          .post(
            "https://admin.akclasses.in/api/monitoring/smart-classroom-dashboard",
            payload
          )
          .then((resp) => {
            let obj_to_array = Object.entries(resp.data?.data?.data).map(
              (obj) => ({
                key_name: obj[1],
              })
            );
            // console.log(obj_to_array);
            this.setState({ user_list: [] });
            obj_to_array.map((sec, idx) => {
              this.state.user_list.push(sec.key_name);

              if (idx === obj_to_array.length - 1) {
                this.setState({
                  user_list: this.state.user_list.sort((a, b) => {
                    return b.running_delay - a.running_delay;
                  }),
                });
              }
            });

            this.setState({
              // user_list: resp.data.data?.data,
              total_stu_in_batch: resp.data?.data.total,
              list_load: false,
              from: resp.data?.data?.from,
              to: resp.data?.data?.to,
            });
          });
      }
    );
  };
  handleAttempt = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        loading: true,
      },
      () => {
        this.getMonitoringData();
      }
    );
  };
  getfilteredUser = (e) => {
    this.setState(
      {
        search: e.target.value,
        list_load: true,
      },
      () => {
        let payload = {
          attempt_id: this.state.attempt_id,
          search: this.state.search,
          batch_id: this.state.batch_id,
          strategy_id: this.state.strategy_id,
          user_id: "",
          delayed: "",
          is_active: "",
        };
        axios
          .post(
            "https://admin.akclasses.in/api/monitoring/smart-classroom-dashboard",
            payload
          )
          .then((resp) => {
            console.log(resp);
            let obj_to_array = Object.entries(resp.data?.data?.data).map(
              (obj) => ({
                key_name: obj[1],
              })
            );
            // console.log(obj_to_array);
            this.setState({ user_list: [] });
            obj_to_array.map((sec, idx) => {
              this.state.user_list.push(sec.key_name);

              if (idx === obj_to_array.length - 1) {
                this.setState(
                  {
                    user_list: this.state.user_list,
                  },
                  () => {
                    console.log(this.state.user_list);
                  }
                );
              }
            });
            this.setState({
              user_list: this.state.user_list.reverse(),
              list_load: false,
              total_stu_in_batch: resp.data?.data?.total,
              from: resp.data?.data?.from,
              to: resp.data?.data?.to,
            });
          });
      }
    );
  };
  searchUser = () => {
    if (this.state.search_by === "user_name") {
      let payload = {
        list_load: true,
        attempt_id: this.state.attempt_id,
        batch_id: this.state.batch_id,
        full_name: this.state.user,
      };
      this.getfilteredUser(payload);
    }
    if (this.state.search_by === "email") {
      let payload = {
        list_load: true,
        attempt_id: this.state.attempt_id,
        batch_id: this.state.batch_id,
        email: this.state.user,
      };
      this.getfilteredUser(payload);
    }
    if (this.state.search_by === "mobile_no") {
      let payload = {
        list_load: true,
        attempt_id: this.state.attempt_id,
        batch_id: this.state.batch_id,
        mobile_no: this.state.user,
      };
      this.getfilteredUser(payload);
    }
  };
  handleNext = (e) => {
    this.setState({
      list_load: true,
    });
    let payload = {
      attempt_id: this.state.attempt_id,
      batch_id: this.state.batch_id,
      page: `${this.state.current_page + 1}`,
      delayed: this.state.delayed_by,
      strategy_id: this.state.strategy_id,
    };
    axios
      .post(
        "https://admin.akclasses.in/api/monitoring/smart-classroom-dashboard",
        payload
      )
      .then((resp) => {
        console.log("this", resp);
        let obj_to_array = Object.entries(resp.data?.data?.data).map((obj) => ({
          key_name: obj[1],
        }));
        this.setState({ user_list: [] });
        obj_to_array.map((sec, idx) => {
          this.state.user_list.push(sec.key_name);

          if (idx === obj_to_array.length - 1) {
            this.setState({
              list_load: false,
              user_list: this.state.user_list,
              current_page: resp.data.data?.current_page,
              last_page: resp.data.data?.last_page,
              from: resp.data?.data?.from,
              to: resp.data?.data?.to,
            });
          }
        });
      });
  };

  handlePrev = (e) => {
    this.setState({
      list_load: true,
    });
    let payload = {
      attempt_id: this.state.attempt_id,
      batch_id: this.state.batch_id,
      page: `${this.state.current_page - 1}`,
      delayed: this.state.delayed_by,
      strategy_id: this.state.strategy_id,
    };
    axios
      .post(
        "https://admin.akclasses.in/api/monitoring/smart-classroom-dashboard",
        payload
      )
      .then((resp) => {
        // console.log(resp);
        let obj_to_array = Object.entries(resp.data?.data?.data).map((obj) => ({
          key_name: obj[1],
        }));
        this.setState({ user_list: [] });
        obj_to_array.map((sec, idx) => {
          this.state.user_list.push(sec.key_name);

          if (idx === obj_to_array.length - 1) {
            this.setState({
              list_load: false,
              user_list: this.state.user_list,
              current_page: resp.data.data?.current_page,
              last_page: resp.data.data?.last_page,
              from: resp.data?.data?.from,
              to: resp.data?.data?.to,
            });
          }
        });
      });
  };

  getUserDetails = (sec) => {
    this.setState(
      {
        user_id: sec?.ID,
      },
      () => {
        let payload = {
          user_id: this.state.user_id,
          attempt_id: this.state.attempt_id,
          batch_id: this.state.batch_id,
        };
        axios
          .post(
            "https://admin.akclasses.in/api/monitoring/smart-classroom-dashboard",
            payload
          )
          .then((resp) => {
            // console.log(resp);
            this.setState({
              mobile_number: resp.data.data.data[0].MOBILE,
              overall_progress: resp.data.data.data[0].overall_progress,
            });
          });
      }
    );
  };
  handleModal = (sec) => {
    if (this.state.batch_id === null || this.state.batch_id === "") {
      alert("Please Select Batch filter!");
    } else {
      this.setState(
        {
          visible: true,
          user_details: sec,
          stat_Loading: true,
        },
        () => {
          let payload = {
            user_id: sec?.ID,
          };
          axios
            .post(
              "https://admin.akclasses.in/api/monitoring/get-users-strategy",
              payload
            )
            .then((resp) => {
              console.log(resp);
              this.setState({
                stat_Loading: false,
                addedStrategies: resp.data?.all_strategy,
              });
            });
        }
      );
    }
  };
  handleUserMeetingList = (e) => {
    // console.log(e.target.data);
    if (this.state.batch_id === null || this.state.batch_id === "") {
      alert("Please Select Batch For Meetings filter!");
    } else {
      this.setState(
        {
          list_load: true,
          meeting_id: e.target.value,
        },
        () => {
          let payload = {
            batch_id: this.state.batch_id,
            // batch_id: "27",
            live_portal_id: this.state.meeting_id,
          };
          axios
            .post(
              "https://admin.akclasses.in/api/monitoring/meeting-status/getLists",
              payload
            )
            .then((resp) => {
              console.log(resp);
              let meeting_users = [];
              let user_list = [];
              resp.data.data.data.forEach((item) => {
                user_list.push(item.user_details);
              });
              meeting_users.push({
                heading: "Absent",
                count: resp.data.absent_user,
                // count: "13",
                status: "0",
              });
              meeting_users.push({
                heading: "Present",
                count: resp.data.present_user,
                // count: "10",
                status: "1",
              });

              this.setState({
                list_load: false,
                // user_list: user_list,
                meeting_users: meeting_users,
              });
            });
        }
      );
    }
  };
  handleMeetingBarUsers = (e) => {
    // console.log(e.target.data);
    this.setState(
      {
        list_load: true,
      },
      () => {
        let payload = {
          batch_id: this.state.batch_id,
          live_portal_id: this.state.meeting_id,
          status: e.target.data?.status,
        };
        axios
          .post(
            "https://admin.akclasses.in/api/monitoring/meeting-status/getLists",
            payload
          )
          .then((resp) => {
            // console.log(resp);
            let user_list = [];
            resp.data.data.data.forEach((item) => {
              user_list.push(item.user_details);
            });
            this.setState({
              list_load: false,
              user_list: user_list,
            });
          });
      }
    );
  };

  render() {
    const { data: chartData } = this.state;
    return (
      <div
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          fontFamily: "roboto",
          // height: "5vh"
        }}
      >
        <div
          className="header"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            color: "#B2B2B2",
          }}
        >
          <div>
            <h3 style={{ fontWeight: "bold" }}>AK CLASSES</h3>
          </div>
          {this.state.showSheetMessage ? (
            <span
              style={{
                position: "relative",
                left: "140px",
                bottom: "30px",
                fontSize: "13px",
                color: "green",
                fontWeight: 500,
              }}
            >
              {this.state.sheetMessage}
            </span>
          ) : (
            ""
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space between",
              alignItems: "center",
              width: "30vw",
              marginRight: "45px",
            }}
          >
            {/* Update spreadsheet option */}
            <span
              style={{
                marginLeft: "-225px",
                marginRight: "45px",
                marginTop: "20px",
                fontSize: "30px",
                fontFamily: "Roboto",
              }}
            >
              <Button
                style={{ backgroundColor: "navy" }}
                onClick={() => {
                  this.updateSpreadSheet();
                }}
              >
                {this.state.sheetLoader
                  ? "Updating....Please Wait"
                  : "Update Spreadsheets"}
              </Button>
            </span>

            {/* Attempt Drop down */}
            <h4 style={{ marginRight: "10px" }}>
              <FormControl
                style={{ marginTop: "12px" }}
                fullWidth
                className={classes.selectFormControl}
              >
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Attempt
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={this.state.attempt_id}
                  inputProps={{
                    required: true,
                    name: "attempt_id",
                    id: "simple-select",
                    onChange: (e) => {
                      this.setState(
                        {
                          [e.target.name]: e.target.value,
                        },
                        () => {
                          this.handleAttempt(e);
                        }
                      );
                      // //console.log(e.target.value);
                    },
                  }}
                  style={{ width: "100px", borderBottom: "none" }}
                >
                  {this.state.attempts.map((v, i) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      key={i}
                      value={v?.id}
                    >
                      {v?.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </h4>
            {/* Subject Deopdown */}
            <h4 style={{ marginRight: "10px", width: "100px" }}>
              <FormControl
                style={{ marginTop: "12px" }}
                fullWidth
                className={classes.selectFormControl}
              >
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Strategies
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={this.state.strategy_id}
                  inputProps={{
                    required: true,
                    name: "strategy_id",
                    id: "simple-select",
                    onChange: (e) => {
                      this.setState(
                        {
                          strategy_id: e.target.value,
                        },
                        () => {
                          this.getMonitoringData();
                        }
                      );
                    },
                    // //console.log(e.target.value);
                  }}
                >
                  {this.state.strategies.map((v, i) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      key={i}
                      value={v?.id}
                    >
                      {v?.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </h4>
            {/* Courses Drop down */}
            <h4 style={{ marginRight: "10px", width: "100px" }}>
              <FormControl
                style={{ marginTop: "12px" }}
                fullWidth
                className={classes.selectFormControl}
              >
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Batch
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={this.state.batch_id}
                  inputProps={{
                    required: true,
                    name: "batch_id",
                    id: "simple-select",
                    onChange: (e) => {
                      this.setState(
                        {
                          // eAlert: false,
                          batch_id: e.target.value,
                        },
                        () => {
                          console.log(this.state.batch_id);
                          this.getMonitoringData2();
                        }
                      );
                    },
                    // //console.log(e.target.value);
                  }}
                >
                  {this.state.batches.map((v, i) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      key={i}
                      value={v.id}
                    >
                      {v.batch_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </h4>
            {/* <br/> */}
            <h4
              style={{ marginRight: "10px", marginTop: "30px", width: "80px" }}
            >
              <Button
                onClick={() => {
                  this.resetFilter();
                }}
              >
                Reset Filter
              </Button>
            </h4>
            {/* <h4 style={{ marginRight: "10px", width: "100px" }}>
              <FormControl
                style={{ marginTop: "12px" }}
                fullWidth
                className={classes.selectFormControl}
              >
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Course
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={this.state.subject}
                  inputProps={{
                    required: true,
                    name: "subject",
                    id: "simple-select",
                    onChange: (e) => {
                      this.setState({
                        subject: e.target.value,
                      });
                    },
                    // //console.log(e.target.value);
                  }}
                >
                  {this.state.subjects.map((v, i) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      key={i}
                      value={v.id}
                    >
                      {v.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </h4> */}
          </div>
        </div>

        {this.state.loading ? (
          <div style={{ textAlign: "center" }}>
            <h3>Wait a while, we are extracting data...</h3>
          </div>
        ) : (
          <div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: " 65% 35%",
              }}
            >
              <div className="graphs">
                {/* CODE FOR BATCH PIE GRAPH */}

                <div
                  style={{
                    margin: "30px",
                    width: "85%",
                    padding: "10px",
                    // border: "2px solid #fff",
                    boxShadow: "2px 2px 4px 4px rgba(155,155,155, 0.18)",
                    // opacity: "18%",
                    borderRadius: "12px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "22%",
                  }}
                >
                  <span
                    style={{
                      marginTop: "20px",
                      marginBottom: "2rem",
                      fontSize: "30px",
                      fontFamily: "Roboto",
                      marginLeft: "-15rem",
                    }}
                  >
                    Batches and Students
                  </span>
                  <PieChart
                    id="pie"
                    dataSource={this.state.batches}
                    palette="Soft Blue"
                    // title="Batches and Students"
                    onPointClick={this.pointClickHandler}
                    // onLegendClick={this.legendClickHandler}
                    style={{
                      width: "90%",
                      marginTop: "-2rem",
                      height: "60vh",
                    }}
                  >
                    <Series argumentField="batch_name" valueField="count">
                      <Label visible={true}>
                        <Connector visible={true} width={1} />
                      </Label>
                    </Series>

                    {/* <Size width={500} /> */}
                    {/* <Export enabled={true} /> */}
                  </PieChart>
                </div>
                <br />

                {/* <div
                  className="graph-component"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "35% 65%",
                  }}
                > */}

                {/* CODE FOR USERS DELAYED USERS */}
                <div
                  style={{
                    margin: "30px",
                    marginTop: "-1.2rem",
                    width: "85%",
                    // border: "2px solid #ddd",
                    // boxShadow: "5px #111",
                    boxShadow: "2px 2px 4px 4px rgba(155,155,155, 0.18)",
                    borderRadius: "12px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "28%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "2rem",
                    }}
                  >
                    <span
                      style={{
                        marginTop: "2rem",
                        fontSize: "30px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Delayed Users
                    </span>
                    <div
                      style={{
                        marginLeft: "30px",
                        marginTop: "10px",
                      }}
                    >
                      <input
                        type="number"
                        name="delayed_from"
                        placeholder="Delayed from"
                        onChange={this.handleChange}
                        style={{
                          width: "100px",
                          height: "40px",
                          border: "none",
                          borderBottom: "2px solid black",
                        }}
                      />
                      <input
                        type="number"
                        name="delayed_to"
                        placeholder="Delayed To"
                        onChange={this.handleChange}
                        style={{
                          width: "100px",
                          // border: "none"
                          height: "40px",
                          border: "none",
                          borderBottom: "2px solid black",
                        }}
                      />
                      <Button
                        onClick={this.handleDelayedBy}
                        style={{
                          position: "relative",
                          // bottom: "62px",
                          // left: "230px",
                        }}
                        color="success"
                      >
                        Search
                      </Button>
                    </div>
                  </div>

                  <PieChart
                    id="pie"
                    type="doughnut"
                    // title="Users Delayed By"
                    palette="Soft Blue"
                    dataSource={this.state.user_delayed_by}
                    onPointClick={this.handleDelayedBar}
                    style={{
                      marginTop: "50px",
                      height: "60vh",
                      marginTop: "-1rem",
                      width: "90%",
                    }}
                  >
                    <Series argumentField="heading" valueField="area">
                      <Label visible={true} format="">
                        <Connector visible={true} />
                      </Label>
                    </Series>
                    {/* <Export enabled={true} /> */}
                    <Legend
                      margin={0}
                      horizontalAlignment="right"
                      verticalAlignment="top"
                    />
                  </PieChart>
                  <span
                    style={{
                      marginLeft: "20rem",
                      marginTop: "30px",
                      fontSize: "30px",
                      fontFamily: "Roboto",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "slategray",
                      }}
                      onClick={() => {
                        // console.log(this.state.delayed_by)
                        window.location.reload();
                        // this.setState({ delayed_by: "" });
                      }}
                    >
                      Reset Filter
                    </Button>
                  </span>
                </div>
                <br />

                <div
                  className="meeting-userActive"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "100%",
                  }}
                >
                  {/* CODE FOR Active & Inactive GRAPH */}

                  <div
                    style={{
                      width: "85%",
                      // height:"70%",
                      // border: "2px solid #ddd",
                      boxShadow: "2px 2px 4px 4px rgba(155,155,155, 0.18)",
                      borderRadius: "12px",
                      marginLeft: "30px",
                      // margin: "10px",
                      padding: "3%",
                      marginTop: "-1rem",
                    }}
                  >
                    <h5
                      style={{
                        fontWeight: "bold",
                        marginLeft: "10px",
                      }}
                    >
                      Active and Inactive Users
                    </h5>

                    <Chart
                      dataSource={this.state.strategy_data}
                      style={{
                        // width: "100%",
                        marginTop: "-1rem",
                        height: "40vh",
                      }}
                      onPointClick={this.handleUserActiveStatus}
                      palette="Carmine"
                    >
                      <CommonSeriesSettings
                        argumentField="heading"
                        type="bar"
                        valueField="count"
                        // ignoreEmptyPoints={true}
                        // barPadding={0}
                        ignoreEmptyPoints={true}
                      />
                      {/* <Series
                        valueField="area"
                        argumentField="heading"
                        type="bar"
                        color="#82CD47"
                      /> */}
                      <CommonAnnotationSettings
                        type="custom"
                        series="Population"
                        render={AnnotationTemplate}
                        allowDragging={true}
                      ></CommonAnnotationSettings>
                      <SeriesTemplate nameField="heading" />
                      {this.state.strategy_data.map((data) => (
                        <Annotation
                          argument={data.heading}
                          key={data.heading}
                          data={data}
                        ></Annotation>
                      ))}
                      <Legend
                        horizontalAlignment="right"
                        verticalAlignment="top"
                      />
                    </Chart>
                  </div>
                  <br />
                  {/* LIVE MEETING ATTENDANCE CODE */}

                  <div
                    style={{
                      width: "85%",
                      // margin: "10px",
                      // border: "2px solid #ddd",
                      borderRadius: "12px",
                      boxShadow: "2px 2px 4px 4px rgba(155,155,155, 0.18)",
                      margin: "0 auto auto -2px",
                      padding: "3%",
                      marginLeft: "30px",
                      marginTop: "0.5rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          fontFamily: "Roboto",
                          marginTop: "10px",
                        }}
                      >
                        Live Meeting Attendance
                      </span>{" "}
                      {/* BATCH OF USERS */}
                      <span style={{ marginTop: "-5px" }}>
                        <FormControl
                          style={{ width: "6rem" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Meetings
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.meeting_id}
                            inputProps={{
                              required: true,
                              name: "meeting_id",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState(
                                  {
                                    eAlert: false,
                                  },
                                  () => {
                                    this.handleUserMeetingList(e);
                                  }
                                );
                              },
                              // //console.log(e.target.value);
                            }}
                          >
                            {this.state.meetings.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v?.id}
                              >
                                {v?.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </span>
                    </div>
                    <Chart
                      dataSource={this.state.meeting_users}
                      style={{
                        // width: "60%",
                        height: "40vh",
                      }}
                      onPointClick={this.handleMeetingBarUsers}
                      id="chart"
                      palette="Carmine"
                    >
                      <CommonSeriesSettings
                        argumentField="heading"
                        valueField="count"
                        type="bar"
                        ignoreEmptyPoints={true}
                        // barPadding={0.3}
                      />
                      {/* <Series
                        valueField="count"
                        argumentField="heading"
                        type="bar"
                        // color="#82CD47"
                      /> */}
                      <CommonAnnotationSettings
                        type="custom"
                        series="Population"
                        render={AnnotationTemplate}
                        allowDragging={true}
                      ></CommonAnnotationSettings>
                      <SeriesTemplate nameField="heading" />
                      {this.state.meeting_users.map((data) => (
                        <Annotation
                          argument={data.heading}
                          key={data.heading}
                          data={data}
                        ></Annotation>
                      ))}
                      <Legend
                        horizontalAlignment="right"
                        verticalAlignment="top"
                      />
                    </Chart>
                  </div>
                </div>

                {/* </div> */}
              </div>

              {/* USER LIST CODE */}
              <div className="users">
                <div
                  style={{
                    width: "100%",
                    margin: "2rem 0 0 -2rem",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                    borderRadius: "12px",
                    boxShadow: "2px 2px 4px 4px rgba(155,155,155, 0.18)",
                  }}
                >
                  {/*  CODE FOR USERS LISTS */}
                  <div
                    style={
                      {
                        // width: "30%",
                        // margin: "10px 50px 0 0",
                      }
                    }
                  >
                    <div
                      style={{
                        marginTop: "2rem",
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "12px",
                        boxShadow: "2px 2px 4px 4px rgba(155,155,155, 0.18)",
                        width: "90%",
                      }}
                    >
                      <img
                        style={{
                          marginTop: "5px",
                          // marginLeft: "-30px",
                        }}
                        src={searchicon}
                        alt=""
                      />

                      <div
                        style={{
                          // position: "relative",
                          bottom: "-50px",
                          // left: "350px",
                          marginLeft: "15px",
                          marginTop: "-15px",

                          width: "80%",
                        }}
                      >
                        <CustomInput
                          labelText={"Search by Name, Email and Mobile"}
                          id="float"
                          inputProps={{
                            onChange: (e) => this.getfilteredUser(e),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          style={{
                            marginTop: "-5px",
                            textAlign: "center",
                            marginLeft: "15px",
                            width: "200px",
                          }}
                        />
                      </div>
                    </div>
                    {this.state.list_load ? (
                      <div>
                        <h3>wait a while we are fetching details...</h3>
                      </div>
                    ) : (
                      <div
                        style={{
                          marginTop: "2rem",
                        }}
                      >
                        <Table
                          size="small"
                          stickyHeader
                          aria-lable="a dense table"
                        >
                          <TableHead
                            style={{
                              backgroundColor: "#217BF4",
                              color: "#fff !important",
                            }}
                          >
                            <TableRow
                            // style={{
                            //   color: "#fff !important",
                            // }}
                            >
                              <TableCell style={{ color: "white" }}>
                                {" "}
                                Name{" "}
                              </TableCell>
                              <TableCell style={{ color: "white" }}>
                                {" "}
                                Delay{" "}
                              </TableCell>
                              <TableCell style={{ color: "white" }}>
                                {" "}
                                Progress(%){" "}
                              </TableCell>
                              <TableCell style={{ color: "white" }}>
                                Detail
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.user_list.length > 0 ? (
                              this.state.user_list.map((sec) => (
                                <TableRow className="hover">
                                  <TableCell component="th" scope="row">
                                    {sec?.FIRST_NAME}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {sec?.running_delay}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {sec?.overall_progress
                                      ? sec.overall_progress.toFixed(2)
                                      : ""}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    {/* <Link> */}
                                    <i
                                      style={{
                                        color: "#68B984",
                                      }}
                                      className="material-icons"
                                      onClick={() => {
                                        this.handleModal(sec);
                                      }}
                                    >
                                      &#xe417;
                                    </i>
                                    {/* </Link> */}
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell>No Data Found</TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </div>
                    )}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        margin: "20px",
                      }}
                    >
                      {this.state.current_page > 0 ? (
                        <button
                          style={{
                            borderRadius: "5px",
                            border: "2px solid #ddd",
                          }}
                          onClick={this.handlePrev}
                        >
                          <span
                            style={{ fontSize: "20px" }}
                            onClick={this.handlePrev}
                          >
                            {" "}
                            &#8592;{" "}
                          </span>
                        </button>
                      ) : null}

                      <h5>
                        Showing {this.state.from}-{this.state.to} of{" "}
                        {this.state.total_stu_in_batch} students
                      </h5>

                      <button
                        style={{
                          borderRadius: "5px",
                          border: "2px solid #ddd",
                        }}
                        onClick={this.handleNext}
                      >
                        <span
                          style={{ fontSize: "20px" }}
                          onClick={this.handleNext}
                        >
                          {" "}
                          &#8594;{" "}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className="modalClass"
          style={{
            position: "absolute",
          }}
        >
          <Modal
            visible={this.state.visible}
            minWidth="1000"
            minHeight="330"
            effect="fadeInUp"
            onClickAway={() => this.setState({ visible: false })}
          >
            {/* <GridContainer> */}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  <div>
                    <h5 style={{ textAlign: "center" }}>
                      Alloted Strategies To User
                    </h5>
                    {/* {this.state.mailS ? (
                          <div>
                            <span style={{ color: "green" }}>
                              Strategy Added Successfully!
                            </span>
                          </div>
                        ) : null} */}

                    {this.state.statLoading ? (
                      <h6 style={{ textAlign: "center" }}>
                        Fetching Strategy Details...
                      </h6>
                    ) : (
                      <div style={{ width: "600px" }}>
                        {/* <label htmlFor="Courses">Strategies</label> */}
                        {/* <MultiSelect
                              disableSearch
                              options={this.state.notaddedStrategies}
                              value={this.state.notAddedStrats}
                              onChange={data => {
                                this.setState({ notAddedStrats: data });
                              }}
                              labelledBy={"Strategies"}
                            /> */}
                        <h6 style={{ textAlign: "center" }}>
                          Alloted Strategies
                        </h6>
                        {this.state.addedStrategies.length > 0 ? (
                          <Tables
                            tableHead={[
                              "Course",
                              "Duration",
                              "Strategy Type",
                              "User Type",
                              "Action",
                            ]}
                            tableData={this.state.addedStrategies.map((sec) => [
                              [sec?.course?.TITLE],
                              [sec?.duration],
                              [sec?.strategy_type],
                              [sec?.type],
                              [
                                <Link
                                  to={{
                                    pathname:
                                      "/admin/monitorUserDaysDashboard/" +
                                      sec.id,
                                    state: {
                                      strategyDays: sec?.strategydays,
                                      // user_name: sec?.user?.FIRST_NAME,
                                      id: sec?.id,
                                      user_details: this.state.user_details,
                                      batch_id: this.state.batch_id,
                                      // mobile_no: sec.MOBILE
                                    },
                                  }}
                                  style={{
                                    textDecoration: "none",
                                  }}
                                >
                                  <Button
                                    className=""
                                    color="success"
                                    onClick={() => {
                                      this.setState(
                                        {
                                          stratId: sec,
                                          visible: true,
                                          averageStrategy: true,
                                          showStrategy: false,
                                        },
                                        () => {
                                          console.log(this.state.stratId);
                                        }
                                      );
                                    }}
                                  >
                                    Details
                                  </Button>
                                </Link>,
                              ],
                            ])}
                          />
                        ) : this.state.stat_Loading ? (
                          <span>Loading..</span>
                        ) : (
                          <span> NO STRATEGIES ARE ALLOTED TO THIS USER</span>
                        )}

                        <GridContainer>
                          {/* SEND MAIL BUTTONS  */}

                          {/* <GridItem xs={12} sm={12} md={4}>
                              <br />
                              <Button
                                className=""
                                color="warning"
                                onClick={this.sendEmail}
                              >
                                {this.state.loading ? <Loader /> : "Yes"}
                              </Button>
                              <Button
                                className=""
                                color="danger"
                                onClick={() => {
                                  this.closeModal();
                                  this.setState({
                                    visible: false,
                                    showStrategy: false,
                                  });
                                }}
                              >
                                No
                              </Button>
                            </GridItem> */}
                        </GridContainer>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </GridItem>
            {/* </GridContainer> */}
          </Modal>
        </div>
      </div>
    );
  }
}
