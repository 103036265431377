import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Loader from "components/Loader";
import Modal from "react-awesome-modal";
import Constants from "variables/Constants";
import { MultiSelect } from "react-multi-select-component";
import renderHTML from "react-render-html";
const classes = makeStyles(styles);
const perPages = ["10", "25", "50", "100"];
export default class FaceToFaceBatchStudents extends Component {
  state = {
    visible: false,
    notifyDelete: false,
    delLoading: false,
    rowId: 0,
    notifyStatus: false,
    secondaryCategories: [],
    batch_id: "",

    active: 1,
    per_page: "10",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    search: "",
    visibleTestSeries: false,
    allotedTestSeries: [],
    notallotedTestSeries: [],
    testSeries: [],
    test: [],
    test_seriesId: [],
    fToFBatchId: "",
    userId: "",
    notifyTest: false,
    notifyMessage: "",
  };
  componentDidMount() {
    this.setState(
      {
        batch_id: window.location.pathname.split("/")[3],
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        this.getallUsers();
      }
    );
  }

  getallUsers = (e) => {
    this.setState(
      {
        search: "",
      },
      () => {
        console.log(this.state.search);
        axios
          .get(
            Constants.getUrls.getFaceBatchStudents +
              "?ftof_batch_id=" +
              this.state.batch_id +
              "&search=" +
              ""
          )
          .then((resp) => {
            console.log(resp);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                search: "",
                btnIdArray,
                active: 1,
                secondaryCategories: resp.data.data,
                total: resp.data.total,
                from: resp.data.from,
                to: resp.data.to,
              },
              () => {
                console.log(this.state.secondaryCategories);
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                  search: "",
                });
              }
            );
          });
      }
    );
  };

  searchUser = (e) => {
    axios
      .get(
        Constants.getUrls.getFaceBatchStudents +
          "?ftof_batch_id=" +
          this.state.batch_id +
          "&search=" +
          this.state.search
      )
      .then((resp) => {
        console.log(resp);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            btnIdArray,
            active: 1,
            secondaryCategories: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            console.log(this.state.secondaryCategories);
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };

  handlePage = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        axios
          .get(
            Constants.getUrls.getFaceBatchStudents +
              "?ftof_batch_id=" +
              this.state.batch_id +
              "&per_page=" +
              this.state.per_page
          )
          .then((resp) => {
            //console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                secondaryCategories: resp.data.data,
                total: resp.data.total,
                from: resp.data.from,
                to: resp.data.to,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };

  currentPage = (v) => {
    let commonUrl =
      Constants.getUrls.getFaceBatchStudents +
      "?ftof_batch_id=" +
      this.state.batch_id +
      "&per_page=" +
      this.state.per_page +
      "&page=";
    axios.get(commonUrl + v).then((resp) => {
      // console.log(resp.data.data);
      this.setState({
        total: resp.data.total,
        from: resp.data.from,
        to: resp.data.to,
        secondaryCategories: resp.data.data,
        operatinalCategories: resp.data.data,
        active: v,
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  getBatchStudents = () => {
    axios
      .get(
        Constants.getUrls.getFaceBatchStudents +
          "?ftof_batch_id=" +
          this.state.batch_id
      )
      .then((resp) => {
        console.log(resp);
        this.setState({
          secondaryCategories: resp.data.data,
        });
      });
  };

  handleModal = (sec) => {
    console.log(sec);
    this.setState({
      rowId: sec.id,
      visible: true,
    });
  };

  handleDelete = (id) => {
    this.setState({ delLoading: true });
    axios.post(Constants.postUrls.deleteBatchStudents + id).then(() => {
      this.setState({
        notifyDelete: true,
        delLoading: false,
      });
      setTimeout(() => {
        // window.location.reload();
        this.setState({ visible: false }, () => {
          this.getBatchStudents();
        });
      }, 2000);
    });
  };

  openModal() {
    this.setState({
      visibleTestSeries: true,
    });
  }
  closeModal() {
    this.setState({
      visibleTestSeries: false,
    });
  }

  handleTestSeries = (sec) => {
    console.log(sec);
    // return;
    const payload = {
      user_id: sec.user_id,
      ftofbatchid: sec.f_to_f_batch_id,
    };
    axios
      .post(Constants.postUrls.getFtoFAllotNotAllotTest, payload)
      .then((resp) => {
        console.log(resp);
        if (resp.data.status === "success") {
          this.setState(
            {
              allotedTestSeries: resp.data.allotedTitles,
              notallotedTestSeries: resp.data.notAllotedTitles,
              testSeries: [],
              userId: sec.user_id,
              fToFBatchId: sec.f_to_f_batch_id,
            },
            () => {
              this.state.notallotedTestSeries.map((val) => {
                console.log(val);
                this.state.testSeries.push({
                  label: val?.title,
                  value: val?.id,
                });
                this.setState(
                  {
                    testSeries: this.state.testSeries,
                  },
                  () => {
                    console.log(this.state.testSeries);
                  }
                );
              });
            }
          );
        }
      });
    this.openModal();
  };

  saveTestSeries = () => {
    console.log(this.state.userId, this.state.fToFBatchId);
    const payload = {
      user_id: this.state.userId,
      ftofbatchid: this.state.fToFBatchId,
      test_series_id: this.state.test_seriesId,
    };
    axios.post(Constants.postUrls.allotFtoFTestSeries, payload).then((resp) => {
      console.log(resp);
      if (resp.data.status == "success") {
        this.setState(
          {
            notifyTest: true,
            notifyMessage: "Test Series Alloted Successfully.",
          },
          () => {
            setTimeout(() => {
              this.setState({
                visibleTestSeries: false,
                notifyTest: false,
              });
            }, 2000);
          }
        );
      } else {
        this.setState({
          notifyTest: true,
          notifyMessage: "Warning: Allot Test Series To This Batch.",
        });
      }
    });
  };

  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody>
                <FormControl
                  style={{
                    position: "absolute",
                    right: "10%",
                    width: "85px",
                    top: "-30px",
                  }}
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    s
                    key={1}
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={this.state.per_page}
                    inputProps={{
                      name: "per_page",
                      id: "simple-select",
                      onChange: (e) => {
                        this.handlePage(e);
                      },
                    }}
                  >
                    {perPages.map((v, i) => (
                      <MenuItem
                        key={i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={v}
                      >
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <h3>Face to Face Students</h3>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    left: "43%",
                    bottom: "-40px",
                  }}
                >
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText={"Search Users"}
                      id="float"
                      inputProps={{
                        onChange: (e) =>
                          this.setState({ search: e.target.value }),
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    <Button
                      onClick={this.searchUser}
                      style={{
                        position: "relative",
                        bottom: "62px",
                        left: "210px",
                      }}
                      color="success"
                    >
                      Search
                    </Button>
                    <Button
                      onClick={(e) => {
                        window.location.reload();
                      }}
                      style={{
                        position: "relative",
                        bottom: "62px",
                        left: "210px",
                      }}
                      color="danger"
                    >
                      Clear
                    </Button>
                  </GridItem>
                </div>
              </CardBody>
              <Table
                tableHead={[
                  "S.no",
                  "Name",
                  "Email",
                  "Mobile Number",
                  "Details",
                  "Action",
                ]}
                tableData={this.state.secondaryCategories.map((sec, index) => [
                  [index + 1],
                  [sec?.name],
                  [sec?.email],
                  [sec?.mobile],
                  [
                    <div>
                      <p>Batch Name: {sec?.ftof_batches?.batch_name} </p>
                      <p>Subject Name: {sec?.ftof_subjects?.title}</p>
                      <p>User Type: {sec?.user_details?.TYPE} </p>
                      <Link
                        key={index}
                        to={{
                          pathname:
                            "/admin/viewfaceStudentAttendance/" + sec.id,
                          state: { data: sec },
                        }}
                      >
                        <Button className="btn-round" color="info">
                          View Attendence
                        </Button>
                      </Link>
                    </div>,
                  ],
                  [
                    <Button
                      className="btn-round"
                      style={{
                        backgroundColor: "rgb(186, 144, 198)",
                      }}
                      onClick={() => {
                        this.handleTestSeries(sec);
                        this.setState({
                          visibleTestSeries: true,
                        });
                      }}
                    >
                      Add Test Series
                    </Button>,
                    <Button
                      className="btn-round"
                      style={{
                        backgroundColor: "#E21818",
                      }}
                      onClick={() => {
                        this.handleModal(sec);
                      }}
                    >
                      Delete
                    </Button>,
                  ],
                ])}
              />
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : (
          <div>
            <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div>
            <div style={{ float: "right", border: "1px solid grey" }}>
              <div
                onClick={this.prevPages}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div>
              {this.state.filteredBtnIdArray.map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(v);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === v ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === v ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black",
                  }}
                >
                  {v}
                </div>
              ))}
              <div
                onClick={this.nextPages}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.filteredBtnIdArray.length === 2 ||
                    this.state.filteredBtnIdArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c8;
                </i>
              </div>
            </div>
          </div>
        )}
        <Modal
          visible={this.state.visible}
          width="600"
          height="100"
          effect="fadeInUp"
          onClickAway={() => this.setState({ visible: false })}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  <div>
                    <h3>Are You Sure You Want to Delete Record?</h3>
                    {this.state.notifyDelete ? (
                      <div>
                        <span style={{ color: "green" }}>
                          User Deleted Successfuly!
                        </span>
                      </div>
                    ) : null}
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <br />
                        <Button
                          className=""
                          color="warning"
                          onClick={() => {
                            this.handleDelete(this.state.rowId);
                          }}
                        >
                          {this.state.delLoading ? <Loader /> : "Yes"}
                        </Button>
                        <Button
                          className=""
                          color="danger"
                          onClick={() => {
                            this.setState({ visible: false });
                          }}
                        >
                          No
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Modal>

        <Modal
          visible={this.state.visibleTestSeries}
          minWidth="3000"
          minHeight="350"
          effect="fadeInRight"
          onClickAway={() => this.closeModal()}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  <div>
                    {this.state.notifyTest ? (
                      <div>
                        <span
                          style={{
                            color: "red",
                            marginLeft: "22%",
                            fontSize: "16px",
                            fontWeight: 500,
                          }}
                        >
                          {this.state.notifyMessage}
                        </span>
                      </div>
                    ) : null}
                    <h5 style={{ textAlign: "center" }}>
                      Do You Want To Add Test Series For This Order?
                    </h5>
                    {this.state.mailFail ? (
                      <div>
                        <span
                          style={{
                            color: "red",
                            textTransform: "capitalize",
                          }}
                        >
                          {this.state.strMessage}
                        </span>
                      </div>
                    ) : null}
                    {this.state.statLoading ? (
                      <h6 style={{ textAlign: "center" }}>
                        Fetching Test Series Details...
                      </h6>
                    ) : (
                      <div style={{ width: "560px" }}>
                        <label htmlFor="Courses">Test Series</label>
                        <MultiSelect
                          disableSearch
                          options={this.state.testSeries}
                          value={this.state.test}
                          onChange={(data) => {
                            this.setState(
                              {
                                test: data,
                              },
                              () => {
                                this.state.test.map((v) => {
                                  this.state.test_seriesId.push(v.value);
                                });
                                this.setState({
                                  test_seriesId: this.state.test_seriesId,
                                });
                              }
                            );
                          }}
                          labelledBy={"Test Series"}
                        />
                        <h6 style={{ textAlign: "center" }}>
                          Alloted Test Series
                        </h6>
                        <Table
                          tableHead={["Id", "Title"]}
                          tableData={this.state.allotedTestSeries.map((sec) => [
                            [sec.id],
                            [sec.title],
                          ])}
                        />
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                            <br />
                            <Button
                              className=""
                              color="warning"
                              onClick={this.saveTestSeries}
                            >
                              {this.state.loading ? <Loader /> : "Yes"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.closeModal();
                                this.setState({
                                  visible: false,
                                  showStrategy: false,
                                });
                              }}
                            >
                              No
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Modal>
      </div>
    );
  }
}
