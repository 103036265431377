/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AddAlert from "@material-ui/icons/AddAlert";
import Constants from "variables/AppConstants";
import "react-tagsinput/react-tagsinput.css";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TagsInput from "react-tagsinput";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
const classes = makeStyles(styles);
class AddAppSubject extends Component {
  state = {
    name: "",
    levelId: "",
    chapters: [],
    levels: [],
    redirect: false,
    show: false,
    loading: false
  };
  componentDidMount() {
    axios.get(Constants.getUrls.levels).then(resp => {
      this.setState({
        levels: resp.data.levels
      });
    });
  }

  handleChange = chapters => {
    this.setState({ chapters });
  };
  handleSubmit = e => {
    // console.log(this.state);
    e.preventDefault();
    this.setState({ loading: true });
    axios.post(Constants.postUrls.postSubject, this.state).then(() => {
      // console.log(resp);
      setTimeout(() => {
        this.setState({
          redirect: true
        });
      }, 2000);
      this.setState({
        show: true,
        loading: false
      });
    });
  };
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/appsubjects"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.show ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Subject Added Successfully!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Add Subject</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Level
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.levelId}
                            inputProps={{
                              required: true,
                              name: "levelId",
                              id: "simple-select",
                              onChange: e =>
                                this.setState({ levelId: e.target.value })
                            }}
                          >
                            {this.state.levels.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null} */}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Name"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ name: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <label>Chapters</label>
                        <br />
                        <TagsInput
                          className="tagStyling"
                          value={this.state.chapters}
                          onChange={this.handleChange}
                          required={true}
                          onlyUnique
                          addOnBlur
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Subject"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/appsubjects"
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddAppSubject;
