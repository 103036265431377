import React, { Component } from "react";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect } from "react-router-dom";
import Card from "components/Card/Card.js";
import EditIcon from "@material-ui/icons/Edit";
import CardHeader from "components/Card/CardHeader.js";
import axios from "axios";
import Loader from "components/Loader";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

class SocialMediaLinks extends Component {
  state = {
    fLink: "",
    tLink: "",
    lLink: "",
    gLink: "",
    yLink: "",
    redirect: false,
    notification: false,
    loading: false
  };
  componentDidMount() {
    axios.get("https://admin.akclasses.in/api/followLinks").then(resp => {
      console.log(resp.data.data);
      if (resp.data.data.length > 0)
      {
        this.setState({
          fLink: resp.data.data[0].FACEBOOK,
          tLink: resp.data.data[0].TWITTER,
          lLink: resp.data.data[0].LINKEDIN,
          gLink: resp.data.data[0].GOOGLE_PLUS,
          yLink: resp.data.data[0].YOUTUBE
        });
      }
      else
      {
        this.setState({
          fLink: "",
          tLink: "",
          lLink: "",
          gLink: "",
          yLink: ""
        });
      }
    });
  }
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();
    let payLoad = {
      facebook: this.state.fLink,
      twitter: this.state.tLink,
      linkedin: this.state.lLink,
      google_plus: this.state.gLink,
      youtube: this.state.yLink
    };
    axios
      .post("https://admin.akclasses.in/api/followLink/update/" + 1, payLoad)
      .then(() => {
        //console.log(resp);
        setTimeout(() => {
          this.setState({
            notification: false
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false
        });
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/categories"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Content Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Social Media Links</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Facebook Link"
                          inputProps={{
                            value: this.state.fLink,
                            onChange: e =>
                              this.setState({ fLink: e.target.value }, () => {
                                //console.log(this.state.fLink);
                              })
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Twitter Link"
                          inputProps={{
                            value: this.state.tLink,
                            onChange: e =>
                              this.setState({ tLink: e.target.value }, () => {
                                //console.log(this.state.tLink);
                              })
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="LinkedIn Link"
                          inputProps={{
                            value: this.state.lLink,
                            onChange: e =>
                              this.setState({ lLink: e.target.value }, () => {
                                //console.log(this.state.lLink);
                              })
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Google+ Link"
                          inputProps={{
                            value: this.state.gLink,
                            onChange: e =>
                              this.setState({ gLink: e.target.value }, () => {
                                //console.log(this.state.gLink);
                              })
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Youtube Link"
                          inputProps={{
                            value: this.state.yLink,
                            onChange: e =>
                              this.setState({ yLink: e.target.value }, () => {
                                //console.log(this.state.yLink);
                              })
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                      <Button className="" type="submit" color="info">
                        {this.state.loading ? <Loader /> : "Update"}
                      </Button>
                    </GridItem>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default SocialMediaLinks;
