/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Constants from "../variables/Constants";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "react-awesome-modal";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
// import editorConfiguration from "../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
const classes = makeStyles(styles);
let ProgressBar = (props) => {
  return (
    <div className="progress-bar">
      <Filler percentage={props.percentage} />
    </div>
  );
};
let Filler = (props) => {
  return (
    <div className="filler" style={{ width: `${props.percentage}%` }}></div>
  );
};
let facultyIds = [];
let fd = new FormData();
class UpdateBookMaster extends Component {
  state = {
    category: "",
    title: "",
    sku: "",
    displayVariant: false,
    twoxprice: "",
    threexprice: "",
    duration: "",
    vendorCommission: 0,
    facultyCommission: 0,
    faculty: [],
    atLeast: false,
    facultyWithName: "",
    akshare: 100,
    productId: "",
    facultyArr: [],
    facultyshare: "",
    proDesc: "",
    courseDetails: "",
    specification: "",
    index: "",
    img: "",
    thumb: "",
    cashback: "",
    discount: "",
    gcLink: "",
    gwLink: "",
    gaLink: "",
    price: "",
    demoLink1: "",
    subject: "",
    demoLink2: "",
    facultyId: "",
    demoLink3: "",
    demoLink4: "",
    subjects: [],
    levels: [],
    streams: [],
    offValue: "",
    offType: "",
    offTypeArray: ["", "percent", "amount"],
    level: "",
    stream: "",
    courses: [],
    redirect: false,
    notification: false,
    loading: false,
    language: "Hindi",
  };
  componentDidMount() {
    axios.get("https://admin.akclasses.in/api/index_book_get").then((resp) => {
      //console.log(resp.data);
      this.setState({
        courses: resp.data,
      });
    });
    axios.get("https://admin.akclasses.in/api/facultyR").then((resp) => {
      console.log(resp.data);
      this.setState({
        facultyArr: resp.data,
      });
    });
    // axios
    //   .get("https://admin.akclasses.in/api/index_without_paginate?type=BOOKS")
    //   .then(resp => {
    //     //console.log(resp.data);
    //     this.setState({
    //       levels: resp.data
    //     });
    //   });
    // axios
    //   .get("https://admin.akclasses.in/api/stream_without_paginate?type=BOOKS")
    //   .then(resp => {
    //     //console.log(resp.data);
    //     this.setState({
    //       streams: resp.data
    //     });
    //   });
    // axios
    //   .get(
    //     "https://admin.akclasses.in/api/subject_master/without_paginate_index"
    //   )
    //   .then(resp => {
    //     //console.log(resp.data);
    //     this.setState({
    //       subjects: resp.data
    //     });
    //   });
    let categoryData = this.props.location.state.categoryData;
    console.log(categoryData);

    let productId = categoryData.ID;
    axios
      .get(
        "https://admin.akclasses.in/api/facultydetail?product_id=" + productId
      )
      .then((resp) => {
        // console.log(resp.data);

        this.setState({ faculty: resp.data }, () => {
          if (this.state.faculty.length === 0) {
            facultyIds = [];
            this.setState({
              akshare: 100,
              // atLeast: true,
              facultyCommission: 0,
            });
          } else {
            let facultyCommission = 0;
            this.state.faculty.forEach((v) => {
              facultyIds.push(parseInt(v.id));
              facultyCommission += parseInt(v.faculty_share);
            });
            let akshare = 100 - facultyCommission;
            this.setState({
              // atLeast: false,
              akshare,
              facultyCommission,
            });
          }
          // console.log(facultyIds);
        });
      });

    this.setState(
      {
        subject: categoryData.subject_master_id,
        category: categoryData.course_category?.ID,
        offType: categoryData.OFFER_TYPE,
        offValue: categoryData.VALUE,
        stream: categoryData.STREAM_ID,
        level: categoryData.LEVEL_ID,
        productId: categoryData.ID,
        title: categoryData.TITLE,
        sku: categoryData.SKU,
        price: categoryData.PRICE,
        duration: categoryData.DURATION,
        akshare: categoryData.AKCLASSES_SHARE
          ? categoryData.AKCLASSES_SHARE
          : 100,
        facultyshare: categoryData.FACULTY_SHARE,
        proDesc: categoryData.DESCRIPTION,
        courseDetails: categoryData.SHORT_DESCRIPTION,
        specification: categoryData.WHAT_LEARN,
        index: categoryData.SYLLABUS_PDF,
        img: categoryData.IMAGE_PATH,
        thumb: categoryData.THUMB_IMAGE_PATH,
        cashback: categoryData.CASHBACK_AMOUNT,
        discount: categoryData.DISCOUNT_AMOUNT,
        gcLink: categoryData.COMMON_GD_LINK,
        gwLink: categoryData.WINDOW_GD_LINK,
        demoLink1: categoryData.DEMO_LINK,
        demoLink2: categoryData.DEMO_LINK1,
        demoLink3: categoryData.DEMO_LINK2,
        demoLink4: categoryData.DEMO_LINK3,
        gaLink: categoryData.ANDROID_GD_LINK,
      },
      () => {
        axios
          .get(
            "https://admin.akclasses.in/api/showCategory_with_level/" +
              this.state.category
          )
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              levels: resp.data.level,
            });
          });
        axios
          .get("https://admin.akclasses.in/api/level/" + this.state.level)
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              streams: resp.data.stream,
            });
          });
        axios
          .get("https://admin.akclasses.in/api/stream/" + this.state.stream)
          .then((resp) => {
            console.log(resp.data);
            this.setState({
              subjects: resp.data.stream.subject_masters,
            });
          });
      }
    );
  }
  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] }, () => {
      //console.log(this.state.img);
    });
  };

  handleLevel = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        streams: [],
        subjects: [],
        aAlert: false,
      },
      () => {
        //console.log(this.state.category);
        axios
          .get(
            "https://admin.akclasses.in/api/showCategory_with_level/" +
              this.state.category
          )
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              levels: resp.data.level,
              level: "",
            });
          });
      }
    );
  };

  handleStream = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        subjects: [],
        cAlert: false,
      },
      () => {
        //console.log(this.state.level);
        axios
          .get("https://admin.akclasses.in/api/level/" + this.state.level)
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              streams: resp.data.stream,
              stream: "",
            });
          });
      }
    );
  };
  handleSubject = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        dAlert: false,
      },
      () => {
        //console.log(this.state.stream);
        axios
          .get("https://admin.akclasses.in/api/stream/" + this.state.stream)
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              subjects: resp.data.stream.subject_masters,
            });
          });
      }
    );
  };
  handleSubmit = (e) => {
    e.preventDefault();
    let facultyDetails = [];
    this.state.faculty.forEach((v) => {
      facultyDetails.push({ fid: v.id, fshare: v.faculty_share });
    });
    if (facultyDetails.length === 0) {
      this.setState({ atLeast: true });
    } else {
      this.setState({
        loading: true,
      });
      fd.append("ak_share", this.state.akshare);
      fd.append("combo_type", facultyDetails.length > 1 ? "Combo" : "Normal");
      fd.append("faculty_detail", JSON.stringify(facultyDetails));
      fd.append("faculty_share", this.state.facultyshare);
      fd.append("discount_amount", this.state.discount);
      fd.append("cash_amount", this.state.cashback);
      fd.append("category", this.state.category);
      fd.append("sku", this.state.sku);
      fd.append("title", this.state.title);
      fd.append("short_description", this.state.courseDetails);
      fd.append("description", this.state.proDesc);
      fd.append("duration", this.state.duration);
      fd.append("faculty", this.state.faculty);
      fd.append("price", this.state.price);
      fd.append("price_1", "");
      fd.append("image_path", this.state.img);
      fd.append("thumb_image", this.state.thumb);
      fd.append("what_learn", this.state.specification);
      fd.append("syllabus_pdf", this.state.index);
      fd.append("common_link", this.state.gcLink);
      fd.append("window_link", this.state.gwLink);
      fd.append("android_link", this.state.gaLink);
      fd.append("subject_master_id", this.state.subject);
      fd.append("type", "BOOKS");
      fd.append("android_link", this.state.gaLink);
      fd.append("level", this.state.level);
      fd.append("stream", this.state.stream);
      fd.append("offer_type", this.state.offType);
      fd.append("value", this.state.offValue);
      fd.append("demo_link", this.state.demoLink1);
      fd.append("demo_link1", this.state.demoLink2);
      fd.append("demo_link2", this.state.demoLink3);
      fd.append("demo_link3", this.state.demoLink4);
      fd.append("language", this.state.language);
      let categoryData = this.props.location.state.categoryData;
      let categoryId = categoryData.ID;

      axios
        .post(
          "https://admin.akclasses.in/api/course/update/" + categoryId,
          fd,
          {
            onUploadProgress: (progressEvent) => {
              this.setState({
                progress: Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                ),
                mediaProgress: true,
              });
            },
          }
        )
        .then(() => {
          //console.log(resp);
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        });
    }
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/booksmaster"} />;
    } else {
      if (this.state.displayVariant) {
        return (
          <Redirect
            to={{
              pathname: "/admin/coursevariants/" + this.state.productId,
            }}
          />
        );
      } else {
        return (
          <div>
            <GridContainer>
              {this.state.notification ? (
                <div style={{ position: "relative", left: "600px" }}>
                  <SnackbarContent
                    message={"Book Master Updated!"}
                    icon={AddAlert}
                    color="success"
                  />
                </div>
              ) : null}
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                    <h4 style={{ color: "black" }}>Book Master</h4>
                  </CardHeader>
                  <CardBody>
                    <form onSubmit={this.handleSubmit}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Category
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.category}
                              inputProps={{
                                name: "category",
                                id: "simple-select",
                                onChange: this.handleLevel,
                              }}
                            >
                              {this.state.courses.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v.ID}
                                >
                                  {v.CATEGORY}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Level
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.level}
                              inputProps={{
                                required: true,
                                name: "level",
                                id: "simple-select",
                                onChange: this.handleStream,
                              }}
                            >
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value=""
                              >
                                Select Category First
                              </MenuItem>
                              {this.state.levels.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v.id}
                                >
                                  {v.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Stream
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.stream}
                              inputProps={{
                                required: true,
                                name: "stream",
                                id: "simple-select",
                                onChange: this.handleSubject,
                              }}
                            >
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value=""
                              >
                                Select Level First
                              </MenuItem>
                              {this.state.streams.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v.id}
                                >
                                  {v.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Subject
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.subject}
                              inputProps={{
                                required: true,
                                name: "subject",
                                id: "simple-select",
                                onChange: (e) => {
                                  const subId = e.target.value;
                                  this.setState({
                                    fAlert: false,
                                    subject: subId,
                                  });
                                },
                                // //console.log(e.target.value);
                              }}
                            >
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value=""
                              >
                                Select Stream First
                              </MenuItem>
                              {this.state.subjects.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v.subject_master.id}
                                >
                                  {v.subject_master.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.fAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="SKU"
                            id="float"
                            inputProps={{
                              value: this.state.sku,
                              required: true,
                              onChange: (e) =>
                                this.setState({ sku: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Title"
                            id="float"
                            inputProps={{
                              value: this.state.title,
                              required: true,
                              onChange: (e) =>
                                this.setState({ title: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Duration"
                            id="float"
                            inputProps={{
                              value: this.state.duration,
                              required: true,
                              onChange: (e) =>
                                this.setState({ duration: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        {/* <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Price"
                            id="float"
                            inputProps={{
                              value: this.state.twoxprice,
                              type: "number",
                              onChange: e =>
                                this.setState({ twoxprice: e.target.value })
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem> */}
                        {/* <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="3 Times Price"
                            id="float"
                            inputProps={{
                              value: this.state.threexprice,
                              type: "number",
                              onChange: e =>
                                this.setState({ threexprice: e.target.value })
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem> */}

                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Ak Classes Share"
                            id="float"
                            inputProps={{
                              type: "float",
                              disabled: true,
                              value: this.state.akshare,
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Offer Value"
                            id="float"
                            inputProps={{
                              type: "number",
                              required: true,
                              value: this.state.offValue,
                              onChange: (e) =>
                                this.setState({ offValue: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Offer Type
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.offType}
                              inputProps={{
                                required: true,
                                name: "offType",
                                id: "simple-select",
                                onChange: (e) => {
                                  this.setState({
                                    offType: e.target.value,
                                    eAlert: false,
                                  });
                                  // //console.log(e.target.value);
                                },
                              }}
                            >
                              {this.state.offTypeArray.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v}
                                >
                                  {v}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Price"
                            id="float"
                            inputProps={{
                              type: "number",
                              value: this.state.price,
                              onChange: (e) =>
                                this.setState({ price: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        {this.state.faculty.length >= 1 ? (
                          <GridItem xs={12} sm={12} md={12}>
                            <table
                              style={{
                                width: "100%",
                                border: "1px black solid",
                                borderCollapse: "collapse",
                              }}
                            >
                              <tr
                                style={{
                                  border: "1px black solid",
                                  borderCollapse: "collapse",
                                }}
                              >
                                <th
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  Sr.No
                                </th>
                                <th
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  Faculty
                                </th>
                                <th
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  Faculty Share
                                </th>
                                <th
                                  style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  Action
                                </th>
                              </tr>
                              {this.state.faculty.map((v, i) => (
                                <tr
                                  style={{
                                    border: "1px black solid",
                                    borderCollapse: "collapse",
                                  }}
                                  key={i}
                                >
                                  <td
                                    style={{
                                      padding: "10px",
                                      textAlign: "center",
                                      border: "1px black solid",
                                      borderCollapse: "collapse",
                                    }}
                                  >
                                    {i + 1}
                                  </td>
                                  <td
                                    style={{
                                      padding: "4px 10px 4px 10px",
                                      textAlign: "center",
                                      border: "1px black solid",
                                      borderCollapse: "collapse",
                                    }}
                                  >
                                    {v.name}
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      textAlign: "center",
                                      border: "1px black solid",
                                      borderCollapse: "collapse",
                                    }}
                                  >
                                    {v.faculty_share}
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      textAlign: "center",
                                      border: "1px black solid",
                                      borderCollapse: "collapse",
                                    }}
                                  >
                                    <input
                                      style={{
                                        backgroundColor: "red",
                                        borderRadius: "10%",
                                        border: "none",
                                        padding: "10px",
                                        color: "white",
                                      }}
                                      onClick={() => {
                                        this.state.faculty.splice(i, 1);
                                        facultyIds.splice(i, 1);
                                        this.setState(
                                          { faculty: this.state.faculty },
                                          () => {
                                            console.log(this.state.faculty);
                                            if (
                                              this.state.faculty.length === 0
                                            ) {
                                              this.setState({
                                                akshare: 100,
                                                atLeast: true,
                                                facultyCommission: 0,
                                              });
                                            } else {
                                              let facultyCommission = 0;
                                              this.state.faculty.forEach(
                                                (v) => {
                                                  facultyCommission +=
                                                    v.faculty_share;
                                                }
                                              );
                                              let akshare =
                                                100 - facultyCommission;
                                              this.setState({
                                                atLeast: false,
                                                akshare,
                                                facultyCommission,
                                              });
                                            }
                                          }
                                        );
                                      }}
                                      type="button"
                                      value="Delete"
                                    />
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </GridItem>
                        ) : null}
                        <GridItem xs={12} sm={12} md={4}>
                          <Button
                            onClick={() => {
                              this.setState({
                                visible: true,
                              });
                            }}
                            className="btn-round"
                            color="success"
                          >
                            Add Faculty
                          </Button>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <label>Product Description</label>
                          <br />
                          <CKEditor
                            editor={ClassicEditor}
                            // config={editorConfiguration}
                            data={
                              this.props.location.state.categoryData.DESCRIPTION
                            }
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                proDesc: data,
                              });
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <label htmlFor="">Course Details</label>
                          <br />
                          <textarea
                            name="courseDetails"
                            onChange={this.handleInput}
                            className="form-control"
                            cols="119"
                            value={this.state.courseDetails}
                            rows="6"
                          ></textarea>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <label>Index of Video Lecture</label>
                          <br />
                          <CKEditor
                            editor={ClassicEditor}
                            // config={editorConfiguration}
                            data={
                              this.props.location.state.categoryData.WHAT_LEARN
                            }
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                specification: data,
                                specificationAlert: false,
                              });
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <label>Upload Index of Video Lecture PDF :</label>
                          <br />
                          <input
                            name="index"
                            onChange={this.handleFile}
                            type="file"
                            accept="application/pdf"
                          />
                        </GridItem>
                        <br />
                        <GridItem xs={12} sm={12} md={4}>
                          <label>Upload Image</label>
                          <br />
                          <input
                            name="img"
                            onChange={this.handleFile}
                            accept="image/*"
                            type="file"
                          />
                          {/* <span
                            style={{
                              color: "red",
                              fontWeight: this.state.error ? "bolder" : "none",
                            }}
                          >
                            Image should be 750px X 180px.
                          </span> */}
                          <img
                            width="200px"
                            src={Constants.imgUrl + this.state.img}
                            alt=""
                          />
                        </GridItem>
                        <br />
                        <GridItem xs={12} sm={12} md={4}>
                          <label>Thumb Image</label>
                          <br />
                          <input
                            accept="image/*"
                            name="thumb"
                            onChange={this.handleFile}
                            type="file"
                          />
                          {/* <span
                            style={{
                              color: "red",
                              fontWeight: this.state.error ? "bolder" : "none",
                            }}
                          >
                            Image should be 370px X 250px.
                          </span> */}
                          <img
                            width="200px"
                            src={Constants.imgUrl + this.state.thumb}
                            alt=""
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Cashback Amount"
                            id="float"
                            inputProps={{
                              type: "number",
                              value: this.state.cashback,
                              required: true,
                              onChange: (e) =>
                                this.setState({ cashback: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Discount Amount"
                            id="float"
                            inputProps={{
                              type: "number",
                              required: true,
                              value: this.state.discount,
                              onChange: (e) =>
                                this.setState({ discount: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Google Drive Link"
                            id="float"
                            inputProps={{
                              value: this.state.gcLink,
                              onChange: (e) =>
                                this.setState({ gcLink: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Windows Google Drive Link"
                            id="float"
                            inputProps={{
                              value: this.state.gwLink,
                              onChange: (e) =>
                                this.setState({ gwLink: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Android Google Drive Link"
                            id="float"
                            inputProps={{
                              value: this.state.gaLink,
                              onChange: (e) =>
                                this.setState({ gaLink: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Demo Link 1"
                            id="float"
                            inputProps={{
                              value: this.state.demoLink1,
                              onChange: (e) =>
                                this.setState({ demoLink1: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Demo Link 2"
                            id="float"
                            inputProps={{
                              value: this.state.demoLink2,
                              onChange: (e) =>
                                this.setState({ demoLink2: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Demo Link 3"
                            id="float"
                            inputProps={{
                              value: this.state.demoLink3,
                              onChange: (e) =>
                                this.setState({ demoLink3: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Demo Link 4"
                            id="float"
                            inputProps={{
                              value: this.state.demoLink4,
                              onChange: (e) =>
                                this.setState({ demoLink4: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <br />
                          {this.state.mediaProgress ? (
                            <ProgressBar percentage={this.state.progress} />
                          ) : null}
                        </GridItem>
                        <br />
                        <GridItem xs={12} sm={12} md={6}>
                          <Button className="" color="warning" type="submit">
                            {this.state.loading ? (
                              <Loader />
                            ) : (
                              "Update Book Master"
                            )}
                          </Button>
                          <Link
                            to={{
                              pathname: "/admin/booksmaster",
                            }}
                          >
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <Modal
              visible={this.state.visible}
              width="700"
              height="175"
              effect="fadeInUp"
              onClickAway={() => {
                this.setState({ visible: false });
              }}
            >
              <GridContainer style={{ paddingLeft: "5px", paddingTop: "20px" }}>
                <GridItem xs={12} sm={12} md={5}>
                  <FormControl fullWidth>
                    <label>Faculty</label>
                    <select
                      style={{ padding: "11px" }}
                      value={this.state.facultyId}
                      name="facultyId"
                      onChange={(e) => {
                        this.setState({ facultyId: e.target.value }, () => {
                          console.log(this.state.facultyId);
                          this.state.facultyArr.forEach((v) => {
                            if (this.state.facultyId == v.id) {
                              this.setState({ facultyWithName: v.name });
                            }
                          });
                        });
                      }}
                    >
                      <option value="">Select</option>
                      {this.state.facultyArr.map((v, i) => (
                        <option key={i} value={v.id}>
                          {v.name}
                        </option>
                      ))}
                    </select>
                  </FormControl>
                  {this.state.bAlert ? (
                    <div>
                      <span style={{ color: "red" }}>*required</span>
                    </div>
                  ) : null}
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <CustomInput
                    labelText="Faculty Share"
                    id="float"
                    inputProps={{
                      type: "float",
                      value: this.state.facultyshare,
                      required: true,
                      onChange: (e) =>
                        this.setState({ facultyshare: e.target.value }),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <Button
                    style={{ marginTop: "20px" }}
                    onClick={() => {
                      if (
                        facultyIds.includes(parseInt(this.state.facultyId)) ===
                        false
                      ) {
                        console.log(facultyIds);
                        facultyIds.push(parseInt(this.state.facultyId));
                        this.state.faculty.push({
                          id: parseInt(this.state.facultyId),
                          name: this.state.facultyWithName,
                          faculty_share: parseFloat(this.state.facultyshare),
                        });
                        this.setState(
                          {
                            faculty: this.state.faculty,
                            facultyshare: "",
                            facultyId: "",
                          },
                          () => {
                            console.log(this.state.faculty);
                            if (this.state.faculty.length === 0) {
                              this.setState({
                                akshare: 100,
                                atLeast: true,
                                facultyCommission: 0,
                              });
                            } else {
                              let facultyCommission = 0;
                              this.state.faculty.forEach((v) => {
                                facultyCommission += v.faculty_share;
                              });
                              let akshare = 100 - facultyCommission;
                              this.setState({
                                atLeast: false,
                                akshare,
                                facultyCommission,
                              });
                            }
                          }
                        );
                      }
                      this.setState({
                        visible: false,
                      });
                    }}
                    className="btn-round"
                    color="success"
                  >
                    Add
                  </Button>
                </GridItem>
              </GridContainer>
            </Modal>
          </div>
        );
      }
    }
  }
}

export default UpdateBookMaster;
