const mainDomain = "https://admin.akclasses.in/";
const imgDomain = "https://admin.akclasses.in";

const Constants = {
  imgUrl: mainDomain,
  imgUrls: imgDomain,
  serverDomain: "https://admin.akclasses.in/",

  getUrls: {
    getSingleSubject: mainDomain + "api/subject_master/show/",
    getStreamsSubject: mainDomain + "api/getStreamsSubject",
    getAllSubjects: mainDomain + "api/getAllSubjects",
    getWings: mainDomain + "api/subjectWings/",
    homepage: mainDomain + "api/homePage",
    getNormalSubjects: mainDomain + "api/showNormalSubjects/",
    getComboSubjects: mainDomain + "api/showComboSubjects/",
    slider: mainDomain + "api/homeSlidingImagesR",
    textBelowslider: mainDomain + "api/textBelowSlider ",
    coursesCategory: mainDomain + "api/courseCategories",
    categoryCourse: mainDomain + "api/courseCategoriesR",
    testcategory: mainDomain + "api/testCategories",
    downloads: mainDomain + "api/downloadsR",
    batch: mainDomain + "api/batchTimingsR",
    books: mainDomain + "api/index_bookCat",
    productByCategory: mainDomain + "api/stream/",
    showNormalSubjects: mainDomain + "api/showNormalSubjects/",
    showComboSubjects: mainDomain + "api/showComboSubjects/",
    getCart: mainDomain + "api/cart",
    media: mainDomain + "api/indexall_media",
    os: mainDomain + "api/indexall_os",
    view: mainDomain + "api/indexall_view",
    franchise: mainDomain + "api/franchiseeCenters",
    about: mainDomain + "api/aboutUs",
    attempts: mainDomain + "api/attempt",
    faculty: mainDomain + "api/faculty",
    testimonials: mainDomain + "api/testimonials",
    specialCourse: mainDomain + "api/courses?per_page=100&type=COURSES",
    user: mainDomain + "api/userdetail",
    getLevelsByCat: mainDomain + "api/showCategory_with_level/",
    productsBycatLevels: mainDomain + "api/get_categ_levl_strm_for_course",
    productsTestseries: mainDomain + "api/get_categ_levl_strm_for_test",
    productsBooks: mainDomain + "api/get_categ_levl_strm_for_book",
    AllOrders: mainDomain + "api/getorder",
    footer: mainDomain + "api/footerTechnical",
    downloaddoc: mainDomain + "api/downloads/files/",
    courseDetail: mainDomain + "api/course/",
    trackOrder: mainDomain + "api/trackOrderLink",
    youtube: mainDomain + "api/youtubeVideo",
    findReset: mainDomain + "api/password/find",
    myTestSeries: mainDomain + "api/get_user_test_series",
    testSeriesByProduct: mainDomain + "api/get_user_subject_by_test",
    getExam: mainDomain + "api/get_questions",
    logistics: mainDomain + "api/logistic",
    getScoreCard: mainDomain + "api/get_all_result",
    getResult: mainDomain + "api/get_per_sub_result",
    getFreeExam: mainDomain + "api/get_free_test_questions",
    UserTestSeries: mainDomain + "api/get_user_test_series",
    freeTestSeries: mainDomain + "api/free_test_subject",
    LiveStreaming: mainDomain + "api/portal/index_without_type",
    extensionProducts: mainDomain + "api/extend_index_without_paginate",
    // LiveStreaming: mainDomain + "api/portal/index?type=Premium",
    courseLiveSession: mainDomain + "api/courseCategoriesR",
    LevelLiveSession: mainDomain + "api/index_without_paginate",
    StreamLiveSession: mainDomain + "api/stream_without_paginate",
    getLectures: mainDomain + "api/lecturelink",

    activationProcess: mainDomain + "api/activation/without_paginate_index",
    getLevelsInliveSession: mainDomain + "api/showCategory_with_level/",
    getStreamsInliveSession: mainDomain + "api/level/",
    getBatchWings: mainDomain + "api/getActivateBatches",
    getBatches: mainDomain + "api/batches",
    getBatchData: mainDomain + "api/getBatchData",
    getAmenities: mainDomain + "api/amenities",
    productDetails: mainDomain + "api/productDetails",
    comboNormalCourses: mainDomain + "api/comboNormalCourses",
    batchWingsData: mainDomain + "api/batchWingsData/",
    coursesAll: mainDomain + "api/showSubjectBatches/",
    coursesAllByIds: mainDomain + "api/showSubjectBatchesByIds/",
    coursesByWings: mainDomain + "api/coursesByWings/",
    getCoursesActiveVarient: mainDomain + "api/showActiveVariantsForDetail/",
    getAttempts: mainDomain + "api/activeAttempts",
    // face to face Apis
    faceCat: mainDomain + "api/faceCat",
    getSubjectsFacetoFace: mainDomain + "api/getFaceSubjects",
    getCategoriesFacetoFace: mainDomain + "api/getfaceCats",
    getLevelsFacetoFace: mainDomain + "api/getCategyLevels",
    getStreamsFacetoFace: mainDomain + "api/getLevelsStream",
    getFacetoFace: mainDomain + "api/getFaceToFace",
    facetofaceOrders: mainDomain + "api/getUserFaceToFaceOrders",
    subjectFilters: mainDomain + "api/subject-filter",
  },
  postUrls: {
    createOrderId: mainDomain + "api/create_order",
    storeOrder: mainDomain + "api/order-place",
    clearCart: mainDomain + "api/cart/delete",
    mail: mainDomain + "api/mail",
    cart: mainDomain + "api/create/cart",
    updateQuantity: mainDomain + "api/update_quantity/",
    login: mainDomain + "api/login",
    register: mainDomain + "api/register",
    updateAddress: mainDomain + "api/user/update",
    coupon: mainDomain + "api/apply_coupon",
    orderPlace: mainDomain + "api/order-place",
    paymentStatus: mainDomain + "api/payment_status/",
    removeCart: mainDomain + "/api/remove_cart_item",
    forgetPassword: mainDomain + "api/password/create",
    confirmPass: mainDomain + "api/admin_pass_change",
    resetPassword: mainDomain + "api/password/reset",
    submitAns: mainDomain + "api/submited_ans",
    submitFreeAns: mainDomain + "api/get_free_test_submited_ans",
    extension: mainDomain + "api/extend_validity",
    order: mainDomain + "api/details",
    faceTofaceOrderPlace: mainDomain + "api/faceToFaceOrderPlace",
    sendOTP: mainDomain + "api/send-otp",
    verifyOTP: mainDomain + "api/verify-otp",
    updateUser: mainDomain + "api/update-user",
  },
};
export default Constants;
