import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Loader from "components/Loader";
import Modal from "react-awesome-modal";
import Constants from "variables/Constants";
import renderHTML from "react-render-html";
const classes = makeStyles(styles);
const perPages = ["10", "25", "50", "100"];
export default class View_Face_Student_Attendance extends Component {
  state = {
    user_id: "",
    user_details: {},
    secondaryCategories: [],
    batch_details: {},
    batchId: "",

    active: 1,
    per_page: "10",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    ftofbatchstudentid: window.location.pathname.split("/")[3],
    show: false,
    message: "",
  };
  componentDidMount() {
    const data = this.props.location.state.data;
    console.log(data);
    this.setState(
      {
        user_id: data?.user_details?.ID,
        batch_details: data?.ftof_batches,
        user_details: data?.user_details,
        batchId: data?.f_to_f_batch_id,
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        axios
          .get(
            "https://admin.akclasses.in/api/ftof_batches_days/get-batch-student-days" +
              "?user_id=" +
              this.state.user_id +
              "&f_to_f_batch_id=" +
              this.state.batchId +
              "&per_page=" +
              this.state.per_page
          )
          .then((resp) => {
            console.log(resp);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.data?.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                active: 1,
                secondaryCategories: resp.data?.data?.data,
                total: resp.data?.data?.total,
                from: resp.data?.data?.from,
                to: resp.data?.data?.to,
              },
              () => {
                console.log(this.state.secondaryCategories);
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  }

  handlePage = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        axios
          .get(
            "https://admin.akclasses.in/api/ftof_batches_days/get-batch-student-days" +
              "?user_id=" +
              this.state.user_id +
              "&f_to_f_batch_id=" +
              this.state.batchId +
              "&per_page=" +
              this.state.per_page
          )
          .then((resp) => {
            //console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.data.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                secondaryCategories: resp.data.data.data,
                total: resp.data.data.total,
                from: resp.data.data.from,
                to: resp.data.data.to,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };

  allotDays = (userId, fToFbatchId) => {
    let payload = {
      userId: userId,
      ftofbatch: fToFbatchId,
      ftofbatchstudentid: this.state.ftofbatchstudentid,
    };
    axios
      .post(Constants.postUrls.allotTestDaysToStudent, payload)
      .then((resp) => {
        console.log(resp);
        if (resp.data.status === "success") {
          this.setState({
            show: true,
            message: resp.data.message,
          });
        }
      });
  };

  currentPage = (v) => {
    let commonUrl =
      "https://admin.akclasses.in/api/ftof_batches_days/get-batch-student-days" +
      "?user_id=" +
      this.state.user_id +
      "&f_to_f_batch_id=" +
      this.state.batchId +
      "&per_page=" +
      this.state.per_page +
      "&page=";
    axios.get(commonUrl + v).then((resp) => {
      // console.log(resp.data.data);
      this.setState({
        total: resp.data.data.total,
        from: resp.data.data.from,
        to: resp.data.data.to,
        secondaryCategories: resp.data.data.data,
        operatinalCategories: resp.data.data.data,
        active: v,
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  render() {
    return (
      <div>
        <GridContainer>
          {this.state.show ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={this.state.message}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative", top: "0px" }}>
                <h3>Face to Face Students</h3>
                <FormControl
                  style={{
                    position: "absolute",
                    right: "10%",
                    width: "85px",
                    top: "-30px",
                  }}
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    s
                    key={1}
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={this.state.per_page}
                    inputProps={{
                      name: "per_page",
                      id: "simple-select",
                      onChange: (e) => {
                        this.handlePage(e);
                      },
                    }}
                  >
                    {perPages.map((v, i) => (
                      <MenuItem
                        key={i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={v}
                      >
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CardBody>
              <Link
                to={{
                  pathname:
                    "/admin/faceToFaceBatchStudents/" +
                    this.state.batch_details.id,
                  //   state: { data: sec },
                }}
              >
                <Button
                  className="btn-round"
                  color="info"
                  style={{ position: "relative", left: "20px" }}
                >
                  Back to Student
                </Button>

                <Button
                  className="btn-round"
                  color="success"
                  style={{ position: "relative", left: "20px" }}
                  onClick={() => {
                    this.allotDays(this.state.user_id, this.state.batchId);
                  }}
                >
                  Allot Days to Student
                </Button>
              </Link>
              <div
                style={{
                  padding: "10px",
                  marginLeft: "10px",
                }}
              >
                <div>
                  {" "}
                  <h3> Name: {this.state.user_details.FIRST_NAME}</h3>
                </div>
                <div>
                  {" "}
                  <h4> Face Batch: {this.state.batch_details.batch_name}</h4>
                </div>
              </div>

              <Table
                tableHead={[
                  "S.no",
                  "Topic",
                  "Sub Topic",
                  "ClassDate",
                  "Status",
                  //   "Details",
                ]}
                tableData={
                  this.state.secondaryCategories
                    ? this.state.secondaryCategories.map((sec, index) => [
                        [index + 1],
                        [sec?.ftof_batch_day?.topic?.name],
                        [sec?.ftof_batch_day?.sub_topic],
                        [sec?.ftof_batch_day?.sub_topic_date],
                        [sec?.status],
                        //   [
                        //     <div>
                        //       <p>Batch Name: {sec?.ftof_batches?.batch_name} </p>
                        //       <p>Subject Name: {sec?.ftof_subjects?.title}</p>
                        //       <p>User Type: {sec?.user_details?.TYPE} </p>
                        //       <Link
                        //         key={index}
                        //         to={{
                        //           pathname: "/admin/viewStudentAttendance/" + sec.id,
                        //           state: { data: sec },
                        //         }}
                        //       >
                        //         <Button className="btn-round" color="info">
                        //           View Attendence
                        //         </Button>
                        //       </Link>
                        //     </div>,
                        //   ],
                      ])
                    : []
                }
              />
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.secondaryCategories?.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : (
          <div>
            <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div>
            <div style={{ float: "right", border: "1px solid grey" }}>
              <div
                onClick={this.prevPages}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div>
              {this.state.filteredBtnIdArray.map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(v);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === v ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === v ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black",
                  }}
                >
                  {v}
                </div>
              ))}
              <div
                onClick={this.nextPages}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.filteredBtnIdArray.length === 2 ||
                    this.state.filteredBtnIdArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c8;
                </i>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
