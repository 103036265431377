import React, { Component } from "react";
import "../scss/Books.scss";
import ak_pic from "../images/ak_pic.png";
import book1 from "../images/book1.png";
import book2 from "../images/book2.png";
import book3 from "../images/book3.png";
import shadowimage from "../images/shadowimage.png";
import Testimonials from "./Testimonials";

export default class Books extends Component {
  state = {
    showBlock: false,
  };
  componentDidMount() {
    if (window.innerWidth <= 360) {
      this.setState({ showBlock: true }, () => {
        console.log(this.state.showBlock);
      });
    }
  }
  render() {
    return (
      <div>
        {/* {this.state.showBlock ? ( */}
        <div className="mainDivBook">
          <div>
            <h3 className="madeEasy">
              CA Foundation Maths <br />
              Made Easy with Aman Khedia Sir
            </h3>
          </div>
          <div className="bookParagraph">
            <h4 className="paragraph showBook">
              AK Classes with its unique and simple learning methodologies,
              thousands of students gain benefit in their CA Examinations. We
              keep always updating our courses with the syllabus and provide
            </h4>
          </div>

          <div className="booksDiv">
            <div className="col-md-12">
              <div className="col-md-3 centerBookImg magSet">
                <div>
                  <img src={book1} alt="" className="book1" />
                </div>
                <div>
                  <h3 className="conceptHandbook">Concept Handbook</h3>
                  <h4 className="bookDetail1 ">
                    The Concept handbook has been designed to make mathematics
                    simple and interesting for students. The most of the
                    concepts of the book have been presented in the form of
                    flowcharts,tabular and graphic presentation which would
                    facilitate easy understanding and learning.
                  </h4>
                </div>
                <div></div>
              </div>
              <div
                className="col-md-3 showBook"
                style={{ position: "relative", left: "15px" }}
              >
                <div>
                  <img src={book2} alt="" className="book1" />
                </div>
                <div>
                  <h3 className="conceptHandbook">Main Book</h3>
                </div>
                <div>
                  <h4 className="bookDetail1">
                    The Main book consists of all the questions on all the
                    concepts including the ICAI module questions and past
                    examination questions.
                  </h4>
                </div>
              </div>
              <div
                className="col-md-3 showBook"
                style={{ position: "relative", right: "0px" }}
              >
                <div>
                  <img src={book3} alt="" className="book1" />
                </div>
                <div>
                  <h3 className="conceptHandbook">Mind Map Book</h3>
                </div>
                <div>
                  <h4 className="bookDetail1">
                    Aman Sir has developed various mind map techniques to help
                    students revise all the chapters in a short span of time.
                  </h4>
                </div>
              </div>
              <div
                className="col-md-3 showBook"
                style={{ position: "relative", right: "-117px" }}
              >
                <img src={shadowimage} alt="" className="shadowImage" />
              </div>
            </div>
          </div>
        </div>
        {/*  ) : (
            <div className="mainDivBook">
                 <div>
               <h3 className="madeEasy">
                 CA Foundation Maths <br />
                 Made Easy with Aman Khedia Sir
               </h3>
             </div>
             </div>
         )} */}
      </div>
    );
  }
}
