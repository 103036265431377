import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Modal from "react-awesome-modal";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Loader from "components/Loader";
import Constants from "variables/Constants";
const classes = makeStyles(styles);
const perPages = ["10", "25", "50", "100"];
class Otps extends Component {
  state = {
    notifyStatus: false,
    notify: false,
    notifyNote: false,
    loading: false,
    loadingId: 0,
    visible: false,
    secondaryCategories: [],
    status: "",
    erro: false,
    attempt: "All Users",
    premiumUser: "",
    user: "",
    userName: "",
    message: "",
    down: false,
    frmDate: "",
    toDate: "",
    active: 1,
    act: 0,
    mobile: 0,
    deleteId: 0,
    ord: true,
    notifyDelete: false,
    delLoading: false,
    per_page: "10",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    search: "",
  };
  componentDidMount() {
    axios
      .get(
        Constants.getUrls.getOtps +"?per_page=" +
          this.state.per_page +
          "&search=" + this.state.search
      )
      .then(resp => {
        console.log(resp.data.data);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            btnIdArray,
            secondaryCategories: resp.data.data.data,
            total: resp.data.data.total,
            from: resp.data.data.from,
            to: resp.data.data.to
          },
          () => {
            console.log(this.state.secondaryCategories)
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              v => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray
            });
          }
        );
      });
  }
  handlePage = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5
      },
      () => {
        axios
          .get(
            Constants.getUrls.getOtps +"?per_page=" +
            this.state.per_page +
            "&search=" + this.state.search
          )
          .then(resp => {
            //console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.data.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                secondaryCategories: resp.data.data.data,
                total: resp.data.data.total,
                from: resp.data.data.from,
                to: resp.data.data.to
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray
                });
              }
            );
          });
      }
    );
  };

  currentPage = v => {
    let commonUrl = Constants.getUrls.getOtps +"?per_page=" +
    this.state.per_page +
    "&search=" + this.state.search;
    axios.get(commonUrl + v).then(resp => {
      // console.log(resp.data.data.data);
      this.setState({
        total: resp.data.data.total,
        from: resp.data.data.from,
        to: resp.data.data.to,
        secondaryCategories: resp.data.data.data,
        active: v
      });
    });
  };
  stats = (id, user) => {
    this.setState({
      loadingId: id
    });
    axios
      // .get("https://admin.akclasses.in/api/all_stud_day_strategy?user_id=" + id)
      .get(Constants.getUrls.all_stud_day_strategy + "?user_id=" + id)
      .then(resp => {
        if (resp.data.status === "failed") {
          this.setState({ loadingId: 0, erro: id });
          setTimeout(() => {
            this.setState({ erro: 0 });
          }, 2000);
        } else {
          this.setState({
            userName: user,
            data: resp.data.data,
            redirect: true
          });
        }
      });
  };
  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          v => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                v => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          v => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                v => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };


  searchUser = () => {
      axios
        .get(Constants.getUrls.getOtps +"?per_page=" +
            this.state.per_page +
            "&search=" + this.state.search).then(resp => {
          //console.log(resp);
          let btnIdArray = [];
          for (let i = 1; i <= resp.data.data.last_page; i++) {
            btnIdArray.push(i);
          }
          this.setState(
            {
              ord: false,
              btnIdArray,
              fromInc: 1,
              active: 1,
              toInc: 5,
              secondaryCategories: resp.data.data.data,
              total: resp.data.data.total,
              from: resp.data.data.from,
              to: resp.data.data.to
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                v => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({
                filteredBtnIdArray
              });
            }
          );
        });
  };
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/admin/otps",
            state: {
              categoryData: this.state.data,
              name: this.state.userName,
              type: "premium"
            }
          }}
        />
      );
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notifyStatus ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Status Changed!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}

            {this.state.notify ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={this.state.premiumUser + " is Premiun User Now!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <Assignment />
                  </CardIcon>
                  <h4>Simple Table</h4>
                </CardHeader>
                <CardBody
                  style={{
                    position: "relative"
                  }}
                >
                  <FormControl
                    style={{
                      position: "absolute",
                      right: "5%",
                      width: "85px"
                    }}
                    className={classes.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Per Page
                    </InputLabel>
                    <Select
                      s
                      key={1}
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={this.state.per_page}
                      inputProps={{
                        name: "per_page",
                        id: "simple-select",
                        onChange: e => {
                          this.handlePage(e);
                        }
                      }}
                    >
                      {perPages.map((v, i) => (
                        <MenuItem
                          key={i}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={v}
                        >
                          {v}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div>
                    <h3>Otps</h3>
                    <div
                      style={{
                        position: "relative",
                        bottom: "0px",
                        left: "60%"
                      }}
                    >
                      <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                          labelText={"Search in " + this.state.attempt}
                          id="float"
                          inputProps={{
                            onChange: e =>
                              this.setState({ search: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                        <Button
                          onClick={this.searchUser}
                          style={{
                            position: "relative",
                            bottom: "62px",
                            left: "210px"
                          }}
                          color="success"
                        >
                          Search
                        </Button>
                      </GridItem>
                    </div>
                  </div>
                  <Table
                    tableHead={[
                      "Sr No.",
                      "Mobile No",
                      "OTP",
                    ]}
                    tableData={this.state.secondaryCategories.map(
                      (sec, index) => [
                        [index + 1],
                        [sec.mobile_no],
                        [sec.otp],
                      ]
                    )}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          {this.state.secondaryCategories.length === 0 ? (
            <div>
              <h6 style={{ textAlign: "center" }}>No Data Found</h6>
            </div>
          ) : (
            <div>
              <div style={{ float: "left" }}>
                Showing {this.state.from} to {this.state.to} of{" "}
                {this.state.total} entries.
              </div>
              <div style={{ float: "right", border: "1px solid grey" }}>
                <div
                  onClick={this.prevPages}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    padding: "4px 0px 4px 0px",
                    backgroundColor: "transparent",
                    fontWeight: "bold",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "grey"
                  }}
                >
                  <i
                    style={{
                      fontSize: "16px",
                      position: "relative",
                      top: "3px"
                    }}
                    className="material-icons"
                  >
                    &#xe5c4;
                  </i>
                </div>
                {this.state.filteredBtnIdArray.map((v, i) => (
                  <div
                    key={i}
                    onClick={() => {
                      this.currentPage(v);
                    }}
                    style={{
                      width: "34px",
                      textAlign: "center",
                      borderLeft:
                        (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                        (v % 2 === 0 && i === 4)
                          ? ""
                          : "1px solid grey",
                      borderRight:
                        (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                        (v % 2 === 0 && i === 0)
                          ? ""
                          : "1px solid grey",
                      backgroundColor:
                        this.state.active === v ? "skyblue" : "transparent",
                      padding: "5px 0px 5px 0px",
                      fontWeight: this.state.active === v ? "bold" : "",
                      display: "inline-block",
                      cursor: "pointer",
                      color: "black"
                    }}
                  >
                    {v}
                  </div>
                ))}
                <div
                  onClick={this.nextPages}
                  style={{
                    width: "34px",
                    borderLeft:
                      this.state.filteredBtnIdArray.length === 2 ||
                      this.state.filteredBtnIdArray.length === 4
                        ? "1px solid grey"
                        : "",
                    padding: "4px 0px 4px 0px",
                    backgroundColor: "transparent",
                    textAlign: "center",
                    fontWeight: "bold",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "grey"
                  }}
                >
                  <i
                    style={{
                      fontSize: "16px",
                      position: "relative",
                      top: "3px"
                    }}
                    className="material-icons"
                  >
                    &#xe5c8;
                  </i>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
  }
}

export default Otps;
