import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Constants from 'variables/Constants';
import Modal from "react-awesome-modal";
import Loader from "components/Loader";
const classes = makeStyles(styles);
const perPages = ["10", "25", "50", "100"];
class QuestionBankSubject extends Component {
    state = {
        data: [],
        notifyStatus: false,
        notifyDelete: false,
        visible: false,
        delLoading: false,
        rowId: 0,
        status: "",
        active: 1,
        subject: [],
        per_page: "10",
        total: 0,
        from: 0,
        to: 0,
        fromInc: 1,
        toInc: 5,
        pageArray: [],
        paginationArray: [],
        currentPage:1,
    }
    componentDidMount() {
        this.getSubject();
    }
    getSubject = () => {
        axios.get(Constants.getUrls.getQuestionBankSubject + "?per_page=" + this.state.per_page).then(resp => {
            // console.log(resp);
            let pageArray = [];
            for (let i = 1; i <= resp.data.last_page; i++) {
                pageArray.push(i);
            }
            this.setState({
                pageArray,
                subject: resp.data.data,
                total: resp.data.total,
                from: resp.data.from,
                to: resp.data.to
            },
                () => {
                    let paginationArray = this.state.pageArray.filter(
                        page => page >= this.state.fromInc && page <= this.state.toInc
                    );
                    this.setState({
                        paginationArray
                    });
                }
            );
        });
    };

    handleDelete = id => {
        this.setState({ delLoading: true });
        axios.delete(Constants.postUrls.deleteQuestionBankSubject + id).then(() => {
            this.setState({
                notifyDelete: true,
                delLoading: false
              });
              setTimeout(() => {
                // this.getLevels();
                window.location.reload();
              }, 2000);
            });
    };
    handleModal = subject => {
        this.setState({
          rowId: subject.id,
          visible: true
        });
      };
    
      handleSelect = (e, id) => {
        this.setState(
          {
            [e.target.name]: e.target.value
          },
          () => {
            const payload = {
              status: this.state.status
            };
            axios
              .post(Constants.postUrls.changeQuestionBankSubjectStatus + id,
                payload
              )
              .then(() => {
                //console.log(res);
                this.setState({
                  notifyStatus: true
                });
                setTimeout(() => {
                  this.setState(
                    {
                      notifyStatus: false,
                      fromInc: 1,
                      active: 1,
                      toInc: 5
                    },
                    () => {
                      this.getSubject();
                    }
                  );
                }, 1000);
              });
          }
        );
      };

      currentPage = page => {
        axios.get(Constants.getUrls.getQuestionBankSubject + "?per_page=" + this.state.per_page + "&page=" + page)
          .then(resp => {
            // console.log(resp);
            this.setState({
              subject: resp.data.data,
              total: resp.data.total,
              from: resp.data.from,
              to: resp.data.to,
              active: page
            });
          });
    };

    changePage = (direction) => {
      if (direction == 'back') {
       this.setState({
        currentPage: this.state.currentPage - 1
       });
       let currentpage = this.state.currentPage - 1;
       axios.get(Constants.getUrls.getQuestionBankSubject + "?per_page=" + this.state.per_page + "&page=" + currentpage)
        .then(resp => {
          // console.log(resp);
          this.setState({
            subject: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
            active: this.state.currentPage
          });
        });
  
      } else if (direction == 'next') {
       this.setState({
        currentPage: this.state.currentPage + 1
       });
       let currentpage = this.state.currentPage + 1;
       axios.get(Constants.getUrls.getQuestionBankSubject + "?per_page=" + this.state.per_page + "&page=" + currentpage)
        .then(resp => {
          // console.log(resp);
          this.setState({
            subject: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
            active: this.state.currentPage
          });
        });
      }
    };

    handlePage = e => {
        // console.log(e.target.value);
        this.setState({
          [e.target.name]: e.target.value,
          fromInc: 1,
          active: 1,
          toInc: 5
        },
          () => {
            let pageNo = "1";
            axios.get(Constants.getUrls.getQuestionBankSubject + "?per_page=" + e.target.value + "&page=" + pageNo)
              .then(resp => {
                // console.log(resp);
                let pageArray = [];
                for (let i = 1; i <= resp.data.last_page; i++) {
                  pageArray.push(i);
                }
                this.setState({
                  pageArray,
                  subject: resp.data.data,
                  total: resp.data.total,
                  from: resp.data.from,
                  to: resp.data.to
                },
                  () => {
                    let paginationArray = this.state.pageArray.filter(
                      page => page >= this.state.fromInc && page <= this.state.toInc
                    );
                    this.setState({
                      paginationArray
                    });
                  }
                );
              });
          }
        );
      };

    render() {
        return (
            <div>
                <GridContainer>
                    {
                        this.state.notifyStatus ? (
                            <div style={{ position: "relative", left: "600px" }}>
                                <SnackbarContent
                                    message={"Status Changed!"}
                                    icon={AddAlert}
                                    color="success"
                                />
                            </div>
                        ) : null
                    }
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <CardIcon color="danger">
                                    <Assignment />
                                </CardIcon>
                                <h4>Simple Table</h4>
                            </CardHeader>
                            <CardBody style={{ position: "relative" }}>
                                <FormControl style={{
                                    position: "absolute",
                                    right: "10%",
                                    width: "85px"
                                }}
                                    className={classes.selectFormControl}
                                >
                                    <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                                        Per Page
                                    </InputLabel>
                                    <Select
                                        s
                                        key={1}
                                        MenuProps={{
                                            className: classes.selectMenu
                                        }}
                                        classes={{
                                            select: classes.select
                                        }}
                                        value={this.state.per_page}
                                        inputProps={{
                                            name: "per_page",
                                            id: "simple-select",
                                            onChange: e => {
                                                this.handlePage(e);
                                            }
                                        }}
                                    >
                                        {
                                            perPages.map((v, i) => (
                                                <MenuItem
                                                    key={i}
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected
                                                    }} value={v}>
                                                    {v}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <h3>Question Bank Subject</h3>
                                <Link to="/admin/addquestionbanksubject">
                                    <Button className="" color="warning">
                                        Add Subject
                                        </Button>
                                </Link>
                                <Table
                                    tableHead={[
                                        "Sr.No.",
                                        "Title",
                                        "Description",
                                        "Status",
                                        "Update",
                                        "Delete"
                                    ]}
                                    tableData={this.state.subject && this.state.subject.map(
                                        (subject, index) => [
                                            [index + 1],
                                            [subject.title],
                                            [subject.description],
                                            [
                                                <Select
                                                    key={index}
                                                    MenuProps={{
                                                        className: classes.selectMenu
                                                    }}
                                                    classes={{
                                                        select: classes.select
                                                    }}
                                                    value={subject.status}
                                                    inputProps={{
                                                        name: "status",
                                                        id: "simple-select",
                                                        onChange: e => {
                                                            this.handleSelect(e, subject.id)
                                                        }
                                                    }}
                                                >
                                                    <MenuItem
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected
                                                        }}
                                                        value="Active"
                                                    >
                                                        ACTIVE
                                                    </MenuItem>
                                                    <MenuItem
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected
                                                        }}
                                                        value="Inactive"
                                                    >
                                                        INACTIVE
                                                    </MenuItem>
                                                </Select>
                                            ],
                                            [
                                                <Link
                                                    key={index}
                                                    to={{
                                                        pathname: "/admin/updatequestionbanksubject/" + subject.id,
                                                        state: { levelData: subject }
                                                    }}
                                                >
                                                    <Button className="btn-round" color="info">
                                                        Update
                                                    </Button>
                                                </Link>
                                            ],
                                            [
                                                <Button
                                                    key={index}
                                                    onClick={() => {
                                                        this.handleModal(subject);
                                                    }}
                                                    className="btn-round"
                                                    color="danger"
                                                >
                                                    {this.state.act === parseInt(subject.id) ? (
                                                        <Loader />
                                                    ) : (
                                                        "Delete"
                                                    )}
                                                </Button>
                                            ]
                                        ]
                                    )}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                    <Modal
                        visible={this.state.visible}
                        width="600"
                        height="100"
                        effect="fadeInUp"
                        onClickAway={() => this.setState({ visible: false })}
                    >
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardBody>
                                        <div>
                                            <h3>Are You Sure You Want to Delete It?</h3>
                                            {this.state.notifyDelete ? (
                                                <div>
                                                    <span style={{ color: "green" }}>
                                                        Question Bank Level Deleted Successfuly!
                                                    </span>
                                                </div>
                                            ) : null}
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={4}>
                                                    <br />
                                                    <Button
                                                        className=""
                                                        color="warning"
                                                        onClick={() => {
                                                            this.handleDelete(this.state.rowId);
                                                        }}
                                                    >
                                                        {this.state.delLoading ? <Loader /> : "Yes"}
                                                    </Button>
                                                    <Button
                                                        className=""
                                                        color="danger"
                                                        onClick={() => {
                                                            this.setState({ visible: false });
                                                        }}
                                                    >
                                                        No
                                                    </Button>
                                                </GridItem>
                                            </GridContainer>
                                        </div>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </Modal>
                </GridContainer>
                {this.state.subject.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : (
          <div>
            <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div>
            <div style={{ float: "right", border: "1px solid grey" }}>
              <div
                onClick={() => this.changePage('back')}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey"
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div>
              {this.state.paginationArray.map((page, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(page);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (page % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                        (page % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (page % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                        (page % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === page ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === page ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black"
                  }}
                >
                  {page}
                </div>
              ))}

              <div
                onClick={() => this.changePage('next')}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.paginationArray.length === 2 ||
                    this.state.paginationArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey"
                }}
              >
              <i
                style={{ fontSize: "16px", position: "relative", top: "3px" }}
                className="material-icons"
              >
                &#xe5c8;
                </i>
            </div>
           </div>
           </div>
    )
  }
      </div>
    );
  }
}
export default QuestionBankSubject;