import { Button, FormControl, TextField } from "@mui/material";
import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import akLogo from "../../images/akLogo.png";
import cancel from "../../images/cancel.png";
import menu from "../../images/menu.png";
import next from "../../images/next.png";
import removeIcon from "../../images/cancel.png";
import init from "../helper/WindowToken";
import Constants from "./Constants";

import Lottie from "lottie-react-web";
import closeEyeImg from "../../images/closeEyeImg.png";
import eye from "../../images/eyeImg.png";
import notificationLoader from "../../images/notification_loader.json";
import profile from "../../images/user (3).png";
import user from "../../images/userBlack.png";
// import OtpInput from "react-otp-input";
import OTPInput, { ResendOTP } from "otp-input-react";
import scholarImage from "../../images/scholarship.png";
import userImage from "../../images/account.png";
import secure from "../../images/secure.png";
import name from "../../images/name.png";
import mail from "../../images/mail.png";
import "../../../css/Header.css";
export default class Header extends Component {
  state = {
    showRegister: false,
    showLogin: false,
    overlay: false,
    courses: [
      { label: "9th Standard", value: "9th Standard" },
      { label: "10th Standard", value: "10th Standard" },
      { label: "CA Foundation", value: "CA Foundation" },
      { label: "CA Inter", value: "CA Inter" },
      { label: "Ca Final", value: "Ca Final" },
    ],
    course: "",
    selectedType: false,
    dropDown: false,
    showNotif: false,
    notiMessage: "",
    btnLoading: false,
    firstName: "",
    email: "",
    mobileNo: "",
    password: "",
    confirmPassword: "",
    userDetails: {
      name: "",
      email: "",
      phone_number: 0,
    },
    showError: false,
    max: false,
    passError: false,
    emailLogin: "",
    passwordLogin: "",
    openEye: false,
    closeEye: true,
    message: "",
    courseCategory: [],
    testCat: [],
    facetofaceCat: [],
    selectCat: false,
    headerType: "",
    categoryId: "",
    categoryType: "",
    levels: [],
    streams: [],
    selectedCourseId: "",
    categoryname: "",
    catName: "",
    attempts: [],
    userInfo: {},
    SideNavBar: false,
    firstScreen: true,
    secondScreen: false,
    loader: false,
    scrolled: false,
    hash: "",
    otp: "",
    userId: "",

    minutes: 1,
    seconds: 0,
  };
  componentDidMount() {
    this.getUserInfo();
    window.addEventListener("scroll", () => {
      const isTop = window.scrollY < 100;
      if (isTop !== true) {
        this.setState({ scrolled: true });
      } else {
        this.setState({ scrolled: false });
      }
    });
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.myInterval);
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  }
  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  getUserInfo = () => {
    if (init() === "success") {
      axios.get(Constants.getUrls.user).then((res) => {
        console.log("userdata", res);
        this.setState({ userInfo: res.data }, () => {});
      });
    }
  };
  updateUserDetails = (e) => {
    console.log("APIupdateUser");
    e.preventDefault();
    if (this.state.showCorrectEmail && this.state.showCorrectName) {
      this.setState({ loading: true });
      const payload = {
        FIRST_NAME: this.state.firstName,
        EMAIL: this.state.email,
        user_id: this.state.userId,
      };
      console.log(payload);
      axios.post(Constants.postUrls.updateUser, payload).then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          this.setState({
            notifMsg: res.data.message,
            showRegister: false,
            loading: false,
          });
          this.getUserInfo();
        }
      });
    } else {
      this.setState({
        notifMsg: "Enter user name and email correctly.",

        loading: false,
      });
    }
  };

  getCategory = (type) => {
    this.setState({
      headerType: type,
    });

    if (type === "course") {
      axios.get(Constants.getUrls.productsBycatLevels).then((resp) => {
        console.log(resp);
        if (resp.data.length > 0) {
          this.setState(
            {
              courseCategory: resp.data,
              categoryId: resp.data[0].Id,
            },
            () => {
              this.getLevels(this.state.categoryId);
            }
          );
        }
      });
    } else if (type === "facetoface") {
      axios.get(Constants.getUrls.faceCat).then((resFaceCat) => {
        if (resFaceCat.data.data.length > 0) {
          this.setState(
            {
              courseCategory: resFaceCat.data.data,
              categoryId: resFaceCat.data.data[0].id,
            },
            () => {
              this.getFacetoFaceLevels(this.state.categoryId);
            }
          );
        }
      });
    } else if (type === "testseries") {
      axios.get(Constants.getUrls.productsTestseries).then((resTest) => {
        if (resTest.data.length > 0) {
          this.setState({
            courseCategory: resTest.data,
          });
        }
      });
    }
  };

  getLevels = (id) => {
    const newId = this.state.courseCategory.find(
      (courseId) => courseId.ID || courseId.id === id
    );
    this.setState({
      levels: newId.level,
      streams: newId.level ? newId.level[0].stream : [],
      selectedCourseId: newId,
    });
  };
  getFacetoFaceLevels = (id) => {
    axios
      .get(
        Constants.getUrls.getLevelsFacetoFace +
          "?category_id=" +
          id +
          "&type=COURSE"
      )
      .then((res) => {
        console.log("getLevels", res);
        this.setState(
          {
            levels: res.data,
            // levelsFacetoFace: res.data,
            // showStreamsF: true,
            // hideLevels: false,
          },
          () => {
            // console.log("id" ,this.state.levels.id,this.state.levels[0].id);
            this.getFacetoFaceStreams(this.state.levels[0].id);
          }
        );
      });
  };
  getFacetoFaceStreams = (id) => {
    axios
      .get(
        Constants.getUrls.getStreamsFacetoFace +
          "?level_id=" +
          id +
          "&type=COURSE"
      )
      .then((res) => {
        console.log("getLevelsstreams", res);
        this.setState({
          streams: res.data,
        });
      });
  };

  showRegister = () => {
    this.setState({
      showRegister: true,
      overlay: true,
    });
  };

  hideRegister = () => {
    this.setState({
      showRegister: false,
      overlay: false,
    });
  };

  showLogin = () => {
    this.setState({
      showLogin: true,
      overlay: true,
    });
  };

  hideLogin = () => {
    this.setState({
      showLogin: false,
      overlay: false,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleRegister = (e) => {
    if (
      this.state.showCorrectName === true &&
      this.state.showCorrectEmail === true &&
      this.state.mobilevalidate === true &&
      this.state.password === this.state.confirmPassword
    ) {
      console.log("inside");
      e.preventDefault();
      this.setState({
        showNotif: true,
        notiMessage: "Creating account...",
        btnLoading: true,
        loader: true,
      });
      const payLoad = {
        FIRST_NAME: this.state.firstName,
        EMAIL: this.state.email,
        mobile: this.state.mobileNo,
        PASSWORD: this.state.password,
        password_confirmation: this.state.confirmPassword,
        course: this.state.course,
      };
      console.log(payLoad);
      axios.post(Constants.postUrls.register, payLoad).then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          console.log("register success");
          this.setState({
            showRegister: false,
            showLogin: true,
            userDetails: res.data.data,
            btnLoading: false,
            message: res.data.message,
            loader: false,
          });
          var frm = document.getElementById("registerForm");
          frm.reset();
        } else {
          this.setState({
            showError: true,
            message: res.data.message,
            btnLoading: false,
            showNotif: false,
            loader: false,
          });
          var frm = document.getElementById("registerForm");
          setTimeout(() => {
            this.setState({ showError: false });

            // frm.reset();
          }, 2000);
        }
      });
    } else {
      // console.log("outside");
      this.setState({
        showError: true,
        message: "Invalid Name,Email,Mobile number or Password.",
        btnLoading: false,
        showNotif: false,
        loader: false,
      });
    }
  };

  handleLogin = (e) => {
    this.setState({ loader: true });
    e.preventDefault();
    this.setState({
      showNotif: true,
      notiMessage: "Signing in...",
      btnLoading: true,
      showError: false,
    });
    const payload = {
      email: this.state.emailLogin,
      password: this.state.passwordLogin,
      status: "main",
    };
    console.log(payload);
    axios.post(Constants.postUrls.login, payload).then((res) => {
      console.log(res);
      if (res.data.status === "success") {
        localStorage.setItem("token", res.data.token);
        if (init() === "success") {
          this.setState({
            userDetails: res.data.data,
            message: res.data.credential,
            showLogin: false,
            loader: false,
          });
          var frm = document.getElementById("loginForm");
          frm.reset();
          window.location.href = "/";
        } else {
          alert("Something went wrong");
        }
      } else {
        this.setState({
          showError: true,
          message: res.data.credential,
          btnLoading: false,
          showNotif: false,
          loader: false,
        });
        var frm = document.getElementById("loginForm");
        setTimeout(() => {
          this.setState({ showError: false });

          frm.reset();
        }, 2000);
      }
    });
  };
  loginUser = (e) => {
    e.preventDefault();
    this.setState({ loaderLogin: true });
    const payload = {
      mobile: this.state.mobileNo,
      name: this.state.firstName,
      hash_key: this.state.hash,
    };
    console.log(payload);
    axios
      .post(Constants.postUrls.sendOTP, payload)
      .then((res) => {
        console.log(res);

        if (res.data.status === "success") {
          this.setState({
            OTPModal: true,
            // showLogin: false,
            otpSent: "Otp Sent",
            from: "Login",
            loaderLogin: false,
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  verifyOTP = (e) => {
    if (this.state.otp.length > 6) {
      e.preventDefault();
      this.setState({
        showError: true,
        message: "Enter Valid OTP of 6 digits",
      });
    } else {
      e.preventDefault();
      this.setState({ loader: true });
      const Type = this.state.from;
      console.log(this.state.Type);

      let ApiUrl =
        Type === "Login"
          ? Constants.postUrls.verifyOTP
          : Constants.postUrls.verifyRegisterOTP;
      let payload;

      payload = {
        mobile: this.state.mobileNo,
        otp: this.state.otp,
      };
      console.log(payload);

      axios.post(ApiUrl, payload).then((res) => {
        console.log("login", res);
        if (res.data.status === "success") {
          localStorage.setItem("token", res.data.token);
          if (init() === "success") {
            if (res.data.user.FIRST_NAME === "") {
              this.setState({
                showRegister: false,
                showLogin: false,
                userId: res.data.user.ID,
                showUserDetails: true,
                loader: false,
              });
              // this.updateUserDetails();
            } else {
              this.setState({
                userDetails: res.data.user,
                message: res.data.message,
                showLogin: false,
                OTPModal: false,
                loader: false,
                userId: res.data.user.ID,
              });
              this.getUserInfo();
            }

            // var frm = document.getElementById("loginForm");
            // frm.reset();
            // window.location.href = "/";
          } else {
            alert("Something went wrong");
          }
        } else {
          this.setState({
            showError: true,
            message: res.data.message,
            btnLoading: false,
            showNotif: false,
            loader: false,
          });
          var frm = document.getElementById("loginForm");
          setTimeout(() => {
            this.setState({ showError: false });

            frm.reset();
          }, 2000);
        }
      });
    }
  };

  logOut = (e) => {
    localStorage.clear();
    window.location.href = "/";
  };
  validateName = (text) => {
    console.log("text", text);
    var reg = /^[A-Za-z ]+$/;
    if (reg.test(text) === false) {
      console.log("Name is Not Correct");
      this.setState({
        firstName: text,
        showCorrectName: false,
        showNameError: true,
      });
      return false;
    } else {
      this.setState({
        firstName: text,
        showCorrectName: true,
        showNameError: false,
      });
      console.log("Name is Correct");
    }
  };
  validateEmail = (text) => {
    console.log(text);
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      console.log("Email is Not Correct");
      this.setState({
        email: text,
        showCorrectEmail: false,
        showEmailError: true,
      });
      return false;
    } else {
      this.setState({
        email: text,
        showCorrectEmail: true,
        showEmailError: false,
      });
      console.log("Email is Correct");
    }
  };
  validateMobile(text) {
    const reg = /^[0]?[789]\d{9}$/;
    if (reg.test(text) === false) {
      this.setState({
        mobilevalidate: false,
        mobileNo: text,
        showmobileError: true,
      });
      return false;
    } else {
      this.setState({
        mobilevalidate: true,
        mobileNo: text,
        message: "",
        showmobileError: false,
      });
      return true;
    }
  }
  validateOTP(text) {
    const reg = /^[0]?[789]\d{5}$/;
    if (reg.test(text) === false) {
      this.setState({
        otpValidate: false,
        otp: text,
        showOtpError: true,
      });
      return false;
    } else {
      this.setState({
        otpValidate: true,
        otp: text,
        message: "",
        showOtpError: false,
      });
      return true;
    }
  }
  render() {
    const { minutes, seconds } = this.state;
    const renderButton = (buttonProps) => {
      return (
        <button
          {...buttonProps}
          style={{
            border: "none",
            backgroundColor: "#fff",
            marginBottom: "10px",
            fontWeight: 500,
          }}
        >
          {this.state.sentOTP ? "OTP Sent" : "Resend OTP"}
        </button>
      );
    };
    const renderTime = (remainingTime) => {
      return <span>00 : {remainingTime} s</span>;
    };
    return (
      <div className="NewHeader">
        <div className="headerTopHead Web">
          <p style={{ fontSize: "20px", fontWeight: "400" }}>
            To Know more about the courses call us at -{" "}
            <span style={{ fontSize: "20px", fontWeight: "900" }}>
              {" "}
              9356624677{" "}
            </span>{" "}
            <span
              style={{
                fontSize: "20px",
                fontWeight: "700",
                marginLeft: "25px",
                marginRight: "25px",
              }}
            >
              |{" "}
            </span>{" "}
            For Any kind of Technical Support Please call us on -{" "}
            <span style={{ fontSize: "20px", fontWeight: "900" }}>
              8766578508
            </span>
          </p>
        </div>
        <div className="headerTopHead Responsive">
          <p style={{ fontSize: "17px", marginTop: "5px" }}>
            To Know more please call
            <span style={{ fontSize: "20px", fontWeight: "900" }}>
              {" "}
              9356624677{" "}
            </span>
          </p>
        </div>

        {window.innerWidth <= 1180 ? (
          <div className="navHeader">
            {" "}
            <div className="">
              <Link to="/">
                <img src={akLogo} alt="" className="logoAK" />
              </Link>
            </div>
            {this.props.From === "SubjectsResp" ? (
              <div
                className=""
                onClick={() => {
                  this.setState({
                    SideNavBar: !this.state.SideNavBar,
                    firstScreen: true,
                    secondScreen: false,
                  });
                }}
              >
                <img
                  src={menu}
                  alt="img"
                  className="menuImg"
                  height={20}
                  width={20}
                />
              </div>
            ) : (
              <div
                className=" menu"
                onClick={() => {
                  this.setState({
                    SideNavBar: !this.state.SideNavBar,
                    firstScreen: true,
                    secondScreen: false,
                  });
                }}
              >
                Menu{" "}
                <img
                  src={menu}
                  alt="img"
                  className="menuImg"
                  height={20}
                  width={20}
                />
              </div>
            )}
          </div>
        ) : (
          <div
            className="mainWrap "
            onMouseLeave={(e) => {
              this.setState({
                dropDown: false,
              });
            }}
          >
            <div className="secondWrap">
              <Link to="/">
                <img src={akLogo} alt="" className="logo" />
              </Link>
              <p
                onMouseOver={(e) => {
                  this.setState(
                    {
                      selectedType: "course",
                      dropDown: true,
                    },
                    () => {
                      this.getCategory(this.state.selectedType);
                    }
                  );
                }}
                onMouseLeave={() => {
                  this.setState({ selectedType: "", dropDown: true });
                }}
                style={{ left: "15px" }}
                className={
                  this.state.selectedType === "course"
                    ? "selectedTypes Menudropdown"
                    : "types"
                }
              >
                <span style={{ display: "inline-flex", marginTop: "6px" }}>
                  Courses
                </span>
                <span
                  className="arrowHexHeader"
                  style={{ top: 13, fontSize: "25px", left: "6px" }}
                >
                  &#129171;
                  {/* <i class="fa-solid fa-caret-down"></i> */}
                </span>
                {/* <span className="arrowHexHeader">&#129171;</span> */}
              </p>
              {/* <p
                className={
                  this.state.selectedType === "facetoface"
                    ? "selectedTypes"
                    : "types"
                }
                style={{ padding: "5px 14px" }}
                onMouseOver={(e) => {
                  this.setState(
                    {
                      selectedType: "facetoface",
                      dropDown: true,
                    },
                    () => {
                      this.getCategory(this.state.selectedType);
                    }
                  );
                }}
                onMouseLeave={() => {
                  this.setState({ selectedType: "face", dropDown: true });
                }}
              >
                {" "}
                <span style={{ display: "inline-flex", marginTop: "6px" }}>
                  Face to Face & Live Stream{" "}
                </span>
                <span
                  className="arrowHexHeader"
                  style={{ top: 1, fontSize: "15px", left: "6px" }}
                >
                  <i class="fa-solid fa-caret-down"></i>
                </span>
              </p> */}
              <p
                onClick={() => {
                  // alert();
                  window.location.href = "/testseries";
                }}
                className={
                  this.state.selectedType === "testseries"
                    ? "selectedTypes"
                    : "types"
                }
                style={{ padding: "5px 14px" }}
                onMouseOver={(e) => {
                  this.setState(
                    {
                      selectedType: "testseries",
                      dropDown: false,
                    },
                    () => {
                      // this.getCategory(this.state.selectedType);
                    }
                  );
                }}
                onMouseLeave={() => {
                  this.setState({ selectedType: "", dropDown: false });
                }}
              >
                {" "}
                <span style={{ display: "inline-flex", marginTop: "6px" }}>
                  Test Series
                </span>{" "}
                <span
                  className="arrowHexHeader"
                  style={{ top: 13, fontSize: "25px", left: "6px" }}
                >
                  &#129171;
                  {/* <i class="fa-solid fa-caret-down"></i> */}
                </span>
              </p>
              <p
                onClick={() => {
                  // alert();
                  window.location.href = "/books";
                }}
                className={
                  this.state.selectedType === "studymaterial"
                    ? "selectedTypes"
                    : "types"
                }
                style={{ padding: "5px 14px" }}
                onMouseOver={(e) => {
                  this.setState({
                    selectedType: "studymaterial",
                    dropDown: false,
                  });
                }}
                onMouseLeave={() => {
                  this.setState({ selectedType: "", dropDown: false });
                }}
              >
                {" "}
                <span style={{ display: "inline-flex", marginTop: "6px" }}>
                  Books
                </span>
                <span
                  className="arrowHexHeader"
                  style={{ top: 13, fontSize: "25px", left: "6px" }}
                >
                  &#129171;
                  {/* <i class="fa-solid fa-caret-down"></i> */}
                </span>
              </p>
            </div>

            <>
            <Link to="/admin/offlineorders">
              <div className="secondWrap">
                <p className="register">
                  Offline Orders
                </p>
              </div>
            </Link>
            </>

            {this.state.dropDown ? (
              <div
                className={
                  window.innerWidth === 1396
                    ? "row dropdownall1396 Menudropdown-contents"
                    : "row dropdownall Menudropdown-contents"
                }
                style={{
                  marginLeft:
                    this.state.selectedType === "course"
                      ? "-45px"
                      : this.state.selectedType === "facetoface"
                      ? "117px"
                      : this.state.selectedType === "testseries"
                      ? "365px"
                      : this.state.selectedType === "studymaterial"
                      ? "497px"
                      : this.state.selectedType === ""
                      ? "-45px"
                      : this.state.selectedType === "face"
                      ? "117px"
                      : this.state.selectedType === "test"
                      ? "365px"
                      : null,
                }}
              >
                <div
                  className="col-md-12"
                  style={{
                    fontFamily: "'Raleway', sans-serif",
                    fontSize: "16px",
                    // marginLeft: "-30px",
                  }}
                >
                  <div className="col-md-4 categoryArea">
                    {this.state.streams.map((cat) => (
                      <div className={this.state.selectCat ? "cat1396" : "cat"}>
                        {this.state.headerType === "facetoface" ? (
                          <Link
                            style={{ textDecoretion: "none" }}
                            to={
                              "/facetoface_details/" +
                              this.state.categoryId +
                              "/" +
                              this.state.levels[0].id +
                              "/" +
                              cat.id
                            }
                          >
                            {" "}
                            <div
                              className="col-md-12 colorDiv"
                              style={{ cursor: "pointer" }}
                            >
                              <div
                                className="col-md-12"
                                style={{ marginLeft: "-10px" }}
                              >
                                <h5
                                  style={{
                                    position: "absolute",
                                    top: "6px",
                                    width: "100%",
                                  }}
                                >
                                  {cat.name}
                                </h5>
                              </div>
                            </div>
                          </Link>
                        ) : (
                          <Link to={"/admin/akWebPortal/subjects/" + cat.id}>
                            <div
                              className="col-md-12 colorDiv"
                              style={{ cursor: "pointer" }}
                            >
                              <div className="col-md-12">
                                <h5
                                  style={{
                                    position: "absolute",
                                    top: "6px",
                                  }}
                                >
                                  {cat.name}
                                </h5>
                              </div>
                            </div>
                          </Link>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        )}
        {this.state.SideNavBar ? (
          <div className="RespHeader slideFromRight">
            <img
              src={cancel}
              alt=""
              className="cancelIcon slidefromLeft"
              onClick={() => {
                this.setState({ SideNavBar: false });
              }}
            />
            <h4
              className="backBtn"
              onClick={() => {
                this.setState({ firstScreen: true, secondScreen: false });
              }}
            >
              Back
            </h4>
            {this.state.firstScreen && (
              <div className="secondDivNavbar">
                {init() === "success" ? (
                  <a
                    href="/dashboard"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <p>
                      {" "}
                      <img
                        src={user}
                        alt="profile"
                        width="16px"
                        height="16px"
                        style={{ marginTop: "-4px" }}
                      />{" "}
                      Hi, {this.state.userInfo?.FIRST_NAME}
                    </p>
                  </a>
                ) : (
                  <>
                    <p
                      onClick={() => {
                        this.setState({ showLogin: true, SideNavBar: false });
                      }}
                    >
                      Login
                    </p>
                    {/* <p
                      onClick={() => {
                        this.setState({
                          showRegister: true,
                          SideNavBar: false,
                        });
                      }}
                    >
                      Register
                    </p> */}
                  </>
                )}
                <p
                  onClick={() => {
                    this.setState({ SideNavBar: false });
                  }}
                >
                  <a href="/" style={{ textDecoration: "none", color: "#000" }}>
                    Home
                  </a>
                </p>
                <p
                  onClick={() => {
                    this.setState(
                      {
                        secondScreen: true,
                        firstScreen: false,
                        showVideoLectures: true,
                        selectedType: "course",
                      },
                      () => {
                        this.getCategory(this.state.selectedType);
                      }
                    );
                  }}
                >
                  Courses{" "}
                  <img
                    src={next}
                    alt=""
                    className="nextIcon slidefromLeft"
                    onClick={() => {
                      this.setState({ SideNavBar: false });
                    }}
                  />
                </p>
                {/* <p
                  onClick={() => {
                    this.setState(
                      {
                        secondScreen: true,
                        firstScreen: false,
                        showVideoLectures: true,
                        selectedType: "facetoface",
                      },
                      () => {
                        this.getCategory(this.state.selectedType);
                      }
                    );
                  }}
                >
                  Face to Face & Live Stream{" "}
                  <img
                    src={next}
                    alt=""
                    className="nextIcon slidefromLeft"
                    onClick={() => {
                      this.setState({ SideNavBar: false });
                    }}
                  />
                </p> */}
                <Link to="/testseries" style={{ color: "black" }}>
                  <p
                    onClick={() => {
                      this.setState(
                        {
                          firstScreen: false,

                          selectedType: "testseries",
                          SideNavBar: false,
                        }
                        // () => {
                        //   this.getCategory(this.state.selectedType);
                        // }
                      );
                    }}
                  >
                    Test Series{" "}
                    <img src={next} alt="" className="nextIcon slidefromLeft" />
                  </p>
                </Link>
                <Link to="/books" style={{ color: "black" }}>
                  <p
                    onClick={() => {
                      this.setState(
                        {
                          firstScreen: false,

                          selectedType: "books",
                        }
                        // () => {
                        //   this.getCategory(this.state.selectedType);
                        // }
                      );
                    }}
                  >
                    Books{" "}
                    <img src={next} alt="" className="nextIcon slidefromLeft" />
                  </p>
                </Link>
              </div>
            )}
            {this.state.secondScreen ? (
              <div>
                {this.state.showVideoLectures ? (
                  <div>
                    {this.state.streams.map((cat) => (
                      <div
                        className="secondDivNavbar"
                        onClick={() => {
                          this.setState({
                            SideNavBar: false,
                          });
                        }}
                      >
                        {this.state.headerType === "facetoface" ? (
                          <Link
                            style={{ textDecoretion: "none" }}
                            to={
                              "/facetoface_details/" +
                              this.state.categoryId +
                              "/" +
                              this.state.levels[0].id +
                              "/" +
                              cat.id
                            }
                          >
                            <div style={{ cursor: "pointer" }}>
                              <p style={{ color: "#000" }}>{cat.name}</p>
                            </div>
                          </Link>
                        ) : (
                          <Link
                            to={"/subjects/" + cat.id}
                            style={{ textDecoretion: "none" }}
                          >
                            <div style={{ cursor: "pointer" }}>
                              <p style={{ color: "#000" }}>{cat.name}</p>
                            </div>
                          </Link>
                        )}
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}
