import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Constants from "../variables/Constants";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/AccountBox";
import axios from "axios";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

class SignIn extends Component {
  state = {
    username: "",
    password: "",
    platform: "",
    redirect: false,
    req: false,
    notification: false,
    error: false,
    loading: false
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.platform === "") {
      this.setState({ req: true });
    } else {
      this.setState({
        loading: true
      });
      if (this.state.platform === "Web") {
        let payLoad = {
          email: this.state.username,
          password: this.state.password,
          status: "dashboard"
        };
        axios.post(Constants.postUrls.login, payLoad).then(resp => {
          // console.log(resp.data);
          if (resp.data.status === "failed") {
            this.setState({
              loading: false,
              error: true
            });
            setTimeout(() => {
              this.setState({
                error: false
              });
            }, 1000);
          } else {
            if (resp.data.role === "admin") {
              localStorage.setItem(
                "joiM2E0Y2UxMzhmODIwYzUwM2RmNjk4ZWE5ODYwZ",
                "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiM2JkYTQzZDA5ZjE0OTk4MDY5OGYwMjkzODcwOTc5NjMyMDI5OTI2NjQwODgxN2I3YmQ5NzNhYjdmMmY1MzNiOTM2ODY5MDI1ZWJhYmJjNGMiLCJpYXQiOjE1OTA1OTcxMDAsIm5iZiI6MTU5MDU5NzEwMCwiZXhwIjoxNjIyMTMzMDk5LCJzdWIiOiIyNzgyIiwic2NvcGVzIjpbXX0.JijU-lbj0UaL6pKKaD24udVQtKfMJ15IwW7tc-lhYR77kZSBO2E_UiGpzg61e6L2Ft7KKhpe4-P1ARs1RXRGAKggp7DDmqio0gQ4gMRt4lXelz_fxjhZos1S2QdrbV5oqjyHNFOrf6js3TRX1P3FWxUHi0bEAiM11KE16KE4kcOOK8E2awP6NcLu__CfjpJnh4maIcqDrhOsKAf15YN_AgAQlJxRs4GjJc_m93IOTEhhoF5yEbZNDkvYKTnPn0g9lTvQx_LJk1I5AnPz_T0EAemDOyOyf832Y8orlfoKBeQySYWctAPV0lWDZLdbJ2eefUoV8k5k97SbN4ZYIx2B6Ssz23MNxFHODRPRDp9Q1Chk3LoATju5a1A-vwCQXPgR2zx_UyAJXWeHd5Ln4db31ZtQceWCjnQwH-8bwFpvfZ2ryLYS27Hrxsr0j4GIw-ki5cXNsclOGRSIvd_3Fuje0UEj_1Msx_Q4DtHwqAnprOo7giLxC_rrRgoQBJkF9jHl5sLvjpfpQRleVi8cka0PdILaLe1nxgQV4-pykT0rNMNcg0bgE1nzEcJCe_Sp7qUoWxAg94TVZ3TkVDOkM-b__yc-xzgdR_H4ZbAsazUe4d22N_8h2Uj8i-bxif-sEMopd700jNGB-Qv4eqpvaF9aBHOCozIyI5DuXU5L_H8svEg"
              );
            }
            if (resp.data.role === "faculty") {
              localStorage.setItem(
                "joiM2E0Y2UxMzhmODIwYzUwM2RmNjk4ZWE5ODYwZ",
                "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiM2E0Y2UxMzhmODIwYzUwM2RmNjk4ZWE5ODYwZTA0ZTIyNTg4MDAzNDk0NTVlZDQ1YzgwNDRkMzEzZWRlNDc3NjEzNTMyYmYxM2Y1NzcwM2QiLCJpYXQiOjE1OTAwNzM2MDIsIm5iZiI6MTU5MDA3MzYwMiwiZXhwIjoxNjIxNjA5NjAyLCJzdWIiOiIyMCIsInNjb3BlcyI6W119.TC5AQTbkQJSpfKgUfSe_FQluwCdVSrsBHg0qxBh2mX1lGvJNqiRkD52TOuQ2HhfI_XZEw5a-BSwfgxqLZ8fhaj9OiJ3cvwS6C8-cDqwyGkSsRhjwRI5tinjaghLULIm06GtJcXedzlx_s9puY83kRHHPA_wAkOqA6UIWPwzSoGI9b0v2sTEpIjWURwarR-40f3V5VLLs5g953wfjGivr-KUrKYuLxKvE1riYJQbpWx--f1C0hPPc3bIT9PtDG_ruXNyEGUOqlMElwIqOZc89HmDWMvrIrwRzUNWejDjyITwUjH94a-EhoGm8egrmcSTivmuti1HTwEyG3qMBiFLC8TyQWuZrLomK0lsYVKuviUH2yvnrxTYdbODzPAlNHQPsyuuGnBdL4MmVWYUG71bh1XKUTUWBb2BBttXVBNme7ioRy0vem0NfccQQqEMbGMo4V1DOe4BFQfDy3SqHZCAyjAJ5j9Z813rfCqDmv3wmTRzvjbxoH_8VbJ7uPNGO_a8fwItS8CFgyp_l91ow-4E4EDiJE7Sk5RmayHl442K-veNrUyCx1K_So_CvIeAy3KI2QqfNam-WsDQwZKzwOHwwDow3bpD3rIWNaUTnGzyAorXwTiaOjKJ3i1LmTkDtWtsSmOlQHX8sGgDb34XnFkaaEOErVlU6fqwKlsrdFJGiNAU"
              );
            }
            if (resp.data.role === "staff") {
              localStorage.setItem(
                "joiM2E0Y2UxMzhmODIwYzUwM2RmNjk4ZWE5ODYwZ",
                "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiOTg1MjBlYWNkMGU0YTU4NTg3NWI2YWE4MjhlYjQxYWViYjc0MDc0ZDc4ZDk0YTEwMGQ0MzU2OTcwNGIzZDQ0ZjNlZWFjYWJhYzM1MWNjYTIiLCJpYXQiOjE1OTEyNjkzMTMsIm5iZiI6MTU5MTI2OTMxMywiZXhwIjoxNjIyODA1MzEzLCJzdWIiOiIzNzQ4Iiwic2NvcGVzIjpbXX0.T1ORgzbVOG3GTHd5PqM0RWufJrzxk-0DcN397GlhCENGbH7L5juugqLMW2UgEaKN-JpBE06Nma1K_vW6pYXBCxz382z_SlQp_wJtSFoP6rLTJuicd5FINdB0eT_WKRqly8IOjnvc9-67dUfeHOY4KuNry8UaeEV8kG5k2YCkyLkgz1hXHwV4kcnsEloQcxh9knVzRxeuNHAizoD83XGQJvbG7a5its9pbeFYsz-Hgj8zMj19bHsK2dDz2s9glG_9ws_FXCCFPmsJSFL2zWxOTwKrnPT0jhe2G0DWxccI1YySQn632Q7xocYB8OHBQkWslISFpjwohAXoQD8EkazKoWioNOzwAfpKMARqUShB5yWKC7J9hnm5Uz-t46LfCN_zR9l7uKoSem3XJRlT-uI1PxVW3QZMC41NcIahAZuTBenhJuFZG4YIZrWOt_gii2DXnl1KoN6jSZ5myCxCVoAXNJ4N0s909fHfbO0wK46myjB-fjOiG2QLZOjoovQXpULaNimN8FAsPixu8ZyGZt0CaORXPfOIPLunUMDXTm7yNaj_MlvELxG-J_c-L0B715GKRSmaGR7-NSP6xvhmrJW2ooS3eL9Wgj3lzd6J7tNXC9uZMpakiYjSniCMt_vJuf_qbJSv2mCba1lYmWLFcn8unJkTwGtVAo29JLfHDkB6GNY"
              );
            }
            if (resp.data.role === "vendor") {
              localStorage.setItem(
                "joiM2E0Y2UxMzhmODIwYzUwM2RmNjk4ZWE5ODYwZ",
                "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiODJjNDM3OTRmYjlhY2EwZWM4MDYxNDJlZDE2ODUyNTUyZWVhYTQxNDllOGUxNDlhODM4MThiZGM0ZTVhODcxMjE3MWUwYjJmZmE5ZWRkNjMiLCJpYXQiOjE1OTQ0NTQwMDMsIm5iZiI6MTU5NDQ1NDAwMywiZXhwIjoxNjI1OTkwMDAzLCJzdWIiOiIzNzM4Iiwic2NvcGVzIjpbXX0.zDZkBVHl23e29QJGTE-5op0qP9MzxK_6-qmk_e_dXB1CoGGj5v6JRSp7SUOPoLXUPbZG96Y7noEeK4XAwb1oR94Qd2rUlE11z4qyNozCdUto9qe1gUeZA5DV_9qE5BbDqppbyK-E-sVIn3vELnyX_u4NjWsHnyhvBQLME479iN6ZzfGyXiBBvmOZQUhSwdiivXM2LXCq5SjEJnBlUESi29UBB5QyGgxEtI7pMCQWeDqD4yJk7MpTOcOPr-Dwum4UO69XI56x6k6K84m8xknjcPrRmN4T-h7W1a15AY3IN5nsPdQFS8YYgJ-h4iDRVdJ3eoIT-FNg32ObbGe7mN-Ikl5Afdg6hTYlS30qi769T88GpOD9DZAneH2rKN2xpff7xs31ytqJZ__8QtHgG5kQYHuTGUxzHamXfjZYTp0675R2todD6qYtuKRknW3AgXL7RqNdRhMBBuMbYdjIVQMGMQ3tt_68EaC2NwrYacrS5uP05ZtgRWOGjvd3nEOFhsQTHu9nMT5qeiVntlIzvSAAx83lcYMNKXCJgTVS2olqpaF40CafyUzCNuVvqvmO3_lud0d4isA0BoTdNy5rp2L49SL-RoPOSAGUH2cNP2k3TZ8rYZ77yvsmHcylb7qqFZTPy3fPZh_mcfzlvb3qVLaqEb20iqZUSzePToqeOyojopE"
              );
            }
            localStorage.setItem("accessToken", resp.data.token);
            localStorage.setItem("category", "Course");
            setTimeout(() => {
              window.location.href = "/";
            }, 1000);
            this.setState({
              notification: true,
              loading: false
            });
          }
        });
      } else {
        let payLoad = {
          username: this.state.username,
          password: this.state.password
        };
        axios
          .post("https://core.akclasses.in/api/login", payLoad)
          .then(resp => {
            // console.log(resp);
            if (resp.data.status === "error") {
              this.setState({
                loading: false,
                error: true
              });
              setTimeout(() => {
                this.setState({
                  error: false
                });
              }, 1000);
            } else {
              localStorage.setItem(
                "joiM2E0Y2UxMzhmODIwYzUwM2RmNjk4ZWE5ODYwZ",
                "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImJlNTk0N2Q5MzFmMzhlYjViOWMzNDAzNjE3YzBmYjJkMDEyOGM4YTc1OGM3MzA0MDZlZDEyNzMyZDNiMTgyYmYyOTkxOTU5MTQ1YWU1OTk2In0.eyJhdWQiOiIxNCIsImp0aSI6ImJlNTk0N2Q5MzFmMzhlYjViOWMzNDAzNjE3YzBmYjJkMDEyOGM4YTc1OGM3MzA0MDZlZDEyNzMyZDNiMTgyYmYyOTkxOTU5MTQ1YWU1OTk2IiwiaWF0IjoxNTk3NjU3MjMwLCJuYmYiOjE1OTc2NTcyMzAsImV4cCI6MTYyOTE5MzIzMCwic3ViIjoiMiIsInNjb3BlcyI6W119.kCfmogPJsgd07Fih84_BL7gE2grIgPxjiddyKD4QhzsWAZgMelogelaQqFis5wPsCPIUgUzXyn0lbWRCcM9n-LIu_3-EmHH296YCjfymceCHmICmC5fsBc_TzySUanLcTjnGJDeFQUIFtCZEAX0hEquWabM-iM0NeP5aJdv_Hhbjlz1M1zOSFRUwYlEYApu_DHqAW4nnoavztHeS_e9EATooFZRezzQevxAfnQj89NHamEPL0ikAFdU71x8GtZFR7JANz3-R78s-pk5st87Io5T7sjCkglxbtiuIU5ti6PQKb-S4ONnU5V1uAB_65YraRTlQ6rV8Z0g8XymUYLrV_89FRFNFRg67o7jqkp0dGHIiTUq9oWXKkadUIV5qi0eIm2zXTbTMVh9eXIeX8pjbeU9r8bjvV0_JlE_Qa9ewWlWDbMrYc6ApuK2mu3Ej0JGfzq80vLqT3Tro_WytAYrl16XR6EuehBKiJ6eCDdb67RgnAnxntt2Kd8Zmr4eUz7rZYiJFG0YGJtXrXurnbHbZ71YMMNsM940iS_SQHbuM1YWMWJNvINKWH5vc8ngyPGykweF9DKZXRFXfxwBOuW3N4U2Qx7ow16-AAUucT-cXXQJUNhRDkLsDKzrYwbRK6VfwEzVbzGmQRwpyK2ZdmzFIeHtF3_g4ogvt1nXft1FF5bA"
              );
              localStorage.setItem("accessToken", resp.data.access_token);
              window.location.href = "/";
            }
          });
      }
    }
  };
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  render() {
    return (
      <div className="signIn">
        {this.state.error ? (
          <div
            style={{
              width: "150px",
              position: "relative",
              left: "70%",
              top: "50px"
            }}
          >
            <SnackbarContent
              message={"Invalid Credentials!"}
              icon={AddAlert}
              color="danger"
            />
          </div>
        ) : null}
        <div className="conTent">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="success">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Welcome Admin!</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <label style={{ marginLeft: "5px" }} htmlFor="">
                          Platform
                        </label>
                        <br />
                        <input
                          type="radio"
                          name="platform"
                          style={{ marginTop: "10px", cursor: "pointer" }}
                          value="Web"
                          onChange={e =>
                            this.setState({
                              platform: e.target.value,
                              req: false
                            })
                          }
                        />
                        <label>Web</label>
                        <input
                          type="radio"
                          name="platform"
                          value="App"
                          style={{
                            marginLeft: "20px",
                            cursor: "pointer",
                            marginTop: "10px"
                          }}
                          onChange={e =>
                            this.setState({
                              platform: e.target.value,
                              req: false
                            })
                          }
                        />
                        <label>App</label>
                        {this.state.req ? (
                          <span style={{ color: "red", marginLeft: "10px" }}>
                            *required
                          </span>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Email"
                          id="float"
                          inputProps={{
                            type: "email",
                            required: true,
                            onChange: e =>
                              this.setState({ username: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Password"
                          id="float"
                          inputProps={{
                            type: "password",
                            required: true,
                            onChange: e =>
                              this.setState({ password: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <Button color="success" type="submit">
                          {this.state.loading ? <Loader /> : "Login"}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default SignIn;
