import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
const classes = makeStyles(styles);

const perPages = ["10", "25", "50", "100"];
class BudgetReport extends Component {
  state = {
    notifyStatus: false,
    down: false,
    ord: true,
    categories: [],
    secondaryCategories: [],
    tableArray: [],
    status: "",
    frmDate: "",
    toDate: "",
    active: 1,
    per_page: "10",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: []
  };

  componentDidMount() {
    axios
      .get(
        "https://admin.akclasses.in/api/budget/get_budget_report?per_page=" +
          this.state.per_page
      )
      .then(resp => {
        // console.log(resp.data);
        // let btnIdArray = [];
        // for (let i = 1; i <= resp.data.last_page; i++) {
        //   btnIdArray.push(i);
        // }
        this.setState(
          {
            // btnIdArray,
            secondaryCategories: resp.data.data
            // total: resp.data.total,
            // from: resp.data.from,
            // to: resp.data.to
          },
          () => {
            // let filteredBtnIdArray = this.state.btnIdArray.filter(
            //   v => v >= this.state.fromInc && v <= this.state.toInc
            // );
            // this.setState({
            //   filteredBtnIdArray
            // });
            let tableArray = this.state.secondaryCategories.map(
              (sec, index) => [
                [index + 1],
                [sec.budget_mode],
                [sec.total]
                // [sec.order_id],
                // [sec.type === "online" ? sec.price : "NA"],
                // [sec.type === "offline" ? sec.price : "NA"]
              ]
            );
            tableArray.push({
              total: true,
              colspan: "1",
              amount: (
                <div>
                  <td> ₹{resp.data.total_mode}</td>
                </div>
              )
            });
            this.setState({ tableArray });
          }
        );
      });
    // axios.get("https://admin.akclasses.in/api/budget_mode/index").then(resp => {
    //   //console.log(resp.data);
    //   this.setState({
    //     categories: resp.data
    //   });
    // });
  }
  handlePage = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5
      },
      () => {
        axios
          .get(
            "https://admin.akclasses.in/api/budget/get_budget_report?per_page=" +
              this.state.per_page
          )
          .then(resp => {
            //console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                ord: true,
                secondaryCategories: resp.data.data,
                total: resp.data.total,
                from: resp.data.from,
                to: resp.data.to
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray
                });
              }
            );
          });
      }
    );
  };
  currentPage = v => {
    let commonUrl = this.state.ord
      ? "https://admin.akclasses.in/api/budget/get_budget_report?per_page=" +
        this.state.per_page +
        "&page="
      : "https://admin.akclasses.in/api/budget/budget_mode_search?budget_mode_id=" +
        this.state.category +
        "per_page=" +
        this.state.per_page +
        "&page=";
    axios.get(commonUrl + v).then(resp => {
      // console.log(resp.data.data);
      this.setState({
        total: resp.data.total,
        from: resp.data.from,
        to: resp.data.to,
        secondaryCategories: resp.data.data,
        active: v
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          v => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                v => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          v => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                v => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  handleSelect = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        const payload = {
          status: this.state.status
        };
        axios
          .post(
            "https://admin.akclasses.in/api/courseCategory/status/" + id,
            payload
          )
          .then(() => {
            //console.log(res);
            this.setState({
              notifyStatus: true
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
      }
    );
  };

  searchRecord = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/budget/budget_mode_search?frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate
      )
      .then(resp => {
        // console.log(resp.data);
        // let btnIdArray = [];
        // for (let i = 1; i <= resp.data.last_page; i++) {
        //   btnIdArray.push(i);
        // }
        this.setState(
          {
            // ord: false,
            down: true,
            // btnIdArray,
            secondaryCategories: resp.data.data
            // fromInc: 1,
            // active: 1,
            // toInc: 5,
            // total: resp.data.total,
            // from: resp.data.from,
            // to: resp.data.to
          },
          () => {
            // let filteredBtnIdArray = this.state.btnIdArray.filter(
            //   v => v >= this.state.fromInc && v <= this.state.toInc
            // );
            // this.setState({
            //   filteredBtnIdArray
            // });
            let tableArray = this.state.secondaryCategories.map(
              (sec, index) => [
                [index + 1],
                [sec.budget_mode],
                [sec.total]
                // [sec.order_id],
                // [sec.type === "online" ? sec.price : "NA"],
                // [sec.type === "offline" ? sec.price : "NA"]
              ]
            );
            tableArray.push({
              total: true,
              colspan: "1",
              amount: (
                <div>
                  <td> ₹{resp.data.total_mode}</td>
                </div>
              )
            });
            this.setState({ tableArray });
          }
        );
      });
  };
  render() {
    return (
      <div>
        <GridContainer>
          {this.state.notifyStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Status Changed!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}

          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <FormControl
                  style={{
                    position: "absolute",
                    right: "10%",
                    width: "85px"
                  }}
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    s
                    key={1}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={this.state.per_page}
                    inputProps={{
                      name: "per_page",
                      id: "simple-select",
                      onChange: e => {
                        this.handlePage(e);
                      }
                    }}
                  >
                    {perPages.map((v, i) => (
                      <MenuItem
                        key={i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={v}
                      >
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <GridItem xs={12} sm={12} md={4}>
                  <h6 stylle={{ marginBottom: "0px" }}>Filter By Date</h6>
                  {/* <FormControl
                    style={{ marginTop: "12px" }}
                    required={true}
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Budget Mode
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={this.state.category}
                      inputProps={{
                        name: "category",
                        id: "simple-select",
                        onChange: e => {
                          this.setState({
                            category: e.target.value,
                            down: false
                          });
                        }
                      }}
                    >
                      {this.state.categories.map((v, i) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          key={i}
                          value={v.id}
                        >
                          {v.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      onChange={e => {
                        let year = e._d.getFullYear().toString();
                        let month = (e._d.getMonth() + 101)
                          .toString()
                          .substring(1);
                        let day = (e._d.getDate() + 100)
                          .toString()
                          .substring(1);
                        this.setState(
                          {
                            frmDate: year + "/" + month + "/" + day
                          },
                          () => {
                            //console.log(this.state.frmDate);
                          }
                        );
                      }}
                      inputProps={{
                        placeholder: "From Date"
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      onChange={e => {
                        let year = e._d.getFullYear().toString();
                        let month = (e._d.getMonth() + 101)
                          .toString()
                          .substring(1);
                        let day = (e._d.getDate() + 100)
                          .toString()
                          .substring(1);
                        this.setState(
                          {
                            toDate: year + "/" + month + "/" + day
                          },
                          () => {
                            //console.log(this.state.toDate);
                          }
                        );
                      }}
                      inputProps={{
                        placeholder: "To Date"
                      }}
                    />
                  </FormControl>
                  {this.state.aAlert ? (
                    <div>
                      <span style={{ color: "red" }}>*required</span>
                    </div>
                  ) : null}
                </GridItem>
                <Button
                  onClick={this.searchRecord}
                  style={{
                    position: "relative",
                    left: "8px"
                  }}
                  color="success"
                >
                  Search
                </Button>

                {this.state.down ? (
                  <a
                    href={
                      "https://admin.akclasses.in/api/budget/budget_excel_report?frmdate=" +
                      this.state.frmDate +
                      "&todate=" +
                      this.state.toDate
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      style={{
                        position: "relative",
                        left: "15px"
                      }}
                      color="danger"
                    >
                      Download
                    </Button>
                  </a>
                ) : null}
                <h3>Budget Reports</h3>
                <Table
                  tableHead={["Sr No.", "Mode Name", "Amount"]}
                  tableData={this.state.tableArray}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : (
          <div>
            {/* <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div> */}
            <div style={{ float: "right", border: "1px solid grey" }}>
              {/* <div
                onClick={this.prevPages}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey"
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div> */}
              {[].map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(v);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === v ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === v ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black"
                  }}
                >
                  {v}
                </div>
              ))}
              {/* <div
                onClick={this.nextPages}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.filteredBtnIdArray.length === 2 ||
                    this.state.filteredBtnIdArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey"
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c8;
                </i>
              </div> */}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default BudgetReport;
