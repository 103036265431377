import React, { Component } from "react";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import { Redirect } from "react-router-dom";
import Card from "components/Card/Card.js";
import EditIcon from "@material-ui/icons/Edit";
import CardHeader from "components/Card/CardHeader.js";
import axios from "axios";
import Loader from "components/Loader";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
const classes = makeStyles(styles);

class AddMailTemplate extends Component {
  state = {
    heading: "",
    title: "",
    type: "",
    template: "",
    redirect: false,
    notification: false,
    loading: false,
    types: ["Order", "Shipping"],
    typeAlert: false,
    templateAlert: false,
  };
  componentDidMount() {
    // axios.get("https://admin.akclasses.in/api/mailTemplate").then(resp => {
    //   //console.log(resp.data);
    //   this.setState({
    //     // heading: resp.data.CONTENT,
    //     template: resp.data.template
    //   });
    // });
  }
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChange(content) {
    // console.log(content); //Get Content Inside Editor
    this.setState(
      {
        template: content,
      },
      () => {
        console.log(this.state.template);
      }
    );
  }

  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    if (this.state.type === "") {
      this.setState({
        typeAlert: true,
      });
    } else if (this.state.template === "") {
      this.setState({
        templateAlert: true,
      });
    } else {
      let payLoad = {
        title: this.state.title,
        type: this.state.type,
        template: this.state.template,
        // template: JSON.stringify(this.state.template)
      };
      console.log(payLoad);

      // Convert payLoad object to JSON string
      // let payLoadJSON = JSON.stringify(payLoad);

      axios
        .post("https://admin.akclasses.in/api/mailTemplate", payLoad)
        .then((resp) => {
          console.log(resp);
          setTimeout(() => {
            this.setState({
              notification: false,
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        });
    }
  };
  render() {
    const { template } = this.state;
    if (this.state.redirect) {
      return <Redirect to={"/admin/mailtemplates"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Mail template added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Mail Template</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Title"
                          id="float"
                          inputProps={{
                            required: true,
                            type: "text",
                            onChange: (e) =>
                              this.setState({ title: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Mail Type
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.type}
                            inputProps={{
                              required: true,
                              name: "type",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  type: e.target.value,
                                  typeAlert: false,
                                });
                                // //console.log(e.target.value);
                              },
                            }}
                          >
                            {this.state.types.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.typeAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      {/* <GridItem xs={12} sm={12} md={12}>
                        <label htmlFor="">Template</label>
                        <br />
                        <textarea
                          name="template"
                          // value={this.state.template}
                          onChange={this.handleInput}
                          className="form-control"
                          cols="119"
                          rows="6"
                        ></textarea>
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={12}>
                        <label>Template</label>
                        <br />
                        {/* <div style={{ color: "black" }}> */}
                        <SunEditor
                          setDefaultStyle="font-family: Roboto; font-size: 10px;"
                          // width="50%"
                          setOptions={{
                            buttonList: [
                              ["undo", "redo"],
                              ["font", "fontSize"],
                              ["paragraphStyle", "blockquote"],
                              [
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript",
                              ],
                              ["fontColor", "hiliteColor"],
                              ["align", "list", "lineHeight"],
                              ["outdent", "indent"],

                              [
                                "table",
                                "horizontalRule",
                                "link",
                                "image",
                                "video",
                              ],
                              // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                              // ['imageGallery'], // You must add the "imageGalleryUrl".
                              // ["fullScreen", "showBlocks", "codeView"],
                              ["preview", "print"],
                              ["removeFormat"],

                              // ['save', 'template'],
                              // '/', Line break
                            ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                            defaultTag: "div",
                            minHeight: "300px",
                            showPathLabel: false,
                            // font: sortedFontOptions
                          }}
                          name="template"
                          value={this.state.template}
                          onChange={this.handleChange.bind(this)}
                        />
                        {/* <CKEditor
                            required={true}
                            editor={ClassicEditor}
                            data={this.state.template}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              this.setState({
                                template: data,
                                bAlert: false,
                              });
                            }}
                            style={{ width: '100%', height: '400px' }} // Set width and height inline
                          /> */}
                        {/* <h2>Preview</h2>
                          <div dangerouslySetInnerHTML={{ __html: template }} /> */}
                        {/* </div> */}
                        {this.state.typeAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                    </GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                      <Button
                        className=""
                        type="submit"
                        color="info"
                        style={{ position: "relative", top: "20px" }}
                      >
                        {this.state.loading ? <Loader /> : "Add Template"}
                      </Button>
                    </GridItem>
                    <br />
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      style={{ position: "relative", top: "10px" }}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          color: "Green",
                          fontWeight: "500",
                        }}
                      >
                        Sample Keywords To Use In Template:
                      </div>
                      <div>
                        <span
                          style={{
                            color: "darkblue",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          {"{{$user_name}}"}
                        </span>
                        :{" "}
                        <span style={{ fontSize: "13px" }}>
                          Use to show users/students name.
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "darkblue",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          {"{{$email}}"}
                        </span>
                        :{" "}
                        <span style={{ fontSize: "13px" }}>
                          Use to show users/students email address.
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "darkblue",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          {"{{$mobile}}"}
                        </span>
                        :{" "}
                        <span style={{ fontSize: "13px" }}>
                          Use to show users/students contact number.
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "darkblue",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          {"{{$address}}"}
                        </span>
                        :{" "}
                        <span style={{ fontSize: "13px" }}>
                          Use to show users/students address.
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "darkblue",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          {"{{$product_details}}"}
                        </span>
                        :{" "}
                        <span style={{ fontSize: "13px" }}>
                          Use to show users/students purchased course name.
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "darkblue",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          {"{{$transaction_id}}"}
                        </span>
                        :{" "}
                        <span style={{ fontSize: "13px" }}>
                          Use to show users/students purchased course
                          transaction number.
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "darkblue",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          {"{{$order_amount}}"}
                        </span>
                        :{" "}
                        <span style={{ fontSize: "13px" }}>
                          Use to show users/students purchased course total
                          amount.
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "darkblue",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          {"{{$quantity}}"}
                        </span>
                        :{" "}
                        <span style={{ fontSize: "13px" }}>
                          Use to show users/students purchased course quantity.
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "darkblue",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          {"{{$discount}}"}
                        </span>
                        :{" "}
                        <span style={{ fontSize: "13px" }}>
                          Use to show users/students purchased course discount
                          on total amount.
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "darkblue",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          {"{{$cashback}}"}
                        </span>
                        :{" "}
                        <span style={{ fontSize: "13px" }}>
                          Use to show users/students purchased course cashback.
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "darkblue",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          {"{{$order_id}}"}
                        </span>
                        :{" "}
                        <span style={{ fontSize: "13px" }}>
                          Use to show users/students purchased course order
                          number.
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "darkblue",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          {"{{$order_date}}"}
                        </span>
                        :{" "}
                        <span style={{ fontSize: "13px" }}>
                          Use to show users/students course purchased date.
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "darkblue",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          {"{{$order_status}}"}
                        </span>
                        :{" "}
                        <span style={{ fontSize: "13px" }}>
                          Use to show users/students purchased course status.
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "darkblue",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          {"{{$product_key}}"}
                        </span>
                        :{" "}
                        <span style={{ fontSize: "13px" }}>
                          Use to show users/students purchased course product
                          key.
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "darkblue",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          {"{{$referal_code}}"}
                        </span>
                        :{" "}
                        <span style={{ fontSize: "13px" }}>
                          Use to show users/students purchased course referal
                          code.
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "darkblue",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          {"{{$attempt}}"}
                        </span>
                        :{" "}
                        <span style={{ fontSize: "13px" }}>
                          Use to show users/students purchased course Attempts.
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "darkblue",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          {"{{$view_validity}"}
                          {/* {"{{$view_validity}"} */}
                        </span>
                        :{" "}
                        <span style={{ fontSize: "13px" }}>
                          Use to show users/students purchased course Views and
                          Validity.
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "darkblue",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          {"{{$logistic}}"}
                        </span>
                        :{" "}
                        <span style={{ fontSize: "13px" }}>
                          Use to show users/students orders logistic provider
                          name.
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "darkblue",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          {"{{$tracking_link}}"}
                        </span>
                        :{" "}
                        <span style={{ fontSize: "13px" }}>
                          Use to show users/students purchased order tracking
                          link.
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "darkblue",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          {"{{$tracking_no}}"}
                        </span>
                        :{" "}
                        <span style={{ fontSize: "13px" }}>
                          Use to show users/students purchased order tracking
                          number.
                        </span>
                      </div>
                    </GridItem>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddMailTemplate;
