// import { isAuth, userToken } from "./AuthHelper";

const init = () => {
  const userToken = localStorage.getItem("token");
  const isAuth = userToken ? true : false;
  // console.log(userToken, isAuth);
  let w = window;
  let status = false;
  w.axios = require("axios");
  if (isAuth) {
    w.axios.defaults.headers.common["Authorization"] = "Bearer " + userToken;

    status = true;
  } else {
    status = false;
  }
  return status ? "success" : "failed";
};
export default init;
