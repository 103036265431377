/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Card from "components/Card/Card.js";
import EditIcon from "@material-ui/icons/Edit";
import CardHeader from "components/Card/CardHeader.js";
import axios from "axios";
import Loader from "components/Loader";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Datetime from "react-datetime";
import Constants from "variables/Constants";
const classes = makeStyles(styles);
let fd = new FormData();

class UpdateFaceToFace extends Component {
  state = {
    type: "",
    catData: [],
    categories: [],
    levels: [],
    streams: [],
    subjects: [],
    facultyArr: [],
    showBoth: false,
    pkey: "",
    category: "",
    level: "",
    stream: "",
    subject: "",
    day: "",
    city: "",
    batch_started_date: "",
    batch_ended_date: "",
    fees: "",
    spot_registration_fees: "",
    color: "",
    image: {},
    redirect: false,
    notification: false,
    loading: false
  };
  componentDidMount() {
    let categoryData = this.props.location.state.categoryData;
    console.log(categoryData);
    axios.get("https://admin.akclasses.in/api/getfaceCats").then(resp => {
      console.log(resp);
      this.setState({
        categories: resp.data.data,
        category: categoryData.category_id,
        level: categoryData.level_id,
        stream: categoryData.stream_id,
        subject: categoryData.subject_id,
        day: categoryData.day,
        city: categoryData.city,
        batch_started_date: categoryData.batch_started_date,
        batch_ended_date: categoryData.batch_ended_date,
        fees: categoryData.fees,
        spot_registration_fees: categoryData.spot_registration_fees,
        color: categoryData.color,
        image: categoryData.image,
      });
    });
    axios.get("https://admin.akclasses.in/api/getCategyLevels?category_id=" + categoryData.category_id + "&type=COURSE")
      .then(resp => {
        console.log(resp.data);
        this.setState({
          levels: resp.data
        });
      },() => {
        console.log(this.state.levels);
      });

    axios.get("https://admin.akclasses.in/api/getLevelsStream?level_id=" + categoryData.level_id + "&type=COURSE")
      .then(resp => {
        console.log(resp);
        this.setState({
          streams: resp.data
        });
      });

      axios.get("https://admin.akclasses.in/api/getFaceSubjects?stream_id=" + categoryData.stream_id)
      .then(resp => {
        console.log(resp);
        this.setState({
          subjects: resp.data.data
        });
      });


  }
  handleLevel = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        aAlert: false
      },
      () => {
        //console.log(this.state.category);
        axios
          .get(
            "https://admin.akclasses.in/api/getCategyLevels?category_id=" + this.state.category + "&type=COURSE"
          )
          .then(resp => {
            console.log(resp.data);
            this.setState({
              levels: resp.data
            });
          });
      }
    );
  };

  handleStream = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        cAlert: false
      },
      () => {
        //console.log(this.state.level);
        axios
          .get("https://admin.akclasses.in/api/getLevelsStream?level_id=" + this.state.level + "&type=COURSE")
          .then(resp => {
            console.log(resp);
            this.setState({
              streams: resp.data
            });
          });
      }
    );
  };

  handleSubject = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        cAlert: false
      },
      () => {
        //console.log(this.state.level);
        axios
          .get("https://admin.akclasses.in/api/getFaceSubjects?stream_id=" + this.state.stream)
          .then(resp => {
            console.log(resp);
            this.setState({
              subjects: resp.data.data
            });
          });
      }
    );
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      dAlert: false
    })
  }

  handleFile = e => {
    this.setState({ [e.target.name]: e.target.files[0] }, () => {
    });
  };
  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();
    console.log(this.state.category);
    console.log(this.state.level);
    console.log(this.state.stream);
    let categoryData = this.props.location.state.categoryData;
    let categoryId = categoryData.id;
    if (this.state.category === "") {
      this.setState({
        aAlert: true
      });
    } else if (this.state.level === "") {
      this.setState({
        cAlert: true
      });
    } else if (this.state.stream === "") {
      this.setState({
        dAlert: true
      });
    } else if (this.state.subject === "") {
      this.setState({
        dAlert: true
      });
    } else {
      this.setState({
        loading: true
      });
    fd.append("category_id", this.state.category);
    fd.append("level_id", this.state.level);
    fd.append("stream_id", this.state.stream);
    fd.append("subject_id", this.state.subject);
    fd.append("day", this.state.day);
    fd.append("image", this.state.image);
    fd.append("city", this.state.city);
    fd.append("batch_started_date", this.state.batch_started_date);
    fd.append("batch_ended_date", this.state.batch_ended_date);
    fd.append("fees", this.state.fees);
    fd.append("spot_registration_fees", this.state.spot_registration_fees);
    fd.append("color", this.state.color);
    fd.append("_method", "PUT");
    this.setState({
      loading: true
    });
    axios.post(Constants.postUrls.addfacetoface + "/" + categoryId, fd).then(resp => {
      console.log(resp);
      setTimeout(() => {
        this.setState({
          redirect: true
        });
      }, 1000);
      this.setState({
        notification: true,
        loading: false
      });
    });
  }
  };
  handleDrop = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        showBoth: false
      },
      () => {
        axios
          .get("https://admin.akclasses.in/api/course/" + this.state.type)
          .then(resp => {
            //console.log(resp.data);
            this.setState({
              catData: resp.data.product.variant
            });
            let viewIds = [];
            let views = [];
            this.state.catData.forEach(v => {
              if (viewIds.includes(v.view_and_validity.id) === false) {
                viewIds.push(v.view_and_validity.id);
                views.push(v.view_and_validity);
              }
            });
            //console.log(views);
            this.setState({ catData: views }, () => {
              this.setState({
                showBoth: this.state.catData.length >= 1 ? true : false
              });
            });
          });
      }
    );
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/facetoface"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Face To Face Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Face To Face</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          required={true}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Category
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.category}
                            inputProps={{
                              name: "category",
                              id: "simple-select",
                              onChange: this.handleLevel
                            }}
                          >
                            {this.state.categories.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.category}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Level
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.level}
                            inputProps={{
                              required: true,
                              name: "level",
                              id: "simple-select",
                              onChange: this.handleStream
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value=""
                            >
                              Select Category First
                            </MenuItem>
                            {this.state.levels.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Stream
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.stream}
                            inputProps={{
                              required: true,
                              name: "stream",
                              id: "simple-select",
                              onChange: this.handleSubject
                              // //console.log(e.target.value);
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value=""
                            >
                              Select Level First
                            </MenuItem>
                            {this.state.streams.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.dAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Subject
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.subject}
                            inputProps={{
                              required: true,
                              name: "subject",
                              id: "simple-select",
                              onChange: this.handleChange
                              // //console.log(e.target.value);
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value=""
                            >
                              Select Stream First
                            </MenuItem>
                            {this.state.subjects.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.dAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Days"
                          id="float"
                          inputProps={{
                            value:this.state.day,
                            required: true,
                            onChange: e =>
                              this.setState({ day: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      
                      <GridItem xs={12} sm={12} md={4}>
                        <label>Batch Started Date</label>
                        <FormControl fullWidth>
                          <Datetime
                              timeFormat={false}
                              onChange={e => {
                                let year = e._d.getFullYear().toString();
                                let month = (e._d.getMonth() + 101)
                                  .toString()
                                  .substring(1);
                                let day = (e._d.getDate() + 100)
                                  .toString()
                                  .substring(1);
                                this.setState(
                                  {
                                    batch_started_date: year + "/" + month + "/" + day
                                  },
                                  () => {
                                    //console.log(this.state.batch_started_date);
                                  }
                                );
                              }}
                              inputProps={{
                                value: this.state.batch_started_date,
                                placeholder: "Batch Started Date"
                              }}
                            />
                        </FormControl>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <label>Batch Ended Date</label>
                        <FormControl fullWidth>
                          <Datetime
                              timeFormat={false}
                              onChange={e => {
                                let year = e._d.getFullYear().toString();
                                let month = (e._d.getMonth() + 101)
                                  .toString()
                                  .substring(1);
                                let day = (e._d.getDate() + 100)
                                  .toString()
                                  .substring(1);
                                this.setState(
                                  {
                                    batch_ended_date: year + "/" + month + "/" + day
                                  },
                                  () => {
                                    //console.log(this.state.batch_ended_date);
                                  }
                                );
                              }}
                              inputProps={{
                                value: this.state.batch_ended_date,
                                placeholder: "Batch Ended Date"
                              }}
                            />
                        </FormControl>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="City Name"
                          id="float"
                          inputProps={{
                            value: this.state.city,
                            required: true,
                            onChange: e =>
                              this.setState({ city: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Fees"
                          id="float"
                          inputProps={{
                            value: this.state.fees,
                            required: true,
                            onChange: e =>
                              this.setState({ fees: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Spot Registration Fees"
                          id="float"
                          inputProps={{
                            value: this.state.spot_registration_fees,
                            required: true,
                            onChange: e =>
                              this.setState({ spot_registration_fees: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>

                      {/* <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Color Code"
                          id="float"
                          inputProps={{
                            value: this.state.color,
                            required: true,
                            onChange: e =>
                              this.setState({ color: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}

                      <GridItem xs={12} sm={12} md={4}>
                      <label>Select Color</label>
                          <br />
                          <input
                            id="color"
                            name="color"
                            onChange={ (e)=>{
                              this.setState({ color: e.target.value})
                            }}
                            type="color"
                            required={true}
                            style={{ marginTop: "15px", height: "50px", width: "200px"}}
                          />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <label>Image</label>
                        <br />
                        <input
                          id="img"
                          name="image"
                          onChange={this.handleFile}
                          accept="image/*"
                          type="file"
                          // required={true}
                        />
                        <img src={Constants.imgDomain + this.state.image} style={{width:"100px",height:"100px"}} />
                      </GridItem>

                      
                      <GridItem xs={12} sm={12} md={5}>
                        <Button className="" type="submit" color="info">
                          {this.state.loading ? (
                            <Loader />
                          ) : (
                            "Update Face To Face"
                          )}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/facetoface"
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateFaceToFace;
