/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Constants from "variables/Constants";
const classes = makeStyles(styles);
class UpdateStrategy extends Component {
  state = {
    course: "",
    type: "",
    user: "",
    description: "",
    faculties: [],
    courses: [],
    data: [],
    id: 0,
    duration: "",
    cTitle: "",
    redirect: false,
    aAlert: false,
    bAlert: false,
    notification: false,
    loading: false
  };
  componentDidMount() {
    let categoryData = this.props.location.state.categoryData;
    console.log(categoryData);
    axios
      .get("https://admin.akclasses.in/api/coursesForFilter?type=COURSES")
      // .get(Constants.getUrls.coursesR + "?type=COURSES")
      .then(resp => {
        console.log(resp);
        this.setState({
          courses: resp.data
        });
      });
    // axios.get("https://admin.akclasses.in/api/faculty")
    axios.get(Constants.getUrls.faculty)
    .then(resp => {
      console.log(resp);
      this.setState({
        faculties: resp.data
      });
    });
    this.setState({
      id: categoryData.id,
      course: categoryData.course_id,
      duration: categoryData.duration,
      description: categoryData.description,
      type: categoryData.strategy_type,
      user: categoryData.type
    });
  }
  findFaculty = data => {
    let obj = this.state.faculties.find(v => v.id === data[0].faculty_id);
    // let obj = this.state.faculties.find(v => v.id === 1);
    if (obj !== undefined) {
      return obj.name;
    }
  };
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.course === "") {
      this.setState({
        bAlert: true
      });
    } else {
      this.setState({
        loading: true
      });
      axios
        // .post("https://admin.akclasses.in/api/strategy/" + this.state.id, {
          .post(Constants.postUrls.updateStrategy + this.state.id, {
          course_id: this.state.course,
          duration: this.state.duration,
          description: this.state.description,
          type: this.state.user,
          strategy_type: this.state.type,
          _method: "put"
        })
        .then(() => {
          // console.log(resp);
          this.setState({
            // data: resp.data,
            notification: true,
            loading: false
          });
          setTimeout(() => {
            this.setState({
              redirect: true
            });
          }, 1000);
        });
    }
  };
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/admin/strategies"
            // pathname: "/admin/days",
            // state: {
            //   data: this.state.data,
            //   courseId: this.state.course,
            //   duration: this.state.duration,
            //   title: this.state.cTitle
            // }
          }}
        />
      );
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Strategy Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Strategy</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Course
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.course}
                            inputProps={{
                              name: "course",
                              id: "simple-select",
                              onChange: e => {
                                this.setState(
                                  {
                                    course: e.target.value
                                  },
                                  () => {
                                    this.setState({
                                      cTitle: this.state.courses.find(
                                        v => v.ID === this.state.course
                                      ).TITLE
                                    });
                                  }
                                );
                              }
                            }}
                          >
                            {this.state.courses.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.ID}
                              >
                                {
                                  <span>
                                  {v.TITLE}(
                                  {v.faculties === null
                                    ? "AK Team"
                                    : (v.faculties.name)}
                                  )
                                  </span>
                                }
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Strategy Type
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.type}
                            inputProps={{
                              name: "type",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  type: e.target.value
                                });
                              }
                            }}
                          >
                            {[
                              { label: "Regular", value: "regular" },
                              { label: "Revision", value: "revision" }
                            ].map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.value}
                              >
                                {v.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            User Type
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.user}
                            inputProps={{
                              name: "user",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  user: e.target.value
                                });
                              }
                            }}
                          >
                            {[
                              { label: "Premium", value: "premium" },
                              { label: "Free", value: "free" }
                            ].map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.value}
                              >
                                {v.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Duration"
                          id="float"
                          inputProps={{
                            required: true,
                            value: this.state.duration,
                            type: "number",
                            onChange: e =>
                              this.setState({ duration: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <label htmlFor="">Name for Showing in Monitoring System</label>
                        <br />
                        <textarea
                          name="description"
                          value={this.state.description}
                          onChange={this.handleInput}
                          className="form-control"
                          cols="119"
                          rows="6"
                        ></textarea>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Update Strategy"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/strategies"
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateStrategy;
