/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
// import editorConfiguration from "../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Constants from "variables/Constants";
import { Modal } from "react-bootstrap";
const classes = makeStyles(styles);

const perPages = ["10", "25", "50", "100"];
export default class QuestionBankLevel extends Component {
  state = {
    data: [],
    notifyStatus: false,
    notifyDelete: false,
    visible: false,
    delLoading: false,
    rowId: 0,
    status: "",
    active: 1,
    level: "",
    subject: "",
    chapter: "",
    questions: [],
    levels: [],
    subjects: [],
    chapters: [],
    sources: [],
    source: "",
    perPage: "10",
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    pageArray: [],
    paginationArray: [],
    redirect: false,
    notification: false,
    loading: false,
    showCK: false,
    submission: false,
    showUploader: false,
    aAlert: false,
    bAlert: false,
    cAlert: false,
    dAlert: false,
    eAlert: false,
    fAlert: false,
    gAlert: false,
    hAlert: false,
    iAlert: false,
    jAlert: false,
    kAlert: false,
    option_value_1: "",
    option_value_2: "",
    option_value_3: "",
    option_value_4: "",
    question: "",
    right_answer: "",
    solution: "",
    quesLoading: "",
    options: ["Option 1", "Option 2", "Option 3", "Option 4"],
  };

  componentDidMount() {
    axios.get(Constants.getUrls.getQuestionBankLevelData).then((resp) => {
      // console.log(resp.data);
      this.setState({
        levels: resp.data,
      });
    });

    axios.get(Constants.getUrls.getActivateSources).then((resp) => {
      // console.log(resp.data);
      this.setState({
        sources: resp.data,
      });
    });
  }

  getLevels = () => {
    axios
      .get(
        Constants.getUrls.getQuestionBankLevel +
          "?perPage=" +
          this.state.perPage
      )
      .then((resp) => {
        // console.log(resp);
        let pageArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          pageArray.push(i);
        }
        this.setState(
          {
            pageArray,
            levels: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            let paginationArray = this.state.pageArray.filter(
              (page) => page >= this.state.fromInc && page <= this.state.toInc
            );
            this.setState({
              paginationArray,
            });
          }
        );
      });
  };

  handleSubject = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // console.log(this.state.subject);
        axios
          .get(Constants.getUrls.show_with_levels + this.state.level)
          .then((resp) => {
            // console.log(resp.data);
            this.setState({
              subjects: resp.data.subjects,
              subject: "",
              chapters: [],
              chapter: "",
              aAlert: false,
            });
          });
      }
    );
  };

  handleChapter = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // console.log(this.state.subject);
        axios
          .get(Constants.getUrls.show_subject + this.state.subject)
          .then((resp) => {
            // console.log(resp.data);
            this.setState({
              chapters: resp.data.chapter,
              chapter: "",
              bAlert: false,
            });
          });
      }
    );
  };

  handleLast = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      cAlert: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.level === "") {
      this.setState({
        aAlert: true,
      });
    } else if (this.state.subject === "") {
      this.setState({
        bAlert: true,
      });
    } else if (this.state.chapter === "") {
      this.setState({
        cAlert: true,
      });
    } else if (this.state.source === "") {
      this.setState({
        kAlert: true,
      });
    } else if (this.state.question === "") {
      this.setState({
        dAlert: true,
      });
    } else if (this.state.option_value_1 === "") {
      this.setState({
        eAlert: true,
      });
    } else if (this.state.option_value_2 === "") {
      this.setState({
        fAlert: true,
      });
    } else if (this.state.option_value_3 === "") {
      this.setState({
        gAlert: true,
      });
    } else if (this.state.option_value_4 === "") {
      this.setState({
        hAlert: true,
      });
    } else if (this.state.right_answer === "") {
      this.setState({
        iAlert: true,
      });
    } else if (this.state.solution === "") {
      this.setState({
        jAlert: true,
      });
    } else {
      this.setState({
        loading: true,
      });
      let valueAnswer =
        this.state.answer === "Option 1"
          ? this.state.option_value_1
          : this.state.answer === "Option 2"
          ? this.state.option_value_2
          : this.state.answer === "Option 3"
          ? this.state.option_value_3
          : this.state.answer === "Option 4"
          ? this.state.option_value_4
          : "";

      let payLoad = {
        level_id: this.state.level,
        subject_id: this.state.subject,
        chapter_id: this.state.chapter,
        source_id: this.state.source,
        question: this.state.question,
        option_value_1: this.state.option_value_1,
        option_value_2: this.state.option_value_2,
        option_value_3: this.state.option_value_3,
        option_value_4: this.state.option_value_4,
        right_answer: this.state.right_answer,
        solution: this.state.solution,
      };
      axios
        .post(Constants.postUrls.createQuestionBank, payLoad)
        .then((resp) => {
          // console.log(resp);
          this.setState({
            // level: "",
            // subject: "",
            // chapter: "",
            question: "",
            option_value_1: "",
            option_value_2: "",
            option_value_3: "",
            option_value_4: "",
            right_answer: "",
            solution: "",
            notification: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({
              notification: false,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        });
    }
  };

  render() {
    // if (this.state.redirect) {
    //     return <Redirect to={"/admin/questionBank"} />;
    // } else {
    return (
      <div>
        <GridContainer>
          {this.state.notifyStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Status Changed!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <EditIcon />
                </CardIcon>
                <h4 style={{ color: "black" }}>Question</h4>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.handleSubmit}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl
                        style={{ marginTop: "12px" }}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Level
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.level}
                          inputProps={{
                            required: true,
                            name: "level",
                            id: "simple-select",
                            onChange: this.handleSubject,
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value=""
                          >
                            Select Level
                          </MenuItem>
                          {this.state.levels.map((v, i) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              key={i}
                              value={v.id}
                            >
                              {v.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {this.state.aAlert ? (
                        <div>
                          <span style={{ color: "red" }}>*required</span>
                        </div>
                      ) : null}
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl
                        style={{ marginTop: "12px" }}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Subject
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.subject}
                          inputProps={{
                            required: true,
                            name: "subject",
                            id: "simple-select",
                            onChange: this.handleChapter,
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value=""
                          >
                            Select Level First
                          </MenuItem>
                          {this.state.subjects.map((v, i) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              key={i}
                              value={v.id}
                            >
                              {v.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {this.state.bAlert ? (
                        <div>
                          <span style={{ color: "red" }}>*required</span>
                        </div>
                      ) : null}
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl
                        style={{ marginTop: "12px" }}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Chapter
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.chapter}
                          inputProps={{
                            required: true,
                            name: "chapter",
                            id: "simple-select",
                            onChange: this.handleLast,
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value=""
                          >
                            Select Subject First
                          </MenuItem>
                          {this.state.chapters.map((v, i) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              key={i}
                              value={v.id}
                            >
                              {v.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {this.state.cAlert ? (
                        <div>
                          <span style={{ color: "red" }}>*required</span>
                        </div>
                      ) : null}
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl
                        style={{ marginTop: "12px" }}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Source
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.source}
                          inputProps={{
                            required: true,
                            name: "source",
                            id: "simple-select",
                            onChange: this.handleLast,
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value=""
                          >
                            Select Source
                          </MenuItem>
                          {this.state.sources.map((v, i) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              key={i}
                              value={v.id}
                            >
                              {v.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {this.state.kAlert ? (
                        <div>
                          <span style={{ color: "red" }}>*required</span>
                        </div>
                      ) : null}
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                      <br />
                      <label>Question</label>
                      <br />
                      <CKEditor
                        required={true}
                        // config={editorConfiguration}
                        editor={ClassicEditor}
                        data={this.state.question}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          //console.log({ data });
                          this.setState({
                            question: data,
                            dAlert: false,
                          });
                        }}
                      />

                      {this.state.dAlert ? (
                        <div>
                          <span style={{ color: "red" }}>*required</span>
                        </div>
                      ) : null}
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <br />
                      <label>Option 1</label>
                      <br />
                      <CKEditor
                        required={true}
                        editor={ClassicEditor}
                        // config={editorConfiguration}
                        data={this.state.option_value_1}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          //console.log({ data });
                          this.setState({
                            option_value_1: data,
                            eAlert: false,
                          });
                        }}
                      />

                      {this.state.eAlert ? (
                        <div>
                          <span style={{ color: "red" }}>*required</span>
                        </div>
                      ) : null}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <br />
                      <label>Option 2</label>
                      <br />
                      <CKEditor
                        required={true}
                        editor={ClassicEditor}
                        // config={editorConfiguration}
                        data={this.state.option_value_2}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          //console.log({ data });
                          this.setState({
                            option_value_2: data,
                            fAlert: false,
                          });
                        }}
                      />

                      {this.state.fAlert ? (
                        <div>
                          <span style={{ color: "red" }}>*required</span>
                        </div>
                      ) : null}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <br />
                      <label>Option 3</label>
                      <br />
                      <CKEditor
                        required={true}
                        editor={ClassicEditor}
                        // config={editorConfiguration}
                        data={this.state.option_value_3}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          //console.log({ data });
                          this.setState({
                            option_value_3: data,
                            gAlert: false,
                          });
                        }}
                      />

                      {this.state.gAlert ? (
                        <div>
                          <span style={{ color: "red" }}>*required</span>
                        </div>
                      ) : null}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <br />
                      <label>Option 4</label>
                      <br />
                      <CKEditor
                        required={true}
                        editor={ClassicEditor}
                        // config={editorConfiguration}
                        data={this.state.option_value_4}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          //console.log({ data });
                          this.setState({
                            option_value_4: data,
                            hAlert: false,
                          });
                        }}
                      />

                      {this.state.hAlert ? (
                        <div>
                          <span style={{ color: "red" }}>*required</span>
                        </div>
                      ) : null}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl
                        style={{ marginTop: "12px" }}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Right Answer
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.right_answer}
                          inputProps={{
                            name: "right_answer",
                            id: "simple-select",
                            onChange: (e) => {
                              this.setState({
                                right_answer: e.target.value,
                              });
                            },
                          }}
                        >
                          {this.state.options.map((v, i) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              key={i}
                              value={v}
                            >
                              {v}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {this.state.iAlert ? (
                        <div>
                          <span style={{ color: "red" }}>*required</span>
                        </div>
                      ) : null}
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <br />
                      <label>Solution</label>
                      <br />
                      <CKEditor
                        editor={ClassicEditor}
                        // config={editorConfiguration}
                        data={this.state.solution}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          //console.log({ data });
                          this.setState({
                            solution: data,
                            jAlert: false,
                          });
                        }}
                      />

                      {this.state.jAlert ? (
                        <div>
                          <span style={{ color: "red" }}>*required</span>
                        </div>
                      ) : null}
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                      <Button className="" color="warning" type="submit">
                        {this.state.loading ? <Loader /> : "Add Question"}
                      </Button>
                      <Link
                        to={{
                          pathname: "/admin/questionbank",
                        }}
                      >
                        <Button className="btn-round" color="danger">
                          Cancel
                        </Button>
                      </Link>
                    </GridItem>

                    {this.state.submission ? (
                      <div style={{ position: "relative", left: "600px" }}>
                        <SnackbarContent
                          message={"Questions Added Successfully!"}
                          icon={AddAlert}
                          color="success"
                        />
                      </div>
                    ) : null}
                    {this.state.notification ? (
                      <div style={{ position: "relative", left: "600px" }}>
                        <SnackbarContent
                          message={"Question Added!"}
                          icon={AddAlert}
                          color="success"
                        />
                      </div>
                    ) : null}
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
// }
