import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import FormControl from "@material-ui/core/FormControl";
import CardHeader from "components/Card/CardHeader.js";
import Datetime from "react-datetime";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Loader from "components/Loader";
import { Block } from "@material-ui/icons";
class SingleFacultyReport extends Component {
  state = {
    secondaryCategories: [],
    akCount: 0,
    venderCount: 0,
    totalCount: 0,
    act: 7,
    set: false,
    pen: false,
    type: "",
    tableArray: [],
    loading: false,
  };

  searchReport = () => {
    console.log(this.state.set,this.state.pen,this.state.act);
    this.setState({
      loading: true,
      secondaryCategories: [],
      venderCount: 0
    });
    axios
      .get(
        this.state.set
          ? "https://admin.akclasses.in/api/login_faculty_settlement?frmdate=" +
              this.state.frmDate +
              "&todate=" +
              this.state.toDate
          : this.state.pen
          ? "https://admin.akclasses.in/api/login_faculty_pending?frmdate=" +
            this.state.frmDate +
            "&todate=" +
            this.state.toDate
          : "https://admin.akclasses.in/api/login_faculty_payment?frmdate=" +
            this.state.frmDate +
            "&todate=" +
            this.state.toDate
      )
      .then(resp => {
        console.log(resp);
        if(resp.data.status==="success")
        {
          this.setState(
            {
              loading: false,
              secondaryCategories: resp.data.data,
              // totalCount: resp.data.total,
              venderCount: resp.data.faculty_share
              // akCount: resp.data.ak_share
            },
            () => {
              let tableArray = this.state.secondaryCategories.map(
                (sec, index) => [
                  [index + 1],
                  [sec.student_name],
                  [sec.order_id],
                  [sec.product],
                  // [sec.ak_share],
                  [sec.faculty_share]
                  // [sec.grand_total]
                  // [
                  //   <Button key={index} color="primary">
                  //     Pending
                  //   </Button>
                  // ]
                ]
              );
  
              // tableArray.push({
              //   total: true,
              //   colspan: "5",
              //   amount: (
              //     <div>
              //       <td> ₹{this.state.totalCount}</td>
              //     </div>
              //   )
              // });
              tableArray.push({
                total3: true,
                colspan: "3",
                amount: (
                  <div>
                    <td> ₹{this.state.venderCount}</td>
                  </div>
                )
              });
              // tableArray.push({
              //   total1: true,
              //   colspan: "5",
              //   amount: (
              //     <div>
              //       <td> ₹{this.state.akCount}</td>
              //     </div>
              //   )
              // });
              this.setState({ tableArray });
            }
          );
        }
      });
  };
  handleOrders = (btn, index) => {
    if (btn === "Settled") {
      this.setState({ set: true, pen: false, act: index, secondaryCategories: [], venderCount: 0 },() => {
        this.searchReport();
      });
    } else if (btn === "Pending") {
      this.setState({ set: false, pen: true, act: index, secondaryCategories: [], venderCount: 0 },() => {
        this.searchReport();
      });
    } else {
      this.setState({ set: false, pen: false, act: index, secondaryCategories: [], venderCount: 0 },() => {
        this.searchReport();
      });
    }
  };
  render() {
    return (
      <div>
        {["Settled", "Pending", "Payment"].map((btn, index) => (
          <Button
            key={index}
            id={btn}
            style={{ marginRight: "10px", marginLeft: "10px" }}
            color={this.state.act === index ? "danger " : "info"}
            onClick={() => {
              this.handleOrders(btn, index);
            }}
          >
            {btn}
          </Button>
        ))}
        <br />
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <br />
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                onChange={e => {
                  let year = e._d.getFullYear().toString();
                  let month = (e._d.getMonth() + 101).toString().substring(1);
                  let day = (e._d.getDate() + 100).toString().substring(1);
                  this.setState(
                    {
                      frmDate: year + "/" + month + "/" + day
                    },
                    () => {
                      //console.log(this.state.frmDate);
                    }
                  );
                }}
                inputProps={{
                  placeholder: "From Date"
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <br />
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                onChange={e => {
                  let year = e._d.getFullYear().toString();
                  let month = (e._d.getMonth() + 101).toString().substring(1);
                  let day = (e._d.getDate() + 100).toString().substring(1);
                  this.setState(
                    {
                      toDate: year + "/" + month + "/" + day
                    },
                    () => {
                      //console.log(this.state.toDate);
                    }
                  );
                }}
                inputProps={{
                  placeholder: "To Date"
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <Button
              style={{ position: "relative", top: "13px", right: "20px" }}
              onClick={this.searchReport}
              color="warning"
            >
              Search
            </Button>
            {/* {this.state.download ? (
              <a
                href={
                  "https://admin.akclasses.in/api/income_report_excel?frmdate=" +
                  this.state.frmDate +
                  "&todate=" +
                  this.state.toDate
                }
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button
                  style={{
                    position: "relative",
                    bottom: "37px",
                    left: "70%"
                  }}
                  color="success"
                  onClick={this.handleDownload}
                >
                  Download Report
                </Button>
              </a>
            ) : null} */}
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              {
                this.state.loading ?
                <h2 style={{ textAlign: "center"}}><Loader /></h2>
                :
                <CardBody style={{ position: "relative" }}>
                  <h3>Faculty</h3>
                  <Table
                    tableHead={[
                      "Sr No.",
                      "Faculty/Student Name",
                      "Order ID",
                      "Product Details",
                      // "AK Classes Share",
                      "Faculty Share"
                      // "Order Amount"
                    ]}
                    tableData={this.state.tableArray}
                  />
                </CardBody>
              }
            </Card>
          </GridItem>
        </GridContainer>
        {
          this.state.loading ?
          <h2 style={{ textAlign: "center"}}><Loader /></h2>
          :
          this.state.secondaryCategories.length === 0 ? (
            <div>
              <h6 style={{ textAlign: "center" }}>No Data Found</h6>
            </div>
          ) : null
        }
      </div>
    );
  }
}

export default SingleFacultyReport;
