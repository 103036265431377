/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
// import editorConfiguration from "../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Datetime from "react-datetime";
import Constants from "variables/Constants";
import TimePicker from "react-time-picker";
import { InputLabel, Select, MenuItem, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import firebase from "firebase";
let time = "00:00";
const classes = makeStyles(styles);
let fd = new FormData();
let ProgressBar = (props) => {
  return (
    <div className="progress-bar">
      <Filler percentage={props.percentage} />
    </div>
  );
};
let Filler = (props) => {
  return (
    <div className="filler" style={{ width: `${props.percentage}%` }}></div>
  );
};
class UpdateSchedule extends Component {
  state = {
    title: "",
    date: "",
    desc: "",
    vLink: "",
    type: "",
    duration: "",
    img: "",
    levels: [],
    streams: [],
    categories: [],
    meetings: [],
    meeting: "",
    category: "",
    level: "",
    stream: "",
    types: ["Free", "Premium"],
    progress: 0,
    mediaProgress: false,
    redirect: false,
    notification: false,
    loading: false,
    batches: [],
    batch_id: "",
    faculty_id: "",
    faculties: [],
    attempts: [],
    attempt_id: "",
  };
  componentDidMount() {
    axios
      .get("https://admin.akclasses.in/api/courseCategoriesR")
      .then((resp) => {
        //console.log(resp.data);
        this.setState({
          categories: resp.data,
        });
      });
    axios.get("https://admin.akclasses.in/api/meetings").then((resp) => {
      console.log(resp);
      if (resp.data.success == false) {
        this.setState({
          meetings: [],
        });
      } else {
        this.setState({
          meetings: resp.data.data.meetings,
        });
      }
    });
    axios.get(Constants.getUrls.getBatches).then((res) => {
      // console.log(res);
      // // this.setState({ catchNull: res.data.data})
      this.setState({
        batches: res.data.data,
      });
    });
    axios
      .get("https://admin.akclasses.in/api/faculty?per_page=100")
      .then((resp) => {
        console.log(resp.data);
        this.setState({
          faculties: resp.data,
        });
      });
    axios
      .get("https://admin.akclasses.in/api/activeAttempts")
      .then((respattempt) => {
        console.log(respattempt);
        this.setState({
          attempts: respattempt.data,
        });
      });
    let categoryData = this.props.location.state.categoryData;
    console.log(categoryData);
    time = categoryData.time;
    this.setState(
      {
        title: categoryData.title,
        duration: categoryData.duration,
        category: categoryData.course_category_id,
        level: categoryData.level_id,
        stream: categoryData.stream_id,
        date: categoryData.date + "T" + categoryData.time,
        desc: categoryData.description,
        meeting: categoryData.link,
        type: categoryData.type,
        img: categoryData.image,
        batch_id: categoryData?.batch_id,
        faculty_id: categoryData?.faculty_id,
        attempt_id: categoryData?.attempt_id,
      },
      () => {
        axios
          .get(
            "https://admin.akclasses.in/api/showCategory_with_level/" +
              this.state.category
          )
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              levels: resp.data.level,
            });
          });
        axios
          .get("https://admin.akclasses.in/api/level/" + this.state.level)
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              streams: resp.data.stream,
            });
          });
        axios.get(Constants.getUrls.getBatches).then((res) => {
          // console.log(res);
          // // this.setState({ catchNull: res.data.data})
          this.setState({
            batches: res.data.data,
          });
        });
        axios
          .get("https://admin.akclasses.in/api/faculty?per_page=100")
          .then((resp) => {
            console.log(resp.data);
            this.setState({
              faculties: resp.data,
            });
          });
      }
    );
  }
  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };
  handleLevel = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        streams: [],
        aAlert: false,
      },
      () => {
        //console.log(this.state.category);
        axios
          .get(
            "https://admin.akclasses.in/api/showCategory_with_level/" +
              this.state.category
          )
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              levels: resp.data.level,
              level: "",
            });
          });
      }
    );
  };
  onChange = (t) => (time = t);
  handleStream = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        bAlert: false,
      },
      () => {
        //console.log(this.state.level);
        axios
          .get("https://admin.akclasses.in/api/level/" + this.state.level)
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              streams: resp.data.stream,
            });
          });
      }
    );
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    // let time = this.state.time;
    // let hours = Number(time.match(/^(\d+)/)[1]);
    // let minutes = Number(time.match(/:(\d+)/)[1]);
    // let AMPM = time.match(/\s(.*)$/)[1];
    // if (AMPM === "PM" && hours < 12) hours = hours + 12;
    // if (AMPM === "AM" && hours === 12) hours = hours - 12;
    // let sHours = hours.toString();
    // let sMinutes = minutes.toString();
    // if (hours < 10) sHours = "0" + sHours;
    // if (minutes < 10) sMinutes = "0" + sMinutes;
    let categoryData = this.props.location.state.categoryData;
    let categoryId = categoryData.id;
    fd.append("title", this.state.title);
    fd.append("course_category_id", this.state.category);
    fd.append("level_id", this.state.level);
    fd.append("stream_id", this.state.stream);
    fd.append("date", this.state.date.substring(0, 10));
    fd.append("time", this.state.date.substring(11, 16));
    fd.append("description", this.state.desc);
    fd.append("duration", this.state.duration);
    fd.append("image", this.state.img);
    fd.append("link", this.state.meeting);
    fd.append("type", this.state.type);
    fd.append("batch_id", this.state.batch_id);
    fd.append("faculty_id", this.state.faculty_id);
    fd.append("attempt_id", this.state.attempt_id);
    axios
      .post("https://admin.akclasses.in/api/portal/update/" + categoryId, fd, {
        onUploadProgress: (progressEvent) => {
          this.setState({
            progress: Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            ),
            mediaProgress: true,
          });
          if (this.state.progress === 100) {
            this.setState({ mediaProgress: false });
          }
        },
      })
      .then(() => {
        let categoryData = this.props.location.state.categoryData;
        let categoryId = categoryData.id;
        // console.log(resp);
        firebase
          .database()
          .ref("livesessions/" + categoryId)
          .set({ title: this.state.title, messages: "" });
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/schedules"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Schedule Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Schedule</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          required={true}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Category
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.category}
                            inputProps={{
                              name: "category",
                              id: "simple-select",
                              onChange: this.handleLevel,
                            }}
                          >
                            {this.state.categories.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.ID}
                              >
                                {v.CATEGORY}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Level
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.level}
                            inputProps={{
                              required: true,
                              name: "level",
                              id: "simple-select",
                              onChange: this.handleStream,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Category First
                            </MenuItem>
                            {this.state.levels.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Stream
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.stream}
                            inputProps={{
                              required: true,
                              name: "stream",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  cAlert: false,
                                  stream: e.target.value,
                                });
                              },
                              // //console.log(e.target.value);
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Level First
                            </MenuItem>
                            {this.state.streams.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Attempt
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.attempt_id}
                            inputProps={{
                              required: true,
                              name: "attempt_id",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState(
                                  {
                                    [e.target.name]: e.target.value,
                                  },
                                  () => {
                                    console.log(this.state.attempt_id);
                                  }
                                );
                              },

                              // //console.log(e.target.value);
                            }}
                          >
                            {this.state.attempts.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.eAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Batch
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.batch_id}
                            inputProps={{
                              required: true,
                              name: "batch_id",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState(
                                  {
                                    eAlert: false,
                                    batch_id: e.target.value,
                                  },
                                  () => {
                                    console.log(this.state.batch_id);
                                  }
                                );
                              },
                              // //console.log(e.target.value);
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Level First
                            </MenuItem>
                            {this.state.batches.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.batch_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Faculty
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.faculty_id}
                            inputProps={{
                              required: true,
                              name: "faculty_id",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  eAlert: false,
                                  faculty_id: e.target.value,
                                });
                              },
                              // //console.log(e.target.value);
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Batch First
                            </MenuItem>
                            {this.state.faculties.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v?.id}
                              >
                                {v?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.eAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          required={true}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Video Link
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.meeting}
                            inputProps={{
                              name: "meeting",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({ meeting: e.target.value });
                              },
                            }}
                          >
                            {this.state.meetings.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.join_url}
                              >
                                {v.topic}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Type
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.type}
                            inputProps={{
                              name: "type",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  type: e.target.value,
                                  aAlert: false,
                                });
                                // //console.log(e.target.value);
                              },
                            }}
                          >
                            {this.state.types.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        <CustomInput
                          labelText="Title"
                          id="float"
                          inputProps={{
                            required: true,
                            value: this.state.title,
                            onChange: (e) =>
                              this.setState({ title: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        Start Time
                        <br />
                        <TextField
                          type="datetime-local"
                          labelText="Date-Time"
                          id="float"
                          value={this.state.date}
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ date: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        End Time
                        <br />
                        <TextField
                          type="datetime-local"
                          labelText="Date-Time"
                          id="float"
                          value={this.state.duration}
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ duration: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        {/* <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={(e) => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  date: year + "/" + month + "/" + day,
                                },
                                () => {
                                  //console.log(this.state.en_str_date);
                                }
                              );
                            }}
                            inputProps={{
                              value: this.state.date,
                              required: true,
                              placeholder: "Date",
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <label htmlFor="">Time</label>
                        <br />
                        <FormControl fullWidth>
                          <TimePicker
                            style={{ marginTop: "15px" }}
                            onChange={this.onChange}
                            value={time}
                          />
                        </FormControl> */}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <label>Description</label>
                        <br />
                        <CKEditor
                          editor={ClassicEditor}
                          data={
                            this.props.location.state.categoryData.description
                          }
                          // config={editorConfiguration}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            this.setState({
                              desc: data,
                            });
                          }}
                        />
                        <br />
                      </GridItem>

                      {/* <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Video Link"
                          id="float"
                          inputProps={{
                            value: this.state.vLink,
                            onChange: e =>
                              this.setState({ vLink: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true,
                            required: true
                          }}
                        />
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={12}>
                        <label>Thumb Image</label>
                        <br />
                        <input
                          name="img"
                          onChange={this.handleFile}
                          accept="image/*"
                          type="file"
                        />
                        <img
                          width="200px"
                          src={Constants.imgUrl + this.state.img}
                          alt=""
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <br />
                        {this.state.mediaProgress ? (
                          <ProgressBar percentage={this.state.progress} />
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Update Schedule"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/schedules",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateSchedule;
