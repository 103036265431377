import axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import checked_radio from "../images/checked_radio.png";
import unchecked_radio from "../images/unchecked_radio.png";
// import "../scss/CourseLanding.scss";
import Constants from "./common/Constants";
import Footer from "./common/Footer";
import Header from "./common/Header";
import CourseLandingResp from "./Screens/CourseLandingResp";
import notFound from "../images/77703-no-data-found.json";
import loader from "../images/97739-loader.json";
import Lottie from "react-lottie";

export default class CourseLanding extends Component {
  state = {
    answer: false,
    answer1: false,
    answer2: false,
    answer3: false,
    streamName: "",
    subjectName: "",
    streamId: window.location.pathname.split("/")[4],
    attemptId: window.location.pathname.split("/")[5],
    subjectId: window.location.pathname.split("/")[6],
    attempts: [],
    language: "Hindi",
    batchWings: [],
    batchId: "",
    courses: [],
    price: "",
    courseId: "",
    sameDate: "",
    getUpcomingDays: [],
    batchWingId: "",
    ischeck: false,
    array_batch_wings_id: [],
    subjectFilter: [],
    array_subject_filter_ids: [],
    subject_filter_id: "",
    getLastDays: [],
    isLoading: true,
  };

  showAnswer = (e) => {
    this.setState({
      answer: !this.state.answer,
    });
  };
  showAnswer1 = (e) => {
    this.setState({
      answer1: !this.state.answer1,
    });
  };
  showAnswer2 = (e) => {
    this.setState({
      answer2: !this.state.answer2,
    });
  };
  showAnswer3 = (e) => {
    this.setState({
      answer3: !this.state.answer3,
    });
  };
  LightenDarkenColor(col, amt) {
    var usePound = false;
    if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
    }
    var num = parseInt(col, 16);
    var r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    var b = ((num >> 8) & 0x00ff) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    var g = (num & 0x0000ff) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }
  hex2(c) {
    c = Math.round(c);
    if (c < 0) c = 0;
    if (c > 255) c = 255;
    var s = c.toString(16);
    if (s.length < 2) s = "0" + s;
    return s;
  }
  color(r, g, b) {
    return "#" + this.hex2(r) + this.hex2(g) + this.hex2(b);
  }
  shade(col, light) {
    var r = parseInt(col.substr(1, 2), 16);
    var g = parseInt(col.substr(3, 2), 16);
    var b = parseInt(col.substr(5, 2), 16);
    if (light < 0) {
      r = (1 + light) * r;
      g = (1 + light) * g;
      b = (1 + light) * b;
    } else {
      r = (1 - light) * r + light * 255;
      g = (1 - light) * g + light * 255;
      b = (1 - light) * b + light * 255;
    }
    return this.color(r, g, b);
  }

  componentDidMount() {
    this.subjectFilter();
    axios
      .get(Constants.getUrls.productByCategory + this.state.streamId)
      .then((resp) => {
        // console.log("courseLand", resp);
        this.setState({
          streamName: resp.data.stream.name,
        });
      })
      .catch((error) => {
        // console.log("courseLand", error.response);
      });

    axios
      .get(Constants.getUrls.getSingleSubject + this.state.subjectId)
      .then((resp) => {
        // console.log("getSingleSub", resp);
        this.setState({
          subjectName: resp.data.title,
        });
      });

    axios.get(Constants.getUrls.getAttempts).then((res) => {
      // console.log(res);
      this.setState(
        {
          attempts: res.data,
          attemptId: res.data[0].id,
        },
        () => {
          this.getSingleAttempt(this.state.attemptId);
        }
      );
    });

    axios
      .get(Constants.getUrls.getWings + this.state.subjectId)
      .then((resp) => {
        // console.log("getwings", resp);
        this.setState(
          {
            batchWings: resp.data.wings,
            batchId: resp.data
              ? resp.data.wings
                ? resp.data.wings[0]
                  ? resp.data.wings[0].batch_wings_id
                  : ""
                : ""
              : "",
          },

          () => {
            this.state.array_batch_wings_id.push({ id: this.state.batchId });
            this.setState({
              array_batch_wings: this.state.array_batch_wings_id,
            });

            axios
              .get(
                Constants.getUrls.coursesAll +
                  this.state.subjectId +
                  "?batch_wings_id=" +
                  this.state.batchId +
                  "&language=" +
                  this.state.language +
                  "&attempt=" +
                  this.state.attemptId +
                  "&subject_filter_id=" +
                  this.state.subject_filter_id
              )
              .then((respAll) => {
                // console.log("getCourses", respAll);
                let allCourses = [];
                respAll.data.data.wingsCourses.forEach((item) => {
                  if (
                    (item.course.project_course_status === "both" ||
                      item.course.project_course_status === "ak1") &&
                    item.course.IS_DELETED === 0
                  ) {
                    allCourses.push(item);
                  }
                });
                let activeCourses = [];
                allCourses.forEach((item) => {
                  if (item.course.STATUS === "ACTIVE") {
                    activeCourses.push(item);
                  }
                  console.log("active", activeCourses);
                });
                this.setState(
                  {
                    courses: activeCourses,
                    loadingWing: false,
                    price: respAll.data
                      ? respAll.data.course
                        ? respAll.data.course.variant
                          ? respAll.data.course.variant[0]
                            ? respAll.data.course.variant[0].price
                            : null
                          : null
                        : null
                      : null,
                    courseId: respAll.data
                      ? respAll.data._id
                        ? respAll.data._id
                        : 0
                      : 0,
                    isLoading: false,
                  },
                  () => {
                    // console.log(this.state.courses);
                    const GetDays = (d, Mention_today = false) => {
                      var DateArray = [];
                      var days = d;
                      for (var i = 0; i < days; i++) {
                        if (!Mention_today && i == 0) {
                          i = 1;
                          days += 1;
                        }
                        var date = new Date();
                        var last = new Date(
                          date.getTime() + i * 24 * 60 * 60 * 1000
                        );
                        var day = last.getDate().toString();
                        var month = (last.getMonth() + 1).toString();
                        var year = last.getFullYear();
                        var monthSplit = month.split("");
                        var daySplit = day.split("");
                        const fulld =
                          Number(year) +
                          "-" +
                          (monthSplit[1]
                            ? Number(month)
                            : "0" + monthSplit[0]) +
                          "-" +
                          (daySplit[1] ? Number(day) : "0" + daySplit[0]);
                        DateArray.push(fulld);
                      }
                      return DateArray;
                    };
                    const currentDate = new Date();
                    const currentDateTime = currentDate.getTime();
                    const last30DaysDate = new Date(
                      currentDate.setDate(currentDate.getDate() - 30)
                    );
                    const last30DaysDateTime = last30DaysDate.getTime();
                    const last30DaysList = this.state.courses.filter((x) => {
                      const elementDateTime = new Date(
                        x.batch.registration_end_date
                      ).getTime();
                      if (
                        elementDateTime <= currentDateTime &&
                        elementDateTime > last30DaysDateTime
                      ) {
                        return true;
                      }
                      return false;
                    });
                    // console.log("last30DaysList", last30DaysList);
                    this.setState(
                      {
                        getUpcomingDays: GetDays(100),
                        getLastDays: last30DaysList,
                      }
                    );
                    let arr1 = this.state.courses;
                    let arr2 = this.state.getUpcomingDays;
                    let arr3 = arr1.filter((arr1Item) =>
                      arr2.includes(
                        arr1Item
                          ? arr1Item.batch
                            ? arr1Item.batch.registration_start_date
                              ? arr1Item.batch.registration_start_date
                              : ""
                            : ""
                          : ""
                      )
                    );
                    // console.log("array3", arr3);
                    this.setState({
                      sameDate: arr3,
                      same: true,
                    });
                  }
                );
              });
          }
        );
      });
  }

  getSingleAttempt = (id) => {
    this.setState({ isLoading: true });
    axios.get(Constants.getUrls.attempts + "/" + id).then((resp) => {
      console.log(resp);
      if (resp.data.status === "suceess") {
        this.setState(
          {
            attemptName: resp.data.data.title,
            attemptId: resp.data.data.id,
          },
          () => {
            axios
              .get(
                Constants.getUrls.coursesAll +
                  this.state.subjectId +
                  "?batch_wings_id=" +
                  this.state.batchId +
                  "&language=" +
                  this.state.language +
                  "&attempt=" +
                  this.state.attemptId +
                  "&subject_filter_id=" +
                  this.state.subject_filter_id
              )
              .then((respAll) => {
                console.log("getCourses", respAll);
                let allCourses = [];
                respAll.data.data.wingsCourses.forEach((item) => {
                  if (
                    (item.course.project_course_status === "both" ||
                      item.course.project_course_status === "ak1") &&
                    item.course.IS_DELETED === 0
                  ) {
                    allCourses.push(item);
                  }
                });
                let activeCourses = [];
                allCourses.forEach((item) => {
                  if (item.course.STATUS === "ACTIVE") {
                    activeCourses.push(item);
                  }
                  console.log("active", activeCourses);
                });
                this.setState(
                  {
                    courses: activeCourses,

                    loadingWing: false,
                    price: respAll.data
                      ? respAll.data.course
                        ? respAll.data.course.variant
                          ? respAll.data.course.variant[0]
                            ? respAll.data.course.variant[0].price
                            : null
                          : null
                        : null
                      : null,
                    courseId: respAll.data
                      ? respAll.data._id
                        ? respAll.data._id
                        : 0
                      : 0,
                    isLoading: false,
                  },
                  () => {
                    console.log(this.state.courses);
                    const GetDays = (d, Mention_today = false) => {
                      var DateArray = [];
                      var days = d;
                      for (var i = 0; i < days; i++) {
                        if (!Mention_today && i == 0) {
                          i = 1;
                          days += 1;
                        }
                        var date = new Date();
                        var last = new Date(
                          date.getTime() + i * 24 * 60 * 60 * 1000
                        );
                        var day = last.getDate().toString();
                        var month = (last.getMonth() + 1).toString();
                        var year = last.getFullYear();
                        var monthSplit = month.split("");
                        var daySplit = day.split("");
                        const fulld =
                          Number(year) +
                          "-" +
                          (monthSplit[1]
                            ? Number(month)
                            : "0" + monthSplit[0]) +
                          "-" +
                          (daySplit[1] ? Number(day) : "0" + daySplit[0]);
                        DateArray.push(fulld);
                      }
                      return DateArray;
                    };
                    this.setState({
                      getUpcomingDays: GetDays(100),
                    });
                    let arr1 = this.state.courses;
                    let arr2 = this.state.getUpcomingDays;
                    let arr3 = arr1.filter((arr1Item) =>
                      arr2.includes(
                        arr1Item
                          ? arr1Item.batch
                            ? arr1Item.batch.registration_start_date
                              ? arr1Item.batch.registration_start_date
                              : ""
                            : ""
                          : ""
                      )
                    );
                    // console.log("array3", arr3);
                    this.setState({
                      sameDate: arr3,
                      same: true,
                    });
                  }
                );
              });
          }
        );
      }
    });
  };

  dateFormat = (cdate) => {
    let date = moment(cdate).format("Do MMMM YYYY");
    return date;
  };

  changeLanguage = (language) => {
    console.log(language);
    this.setState({ isLoading: true });
    if (language === "Hindi") {
      this.setState({
        backHindiColor: true,
        backEnglishColor: false,
      });
    } else {
      this.setState({
        backHindiColor: false,
        backEnglishColor: true,
      });
    }
    this.setState({
      loadingWing: true,
    });

    this.setState(
      {
        language: language,
      },
      () => {
        const payload = {
          course_id: this.state.courseId,
          batch_wings_id: this.state.batchId,
          language: this.state.language,
        };

        axios
          .get(
            Constants.getUrls.coursesAll +
              this.state.subjectId +
              "?batch_wings_id=" +
              this.state.batchId +
              "&language=" +
              this.state.language +
              "&attempt=" +
              this.state.attemptId +
              "&subject_filter_id=" +
              this.state.subject_filter_id
          )
          .then((respAll) => {
            console.log("getLanguageChange", respAll);
            let allCourses = [];
            respAll.data.data.wingsCourses.forEach((item) => {
              if (
                (item.course.project_course_status === "both" ||
                  item.course.project_course_status === "ak1") &&
                item.course.IS_DELETED === 0
              ) {
                allCourses.push(item);
              }
            });
            let activeCourses = [];
            allCourses.forEach((item) => {
              if (item.course.STATUS === "ACTIVE") {
                activeCourses.push(item);
              }
              console.log("active", activeCourses);
            });
            this.setState(
              {
                courses: activeCourses,

                loadingWing: false,
                price: respAll.data
                  ? respAll.data.course
                    ? respAll.data.course.variant
                      ? respAll.data.course.variant[0]
                        ? respAll.data.course.variant[0].price
                        : null
                      : null
                    : null
                  : null,
                courseId: respAll.data
                  ? respAll.data._id
                    ? respAll.data._id
                    : 0
                  : 0,
                isLoading: false,
              },
              () => {
                console.log(this.state.courses);
                const GetDays = (d, Mention_today = false) => {
                  var DateArray = [];
                  var days = d;
                  for (var i = 0; i < days; i++) {
                    if (!Mention_today && i == 0) {
                      i = 1;
                      days += 1;
                    }
                    var date = new Date();
                    var last = new Date(
                      date.getTime() + i * 24 * 60 * 60 * 1000
                    );
                    var day = last.getDate().toString();
                    var month = (last.getMonth() + 1).toString();
                    var year = last.getFullYear();
                    var monthSplit = month.split("");
                    var daySplit = day.split("");
                    const fulld =
                      Number(year) +
                      "-" +
                      (monthSplit[1] ? Number(month) : "0" + monthSplit[0]) +
                      "-" +
                      (daySplit[1] ? Number(day) : "0" + daySplit[0]);
                    DateArray.push(fulld);
                  }
                  return DateArray;
                };
                this.setState({
                  getUpcomingDays: GetDays(100),
                });
                let arr1 = this.state.courses;
                let arr2 = this.state.getUpcomingDays;
                let arr3 = arr1.filter((arr1Item) =>
                  arr2.includes(
                    arr1Item
                      ? arr1Item.batch
                        ? arr1Item.batch.registration_start_date
                          ? arr1Item.batch.registration_start_date
                          : ""
                        : ""
                      : ""
                  )
                );
                // console.log("array3", arr3);
                this.setState({
                  sameDate: arr3,
                  same: true,
                });
              }
            );
          });
      }
    );
  };

  getCoursesWing = (batchWingId, e, index) => {
    // console.log(e,index);
    // this.setState({ischeck : !e.target.checked })
    // console.log(batchWingId);
    // console.log(this.state.array_batch_wings_id.length);
    // if(this.state.array_batch_wings_id.length > 0){
    // let letsCheck = e.target.checked;
    // if (letsCheck) {
    //   if (!this.state.array_batch_wings_id.find((f) => f.id === batchWingId)) {
    //     this.state.array_batch_wings_id.push({ id: batchWingId });
    //   }
    // } else {
    //   if (this.state.array_batch_wings_id.find((f) => f.id === batchWingId)) {
    //     let x = this.state.array_batch_wings_id.findIndex((b) => {
    //       return b.id === batchWingId;
    //     });
    //     // console.log(x);
    //     this.state.array_batch_wings_id.splice(x, 1);
    //   }
    // }
    // // console.log(this.state.array_batch_wings_id);
    // // }
    // // console.log(this.state.array_batch_wings_id);
    // // if (letsCheck) {
    // let idsArr = [];
    // this.state.array_batch_wings_id.map((m) => {
    //   idsArr.push(m.id);
    // });

    // this.setState(
    //   {
    //     batchId: idsArr,
    //     wingSelected: true,
    //     wingIndex: index,
    //     loadingWing: true,
    //     [e.target.name]: e.target.value
    //   },
    //   () => {
    // console.log(this.state.batchId);
    this.setState({ isLoading: true });
    axios
      .get(
        Constants.getUrls.coursesAll +
          this.state.subjectId +
          "?batch_wings_id=" +
          batchWingId +
          "&language=" +
          this.state.language +
          "&attempt=" +
          this.state.attemptId +
          "&subject_filter_id=" +
          this.state.subject_filter_id
      )
      .then((resp) => {
        console.log(resp);
        let allCourses = [];
        resp.data.data.wingsCourses.forEach((item) => {
          if (
            (item.course.project_course_status === "both" ||
              item.course.project_course_status === "ak1") &&
            item.course.IS_DELETED === 0
          ) {
            allCourses.push(item);
          }
        });
        let activeCourses = [];
        allCourses.forEach((item) => {
          if (item.course.STATUS === "ACTIVE") {
            activeCourses.push(item);
          }
          console.log("active", activeCourses);
        });
        this.setState(
          {
            courses: activeCourses,

            loadingWing: false,
            batchId: batchWingId,
            price: resp.data
              ? resp.data.course
                ? resp.data.course.variant
                  ? resp.data.course.variant[0]
                    ? resp.data.course.variant[0].price
                    : null
                  : null
                : null
              : null,
            isLoading: false,
          },
          () => {
            console.log(this.state.courses);
            const GetDays = (d, Mention_today = false) => {
              var DateArray = [];
              var days = d;
              for (var i = 0; i < days; i++) {
                if (!Mention_today && i == 0) {
                  i = 1;
                  days += 1;
                }
                var date = new Date();
                var last = new Date(date.getTime() + i * 24 * 60 * 60 * 1000);
                var day = last.getDate().toString();
                var month = (last.getMonth() + 1).toString();
                var year = last.getFullYear();
                var monthSplit = month.split("");
                var daySplit = day.split("");
                const fulld =
                  Number(year) +
                  "-" +
                  (monthSplit[1] ? Number(month) : "0" + monthSplit[0]) +
                  "-" +
                  (daySplit[1] ? Number(day) : "0" + daySplit[0]);
                DateArray.push(fulld);
              }
              return DateArray;
            };
            this.setState({
              getUpcomingDays: GetDays(100),
            });
            let arr1 = this.state.courses;
            let arr2 = this.state.getUpcomingDays;
            let arr3 = arr1
              ? arr1.filter((arr1Item) =>
                  arr2.includes(
                    arr1Item
                      ? arr1Item.batch
                        ? arr1Item.batch.registration_start_date
                          ? arr1Item.batch.registration_start_date
                          : ""
                        : ""
                      : ""
                  )
                )
              : null;
            // console.log("array3", arr3);
            this.setState({
              sameDate: arr3,
              same: true,
            });
          }
        );
      });
    //   }
    // );
    // }
  };

  checkedBatch = (id) => {
    let wingsId = id;
    let istrue = false;
    if (this.state.array_batch_wings_id.length > 0) {
      let batch = this.state.array_batch_wings_id.find((v) => v.id === id);
      if (batch) {
        istrue = true;
      } else {
        istrue = false;
      }
    } else {
      istrue = false;
    }
    return istrue;
  };
  checkedSubjects = (id) => {
    let istrue = false;
    if (this.state.array_subject_filter_ids.length > 0) {
      let subject = this.state.array_subject_filter_ids.find(
        (v) => v.id === id
      );
      if (subject) {
        istrue = true;
      } else {
        istrue = false;
      }
    } else {
      istrue = false;
    }
    return istrue;
  };
  // changeActive = (e) => {
  //   console.log(e.target.value);
  //   const value = e.target.value;
  //   let arrayId =[this.state.array_batch_wings_id]
  //   if(!arrayId.includes(value)){
  //     arrayId.push({"id":value});
  //   }
  //   console.log(arrayId);
  //   this.getCoursesWing(value)

  // let ischeck = false;
  // this.state.array_batch_wings_id.forEach((v) => {
  //   if (v.id !== value) {
  //     this.state.array_batch_wings_id.push({ id: value });
  //   }
  // });
  // console.log(this.state.array_batch_wings_id);
  // };

  // changeActive=(e)=>{
  //   console.log(e.target.value);
  //   console.log(this.state.array_batch_wings_id);
  // }
  subjectFilter = () => {
    axios
      .get(
        Constants.getUrls.subjectFilters + "?subject_id=" + this.state.subjectId
      )
      .then((res) => {
        console.log(res);
        let subject_array = res.data[0];

        this.setState({ subjectFilter: subject_array.reverse() }, () => {
          this.setState({
            subject_filter_id: this.state.subjectFilter[0].id,
          });
          this.state.array_subject_filter_ids.push({
            id: this.state.subjectFilter[0].id,
          });
          this.setState({
            array_subject_filter_ids: this.state.array_subject_filter_ids,
          });
          // console.log("subjectFilter", this.state.subjectFilter);
        });
      });
  };
  getCoursesFilter = (id) => {
    this.setState(
      {
        subject_filter_id: id,
        isLoading: true,
      },
      () => {
        axios
          .get(
            Constants.getUrls.coursesAll +
              this.state.subjectId +
              "?batch_wings_id=" +
              this.state.batchId +
              "&language=" +
              this.state.language +
              "&attempt=" +
              this.state.attemptId +
              "&subject_filter_id=" +
              id
          )
          .then((resp) => {
            console.log(resp);
            let allCourses = [];
            resp.data.data.wingsCourses.forEach((item) => {
              if (
                (item.course.project_course_status === "both" ||
                  item.course.project_course_status === "ak1") &&
                item.course.IS_DELETED === 0
              ) {
                allCourses.push(item);
              }
            });
            let activeCourses = [];
            allCourses.forEach((item) => {
              if (item.course.STATUS === "ACTIVE") {
                activeCourses.push(item);
              }
              console.log("active", activeCourses);
            });
            this.setState(
              {
                courses: activeCourses,
                isLoading: false,

                // loadingWing: false,
                // price: resp.data
                //   ? resp.data.course
                //     ? resp.data.course.variant
                //       ? resp.data.course.variant[0]
                //         ? resp.data.course.variant[0].price
                //         : null
                //       : null
                //     : null
                //   : null,
              }
              // () => {
              //   console.log(this.state.courses);
              //   const GetDays = (d, Mention_today = false) => {
              //     var DateArray = [];
              //     var days = d;
              //     for (var i = 0; i < days; i++) {
              //       if (!Mention_today && i == 0) {
              //         i = 1;
              //         days += 1;
              //       }
              //       var date = new Date();
              //       var last = new Date(date.getTime() + i * 24 * 60 * 60 * 1000);
              //       var day = last.getDate().toString();
              //       var month = (last.getMonth() + 1).toString();
              //       var year = last.getFullYear();
              //       var monthSplit = month.split("");
              //       var daySplit = day.split("");
              //       const fulld =
              //         Number(year) +
              //         "-" +
              //         (monthSplit[1] ? Number(month) : "0" + monthSplit[0]) +
              //         "-" +
              //         (daySplit[1] ? Number(day) : "0" + daySplit[0]);
              //       DateArray.push(fulld);
              //     }
              //     return DateArray;
              //   };
              //   this.setState({
              //     getUpcomingDays: GetDays(100),
              //   });
              //   let arr1 = this.state.courses;
              //   let arr2 = this.state.getUpcomingDays;
              //   let arr3 = arr1
              //     ? arr1.filter((arr1Item) =>
              //         arr2.includes(
              //           arr1Item
              //             ? arr1Item.batch
              //               ? arr1Item.batch.registration_start_date
              //                 ? arr1Item.batch.registration_start_date
              //                 : ""
              //               : ""
              //             : ""
              //         )
              //       )
              //     : null;
              //   // console.log("array3", arr3);
              //   this.setState({
              //     sameDate: arr3,
              //     same: true,
              //   });
              // }
            );
          })
          .catch((error) => {
            // console.log("courseLand", error.response);
          });
      }
    );
  };
  selectBatch = (subject_id, batch_id, course_id, batch_wings_id) => {
    window.location.href =
      "/course_details/" +
      this.state.streamId +
      "/" +
      this.state.attemptId +
      "/" +
      subject_id +
      "/" +
      batch_id +
      "/" +
      course_id +
      "/" +
      batch_wings_id;
  };
  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: notFound,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const defaultLoader = {
      loop: false,
      autoplay: true,
      animationData: loader,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div className="CoursesPage">
        <Header />
        <div className="Responsive">
          <CourseLandingResp />
        </div>
        <div className="Web">
          <section className="section1">
            <h1 className="fonts"> {this.state.subjectName}</h1>
          </section>
          <section className="section2">
            <span className="showing">
              Showing Courses for{" "}
              <span className="jun">{this.state.attemptName} Attempt</span>{" "}
              <select
                name="attempt"
                id=""
                className="selectAttCourse"
                onChange={(e) => {
                  this.setState(
                    {
                      attemptShow: true,
                      attemptId: e.target.value,
                    },
                    () => {
                      this.getSingleAttempt(this.state.attemptId);
                    }
                  );
                }}
              >
                {this.state.attempts.map((attempt) => (
                  <option value={attempt.id}>{attempt.title}</option>
                ))}
              </select>
            </span>
          </section>
          <section className="section3 container ">
            <div
              className="row"
              style={{
                display: "grid",
                gridTemplateColumns: "25% 75%",
              }}
            >
              <div className="col-sm-3">
                <span>
                  <button
                    className={
                      this.state.language === "Hindi"
                        ? "blueBtn"
                        : "grayBtnSelect"
                    }
                    onClick={() => this.changeLanguage("Hindi")}
                  >
                    Hindi
                  </button>
                  <button
                    className={
                      this.state.language === "English"
                        ? "blueBtnSelect"
                        : "grayBtn"
                    }
                    onClick={() => this.changeLanguage("English")}
                  >
                    English
                  </button>
                </span>
                <div className="lectureNote">
                  <h5 className="lectures">
                    Lectures are available in English for South Indian Students.
                  </h5>
                </div>
                <div className="batchType">
                  <h3 className="typeName">Batch Type</h3>
                  {this.state.batchWings.map((wing, i) => (
                    <div className="checkDiv">
                      {wing.batch_wing?.title && (
                        <button
                          className="batch_button"
                          name="batch"
                          onClick={(e) => {
                            this.getCoursesWing(wing.batch_wing.id, e, i);
                          }}
                        >
                          {this.state.batchId === wing.batch_wing?.id ? (
                            <img className="button_radio" src={checked_radio} />
                          ) : (
                            <img
                              className="button_radio"
                              src={unchecked_radio}
                            />
                          )}{" "}
                          <span>
                            {wing.batch_wing?.title
                              ? wing.batch_wing?.title
                              : ""}
                          </span>
                        </button>
                      )}
                    </div>
                  ))}
                </div>
                <div className="chooseSubjects">
                  <h3 className="typeName">Choose Subject</h3>
                  {this.state.subjectFilter.map((course) => (
                    <div className="checkDiv1">
                      {course.title && (
                        <button
                          className="batch_button"
                          // type="radio"
                          // id={course.id}
                          // value={course.id}
                          name="subject"
                          // checked={this.checkedSubjects(course.id)}
                          onClick={(e) => {
                            // console.log(e.target.value)
                            this.getCoursesFilter(course.id);
                          }}
                        >
                          {this.state.subject_filter_id === course.id ? (
                            <img className="button_radio" src={checked_radio} />
                          ) : (
                            <img
                              className="button_radio"
                              src={unchecked_radio}
                            />
                          )}{" "}
                          <span>{course.title}</span>
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-sm-9">
                {this.state.isLoading ? (
                  <Lottie
                    options={defaultLoader}
                    height={80}
                    width={80}
                    isStopped={this.state.isStopped}
                    isPaused={this.state.isPaused}
                  />
                ) : (
                  <section className="section4">
                    <div
                      className="row"
                      // style={{
                      //   display: "grid",
                      //   gridTemplateColumns: "25% 25% 25%",
                      // }}
                    >
                      {this.state.courses.length > 0 ? (
                        this.state.courses.map((batch) => (
                          <div className="col-md-4 margSec4">
                            <div
                              className="row"
                              style={{
                                width: "30%",
                                display: "grid",
                                gridTemplateColumns: "60% 40%",
                                marginLeft: "-5px",
                              }}
                            >
                              <div className="col-md-7 newRes">
                                <h5
                                  className="registration"
                                  style={{ marginLeft: "4px" }}
                                >
                                  Registration Ends on &nbsp;
                                  {this.dateFormat(
                                    batch?.batch?.registration_end_date
                                  )}
                                </h5>
                              </div>
                              <div className="col-md-5 newRes">
                                <button
                                  className="batchButton"
                                  style={{
                                    backgroundColor: batch?.batch.color,
                                  }}
                                >
                                  {batch?.batch?.batch_name}
                                </button>
                              </div>
                            </div>
                            <div className="row">
                              <div className="courseDivArea">
                                {this.state.getUpcomingDays.includes(
                                  batch?.batch?.registration_start_date
                                ) ? (
                                  <div className="overLayWrap"></div>
                                ) : null}
                                <div className="batchBackColor">
                                  <img
                                    src={
                                      Constants.imgUrl +
                                      "/" +
                                      batch.course.IMAGE_PATH
                                    }
                                    alt=""
                                    width={290}
                                    height={165}
                                  />

                                  <div className="row">
                                    <div className="daysAreaCourse">
                                      <div style={{ textAlign: "center" }}>
                                        <h5 className="subnaame">
                                          {batch.course.TITLE}
                                        </h5>
                                      </div>
                                      <div className="daysDivCourse">
                                        <h5 className="daysCourse">
                                          {/* <span className="hexCode">
                                            &#10004; &nbsp;
                                          </span> */}
                                          {/* <i class="fa-solid fa-check hexCode"></i> */}
                                          &#10003;
                                          <span className="totalDays">
                                            {" "}
                                            100 Days
                                          </span>{" "}
                                          <span>Target Strategy</span>
                                        </h5>

                                        <h5 className="daysCourse">
                                          {/* <span className="hexCode">
                                            &#10004; &nbsp;
                                          </span> */}
                                          {/* <i class="fa-solid fa-check hexCode"></i> */}
                                          &#10003;
                                          <span className="totalDays">
                                            {" "}
                                            15 Mock Test
                                          </span>{" "}
                                          <span>Papers with Evaluation</span>
                                        </h5>

                                        <h5 className="daysCourse">
                                          {/* <span className="hexCode">
                                            &#10004; &nbsp;
                                          </span> */}
                                          {/* <i class="fa-solid fa-check hexCode"></i> */}
                                          &#10003;
                                          <span className="totalDays">
                                            {" "}
                                            20 Live
                                          </span>{" "}
                                          <span>Revision Sessions</span>
                                        </h5>

                                        <h5 className="daysCourse">
                                          {/* <span className="hexCode">
                                            &#10004; &nbsp;
                                          </span> */}
                                          {/* <i class="fa-solid fa-check hexCode"></i> */}
                                          &#10003;
                                          <span className="totalDays">
                                            {" "}
                                            12 Live
                                          </span>{" "}
                                          <span>Quiz Sessions</span>
                                        </h5>

                                        <h5 className="daysCourse">
                                          {/* <span className="hexCode">
                                            &#10004; &nbsp;
                                          </span>{" "
                                           <i class="fa-solid fa-check hexCode"></i>} */}
                                          {/* <i class="fa-solid fa-check hexCode"></i> */}
                                          &#10003; Doubt Sessions
                                          <span className="totalDays">
                                            {" "}
                                            as per 100 Days
                                          </span>
                                        </h5>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="enrollAreaCourse">
                                    <div
                                      className="row"
                                      style={{
                                        opacity:
                                          !this.state.getUpcomingDays.includes(
                                            batch.batch?.registration_start_date
                                          ) &&
                                          !this.state.getLastDays.find(
                                            (item) =>
                                              item.batch
                                                .registration_end_date ===
                                              batch.batch.registration_end_date
                                          )
                                            ? 1
                                            : 0.5,
                                        display: "grid",
                                        gridTemplateColumns: "25% 50% 20%",
                                      }}
                                    >
                                      <div className="col-md-4">
                                        <h5
                                          className="hexTime"
                                          style={{ color: batch?.batch.color }}
                                        >
                                          {/* <span
                                            style={{
                                              marginRight: "4px",
                                              color: batch?.batch.color,
                                            }}
                                          >
                                            
                                            &#9201;
                                          </span> */}
                                          {/* <i
                                            class="fa-regular fa-clock"
                                            style={{
                                              marginRight: "4px",
                                              color: batch?.batch.color,
                                            }}
                                          ></i> */}
                                          &#128336;
                                          {batch.course
                                            ? batch.course.DURATION
                                            : // + " Hrs."
                                              ""}
                                        </h5>
                                      </div>
                                      <div className="col-md-5">
                                        {!this.state.getUpcomingDays.includes(
                                          batch.batch?.registration_start_date
                                        ) &&
                                        !this.state.getLastDays.find(
                                          (item) =>
                                            item.batch.registration_end_date ===
                                            batch.batch.registration_end_date
                                        ) ? (
                                          <Link
                                            to={
                                              "/admin/akWebPortal/course_details/" +
                                              this.state.streamId +
                                              "/" +
                                              this.state.attemptId +
                                              "/" +
                                              this.state.subjectId +
                                              "/" +
                                              batch.batch_id +
                                              "/" +
                                              batch.course_id +
                                              "/" +
                                              batch.batch_wings_id
                                            }
                                          >
                                            <button
                                              className="enrollBtnCourse"
                                              style={{
                                                backgroundColor:
                                                  batch?.batch.color,
                                              }}
                                            >
                                              Enroll Now
                                            </button>
                                          </Link>
                                        ) : null}
                                      </div>
                                      <div className="col-md-3">
                                        <h5
                                          className="courseAmt"
                                          style={{
                                            color: batch?.batch.color,
                                            position: "relative",
                                            marginLeft: "30px",
                                          }}
                                        >
                                          {batch.course
                                            ? batch.course.variant
                                              ? batch.course.variant[0]
                                                ? batch.course.variant[0].price
                                                : batch.course.PRICE
                                              : batch.course.PRICE
                                            : batch.course.PRICE}
                                          /-
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div
                          className="col-md-4 regiBatches"
                          style={{
                            textAlign: "center",
                            marginTop: "11%",
                            fontSize: "24px",
                            fontFamily: "raleway",
                          }}
                        >
                          <Lottie
                            options={defaultOptions}
                            height={200}
                            width={300}
                            isStopped={this.state.isStopped}
                            isPaused={this.state.isPaused}
                          />
                        </div>
                      )}
                    </div>
                  </section>
                )}
                <div
                  className="row"
                  style={{
                    marginTop: this.state.courses.length > 0 ? "55px" : "27%",
                  }}
                  onClick={this.showAnswer}
                >
                  <div className="batchSystem">
                    <div className="row">
                      <div className="col-md-10">
                        <h4 className="questions">
                          What is Batch System and its Benefits?
                        </h4>
                      </div>
                      {this.state.answer ? (
                        <div className="col-md-2 arrowHexRight">
                          {/* <span
                            style={{ fontSize: "30px" }}
                            onClick={this.showAnswer}
                          >
                            &#129171;
                          </span> */}
                          <i
                            class="fa-solid fa-caret-down"
                            style={{ fontSize: "18px", marginTop: "10px" }}
                            onClick={this.showAnswer}
                          ></i>
                        </div>
                      ) : (
                        <div className="col-md-2 arrowHexRight">
                          {/* <span
                            style={{ fontSize: "30px" }}
                            onClick={this.showAnswer}
                          >
                            &#129170;
                          </span> */}
                          <i
                            class="fa-solid fa-caret-right"
                            style={{ fontSize: "18px", marginTop: "10px" }}
                            onClick={this.showAnswer}
                          ></i>
                        </div>
                      )}
                    </div>
                    {this.state.answer ? (
                      <h5 className="answers">
                        We provide automatic lorem Ipsum is simply dummy text of
                        the printing and typesetting industry. Lorem Ipsum has
                        been the industry's standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </h5>
                    ) : null}
                  </div>
                </div>

                <div
                  className="row"
                  style={{ marginTop: "20px" }}
                  onClick={this.showAnswer1}
                >
                  <div className="batchSystem">
                    <div className="row">
                      <div className="col-md-10">
                        <h4 className="questions">What is Target Strategy?</h4>
                      </div>
                      {this.state.answer1 ? (
                        <div className="col-md-2 arrowHexRight">
                          <i
                            class="fa-solid fa-caret-down"
                            style={{ fontSize: "18px", marginTop: "10px" }}
                            onClick={this.showAnswer1}
                          ></i>
                        </div>
                      ) : (
                        <div className="col-md-2 arrowHexRight">
                          <i
                            class="fa-solid fa-caret-right"
                            style={{ fontSize: "18px", marginTop: "10px" }}
                            onClick={this.showAnswer1}
                          ></i>
                        </div>
                      )}
                    </div>
                    {this.state.answer1 ? (
                      <h5 className="answers">
                        We provide automatic lorem Ipsum is simply dummy text of
                        the printing and typesetting industry. Lorem Ipsum has
                        been the industry's standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </h5>
                    ) : null}
                  </div>
                </div>

                <div
                  className="row"
                  style={{ marginTop: "20px" }}
                  onClick={this.showAnswer2}
                >
                  <div className="batchSystem">
                    <div className="row">
                      <div className="col-md-10">
                        <h4 className="questions">
                          Importance of Live Session
                        </h4>
                      </div>
                      {this.state.answer2 ? (
                        <div className="col-md-2 arrowHexRight">
                          {/* <span
                            style={{ fontSize: "30px" }}
                            onClick={this.showAnswer2}
                          >
                            &#129171;
                          </span> */}
                          <i
                            class="fa-solid fa-caret-down"
                            style={{ fontSize: "18px", marginTop: "10px" }}
                            onClick={this.showAnswer2}
                          ></i>
                        </div>
                      ) : (
                        <div className="col-md-2 arrowHexRight">
                          <i
                            class="fa-solid fa-caret-right"
                            style={{ fontSize: "18px", marginTop: "10px" }}
                            onClick={this.showAnswer2}
                          ></i>
                        </div>
                      )}
                    </div>
                    {this.state.answer2 ? (
                      <h5 className="answers">
                        We provide automatic lorem Ipsum is simply dummy text of
                        the printing and typesetting industry. Lorem Ipsum has
                        been the industry's standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </h5>
                    ) : null}
                  </div>
                </div>

                <div
                  className="row"
                  style={{ marginTop: "20px" }}
                  onClick={this.showAnswer3}
                >
                  <div className="batchSystem">
                    <div className="row">
                      <div className="col-md-10">
                        <h4 className="questions">
                          Know more Benefits of Doubt Portal
                        </h4>
                      </div>
                      {this.state.answer3 ? (
                        <div className="col-md-2 arrowHexRight">
                          {/* <span
                            style={{ fontSize: "30px" }}
                            onClick={this.showAnswer3}
                          >
                            &#129171;
                          </span> */}
                          <i
                            class="fa-solid fa-caret-down"
                            style={{ fontSize: "18px", marginTop: "10px" }}
                            onClick={this.showAnswer3}
                          ></i>
                        </div>
                      ) : (
                        <div className="col-md-2 arrowHexRight">
                          {/* <span
                            style={{ fontSize: "30px" }}
                            onClick={this.showAnswer3}
                          >
                            &#129170;
                          </span> */}
                          <i
                            class="fa-solid fa-caret-right"
                            style={{ fontSize: "18px", marginTop: "10px" }}
                            onClick={this.showAnswer3}
                          ></i>
                        </div>
                      )}
                    </div>
                    {this.state.answer3 ? (
                      <h5 className="answers">
                        We provide automatic lorem Ipsum is simply dummy text of
                        the printing and typesetting industry. Lorem Ipsum has
                        been the industry's standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </h5>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}
