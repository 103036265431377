/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
// import editorConfiguration from "../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
const classes = makeStyles(styles);
let fd = new FormData();
let ProgressBar = (props) => {
  return (
    <div className="progress-bar">
      <Filler percentage={props.percentage} />
    </div>
  );
};
let Filler = (props) => {
  return (
    <div className="filler" style={{ width: `${props.percentage}%` }}></div>
  );
};
let questions = [];
class UpdateQuestion extends Component {
  state = {
    paper: "",
    name: "",
    category: "",
    testSeriesType: "",
    option_value_1: "",
    option_value_2: "",
    option_value_3: "",
    option_value_4: "",
    question: "",
    answer: "",
    solution: "",
    testCategory: [],
    testName: [],
    testPaper: [],
    file: "",
    redirect: false,
    notification: false,
    loading: false,
    showCK: false,
    showUploader: false,
    levels: [],
    streams: [],
    level: "",
    stream: "",
    aAlert: false,
    bAlert: false,
    cAlert: false,
    dAlert: false,
    eAlert: false,
    fAlert: false,
    gAlert: false,
    options: ["Option 1", "Option 2", "Option 3", "Option 4"],
  };
  componentDidMount() {
    axios.get("https://admin.akclasses.in/api/testCategoriesR").then((resp) => {
      //console.log(resp.data);
      this.setState({
        testCategory: resp.data,
      });
    });
    axios
      .get("https://admin.akclasses.in/api/coursesR?type=TEST_SERIES")
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          testName: resp.data,
        });
      });
    axios.get("https://admin.akclasses.in/api/subjectsR").then((resp) => {
      // console.log(resp.data);
      this.setState({
        testPaper: resp.data,
      });
    });
    axios
      .get(
        "https://admin.akclasses.in/api/index_without_paginate?type=Test_Series"
      )
      .then((resp) => {
        //console.log(resp.data);
        this.setState({
          levels: resp.data,
        });
      });
    axios
      .get(
        "https://admin.akclasses.in/api/stream_without_paginate?type=Test_Series"
      )
      .then((resp) => {
        //console.log(resp.data);
        this.setState({
          streams: resp.data,
        });
      });
    let categoryData = this.props.location.state.categoryData;
    // console.log(categoryData);
    if (categoryData.QUESTION === null) {
      this.setState({ showUploader: true });
    } else {
      this.setState({ showCK: true });
    }
    this.setState({
      category: categoryData.CATEGORY_ID,
      level: categoryData.LEVEL_ID,
      stream: categoryData.STREAM_ID,
      name: categoryData.REF_COURSE_ID,
      paper: categoryData.REF_SUBJECT_ID,
      question: categoryData.QUESTION,
      option_value_1: categoryData.OPTION_VALUE_1,
      option_value_2: categoryData.OPTION_VALUE_2,
      option_value_3: categoryData.OPTION_VALUE_3,
      option_value_4: categoryData.OPTION_VALUE_4,
      answer:
        categoryData.ANSWER === categoryData.OPTION_VALUE_1
          ? "Option 1"
          : categoryData.ANSWER === categoryData.OPTION_VALUE_2
          ? "Option 2"
          : categoryData.ANSWER === categoryData.OPTION_VALUE_3
          ? "Option 3"
          : categoryData.ANSWER === categoryData.OPTION_VALUE_4
          ? "Option 4"
          : "",
      solution: categoryData.SOLUTION,
      file: categoryData.UPLOADED_PAPER,
    });
  }
  handleSecondaryCategory = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        //console.log(this.state.stream);
        axios
          .get("https://admin.akclasses.in/api/stream/" + this.state.stream)
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              testName: resp.data.stream.product,
              name: "",
              testPaper: [],
              paper: "",
            });
          });
      }
    );
  };
  handleLevel = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        //console.log(this.state.category);
        axios
          .get(
            "https://admin.akclasses.in/api/showCategory_with_level/" +
              this.state.category
          )
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              levels: resp.data.level,
              level: "",
              streams: [],
              stream: "",
              testPaper: [],
              paper: "",
              testName: [],
              name: "",
            });
          });
      }
    );
  };

  handleStream = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        //console.log(this.state.level);
        axios
          .get("https://admin.akclasses.in/api/level/" + this.state.level)
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              streams: resp.data.stream,
              stream: "",
              testPaper: [],
              paper: "",
              testName: [],
              name: "",
            });
          });
      }
    );
  };

  handleTernaryCategory = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        //console.log(this.state.name);
        axios
          .get("https://admin.akclasses.in/api/course/" + this.state.name)
          .then((resp) => {
            //console.log(resp);
            this.setState({
              testPaper: resp.data.product.subject,
              testSeriesType: resp.data.product.TEST_SERIES_TYPE,
              paper: "",
            });
          });
      }
    );
  };
  handleType = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        aAlert: false,
      },
      () => {
        //console.log(this.state.category);
        if (this.state.testSeriesType === "Upload") {
          this.setState({
            showUploader: true,
            showCK: false,
          });
        }
        if (this.state.testSeriesType === "Optional") {
          this.setState({
            showCK: true,
            showUploader: false,
          });
        }
      }
    );
  };
  handleFile = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.files[0],
      },
      () => {
        //console.log(this.state.file);
      }
    );
  };
  addMore = () => {
    let payLoad = {
      REF_COURSE_ID: this.state.name,
      category_id: this.state.category,
      ref_subject_id: this.state.paper,
      question: this.state.question,
      level_id: this.state.level,
      stream_id: this.state.stream,
      option_value_1: this.state.option_value_1,
      option_value_2: this.state.option_value_2,
      option_value_3: this.state.option_value_3,
      option_value_4: this.state.option_value_4,
      answer: this.state.answer,
    };
    questions.push(payLoad);
    //console.log(questions);

    this.setState({
      paper: "",
      name: "",
      category: "",
      testSeriesType: "",
      option_value_1: "",
      option_value_2: "",
      option_value_3: "",
      option_value_4: "",
      question: "",
      answer: "",
      testName: [],
      testPaper: [],
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.showCK) {
      if (this.state.question === "") {
        this.setState({
          aAlert: true,
        });
      } else if (this.state.option_value_1 === "") {
        this.setState({
          bAlert: true,
        });
      } else if (this.state.option_value_2 === "") {
        this.setState({
          cAlert: true,
        });
      } else if (this.state.option_value_3 === "") {
        this.setState({
          dAlert: true,
        });
      } else if (this.state.option_value_4 === "") {
        this.setState({
          eAlert: true,
        });
      } else if (this.state.answer === "") {
        this.setState({
          fAlert: true,
        });
      } else if (this.state.solution === "") {
        this.setState({
          gAlert: true,
        });
      } else {
        this.setState({
          loading: true,
        });
        let valueAnswer =
          this.state.answer === "Option 1"
            ? this.state.option_value_1
            : this.state.answer === "Option 2"
            ? this.state.option_value_2
            : this.state.answer === "Option 3"
            ? this.state.option_value_3
            : this.state.answer === "Option 4"
            ? this.state.option_value_4
            : "";
        let categoryData = this.props.location.state.categoryData;
        let categoryId = categoryData.ID;
        let payLoad = {
          ref_course_id: this.state.name,
          category_id: this.state.category,
          ref_subject_id: this.state.paper,
          question: this.state.question,
          solution: this.state.solution,
          level_id: this.state.level,
          stream_id: this.state.stream,
          option_value_1: this.state.option_value_1,
          option_value_2: this.state.option_value_2,
          option_value_3: this.state.option_value_3,
          option_value_4: this.state.option_value_4,
          answer: valueAnswer,
        };
        axios
          .post(
            "https://admin.akclasses.in/api/question/update/" + categoryId,
            payLoad
          )
          .then(() => {
            //console.log(resp);
            setTimeout(() => {
              this.setState({
                redirect: true,
              });
            }, 1000);
            this.setState({
              notification: true,
              loading: false,
            });
          });
      }
    } else {
      this.setState({
        loading: true,
      });
      fd.append("ref_course_id", this.state.name);
      fd.append("category_id", this.state.category);
      fd.append("ref_subject_id", this.state.paper);
      fd.append("level_id", this.state.level);
      fd.append("stream_id", this.state.stream);
      fd.append("uploaded_paper", this.state.file);
      let categoryData = this.props.location.state.categoryData;
      let categoryId = categoryData.ID;
      axios
        .post(
          "https://admin.akclasses.in/api/question/update/" + categoryId,
          fd,
          {
            onUploadProgress: (progressEvent) => {
              this.setState({
                progress: Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                ),
                mediaProgress: true,
              });
            },
          }
        )
        .then(() => {
          //console.log(resp);
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        });
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/questions"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Question Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Question</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Test Category
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.category}
                            inputProps={{
                              disabled: true,
                              name: "category",
                              id: "simple-select",
                              onChange: this.handleLevel,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                            >
                              Select Category
                            </MenuItem>
                            {this.state.testCategory.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.ID}
                              >
                                {v.CATEGORY}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Level
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.level}
                            inputProps={{
                              disabled: true,
                              required: true,
                              name: "level",
                              id: "simple-select",
                              onChange: this.handleStream,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Category First
                            </MenuItem>
                            {this.state.levels.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null} */}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Stream
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.stream}
                            inputProps={{
                              required: true,
                              disabled: true,
                              name: "stream",
                              id: "simple-select",
                              onChange: this.handleSecondaryCategory,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Level First
                            </MenuItem>
                            {this.state.streams.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* {this.state.dAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null} */}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Test Series Name
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.name}
                            inputProps={{
                              name: "name",
                              disabled: true,
                              id: "simple-select",
                              onChange: this.handleTernaryCategory,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                            >
                              Select Category First
                            </MenuItem>
                            {this.state.testName.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.course.ID}
                              >
                                {v.course.TITLE}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Test Paper(s)
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.paper}
                            inputProps={{
                              name: "paper",
                              disabled: true,
                              id: "simple-select",
                              onChange: this.handleType,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                            >
                              Select Name First
                            </MenuItem>
                            {this.state.testPaper.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.ID}
                              >
                                {v.TITLE}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      {this.state.showCK ? (
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <br />
                            <label>Question</label>
                            <br />
                            <CKEditor
                              required={true}
                              editor={ClassicEditor}
                              // config={editorConfiguration}
                              data={
                                this.props.location.state.categoryData.QUESTION
                              }
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                //console.log({ data });
                                this.setState({
                                  question: data,
                                  aAlert: false,
                                });
                              }}
                            />
                            {this.state.aAlert ? (
                              <div>
                                <span style={{ color: "red" }}>*required</span>
                              </div>
                            ) : null}
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <br />
                            <label>Option 1</label>
                            <br />
                            <CKEditor
                              required={true}
                              editor={ClassicEditor}
                              // config={editorConfiguration}
                              data={
                                this.props.location.state.categoryData
                                  .OPTION_VALUE_1
                              }
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                //console.log({ data });
                                this.setState({
                                  option_value_1: data,
                                  bAlert: false,
                                });
                              }}
                            />
                            {this.state.bAlert ? (
                              <div>
                                <span style={{ color: "red" }}>*required</span>
                              </div>
                            ) : null}
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <br />
                            <label>Option 2</label>
                            <br />
                            <CKEditor
                              required={true}
                              editor={ClassicEditor}
                              // config={editorConfiguration}
                              data={
                                this.props.location.state.categoryData
                                  .OPTION_VALUE_2
                              }
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                //console.log({ data });
                                this.setState({
                                  option_value_2: data,
                                  cAlert: false,
                                });
                              }}
                            />
                            {this.state.cAlert ? (
                              <div>
                                <span style={{ color: "red" }}>*required</span>
                              </div>
                            ) : null}
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <br />
                            <label>Option 3</label>
                            <br />
                            <CKEditor
                              required={true}
                              editor={ClassicEditor}
                              // config={editorConfiguration}
                              data={
                                this.props.location.state.categoryData
                                  .OPTION_VALUE_3
                              }
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                //console.log({ data });
                                this.setState({
                                  option_value_3: data,
                                  dAlert: false,
                                });
                              }}
                            />
                            {this.state.dAlert ? (
                              <div>
                                <span style={{ color: "red" }}>*required</span>
                              </div>
                            ) : null}
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <br />
                            <label>Option 4</label>
                            <br />
                            <CKEditor
                              required={true}
                              editor={ClassicEditor}
                              // config={editorConfiguration}
                              data={
                                this.props.location.state.categoryData
                                  .OPTION_VALUE_4
                              }
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                //console.log({ data });
                                this.setState({
                                  option_value_4: data,
                                  eAlert: false,
                                });
                              }}
                            />
                            {this.state.eAlert ? (
                              <div>
                                <span style={{ color: "red" }}>*required</span>
                              </div>
                            ) : null}
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <FormControl
                              style={{ marginTop: "12px" }}
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                              >
                                Answer
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={this.state.answer}
                                inputProps={{
                                  name: "answer",
                                  id: "simple-select",
                                  onChange: (e) => {
                                    this.setState({
                                      answer: e.target.value,
                                    });
                                  },
                                }}
                              >
                                {this.state.options.map((v, i) => (
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    key={i}
                                    value={v}
                                  >
                                    {v}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {this.state.fAlert ? (
                              <div>
                                <span style={{ color: "red" }}>*required</span>
                              </div>
                            ) : null}
                          </GridItem>
                          {/* <GridItem xs={12} sm={12} md={7}>
                            <Button color="success" onClick={this.addMore}>
                              Add More
                            </Button>
                          </GridItem> */}
                        </GridContainer>
                      ) : null}
                      <GridItem xs={12} sm={12} md={12}>
                        <label>Solution</label>
                        <br />
                        <CKEditor
                          editor={ClassicEditor}
                          // config={editorConfiguration}
                          data={this.props.location.state.categoryData.SOLUTION}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            //console.log({ data });
                            this.setState({
                              solution: data,
                              gAlert: false,
                            });
                          }}
                        />
                        {this.state.gAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      {this.state.showUploader ? (
                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <label>Upload Paper</label>
                          <br />
                          <input
                            name="file"
                            onChange={this.handleFile}
                            type="file"
                            accept="application/pdf"
                          />
                        </GridItem>
                      ) : null}
                      <GridItem xs={12} sm={12} md={12}>
                        <br />
                        {this.state.mediaProgress ? (
                          <ProgressBar percentage={this.state.progress} />
                        ) : null}
                      </GridItem>
                      <br />
                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Update Question"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/questions",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateQuestion;
