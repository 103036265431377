import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import Modal from "react-awesome-modal";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Loader from "components/Loader";
import Constants from "variables/Constants";
const classes = makeStyles(styles);
const perPages = ["10", "25", "50", "100"];
const courses = [
  "All Users",
  // "9th Standard",
  // "10th Standard",
  // "CA Foundation",
  // "CA Inter",
  // "CA Final"
];
class PremiumUsers extends Component {
  state = {
    notifyDelete: false,
    delLoading: false,
    rowId: 0,
    notifyStatus: false,
    secondaryCategories: [],
    data: [],
    status: "",
    act: 0,
    delNot: false,
    erro: false,
    userName: "",
    acct: 0,
    message: "",
    user: "",
    down: false,
    frmDate: "",
    toDate: "",
    notifyNote: false,
    loading: false,
    redirect: false,
    visible: false,
    ord: true,
    attempt: "All Users",
    per_page: "10",
    active: 1,
    currentPage: 1,
    loadingId: 0,
    prevPage: 0,
    mobile: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    reset_userId: "",
    resetStat: false,
  };
  componentDidMount() {
    axios
      .get(
        "https://admin.akclasses.in/api/get_alluser?type=premium&attempt=" +
          this.state.attempt +
          "&per_page=" +
          this.state.per_page
      )
      .then((resp) => {
        console.log(resp.data);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            btnIdArray,
            secondaryCategories: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
          },
          () => {
            console.log(this.state.secondaryCategories);
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  }
  stats = (id, user) => {
    this.setState({
      loadingId: id,
    });
    console.log(id);
    axios
      .get(
        Constants.getUrls.getolduserStrategy +
          "?user_id=" +
          id +
          "&per_page=" +
          this.state.per_page
      )
      .then((resp) => {
        console.log(resp);
        // console.log(resp.data.data.data.map((v)=>{ return v.user.data}))
        if (resp.data.status === "failed") {
          this.setState({ loadingId: 0, erro: id });
          setTimeout(() => {
            this.setState({ erro: 0 });
          }, 2000);
        } else {
          resp.data.data.data.map((v) => {
            this.state.data.push(v);
          });
          this.setState({
            userName: user,
            data: this.state.data,
            redirect: true,
          });
        }
      });
    // axios
    //  .get("https://admin.akclasses.in/api/allStudentsDayStrategy?user_id=" + id)
    //   // .get("https://admin.akclasses.in/api/all_stud_day_strategy?user_id=" + id)
    //   .then((resp) => {
    //     console.log(resp.data.data.map((v)=>{ return v.user.data}))
    //     if (resp.data.status === "failed") {
    //       this.setState({ loadingId: 0, erro: id });
    //       setTimeout(() => {
    //         this.setState({ erro: 0 });
    //       }, 2000);
    //     } else {
    //       resp.data.data.map(v => {
    //         this.state.data.push(v.user.data);
    //       });
    //       this.setState({
    //         userName: user,
    //         data: this.state.data,
    //         redirect: true
    //       });
    //     }
    //   });
  };
  handleReset = (sec) => {
    this.setState(
      {
        reset_userId: sec.ID,
      },
      () => {
        console.log(this.state.reset_userId);
      }
    );
    axios
      .post(
        "https://admin.akclasses.in/api/reset_user_strategy?status=INACTIVE&user_id=" +
          sec.ID
      )
      .then((resp) => {
        console.log(resp);
        this.setState({ resetStat: true, message: resp.data.message });
      });
    setTimeout(() => {
      this.setState({
        resetStat: false,
      });
    }, 3000);
  };
  handlePage = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        axios
          .get(
            "https://admin.akclasses.in/api/get_alluser?type=premium&attempt=" +
              this.state.attempt +
              "&per_page=" +
              this.state.per_page
          )
          .then((resp) => {
            //console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                secondaryCategories: resp.data.data,
                total: resp.data.total,
                from: resp.data.from,
                to: resp.data.to,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };
  handleSelect = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const payload = {
          status: this.state.status,
        };
        axios
          .post("https://admin.akclasses.in/api/user/status/" + id, payload)
          .then(() => {
            ////console.log(res);
            this.setState({
              notifyStatus: true,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
      }
    );
  };
  handleModal = (no, name, id, type) => {
    this.setState({
      mobile: no,
      user: name,
      rowId: id,
      visible: true,
      delModal: type === "del" ? true : false,
    });
  };
  sendMessage = () => {
    this.setState({ loading: true });
    axios
      .post("https://admin.akclasses.in/api/send_sms", {
        message: this.state.message,
        mobile: this.state.mobile,
      })
      .then(() => {
        //console.log(resp);
        this.setState({
          notifyNote: true,
          loading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };
  currentPage = (v) => {
    let commonUrl = this.state.ord
      ? "https://admin.akclasses.in/api/get_alluser?type=premium&attempt=" +
        this.state.attempt +
        "&per_page=" +
        this.state.per_page +
        "&page="
      : this.state.attempt === "All Users"
      ? "https://admin.akclasses.in/api/search_alluser?type=premium" +
        "&s=" +
        this.state.user +
        "&per_page=" +
        this.state.per_page +
        "&page="
      : "https://admin.akclasses.in/api/search_alluser?type=premium&attempt=" +
        this.state.attempt +
        "&s=" +
        this.state.user +
        "&per_page=" +
        this.state.per_page +
        "&page=";
    axios.get(commonUrl + v).then((resp) => {
      // console.log(resp.data.data);
      this.setState({
        total: resp.data.total,
        from: resp.data.from,
        to: resp.data.to,
        secondaryCategories: resp.data.data,
        active: v,
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  handleCourses = (btn, index) => {
    axios
      .get(
        "https://admin.akclasses.in/api/get_alluser?type=premium&attempt=" +
          btn +
          "&per_page=" +
          this.state.per_page
      )
      .then((resp) => {
        ////console.log(resp);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            fromInc: 1,
            active: 1,
            toInc: 5,
            btnIdArray,
            attempt: btn,
            ord: true,
            secondaryCategories: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
            act: index,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };
  handleDelete = (id) => {
    this.setState({ delLoading: true });
    let payload = {
      method: "Premium",
    };
    axios
      .post("https://admin.akclasses.in/api/user/delete/" + id, payload)
      .then(() => {
        this.setState({
          notifyDelete: true,
          delLoading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
  };
  // handleDelete = id => {
  //   this.setState({ acct: id });
  //   axios.post("https://admin.akclasses.in/api/user/delete/" + id).then(() => {
  //     // console.log(res.data);
  //     this.setState({
  //       delNot: true,
  //       acct: 0
  //     });
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 2000);
  //   });
  // };
  searchUser = () => {
    if (this.state.attempt === "All Users") {
      axios
        .get(
          "https://admin.akclasses.in/api/search_alluser?type=premium" +
            "&s=" +
            this.state.user +
            "&per_page=" +
            this.state.per_page
        )
        .then((resp) => {
          // console.log(resp);
          let btnIdArray = [];
          for (let i = 1; i <= resp.data.last_page; i++) {
            btnIdArray.push(i);
          }
          this.setState(
            {
              btnIdArray,
              ord: false,
              fromInc: 1,
              active: 1,
              toInc: 5,
              secondaryCategories: resp.data.data,
              total: resp.data.total,
              from: resp.data.from,
              to: resp.data.to,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({
                filteredBtnIdArray,
              });
            }
          );
        });
    } else {
      axios
        .get(
          "https://admin.akclasses.in/api/search_alluser?type=premium&attempt=" +
            this.state.attempt +
            "&s=" +
            this.state.user +
            "&per_page=" +
            this.state.per_page
        )
        .then((resp) => {
          ////console.log(resp);
          let btnIdArray = [];
          for (let i = 1; i <= resp.data.last_page; i++) {
            btnIdArray.push(i);
          }
          this.setState(
            {
              btnIdArray,
              ord: false,
              fromInc: 1,
              active: 1,
              toInc: 5,
              secondaryCategories: resp.data.data,
              total: resp.data.total,
              from: resp.data.from,
              to: resp.data.to,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({
                filteredBtnIdArray,
              });
            }
          );
        });
    }
  };
  searchRecord = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/get_alluser?type=premium&attempt=" +
          this.state.attempt +
          "&frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate
      )
      .then((resp) => {
        this.setState({
          down: true,
          secondaryCategories: resp.data,
          filteredBtnIdArray: [],
          from: 0,
          to: 0,
          total: 0,
        });
      });
  };
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/admin/userstats",
            state: {
              categoryData: this.state.data,
              name: this.state.userName,
              type: "premium",
            },
          }}
        />
      );
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notifyStatus ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Status Changed!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            {this.state.resetStat ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={this.state.message}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            {this.state.delNot ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Premium User Deleted!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <Assignment />
                  </CardIcon>
                  <h4>Simple Table</h4>
                </CardHeader>
                <CardBody
                  style={{
                    position: "relative",
                  }}
                >
                  <FormControl
                    style={{
                      position: "absolute",
                      right: "5%",
                      width: "85px",
                    }}
                    className={classes.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Per Page
                    </InputLabel>
                    <Select
                      s
                      key={1}
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.per_page}
                      inputProps={{
                        name: "per_page",
                        id: "simple-select",
                        onChange: (e) => {
                          this.handlePage(e);
                        },
                      }}
                    >
                      {perPages.map((v, i) => (
                        <MenuItem
                          key={i}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={v}
                        >
                          {v}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <label
                    style={{
                      marginLeft: "12px",
                      color: "black",
                      fontSize: "17px",
                    }}
                  >
                    Filter The Users
                  </label>
                  <br />
                  <br />
                  <label
                    style={{
                      marginLeft: "12px",
                    }}
                  >
                    By Course
                  </label>
                  <br />
                  {courses.map((btn, index) => (
                    <Button
                      key={index}
                      id={btn}
                      style={{ marginRight: "10px", marginLeft: "10px" }}
                      color={this.state.act === index ? "danger " : "info"}
                      onClick={() => {
                        this.handleCourses(btn, index);
                      }}
                    >
                      {btn}
                    </Button>
                  ))}
                  <h6
                    style={{
                      color: "grey",
                      marginBottom: "0px",
                      marginLeft: "10px",
                    }}
                  >
                    Filter By Date
                  </h6>
                  <GridContainer>
                    <GridItem
                      style={{ marginLeft: "10px" }}
                      xs={12}
                      sm={12}
                      md={3}
                    >
                      <FormControl fullWidth>
                        <Datetime
                          timeFormat={false}
                          onChange={(e) => {
                            let year = e._d.getFullYear().toString();
                            let month = (e._d.getMonth() + 101)
                              .toString()
                              .substring(1);
                            let day = (e._d.getDate() + 100)
                              .toString()
                              .substring(1);
                            this.setState(
                              {
                                frmDate: year + "/" + month + "/" + day,
                              },
                              () => {
                                //console.log(this.state.frmDate);
                              }
                            );
                          }}
                          inputProps={{
                            placeholder: "From Date",
                          }}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <FormControl fullWidth>
                        <Datetime
                          timeFormat={false}
                          onChange={(e) => {
                            let year = e._d.getFullYear().toString();
                            let month = (e._d.getMonth() + 101)
                              .toString()
                              .substring(1);
                            let day = (e._d.getDate() + 100)
                              .toString()
                              .substring(1);
                            this.setState(
                              {
                                toDate: year + "/" + month + "/" + day,
                              },
                              () => {
                                //console.log(this.state.toDate);
                              }
                            );
                          }}
                          inputProps={{
                            placeholder: "To Date",
                          }}
                        />
                      </FormControl>
                      {this.state.aAlert ? (
                        <div>
                          <span style={{ color: "red" }}>*required</span>
                        </div>
                      ) : null}
                    </GridItem>
                    <Button
                      onClick={this.searchRecord}
                      style={{
                        position: "relative",
                        left: "2px",
                        bottom: "10px",
                      }}
                      color="success"
                    >
                      Search
                    </Button>

                    {this.state.down ? (
                      <a
                        href={
                          "https://admin.akclasses.in/api/export_by_date?type=premium&attempt=" +
                          this.state.attempt +
                          "&frmdate=" +
                          this.state.frmDate +
                          "&todate=" +
                          this.state.toDate
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          style={{
                            position: "relative",
                            left: "2px",
                            bottom: "10px",
                          }}
                          onClick={() => {
                            this.setState({ down: false });
                          }}
                          color="danger"
                        >
                          Download
                        </Button>
                      </a>
                    ) : null}
                  </GridContainer>
                  <div style={{ position: "relative", top: "50px" }}>
                    <h3>Premium Users</h3>
                    <Link to="/admin/addpremiumuser">
                      <Button className="" color="warning">
                        Add Premium User
                      </Button>
                    </Link>
                    <div
                      style={{
                        position: "relative",
                        bottom: "63px",
                        left: "63%",
                      }}
                    >
                      <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                          labelText={"Search in " + this.state.attempt}
                          id="float"
                          inputProps={{
                            onChange: (e) =>
                              this.setState({ user: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        <Button
                          onClick={this.searchUser}
                          style={{
                            position: "relative",
                            bottom: "62px",
                            left: "200px",
                          }}
                          color="success"
                        >
                          Search
                        </Button>
                      </GridItem>
                    </div>
                  </div>
                  <Table
                    tableHead={[
                      "Sr No.",
                      "Name",
                      "Email",
                      "City",
                      // "Password",
                      "Mobile",
                      // "City",
                      // "State",
                      // "Course Name",
                      "Added Date",
                      "Send",
                      "Delete",
                      "Update",
                    ]}
                    tableData={this.state.secondaryCategories.map(
                      (sec, index) => [
                        [index + 1],
                        [sec.FIRST_NAME],
                        [sec.email ? sec.email : "Not Available"],
                        [sec.CITY ? sec.CITY : "Not Available"],
                        // [sec.DE_PASSWORD],
                        [sec.MOBILE],
                        // [sec.CITY],
                        // [sec.STATE],
                        // [sec.ATTEMPT],
                        [sec.ADDED_DATE === null ? "NA" : sec.ADDED_DATE],
                        [
                          <Button
                            key={index}
                            onClick={() => {
                              this.handleModal(
                                sec.MOBILE,
                                sec.FIRST_NAME,
                                sec.ID,
                                "noDel"
                              );
                            }}
                            style={{ width: "120px" }}
                            className="btn-round"
                            color="primary"
                          >
                            Message
                          </Button>,
                        ],
                        [
                          <Button
                            key={index}
                            onClick={() => {
                              this.handleModal(
                                sec.MOBILE,
                                sec.FIRST_NAME,
                                sec.ID,
                                "del"
                              );
                            }}
                            className="btn-round"
                            color="danger"
                          >
                            Delete
                          </Button>,
                        ],
                        [
                          <div key={index}>
                            <Link
                              to={{
                                pathname: "/admin/updatepremiumuser/" + sec.ID,
                                state: { categoryData: sec },
                              }}
                            >
                              <Button className="btn-round" color="info">
                                Update
                              </Button>
                            </Link>
                            <Button
                              onClick={() => {
                                this.stats(sec.ID, sec.FIRST_NAME);
                              }}
                              style={{ width: "120px" }}
                              className="btn-round"
                              color="warning"
                            >
                              {this.state.loadingId === sec.ID ? (
                                <Loader />
                              ) : this.state.erro === sec.ID ? (
                                "Strategy Not Found"
                              ) : (
                                "Strategy Stats"
                              )}
                            </Button>
                            {/* <Button
                              onClick={() => {
                                this.handleReset(sec);
                              }}
                              style={{ width: "120px" }}
                              className="btn-round"
                              color="danger"
                            >
                              { this.state.resetStat ? (
                                 "Reseting"
                              ): "Reset" }
                            </Button> */}
                          </div>,
                        ],
                        // [
                        //   <Select
                        //     key={index}
                        //     MenuProps={{
                        //       className: classes.selectMenu
                        //     }}
                        //     classes={{
                        //       select: classes.select
                        //     }}
                        //     value={sec.STATUS}
                        //     inputProps={{
                        //       name: "status",
                        //       id: "simple-select",
                        //       onChange: e => {
                        //         this.handleSelect(e, sec.ID);
                        //       }
                        //     }}
                        //   >
                        //     <MenuItem
                        //       classes={{
                        //         root: classes.selectMenuItem,
                        //         selected: classes.selectMenuItemSelected
                        //       }}
                        //       value="ACTIVE"
                        //     >
                        //       ACTIVE
                        //     </MenuItem>
                        //     <MenuItem
                        //       classes={{
                        //         root: classes.selectMenuItem,
                        //         selected: classes.selectMenuItemSelected
                        //       }}
                        //       value="INACTIVE"
                        //     >
                        //       INACTIVE
                        //     </MenuItem>
                        //   </Select>
                        // ]
                      ]
                    )}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          {this.state.secondaryCategories.length === 0 ? (
            <div>
              <h6 style={{ textAlign: "center" }}>No Data Found</h6>
            </div>
          ) : (
            <div>
              <div style={{ float: "left" }}>
                Showing {this.state.from} to {this.state.to} of{" "}
                {this.state.total} entries.
              </div>
              <div style={{ float: "right", border: "1px solid grey" }}>
                <div
                  onClick={this.prevPages}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    padding: "4px 0px 4px 0px",
                    backgroundColor: "transparent",
                    fontWeight: "bold",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "grey",
                  }}
                >
                  <i
                    style={{
                      fontSize: "16px",
                      position: "relative",
                      top: "3px",
                    }}
                    className="material-icons"
                  >
                    &#xe5c4;
                  </i>
                </div>
                {this.state.filteredBtnIdArray.map((v, i) => (
                  <div
                    key={i}
                    onClick={() => {
                      this.currentPage(v);
                    }}
                    style={{
                      width: "34px",
                      textAlign: "center",
                      borderLeft:
                        (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                        (v % 2 === 0 && i === 4)
                          ? ""
                          : "1px solid grey",
                      borderRight:
                        (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                        (v % 2 === 0 && i === 0)
                          ? ""
                          : "1px solid grey",
                      backgroundColor:
                        this.state.active === v ? "skyblue" : "transparent",
                      padding: "5px 0px 5px 0px",
                      fontWeight: this.state.active === v ? "bold" : "",
                      display: "inline-block",
                      cursor: "pointer",
                      color: "black",
                    }}
                  >
                    {v}
                  </div>
                ))}
                <div
                  onClick={this.nextPages}
                  style={{
                    width: "34px",
                    borderLeft:
                      this.state.filteredBtnIdArray.length === 2 ||
                      this.state.filteredBtnIdArray.length === 4
                        ? "1px solid grey"
                        : "",
                    padding: "4px 0px 4px 0px",
                    backgroundColor: "transparent",
                    textAlign: "center",
                    fontWeight: "bold",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "grey",
                  }}
                >
                  <i
                    style={{
                      fontSize: "16px",
                      position: "relative",
                      top: "3px",
                    }}
                    className="material-icons"
                  >
                    &#xe5c8;
                  </i>
                </div>
              </div>
            </div>
          )}
          <Modal
            visible={this.state.visible}
            width={this.state.delModal ? "600" : "1000"}
            height={this.state.delModal ? "100" : "330"}
            effect="fadeInUp"
            onClickAway={() => {
              this.setState({ visible: false });
            }}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  {/* <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                  <h4>Stacked Form</h4> 
                  </CardHeader> */}
                  <CardBody>
                    {this.state.delModal ? (
                      <div>
                        <h3>Are You Sure You Want to Delete It?</h3>
                        {this.state.notifyDelete ? (
                          <div>
                            <span style={{ color: "green" }}>
                              User Deleted Successfuly!
                            </span>
                          </div>
                        ) : null}
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                            <br />
                            <Button
                              className=""
                              color="warning"
                              onClick={() => {
                                this.handleDelete(this.state.rowId);
                              }}
                            >
                              {this.state.delLoading ? <Loader /> : "Yes"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({ visible: false });
                              }}
                            >
                              No
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </div>
                    ) : (
                      <div>
                        <h3 style={{ textAlign: "center" }}>
                          Send Message to{" "}
                          <span style={{ color: "purple", fontWeight: "bold" }}>
                            {this.state.user}
                          </span>
                        </h3>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <label>Message</label>
                            <br />
                            <textarea
                              name="message"
                              cols="100"
                              rows="5"
                              onChange={(e) => {
                                this.setState({ message: e.target.value });
                              }}
                            >
                              {this.state.message}
                            </textarea>
                            {this.state.notifyNote ? (
                              <div>
                                <span style={{ color: "green" }}>
                                  Message Sent Successfuly!
                                </span>
                              </div>
                            ) : null}
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <Button
                              className=""
                              color="warning"
                              onClick={this.sendMessage}
                            >
                              {this.state.loading ? "Sending..." : "Send"}
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({ visible: false });
                              }}
                            >
                              Cancel
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Modal>
        </div>
      );
    }
  }
}

export default PremiumUsers;
