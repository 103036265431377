import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
// @material-ui/core components
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
const classes = makeStyles(styles);
let fd = new FormData();
class AddTestOrder extends Component {
  state = {
    tran: "",
    cusName: "",
    orderDate: "",
    orderTime: "",
    contact: "",
    email: "",
    add1: "",
    facultyCommission: 0,
    vendorCommission: 0,
    add2: "",
    city: "",
    orderId: "",
    state: "",
    country: "India",
    pincode: "",
    akShare: 100,
    delStatus: "",
    payStatus: "",
    shipDate: "",
    attempt: "",
    products: [],
    attempts: [],
    delivery: ["Order Placed", "Packaged", "Shipped", "Delivered", "Cancelled"],
    payment: ["Collected", "Not_Collected"],
    vendors: [],
    faculties: [],
    trackingNo: "",
    total: "",
    productId: "",
    reference: "",
    vendorSelected: false,
    warning: false,
    redirect: false,
    max: false,
    notification: false,
    aAlert: false,
    bAlert: false,
    cAlert: false,
    dAlert: false,
    loading: false
  };
  componentDidMount() {
    axios
      .get("https://admin.akclasses.in/api/coursesR?type=TEST_SERIES")
      .then(resp => {
        // console.log(resp.data);
        this.setState({
          products: resp.data
        });
      });
    axios.get("https://admin.akclasses.in/api/attempt").then(resp => {
      // console.log(resp.data);
      this.setState({
        attempts: resp.data
      });
    });
    axios.get("https://admin.akclasses.in/api/venderR").then(resp => {
      //console.log(resp.data.data);
      this.setState({
        vendors: resp.data
      });
    });
    axios.get("https://admin.akclasses.in/api/generate_orderId").then(resp => {
      //console.log(resp.data);
      this.setState({
        orderId: resp.data
      });
    });
    axios.get("https://admin.akclasses.in/api/faculty").then(resp => {
      // console.log(resp.data);
      this.setState({
        faculties: resp.data
      });
    });
  }
  handleProducts = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        cAlert: false
      },
      () => {
        axios
          .get("https://admin.akclasses.in/api/course/" + this.state.productId)
          .then(resp => {
            // console.log(resp.data);
            let facultyCommission = 0;
            resp.data.product.faculty.forEach(v => {
              facultyCommission += v.faulty_share;
            });
            this.setState({ total: resp.data.product.OFFER_PRICE });
            if (this.state.vendorSelected) {
              axios
                .get(
                  "https://admin.akclasses.in/api/get_vendor_product/" +
                    this.state.reference +
                    "?product_id=" +
                    this.state.productId
                )
                .then(resp => {
                  // console.log(resp.data);
                  if (resp.data.length > 0) {
                    let vendorCommission = resp.data.find(
                      v => v.product_id === parseInt(this.state.productId)
                    ).commission_percent;
                    this.setState(
                      {
                        vendorCommission,
                        warning: false
                      },
                      () => {
                        // console.log(vendorCommission);
                        let total =
                          facultyCommission +
                          parseInt(this.state.vendorCommission);
                        this.setState({
                          akShare: 100 - total
                        });
                      }
                    );
                  } else {
                    this.setState({
                      warning: true,
                      akShare: 100 - facultyCommission
                    });
                  }
                });
            } else {
              this.setState({
                akShare: 100 - facultyCommission,
                facultyCommission
              });
            }
          });
      }
    );
    // //console.log(e.target.value);
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.max === true) {
      this.setState({ max: true });
    } else if (this.state.delStatus === "") {
      this.setState({ aAlert: true });
    } else if (this.state.payStatus === "") {
      this.setState({ bAlert: true });
    } else if (this.state.productId === "") {
      this.setState({ cAlert: true });
    } else if (this.state.reference === "") {
      this.setState({ dAlert: true });
    } else if (this.state.warning === true) {
      this.setState({ warning: true });
    } else {
      this.setState({
        loading: true
      });
      fd.append("tx_id", this.state.tran);
      fd.append("name", this.state.cusName);
      fd.append("contact_no", this.state.contact);
      fd.append("email", this.state.email);
      // fd.append("address", this.state.add1);
      // fd.append("address_2", "");
      // fd.append("city", this.state.city);
      // fd.append("state", this.state.state);
      // fd.append("country", "");
      // fd.append("pincode", this.state.pincode);
      fd.append("delivery_status", "Delivered");
      fd.append("payment_status", this.state.payStatus);
      fd.append("ak_share", this.state.akShare);
      // fd.append("shipping_date", "");
      fd.append("attempt", this.state.attempt);
      fd.append("product_details", this.state.productId);
      fd.append("vender", this.state.reference);
      // fd.append("shipping", "");
      fd.append("grand_total", this.state.total);
      fd.append("ordID", this.state.orderId);
      fd.append("order_type", "TEST_SERIES");
      fd.append("exam_given", "no");
      fd.append("order_date", this.state.orderDate);
      fd.append("order_time", this.state.orderTime);

      axios
        .post("https://admin.akclasses.in/api/offlineOrder/create", fd)
        .then(() => {
          //console.log(resp);
          localStorage.setItem("category", "Test_Series");
          setTimeout(() => {
            this.setState({
              redirect: true
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false
          });
        });
    }
  };
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  findFaculty = data => {
    let obj = this.state.faculties.find(v => v.id === data[0]?.faculty_id);
    if (obj !== undefined) {
      return obj.name;
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/offlineorders"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Order Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Test Series Offline Order</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText={"Order ID: " + this.state.orderId}
                          id="disabled"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true
                          }}
                        />
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Transaction Details"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ tran: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={6}>
                        <label>Order Date</label>
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={e => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              let time = new Date(); // for now
                              let hour = time.getHours();
                              let minute = time.getMinutes();
                              let second = time.getSeconds();
                              this.setState(
                                {
                                  orderDate: year + "/" + month + "/" + day,
                                  orderTime: hour + ":" + minute + ":" + second,
                                },
                                () => {
                                  //console.log(this.state.orderDate);
                                }
                              );
                            }}
                            inputProps={{
                              required: true,
                              placeholder: "Order Date"
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Customer Name"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ cusName: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Customer Contact#"
                          id="float"
                          inputProps={{
                            type: "number",
                            required: true,
                            onChange: e =>
                              this.setState({ contact: e.target.value }, () => {
                                if (this.state.contact.length > 10) {
                                  this.setState({ max: true });
                                } else {
                                  this.setState({ max: false });
                                }
                              })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                        {this.state.max ? (
                          <div>
                            <span style={{ color: "red" }}>
                              Max Allowed Characters : 10
                            </span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Customer Email"
                          id="float"
                          inputProps={{
                            type: "email",
                            required: true,
                            onChange: e =>
                              this.setState({ email: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Street Address(House number and Street name*)"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ add1: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Street Address(optional)"
                          id="float"
                          inputProps={{
                            onChange: e =>
                              this.setState({ add2: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="City"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ city: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="State"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ state: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Ak Share"
                          id="float"
                          inputProps={{
                            type: "float",
                            disabled: true,
                            value: this.state.akShare
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Postcode/ZIP"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ pincode: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Delivery Status
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.delStatus}
                            inputProps={{
                              name: "delStatus",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  delStatus: e.target.value,
                                  aAlert: false
                                });
                                // //console.log(e.target.value);
                              }
                            }}
                          >
                            {this.state.delivery.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Payment Status
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.payStatus}
                            inputProps={{
                              name: "payStatus",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  payStatus: e.target.value,
                                  bAlert: false
                                });
                                // //console.log(e.target.value);
                              }
                            }}
                          >
                            {this.state.payment.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={e => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  shipDate: year + "/" + month + "/" + day
                                },
                                () => {
                                  //console.log(this.state.shipDate);
                                }
                              );
                            }}
                            inputProps={{
                              placeholder: "Shipping Date"
                            }}
                          />
                        </FormControl>
                      </GridItem> */}
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Tracking No.(if any)"
                          id="float"
                          inputProps={{
                            onChange: e =>
                              this.setState({ trackingNo: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Products
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.productId}
                            inputProps={{
                              name: "productId",
                              id: "simple-select",
                              onChange: e => {
                                this.handleProducts(e);
                              }
                            }}
                          >
                            {this.state.products.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.course.ID}
                              >
                                {
                                  <span>
                                    {v.course.TITLE}(
                                    {v.course.faculty.length > 1
                                      ? "AK Team"
                                      : this.findFaculty(v.course.faculty)}
                                    )
                                  </span>
                                }
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Grand Total"
                          id="float"
                          inputProps={{
                            value: this.state.total,
                            onChange: e => {
                              this.setState({ total: e.target.value });
                            }
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Reference By
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.reference}
                            inputProps={{
                              name: "reference",
                              id: "simple-select",
                              onChange: e => {
                                this.setState(
                                  {
                                    reference: e.target.value,
                                    vendorSelected: true,
                                    dAlert: false
                                  },
                                  () => {
                                    axios
                                      .get(
                                        "https://admin.akclasses.in/api/get_vendor_product/" +
                                          this.state.reference +
                                          "?product_id=" +
                                          this.state.productId
                                      )
                                      .then(resp => {
                                        // console.log(resp.data);
                                        if (resp.data.length > 0) {
                                          let vendorCommission = resp.data.find(
                                            v =>
                                              v.product_id ===
                                              parseInt(this.state.productId)
                                          ).commission_percent;
                                          this.setState(
                                            {
                                              vendorCommission,
                                              warning: false
                                            },
                                            () => {
                                              let total =
                                                this.state.facultyCommission +
                                                parseInt(
                                                  this.state.vendorCommission
                                                );
                                              this.setState({
                                                akShare: 100 - total
                                              });
                                            }
                                          );
                                        } else {
                                          this.setState({ warning: true });
                                        }
                                      });
                                  }
                                );
                                // //console.log(e.target.value);
                              }
                            }}
                          >
                            {this.state.vendors.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.institute_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.dAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                        {this.state.warning ? (
                          <div>
                            <span style={{ color: "red" }}>
                              This Vendor is Not Available for Selected
                              Product!!!
                            </span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Attempt
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.attempt}
                            inputProps={{
                              name: "attempt",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  attempt: e.target.value,
                                  iAlert: false
                                });
                                // //console.log(e.target.value);
                              }
                            }}
                          >
                            {this.state.attempts.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.iAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? (
                            <Loader />
                          ) : (
                            "Add Test Series Offline Order"
                          )}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/offlineorders"
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddTestOrder;
