/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
// import editorConfiguration from "../variables/EditorConfiguration";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";

const classes = makeStyles(styles);
class AddStrategy extends Component {
  state = {
    type: "",
    topic: "",
    paper: "",
    time: "",
    course: "",
    allCourses: [],
    lectures: [],
    lectureIds: [],
    topics: [],
    allTopics: [],
    topicIds: [],
    papers: [],
    tSeries: [],
    data: [],
    day: 1,
    id: 0,
    test: "",
    description: "",
    cTitle: "",
    lView: false,
    tView: false,
    rView: false,
    preview: false,
    redirect: false,
    notification: false,
    loading: false,
    sec: {},
  };
  componentDidMount() {
    // axios.get("https://admin.akclasses.in/api/topic?per_page=100")
    axios
      .get("https://admin.akclasses.in/api/topic?per_page=100")
      .then((resp) => {
        // console.log(resp);
        this.setState({
          allTopics: resp.data.data,
        });
      });
    console.log(this.props.location.state.sec);
    this.setState({
      sec: this.props.location.state.sec,
      data: this.props.location.state.data,
      id: this.props.location.state.id,
      course: this.props.location.state.courseId,
      day: this.props.location.state.data.length + 1,
      cTitle: this.props.location.state.title,
      duration: this.props.location.state.duration,
    });
    axios
      .get("https://admin.akclasses.in/api/coursesForFilter?type=COURSES")
      .then((resp) => {
        console.log(resp);
        this.setState({
          allCourses: resp.data,
        });
      });
    axios
      .get("https://admin.akclasses.in/api/coursesForFilter?type=Test_Series")
      .then((resp) => {
        console.log(resp.data);
        this.setState({
          tSeries: resp.data,
        });
      });
    axios
      .get(
        "https://admin.akclasses.in/api/courseTopics?course_id=" +
          this.state.course
      )
      .then((resp) => {
        console.log(resp.data);
        this.setState({
          topics: resp.data.course_topic,
        });
      });
  }

  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    let payLoad;
    if (this.state.type === "Lectures") {
      payLoad = {
        strategy_id: this.state.id,
        type: this.state.type,
        target_time: this.state.time,
        topic_id: this.state.topic,
        description: this.state.description,
        lectures: this.state.lectureIds,
        course_id: this.state.course,
      };
    } else if (this.state.type === "Revision") {
      payLoad = {
        strategy_id: this.state.id,
        type: this.state.type,
        target_time: this.state.time,
        multitopic: this.state.topicIds,
        description: this.state.description,
        test: this.state.test,
        test_paper: this.state.paper,
      };
    } else if (this.state.type === "TestPapers") {
      payLoad = {
        strategy_id: this.state.id,
        type: this.state.type,
        test: this.state.test,
        test_paper: this.state.paper,
        description: this.state.description,
      };
    }

    //console.log(payLoad);

    axios
      .post("https://admin.akclasses.in/api/strategyDay", payLoad)
      .then(() => {
        //console.log(resp);
        axios
          .get(
            "https://admin.akclasses.in/api/strategy?strategy=" + this.state.id
          )
          .then((resp) => {
            // console.log(resp);
            this.setState({
              data: resp.data,
              redirect: true,
              loading: false,
            });
          });
      });
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/admin/strategies",
            state: this.state.sec,
          }}
        />
      );
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Strategy Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Strategy</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Type
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.type}
                            inputProps={{
                              name: "type",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState(
                                  {
                                    type: e.target.value,
                                    preview: true,
                                  },
                                  () => {
                                    console.log(this.state.type);
                                    if (this.state.type === "Lectures") {
                                      axios
                                        .get(
                                          "https://admin.akclasses.in/api/courseTopics?course_id=" +
                                            this.state.course
                                        )
                                        .then((resp) => {
                                          console.log(resp.data);
                                          this.setState({
                                            topics: resp.data.course_topic,
                                          });
                                        });
                                      setTimeout(() => {
                                        this.setState({
                                          preview: false,
                                          lView: true,
                                          rView: false,
                                          tView: false,
                                          time: "",
                                          description: "",
                                        });
                                      }, 1);
                                    } else if (this.state.type === "Revision") {
                                      setTimeout(() => {
                                        this.setState({
                                          preview: false,
                                          rView: true,
                                          lView: false,
                                          tView: false,
                                          time: "",
                                          description: "",
                                        });
                                      }, 1);
                                    } else if (
                                      this.state.type === "TestPapers"
                                    ) {
                                      setTimeout(() => {
                                        this.setState({
                                          preview: false,
                                          tView: true,
                                          rView: false,
                                          lView: false,
                                          time: "",
                                          description: "",
                                        });
                                      }, 1);
                                    }
                                  }
                                );
                              },
                            }}
                          >
                            {["Lectures", "Revision", "TestPapers"].map(
                              (v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  key={i}
                                  value={v}
                                >
                                  {v}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </GridItem>
                      {this.state.preview ? (
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: "14px",
                                marginTop: "20px",
                              }}
                            >
                              {"Loading..."}
                            </div>
                          </GridItem>
                        </GridContainer>
                      ) : (
                        <GridItem xs={12} sm={12} md={12}>
                          {this.state.lView ? (
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <FormControl
                                  style={{ marginTop: "12px" }}
                                  fullWidth
                                  className={classes.selectFormControl}
                                >
                                  {/* <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Courses
                            </InputLabel> */}
                                  <span>Course</span>
                                  <Select
                                    MenuProps={{
                                      className: classes.selectMenu,
                                    }}
                                    classes={{
                                      select: classes.select,
                                    }}
                                    value={this.state.course}
                                    inputProps={{
                                      required: true,
                                      name: "course",
                                      id: "simple-select",
                                      onChange: (e) => {
                                        this.setState(
                                          {
                                            course: e.target.value,
                                          },
                                          () => {
                                            axios
                                              .get(
                                                "https://admin.akclasses.in/api/courseTopics?course_id=" +
                                                  this.state.course
                                              )
                                              .then((resp) => {
                                                console.log(resp.data);
                                                this.setState({
                                                  topics:
                                                    resp.data.course_topic,
                                                });
                                              });
                                          }
                                        );
                                      },
                                    }}
                                  >
                                    {this.state.allCourses.map((v, i) => (
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected:
                                            classes.selectMenuItemSelected,
                                        }}
                                        key={i}
                                        value={v.ID}
                                      >
                                        {v.TITLE}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <FormControl
                                  style={{ marginTop: "12px" }}
                                  fullWidth
                                  className={classes.selectFormControl}
                                >
                                  <InputLabel
                                    htmlFor="simple-select"
                                    className={classes.selectLabel}
                                  >
                                    Topic
                                  </InputLabel>
                                  <Select
                                    MenuProps={{
                                      className: classes.selectMenu,
                                    }}
                                    classes={{
                                      select: classes.select,
                                    }}
                                    value={this.state.topic}
                                    inputProps={{
                                      name: "topic",
                                      id: "simple-select",
                                      onChange: (e) => {
                                        this.setState(
                                          {
                                            topic: e.target.value,
                                          },
                                          () => {
                                            console.log(this.state.topic);
                                            let topic = this.state.topics.find(
                                              (v) =>
                                                v?.topic?.id ===
                                                this.state.topic
                                            );
                                            console.log(topic);
                                            let lectures = [];
                                            for (
                                              let index = 1;
                                              index <=
                                              topic.topic.no_of_lecture;
                                              index++
                                            ) {
                                              lectures.push(index);
                                            }
                                            let submittedLectures = [];
                                            axios
                                              .get(
                                                "https://admin.akclasses.in/api/strategy"
                                              )
                                              .then((resp) => {
                                                console.log(resp);

                                                resp.data.forEach((v) => {
                                                  v.strategydays.forEach(
                                                    (a) => {
                                                      if (
                                                        a.topic_id ===
                                                          this.state.topic &&
                                                        a.strategy_id ===
                                                          this.state.id
                                                      ) {
                                                        submittedLectures.push(
                                                          ...a.lectures
                                                        );
                                                      }
                                                    }
                                                  );
                                                });
                                                let filteredLectures = [];
                                                lectures.forEach((v) => {
                                                  if (
                                                    submittedLectures.includes(
                                                      v
                                                    ) === false
                                                  ) {
                                                    filteredLectures.push(v);
                                                  }
                                                });
                                                if (
                                                  filteredLectures.length === 0
                                                ) {
                                                  this.setState({
                                                    lectures,
                                                    lectureIds: [],
                                                  });
                                                } else {
                                                  this.setState({
                                                    lectures: filteredLectures,
                                                    lectureIds: [],
                                                  });
                                                }
                                              });
                                          }
                                        );
                                      },
                                    }}
                                  >
                                    {this.state.topics.map((v, i) => (
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected:
                                            classes.selectMenuItemSelected,
                                        }}
                                        key={i}
                                        value={v?.topic?.id}
                                      >
                                        {v?.topic?.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText="Time"
                                  id="float"
                                  inputProps={{
                                    required: true,
                                    value: this.state.time,
                                    type: "number",
                                    onChange: (e) =>
                                      this.setState({ time: e.target.value }),
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem
                                style={{ marginTop: "11px" }}
                                xs={12}
                                sm={12}
                                md={6}
                              >
                                <FormControl
                                  fullWidth
                                  className={classes.selectFormControl}
                                >
                                  <InputLabel
                                    htmlFor="multiple-select"
                                    className={classes.selectLabel}
                                  >
                                    Lectures
                                  </InputLabel>
                                  <Select
                                    multiple
                                    value={this.state.lectureIds}
                                    MenuProps={{
                                      className: classes.selectMenu,
                                      classes: { paper: classes.selectPaper },
                                    }}
                                    classes={{ select: classes.select }}
                                    inputProps={{
                                      name: "multipleSelect",
                                      id: "multiple-select",
                                      onChange: (e) =>
                                        this.setState(
                                          { lectureIds: e.target.value },
                                          () => {
                                            //console.log(this.state.lectureIds);
                                          }
                                        ),
                                    }}
                                  >
                                    {this.state.lectures.map((v, i) => (
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected:
                                            classes.selectMenuItemSelected,
                                        }}
                                        key={i}
                                        value={v}
                                      >
                                        {"Lecture " + v}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={12}>
                                <label>Special Points</label>
                                <br />
                                <CKEditor
                                  editor={ClassicEditor}
                                  // config={editorConfiguration}
                                  data={this.state.defGoal}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    //console.log({ data });
                                    this.setState({
                                      description: data,
                                    });
                                  }}
                                />
                                <br />
                              </GridItem>
                            </GridContainer>
                          ) : this.state.rView ? (
                            <GridContainer>
                              <GridItem
                                style={{ marginTop: "11px" }}
                                xs={12}
                                sm={12}
                                md={6}
                              >
                                <FormControl
                                  fullWidth
                                  className={classes.selectFormControl}
                                >
                                  <InputLabel
                                    htmlFor="multiple-select"
                                    className={classes.selectLabel}
                                  >
                                    Topics
                                  </InputLabel>
                                  <Select
                                    multiple
                                    value={this.state.topicIds}
                                    MenuProps={{
                                      className: classes.selectMenu,
                                      classes: { paper: classes.selectPaper },
                                    }}
                                    classes={{ select: classes.select }}
                                    inputProps={{
                                      name: "multipleSelect",
                                      id: "multiple-select",
                                      onChange: (e) =>
                                        this.setState(
                                          { topicIds: e.target.value },
                                          () => {
                                            //console.log(this.state.topicIds);
                                          }
                                        ),
                                    }}
                                  >
                                    {this.state.allTopics.map((v, i) => (
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected:
                                            classes.selectMenuItemSelected,
                                        }}
                                        key={i}
                                        value={v?.topic?.id}
                                      >
                                        {v?.topic?.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText="Time"
                                  id="float"
                                  inputProps={{
                                    required: true,
                                    value: this.state.time,
                                    type: "number",
                                    onChange: (e) =>
                                      this.setState({ time: e.target.value }),
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <FormControl
                                  style={{ marginTop: "12px" }}
                                  fullWidth
                                  className={classes.selectFormControl}
                                >
                                  <InputLabel
                                    htmlFor="simple-select"
                                    className={classes.selectLabel}
                                  >
                                    Test Series
                                  </InputLabel>
                                  <Select
                                    MenuProps={{
                                      className: classes.selectMenu,
                                    }}
                                    classes={{
                                      select: classes.select,
                                    }}
                                    value={this.state.test}
                                    inputProps={{
                                      name: "test",
                                      id: "simple-select",
                                      onChange: (e) => {
                                        this.setState(
                                          {
                                            test: e.target.value,
                                          },
                                          () => {
                                            axios
                                              .get(
                                                "https://admin.akclasses.in/api/course/" +
                                                  this.state.test
                                              )
                                              .then((resp) => {
                                                this.setState({
                                                  papers:
                                                    resp.data.product.subject,
                                                });
                                              });
                                          }
                                        );
                                      },
                                    }}
                                  >
                                    {this.state.tSeries.map((v, i) => (
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected:
                                            classes.selectMenuItemSelected,
                                        }}
                                        key={i}
                                        value={v.ID}
                                      >
                                        {v.TITLE}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <FormControl
                                  style={{ marginTop: "12px" }}
                                  fullWidth
                                  className={classes.selectFormControl}
                                >
                                  <InputLabel
                                    htmlFor="simple-select"
                                    className={classes.selectLabel}
                                  >
                                    Paper
                                  </InputLabel>
                                  <Select
                                    MenuProps={{
                                      className: classes.selectMenu,
                                    }}
                                    classes={{
                                      select: classes.select,
                                    }}
                                    value={this.state.paper}
                                    inputProps={{
                                      name: "paper",
                                      id: "simple-select",
                                      onChange: (e) => {
                                        this.setState({
                                          paper: e.target.value,
                                        });
                                      },
                                    }}
                                  >
                                    <MenuItem
                                      classes={{
                                        root: classes.selectMenuItem,
                                        selected:
                                          classes.selectMenuItemSelected,
                                      }}
                                      key={1}
                                      value={""}
                                    >
                                      First Select Test Series
                                    </MenuItem>
                                    {this.state.papers.map((v, i) => (
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected:
                                            classes.selectMenuItemSelected,
                                        }}
                                        key={i}
                                        value={v.ID}
                                      >
                                        {v.TITLE}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={12}>
                                <label>Description</label>
                                <br />
                                <CKEditor
                                  editor={ClassicEditor}
                                  // config={editorConfiguration}
                                  data={this.state.defGoal}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    //console.log({ data });
                                    this.setState({
                                      description: data,
                                    });
                                  }}
                                />
                                <br />
                              </GridItem>
                            </GridContainer>
                          ) : this.state.tView ? (
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <FormControl
                                  style={{ marginTop: "12px" }}
                                  fullWidth
                                  className={classes.selectFormControl}
                                >
                                  <InputLabel
                                    htmlFor="simple-select"
                                    className={classes.selectLabel}
                                  >
                                    Test Series
                                  </InputLabel>
                                  <Select
                                    MenuProps={{
                                      className: classes.selectMenu,
                                    }}
                                    classes={{
                                      select: classes.select,
                                    }}
                                    value={this.state.test}
                                    inputProps={{
                                      name: "test",
                                      id: "simple-select",
                                      onChange: (e) => {
                                        this.setState(
                                          {
                                            test: e.target.value,
                                          },
                                          () => {
                                            axios
                                              .get(
                                                "https://admin.akclasses.in/api/course/" +
                                                  this.state.test
                                              )
                                              .then((resp) => {
                                                this.setState({
                                                  papers:
                                                    resp.data.product.subject,
                                                });
                                              });
                                          }
                                        );
                                      },
                                    }}
                                  >
                                    {this.state.tSeries.map((v, i) => (
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected:
                                            classes.selectMenuItemSelected,
                                        }}
                                        key={i}
                                        value={v.ID}
                                      >
                                        {v.TITLE}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <FormControl
                                  style={{ marginTop: "12px" }}
                                  fullWidth
                                  className={classes.selectFormControl}
                                >
                                  <InputLabel
                                    htmlFor="simple-select"
                                    className={classes.selectLabel}
                                  >
                                    Paper
                                  </InputLabel>
                                  <Select
                                    MenuProps={{
                                      className: classes.selectMenu,
                                    }}
                                    classes={{
                                      select: classes.select,
                                    }}
                                    value={this.state.paper}
                                    inputProps={{
                                      name: "paper",
                                      id: "simple-select",
                                      onChange: (e) => {
                                        this.setState({
                                          paper: e.target.value,
                                        });
                                      },
                                    }}
                                  >
                                    <MenuItem
                                      classes={{
                                        root: classes.selectMenuItem,
                                        selected:
                                          classes.selectMenuItemSelected,
                                      }}
                                      key={1}
                                      value={""}
                                    >
                                      First Select Test Series
                                    </MenuItem>
                                    {this.state.papers.map((v, i) => (
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected:
                                            classes.selectMenuItemSelected,
                                        }}
                                        key={i}
                                        value={v.ID}
                                      >
                                        {v.TITLE}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={12}>
                                <label>Description</label>
                                <br />
                                <CKEditor
                                  editor={ClassicEditor}
                                  // config={editorConfiguration}
                                  data={this.state.defGoal}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    //console.log({ data });
                                    this.setState({
                                      description: data,
                                    });
                                  }}
                                />
                                <br />
                              </GridItem>
                            </GridContainer>
                          ) : null}
                        </GridItem>
                      )}
                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? (
                            <Loader />
                          ) : (
                            "Add Day " + this.state.day
                          )}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/days",
                            state: this.state.sec,
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddStrategy;
