/* eslint-disable react/prop-types */
import React, { Component,useEffect } from "react";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect } from "react-router-dom";
import Card from "components/Card/Card.js";
import EditIcon from "@material-ui/icons/Edit";
import CardHeader from "components/Card/CardHeader.js";
import axios from "axios";
import Loader from "components/Loader";
import CardIcon from "components/Card/CardIcon.js";
import Constants from "../variables/Constants";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

// import logger from 'logging-library';
// import { CustomErrorComponent } from 'custom-error';
import FileViewer from 'react-file-viewer';
import { Logger } from "logging-library";
import myPdf from '../assets/Questions.docx';



// import XMLData from '../assets/XMLData.xml';
// import XMLParser from 'react-xml-parser';
let fd = new FormData();
let ProgressBar = props => {
  return (
    <div className="progress-bar">
      <Filler percentage={props.percentage} />
    </div>
  );
};
let Filler = props => {
  return (
    <div className="filler" style={{ width: `${props.percentage}%` }}></div>
  );
};

const file = myPdf;
const type = 'docx';

const onError = e => {
  console.log(e, "error in file-viewer");
};

class AdministratorProfile extends Component {
  state = {
    fname: "",
    lname: "",
    defGoal: "",
    uname: "",
    dob: "",
    email: "",
    img: [],
    redirect: false,
    notification: false,
    loading: false,
    childrens: [],
  };

  componentDidMount() {
    axios.get(Constants.getUrls.coursesMaster).then(resp => {
      //console.log(resp.data.data);
      this.setState({
        fname: resp.data.fname,
        img: resp.data.lname,
        lname: resp.data.defGoal,
        uname: resp.data.uname,
        email: resp.data.dob,
        dob: resp.data.img
      });
    });


    // var pdfToJson = require('pdf-to-json');
    // fetch('/test/test.pdf').then((response) => {
    //     return response.blob();
    // }).then((response) => {
    //     var fileReader = new FileReader();
    //     fileReader.onload = function (e) {
    //         pdfToJson(new Uint8Array(e.target.result)).then(function (pages) {
    //             pages.forEach(element => {
    //                 var div = document.createElement('div');
    //                 div.innerHTML = ('<span>' + element.text + '</span><img src="' + element.images[0] + '">');
    //                 document.getElementById('app').append(div);
    //             });
    //         });
    //     };
    //     fileReader.readAsArrayBuffer(response);
    // });

    // var xmlText = XMLData;
    // var XMLParser = require('react-xml-parser');
    // var xml = new XMLParser().parseFromString(XMLData);    // Assume xmlText contains the example XML
    // console.log(xml);
    // console.log(xml.getElementsByTagName('Name'));
    
    // TRY 1
    // axios.get(XMLData, {
    //   "Content-Type": "application/xml; charset=utf-8; standalone=yes"
    // }).then((response) => {
    //   var xml = new XMLParser().parseFromString(response); 
    //   var xml = response.data; 
    //   const parseString = require('react-native-xml2js').parseString;
    //   parseString(response, function (err, result) {
    //     console.log(response);
    // });
      // console.log(response);
      // this.setState{
      //   childrens: xml.children
      // }
    // });
  }
  handleFile = e => {
    this.setState({ [e.target.name]: e.target.files[0] }, () => {
      //console.log(this.state.img);
    });
  };
  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();
    fd.append("heading", this.state.fname);
    fd.append("heading", this.state.lname);
    fd.append("heading", this.state.uname);
    fd.append("heading", this.state.dob);
    fd.append("heading", this.state.img);
    fd.append("heading", this.state.email);

    axios
      .post(Constants.postUrls.updateAdministratorProfile, fd, {
        onUploadProgress: progressEvent => {
          this.setState({
            progress: Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            ),
            mediaProgress: true
          });
          if (this.state.progress === 100) {
            this.setState({ mediaProgress: false });
          }
        }
      })
      .then(() => {
        //console.log(resp);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        this.setState({
          notification: true,
          loading: false
        });
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/categories"} />;
    } else {
      return (
        <div>
          {/* <FileViewer
            fileType={type}
            filePath={file}
            errorComponent={CustomErrorComponent}
            onError={this.onError}/> */}

      {/* <h1>React File Viewer Demo</h1>
      <h2>Displaying file with extension {type}</h2>
      <FileViewer fileType={type} filePath={file} onError={onError} />*/}

          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Content Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Administrator Profile</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="First Name"
                          inputProps={{
                            value: this.state.fname,
                            onChange: e =>
                              this.setState({ fname: e.target.value }, () => {
                                //console.log(this.state.fname);
                              })
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Last Name"
                          inputProps={{
                            value: this.state.lname,
                            onChange: e =>
                              this.setState({ lname: e.target.value }, () => {
                                //console.log(this.state.lname);
                              })
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Username"
                          inputProps={{
                            value: this.state.uname,
                            onChange: e =>
                              this.setState({ uname: e.target.value }, () => {
                                //console.log(this.state.uname);
                              })
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Email"
                          inputProps={{
                            value: this.state.email,
                            onChange: e =>
                              this.setState({ email: e.target.value }, () => {
                                //console.log(this.state.email);
                              })
                          }}
                          id="float"
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <label>Photo</label>
                        <br />
                        <input
                          name="img"
                          onChange={this.handleFile}
                          accept="image/*"
                          type="file"
                        />
                        <img
                          width="200px"
                          src={Constants.imgUrl + this.state.img}
                          alt=""
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <br />
                        {this.state.mediaProgress ? (
                          <ProgressBar percentage={this.state.progress} />
                        ) : null}
                      </GridItem>
                    </GridContainer>
                    <br />
                    <GridItem xs={12} sm={12} md={5}>
                      <Button className="" type="submit" color="info">
                        {this.state.loading ? <Loader /> : "Update"}
                      </Button>
                    </GridItem>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
  onError(e) {
    console.log("error");
    Logger.logError(e, 'error in file-viewer');
  }
}

export default AdministratorProfile;
