import React, { Component } from "react";
// import React from "react";
// react plugin for creating charts
// import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
// import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import Tooltip from "@material-ui/core/Tooltip";
// import Icon from "@material-ui/core/Icon";
import axios from "axios";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
// import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
// import Warning from "@material-ui/icons/Warning";
// import DateRange from "@material-ui/icons/DateRange";
// import LocalOffer from "@material-ui/icons/LocalOffer";
// import Update from "@material-ui/icons/Update";
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import AccessTime from "@material-ui/icons/AccessTime";
// import Refresh from "@material-ui/icons/Refresh";
// import Edit from "@material-ui/icons/Edit";
// import Place from "@material-ui/icons/Place";
// import ArtTrack from "@material-ui/icons/ArtTrack";
// import Language from "@material-ui/icons/Language";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
// import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
// import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";

// import {
//   dailySalesChart,
//   emailsSubscriptionChart,
//   completedTasksChart
// } from "variables/charts";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

// import priceImage1 from "assets/img/card-2.jpeg";
// import priceImage2 from "assets/img/card-3.jpeg";
// import priceImage3 from "assets/img/card-1.jpeg";
import Constants from "variables/AppConstants";
import { InputLabel, MenuItem, Select } from "@material-ui/core";

// const us_flag = require("assets/img/flags/US.png");
// const de_flag = require("assets/img/flags/DE.png");
// const au_flag = require("assets/img/flags/AU.png");
// const gb_flag = require("assets/img/flags/GB.png");
// const ro_flag = require("assets/img/flags/RO.png");
// const br_flag = require("assets/img/flags/BR.png");

// var mapData = {
//   AU: 760,
//   BR: 550,
//   CA: 120,
//   DE: 1300,
//   FR: 540,
//   GB: 690,
//   GE: 200,
//   IN: 200,
//   RO: 600,
//   RU: 300,
//   US: 2920
// };
const classes = makeStyles(styles);

// const useStyles = makeStyles(styles);
class Dashboard extends Component {
  state = {
    ans: 0,
    searching: false,
    download: 0,
    qcreated: 0,
    oCount: 0,
    aCount: 0,
    rCount: 0,
    cCount: 0,
    secondaryCategories: [],
    query: 0,
    subjects: [],
    subject: "",
    subjectName: "",
  };
  componentDidMount() {
    this.getSubjects();
    axios.get(Constants.getUrls.counts).then(resp => {
      this.setState({
        ans: resp.data.ans,
        download: resp.data.download,
        qcreated: resp.data.qcreated,
        query: resp.data.query
      });
    });
  }

  getSubjects = e => {
    // axios.get("https://core.akclasses.in/api/subject_visecounts").then(resp => {
    axios.get("https://core.akclasses.in/api/subjects").then(res => {
      console.log(res);
      if(res.data.status==="success")
      {
        this.setState({
          subjects: res.data.subjects,
          subject: res.data.subjects[0].id,
          subjectName: res.data.subjects[0].name
        })
        axios
      .get(
        "https://core.akclasses.in/api/filteredQueries?subject_id=" + res.data.subjects[0].id + "&frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate
      ).then(resp => {
        console.log(resp);
        let oCount = 0;
        let aCount = 0;
        let rCount = 0;
        let cCount = 0;
        resp.data.detail.forEach(v => {
          oCount += v.open;
          aCount += v.approved;
          rCount += v.rejected;
          cCount += v.closed;
        });
        // console.log(oCount, aCount, rCount, cCount);
        this.setState({
          secondaryCategories: resp.data.detail,
          oCount,
          aCount,
          rCount,
          cCount
        });
      });

      }
    });
  }

  filterQueries = subjectId => {
    console.log(subjectId);
    this.setState({ subject: subjectId });
    axios
      .get(
        "https://core.akclasses.in/api/filteredQueries?subject_id=" + subjectId + "&frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate
      ).then(resp => {
        console.log(resp);
        let oCount = 0;
        let aCount = 0;
        let rCount = 0;
        let cCount = 0;
        resp.data.detail.forEach(v => {
          oCount += v.open;
          aCount += v.approved;
          rCount += v.rejected;
          cCount += v.closed;
        });
        // console.log(oCount, aCount, rCount, cCount);
        this.setState({
          subjectName: resp.data.name,
          secondaryCategories: resp.data.detail,
          oCount,
          aCount,
          rCount,
          cCount
        });
      });
  }

  searchReport = () => {
    this.setState({ searching: true });
    axios
      .get(
        "https://core.akclasses.in/api/filteredQueries?subject_id=" + this.state.subject + "&frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate
      )
      .then(resp => {
        // console.log(resp.data);
        let oCount = 0;
        let aCount = 0;
        let rCount = 0;
        let cCount = 0;
        resp.data.detail.forEach(v => {
          oCount += v.open;
          aCount += v.approved;
          rCount += v.rejected;
          cCount += v.closed;
        });
        this.setState({
          searching: false,
          secondaryCategories: resp.data.detail,
          oCount,
          aCount,
          rCount,
          cCount
        });
      });
  };
  // export default function Dashboard() {
  // const classes = useStyles();
  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <i className="fas fa-phone" />
                  {/* <Icon>content_copy</Icon> */}
                </CardIcon>
                <p
                  style={{ color: "black", fontSize: "20px" }}
                  className={classes.cardCategory}
                >
                  Queries Asked
                </p>
                <h3 style={{ color: "black" }} className={classes.cardTitle}>
                  {/* 49/50 <small>GB</small> */}
                  {this.state.query}
                </h3>
              </CardHeader>
              <CardFooter stats>
                {/*<div className={classes.stats}>
                <Danger>
                  <Warning />
                </Danger>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  Get more space
                </a>
              </div> */}
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <i className="fas fa-question" />
                  {/* <Store /> */}
                </CardIcon>
                <p
                  style={{ color: "black", fontSize: "20px" }}
                  className={classes.cardCategory}
                >
                  Questions Created
                </p>
                <h3 style={{ color: "black" }} className={classes.cardTitle}>
                  {/* $34,245 */}
                  {this.state.qcreated}
                </h3>
              </CardHeader>
              <CardFooter stats>
                {/* <div className={classes.stats}>
                <DateRange />
                Last 24 Hours
              </div> */}
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <i className="fas fa-check" />
                  {/* <Icon>info_outline</Icon> */}
                </CardIcon>
                <p
                  style={{ color: "black", fontSize: "20px" }}
                  className={classes.cardCategory}
                >
                  Answers
                </p>
                <h3 style={{ color: "black" }} className={classes.cardTitle}>
                  {this.state.ans}
                  {/* 75 */}
                </h3>
              </CardHeader>
              <CardFooter stats>
                {/* <div className={classes.stats}>
                <LocalOffer />
                Tracked from Github
              </div> */}
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <i className="fas fa-arrow-circle-down" />
                </CardIcon>
                <p
                  style={{ color: "black", fontSize: "20px" }}
                  className={classes.cardCategory}
                >
                  Downloadables
                </p>
                <h3 style={{ color: "black" }} className={classes.cardTitle}>
                  {this.state.download}
                  {/* +245 */}
                </h3>
              </CardHeader>
              <CardFooter stats>
                {/* <div className={classes.stats}>
                <Update />
                Just Updated
              </div> */}
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <br />
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                onChange={e => {
                  let year = e._d.getFullYear().toString();
                  let month = (e._d.getMonth() + 101).toString().substring(1);
                  let day = (e._d.getDate() + 100).toString().substring(1);
                  this.setState(
                    {
                      frmDate: year + "/" + month + "/" + day
                    },
                    () => {
                      //console.log(this.state.frmDate);
                    }
                  );
                }}
                inputProps={{
                  placeholder: "From Date"
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <br />
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                onChange={e => {
                  let year = e._d.getFullYear().toString();
                  let month = (e._d.getMonth() + 101).toString().substring(1);
                  let day = (e._d.getDate() + 100).toString().substring(1);
                  this.setState(
                    {
                      toDate: year + "/" + month + "/" + day
                    },
                    () => {
                      //console.log(this.state.toDate);
                    }
                  );
                }}
                inputProps={{
                  placeholder: "To Date"
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <Button
              style={{ position: "relative", top: "13px", right: "20px" }}
              onClick={this.searchReport}
              color="warning"
            >
              {this.state.searching ? "Searching" : "Search"}
            </Button>
          </GridItem>

          <GridItem xs={12} sm={12} md={4}>
            <FormControl
              style={{ marginTop: "12px" }}
              fullWidth
              className={classes.selectFormControl}
            >
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Select Subject
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={this.state.subject}
                inputProps={{
                  required: true,
                  name: "subject",
                  id: "simple-select",
                  onChange: e => this.filterQueries(e.target.value)
                }}
              >
                {this.state.subjects.map((v, i) => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    key={i}
                    value={v.id}
                  >
                    {v.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
          </GridContainer>
          <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <h3>Queries Status for {this.state.subjectName}</h3>
            <table
              style={{
                width: "100%",
                border: "1px grey solid",
                borderCollapse: "collapse"
              }}
            >
              <tr
                style={{
                  border: "1px grey solid",
                  borderCollapse: "collapse"
                }}
              >
                <th
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    border: "1px grey solid",
                    borderCollapse: "collapse"
                  }}
                >
                  Sr No.
                </th>
                <th
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    border: "1px grey solid",
                    borderCollapse: "collapse"
                  }}
                >
                  Chapter
                </th>
                <th
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    border: "1px grey solid",
                    borderCollapse: "collapse"
                  }}
                >
                  Open
                </th>
                <th
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    border: "1px grey solid",
                    borderCollapse: "collapse"
                  }}
                >
                  Approved
                </th>
                <th
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    border: "1px grey solid",
                    borderCollapse: "collapse"
                  }}
                >
                  Rejected
                </th>
                <th
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    border: "1px grey solid",
                    borderCollapse: "collapse"
                  }}
                >
                  Closed
                </th>
              </tr>
              {this.state.secondaryCategories.map((sec, index) => (
                <tr
                  style={{
                    border: "1px grey solid",
                    borderCollapse: "collapse"
                  }}
                  key={index}
                >
                  <td
                    style={{
                      padding: "10px",
                      textAlign: "center",
                      border: "1px grey solid",
                      borderCollapse: "collapse"
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      padding: "10px",
                      textAlign: "center",
                      border: "1px grey solid",
                      borderCollapse: "collapse"
                    }}
                  >
                    {sec.name}
                  </td>
                  <td
                    style={{
                      padding: "10px",
                      textAlign: "center",
                      border: "1px grey solid",
                      borderCollapse: "collapse"
                    }}
                  >
                    {sec.open}
                  </td>
                  <td
                    style={{
                      padding: "10px",
                      textAlign: "center",
                      border: "1px grey solid",
                      borderCollapse: "collapse"
                    }}
                  >
                    {sec.approved}
                  </td>
                  <td
                    style={{
                      padding: "10px",
                      textAlign: "center",
                      border: "1px grey solid",
                      borderCollapse: "collapse"
                    }}
                  >
                    {sec.rejected}
                  </td>
                  <td
                    style={{
                      padding: "10px",
                      textAlign: "center",
                      border: "1px grey solid",
                      borderCollapse: "collapse"
                    }}
                  >
                    {sec.closed}
                  </td>
                </tr>
              ))}
              {this.state.secondaryCategories.length > 0 ? (
                <tr>
                  <td
                    style={{
                      padding: "10px",
                      textAlign: "center",
                      border: "1px grey solid",
                      borderCollapse: "collapse"
                    }}
                  ></td>
                  <th
                    style={{
                      padding: "10px",
                      textAlign: "center",
                      border: "1px grey solid",
                      borderCollapse: "collapse"
                    }}
                  >
                    Totals
                  </th>
                  <th
                    style={{
                      padding: "10px",
                      textAlign: "center",
                      border: "1px grey solid",
                      borderCollapse: "collapse"
                    }}
                  >
                    {this.state.oCount}
                  </th>
                  <th
                    style={{
                      padding: "10px",
                      textAlign: "center",
                      border: "1px grey solid",
                      borderCollapse: "collapse"
                    }}
                  >
                    {this.state.aCount}
                  </th>
                  <th
                    style={{
                      padding: "10px",
                      textAlign: "center",
                      border: "1px grey solid",
                      borderCollapse: "collapse"
                    }}
                  >
                    {this.state.rCount}
                  </th>
                  <th
                    style={{
                      padding: "10px",
                      textAlign: "center",
                      border: "1px grey solid",
                      borderCollapse: "collapse"
                    }}
                  >
                    {this.state.cCount}
                  </th>
                </tr>
              ) : null}
            </table>
          </GridItem>
        </GridContainer>
        {/* <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Language />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Global Sales by Top Locations
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer justify="space-between">
                  <GridItem xs={12} sm={12} md={5}>
                    <Table
                      tableData={[
                        [
                          <img src={us_flag} alt="us_flag" key={"flag"} />,
                          "USA",
                          "2.920",
                          "53.23%"
                        ],
                        [
                          <img src={de_flag} alt="us_flag" key={"flag"} />,
                          "Germany",
                          "1.300",
                          "20.43%"
                        ],
                        [
                          <img src={au_flag} alt="us_flag" key={"flag"} />,
                          "Australia",
                          "760",
                          "10.35%"
                        ],
                        [
                          <img src={gb_flag} alt="us_flag" key={"flag"} />,
                          "United Kingdom",
                          "690",
                          "7.87%"
                        ],
                        [
                          <img src={ro_flag} alt="us_flag" key={"flag"} />,
                          "Romania",
                          "600",
                          "5.94%"
                        ],
                        [
                          <img src={br_flag} alt="us_flag" key={"flag"} />,
                          "Brasil",
                          "550",
                          "4.34%"
                        ]
                      ]}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <VectorMap
                      map={"world_mill"}
                      backgroundColor="transparent"
                      zoomOnScroll={false}
                      containerStyle={{
                        width: "100%",
                        height: "280px"
                      }}
                      containerClassName="map"
                      regionStyle={{
                        initial: {
                          fill: "#e4e4e4",
                          "fill-opacity": 0.9,
                          stroke: "none",
                          "stroke-width": 0,
                          "stroke-opacity": 0
                        }
                      }}
                      series={{
                        regions: [
                          {
                            values: mapData,
                            scale: ["#AAAAAA", "#444444"],
                            normalizeFunction: "polynomial"
                          }
                        ]
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart className={classes.cardHover}>
              <CardHeader color="info" className={classes.cardHeaderHover}>
                <ChartistGraph
                  className="ct-chart-white-colors"
                  data={dailySalesChart.data}
                  type="Line"
                  options={dailySalesChart.options}
                  listener={dailySalesChart.animation}
                />
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="Refresh"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button simple color="info" justIcon>
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Change Date"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="transparent" simple justIcon>
                      <Edit className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardTitle}>Daily Sales</h4>
                <p className={classes.cardCategory}>
                  <span className={classes.successText}>
                    <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                  </span>{" "}
                  increase in today sales.
                </p>
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> updated 4 minutes ago
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart className={classes.cardHover}>
              <CardHeader color="warning" className={classes.cardHeaderHover}>
                <ChartistGraph
                  className="ct-chart-white-colors"
                  data={emailsSubscriptionChart.data}
                  type="Bar"
                  options={emailsSubscriptionChart.options}
                  responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                  listener={emailsSubscriptionChart.animation}
                />
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="Refresh"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button simple color="info" justIcon>
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Change Date"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="transparent" simple justIcon>
                      <Edit className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardTitle}>Email Subscriptions</h4>
                <p className={classes.cardCategory}>
                  Last Campaign Performance
                </p>
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> campaign sent 2 days ago
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart className={classes.cardHover}>
              <CardHeader color="danger" className={classes.cardHeaderHover}>
                <ChartistGraph
                  className="ct-chart-white-colors"
                  data={completedTasksChart.data}
                  type="Line"
                  options={completedTasksChart.options}
                  listener={completedTasksChart.animation}
                />
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="Refresh"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button simple color="info" justIcon>
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Change Date"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="transparent" simple justIcon>
                      <Edit className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardTitle}>Completed Tasks</h4>
                <p className={classes.cardCategory}>
                  Last Campaign Performance
                </p>
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> campaign sent 2 days ago
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <h3>Manage Listings</h3>
        <br />
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card product className={classes.cardHover}>
              <CardHeader image className={classes.cardHeaderHover}>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <img src={priceImage1} alt="..." />
                </a>
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="View"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="transparent" simple justIcon>
                      <ArtTrack className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Edit"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="success" simple justIcon>
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Remove"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="danger" simple justIcon>
                      <Edit className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardProductTitle}>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    Cozy 5 Stars Apartment
                  </a>
                </h4>
                <p className={classes.cardProductDesciprion}>
                  The place is close to Barceloneta Beach and bus stop just 2
                  min by walk and near to {'"'}Naviglio{'"'} where you can enjoy
                  the main night life in Barcelona.
                </p>
              </CardBody>
              <CardFooter product>
                <div className={classes.price}>
                  <h4>$899/night</h4>
                </div>
                <div className={`${classes.stats} ${classes.productStats}`}>
                  <Place /> Barcelona, Spain
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card product className={classes.cardHover}>
              <CardHeader image className={classes.cardHeaderHover}>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <img src={priceImage2} alt="..." />
                </a>
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="View"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="transparent" simple justIcon>
                      <ArtTrack className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Edit"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="success" simple justIcon>
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Remove"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="danger" simple justIcon>
                      <Edit className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardProductTitle}>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    Office Studio
                  </a>
                </h4>
                <p className={classes.cardProductDesciprion}>
                  The place is close to Metro Station and bus stop just 2 min by
                  walk and near to {'"'}Naviglio{'"'} where you can enjoy the
                  night life in London, UK.
                </p>
              </CardBody>
              <CardFooter product>
                <div className={classes.price}>
                  <h4>$1.119/night</h4>
                </div>
                <div className={`${classes.stats} ${classes.productStats}`}>
                  <Place /> London, UK
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card product className={classes.cardHover}>
              <CardHeader image className={classes.cardHeaderHover}>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <img src={priceImage3} alt="..." />
                </a>
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="View"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="transparent" simple justIcon>
                      <ArtTrack className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Edit"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="success" simple justIcon>
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Remove"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="danger" simple justIcon>
                      <Edit className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardProductTitle}>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    Beautiful Castle
                  </a>
                </h4>
                <p className={classes.cardProductDesciprion}>
                  The place is close to Metro Station and bus stop just 2 min by
                  walk and near to {'"'}Naviglio{'"'} where you can enjoy the
                  main night life in Milan.
                </p>
              </CardBody>
              <CardFooter product>
                <div className={classes.price}>
                  <h4>$459/night</h4>
                </div>
                <div className={`${classes.stats} ${classes.productStats}`}>
                  <Place /> Milan, Italy
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer> */}
      </div>
    );
  }
}
export default Dashboard;
