import React, { Component } from "react";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Modal from "react-awesome-modal";
import Datetime from "react-datetime";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Loader from "components/Loader";
const classes = makeStyles(styles);
class VendorOrders extends Component {
  state = {
    notifyStatus: false,
    moving: false,
    moved: false,
    direct: true,
    pay: false,
    notify: false,
    secondaryCategories: [],
    vendors: [],
    tablesArray: [],
    status: "",
    frmDate: "",
    toDate: "",
    vendor: "",
    mailSent: false,
    sendMail: false,
    penEmail: false,
    payLoading: false,
    showSent: false,
    showStatus: false,
    sentPayment: false,
    paymentMailSent: false,
    mailLoading: false,
    payButton: false,
    active: 0,
    loading: "",
    akCount: 0,
    venderCount: 0,
    totalCount: 0,
    gstCount: 0,
  };

  componentDidMount() {
    axios
      .get(
        "https://admin.akclasses.in/api/get_all_offline_order?email_for_vender_status=0"
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState(
          {
            secondaryCategories: resp.data.data,
            totalCount: resp.data.total,
            venderCount: resp.data.vender,
            akCount: resp.data.ak_share,
          },
          () => {
            let tablesArray = [];
            this.state.secondaryCategories.forEach((v) => {
              if (v.vender_list) {
                tablesArray.push(v);
              }
            });
            // console.log(tablesArray);
            this.setState({ tablesArray });
          }
        );
      });
    axios.get("https://admin.akclasses.in/api/venderR").then((resp) => {
      //console.log(resp.data);
      this.setState({
        vendors: resp.data,
      });
    });
  }

  searchUser = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/get_order_of_vender?id=" +
          this.state.vendor
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState(
          {
            direct: false,
            secondaryCategories: resp.data.data.offline_order,
            totalCount: resp.data.total,
            venderCount: resp.data.vender,
            akCount: resp.data.ak_share,
            payButton: false,
            showSent: this.state.active === 0 ? true : false,
            sentPayment: this.state.active === 1 ? true : false,
            showStatus: true,
          },
          () => {
            let gstTotal = 0;
            let taxTotal = 0;
            this.state.secondaryCategories.forEach((cat) => {
              gstTotal += Math.floor(
                ((parseInt(cat.GRAND_TOTAL) - parseInt(cat.vender_commission)) /
                  118) *
                  18
              );
              taxTotal += Math.floor((parseInt(cat.ak_commission) / 118) * 100);
            });
            this.setState({ gstTotal, taxTotal });

            let tablesArray = [];
            this.state.secondaryCategories.forEach((v) => {
              if (v.vender_list) {
                tablesArray.push(v);
              }
            });
            //console.log(tablesArray);
            this.setState({ tablesArray });
          }
        );
      });
  };

  showSent = () => {
    if (this.state.direct) {
      axios
        .get("https://admin.akclasses.in/api/get_all_offline_order")
        .then((resp) => {
          // console.log(resp.data);
          this.setState(
            {
              active: 1,
              secondaryCategories: resp.data.data,
              payButton: false,
              totalCount: resp.data.total,
              venderCount: resp.data.vender,
              akCount: resp.data.ak_share,
            },

            () => {
              let gstTotal = 0;
              let taxTotal = 0;
              this.state.secondaryCategories.forEach((cat) => {
                gstTotal += Math.floor(
                  ((parseInt(cat.GRAND_TOTAL) -
                    parseInt(cat.vender_commission)) /
                    118) *
                    18
                );
                taxTotal += Math.floor(
                  (parseInt(cat.ak_commission) / 118) * 100
                );
              });
              this.setState({ gstTotal, taxTotal });
              let tablesArray = [];
              this.state.secondaryCategories.forEach((v) => {
                if (v.vender_list) {
                  tablesArray.push(v);
                }
              });
              // console.log(tablesArray);
              this.setState({ tablesArray });
            }
          );
        });
    } else {
      axios
        .get(
          "https://admin.akclasses.in/api/get_order_of_vender?id=" +
            this.state.vendor
        )
        .then((resp) => {
          // console.log(resp.data);
          this.setState(
            {
              active: 1,
              penEmail: false,
              secondaryCategories: resp.data.data.offline_order,
              totalCount: resp.data.total,
              venderCount: resp.data.vender,
              akCount: resp.data.ak_share,
              sentPayment: true,
              payButton: false,
              sendMail: false,
              showSent: false,
            },
            () => {
              let gstTotal = 0;
              let taxTotal = 0;
              this.state.secondaryCategories.forEach((cat) => {
                gstTotal += Math.floor(
                  ((parseInt(cat.GRAND_TOTAL) -
                    parseInt(cat.vender_commission)) /
                    118) *
                    18
                );
                taxTotal += Math.floor(
                  (parseInt(cat.ak_commission) / 118) * 100
                );
              });
              this.setState({ gstTotal, taxTotal });
              let tablesArray = [];
              this.state.secondaryCategories.forEach((v) => {
                if (v.vender_list) {
                  tablesArray.push(v);
                }
              });
              //console.log(tablesArray);
              this.setState({ tablesArray });
            }
          );
        });
    }
  };

  showUnSent = () => {
    if (this.state.direct) {
      axios
        .get("https://admin.akclasses.in/api/get_all_offline_order")
        .then((resp) => {
          // console.log(resp.data);
          this.setState(
            {
              active: 0,
              secondaryCategories: resp.data.data,
              totalCount: resp.data.total,
              payButton: false,
              venderCount: resp.data.vender,
              akCount: resp.data.ak_share,
            },
            () => {
              let gstTotal = 0;
              let taxTotal = 0;
              this.state.secondaryCategories.forEach((cat) => {
                gstTotal += Math.floor(
                  ((parseInt(cat.GRAND_TOTAL) -
                    parseInt(cat.vender_commission)) /
                    118) *
                    18
                );
                taxTotal += Math.floor(
                  (parseInt(cat.ak_commission) / 118) * 100
                );
              });
              this.setState({ gstTotal, taxTotal });
              let tablesArray = [];
              this.state.secondaryCategories.forEach((v) => {
                if (v.vender_list) {
                  tablesArray.push(v);
                }
              });
              // console.log(tablesArray);
              this.setState({ tablesArray });
            }
          );
        });
    } else {
      this.setState({ sendMail: false });
      axios
        .get(
          "https://admin.akclasses.in/api/get_order_of_vender?id=" +
            this.state.vendor
        )
        .then((resp) => {
          // console.log(resp.data);
          this.setState(
            {
              penEmail: true,
              active: 0,
              secondaryCategories: resp.data.data.offline_order,
              totalCount: resp.data.total,
              venderCount: resp.data.vender,
              akCount: resp.data.ak_share,
              payButton: false,
              sentPayment: false,
              showSent: true,
            },
            () => {
              let gstTotal = 0;
              let taxTotal = 0;
              this.state.secondaryCategories.forEach((cat) => {
                gstTotal += Math.floor(
                  ((parseInt(cat.GRAND_TOTAL) -
                    parseInt(cat.vender_commission)) /
                    118) *
                    18
                );
                taxTotal += Math.floor(
                  (parseInt(cat.ak_commission) / 118) * 100
                );
              });
              this.setState({ gstTotal, taxTotal });
              let tablesArray = [];
              this.state.secondaryCategories.forEach((v) => {
                if (v.vender_list) {
                  tablesArray.push(v);
                }
              });
              //console.log(tablesArray);
              this.setState({ tablesArray });
            }
          );
        });
    }
  };

  showPayment = () => {
    if (this.state.direct) {
      axios
        .get("https://admin.akclasses.in/api/get_all_offline_order")
        .then((resp) => {
          // console.log(resp.data);
          this.setState(
            {
              active: 2,
              secondaryCategories: resp.data.data,
              totalCount: resp.data.total,
              payButton: false,
              venderCount: resp.data.vender,
              akCount: resp.data.ak_share,
            },
            () => {
              let gstTotal = 0;
              let taxTotal = 0;
              this.state.secondaryCategories.forEach((cat) => {
                gstTotal += Math.floor(
                  ((parseInt(cat.GRAND_TOTAL) -
                    parseInt(cat.vender_commission)) /
                    118) *
                    18
                );
                taxTotal += Math.floor(
                  (parseInt(cat.ak_commission) / 118) * 100
                );
              });
              this.setState({ gstTotal, taxTotal });
              let tablesArray = [];
              this.state.secondaryCategories.forEach((v) => {
                if (v.vender_list) {
                  tablesArray.push(v);
                }
              });
              // console.log(tablesArray);
              this.setState({ tablesArray });
            }
          );
        });
    } else {
      axios
        .get(
          "https://admin.akclasses.in/api/get_order_of_vender?id=" +
            this.state.vendor
        )
        .then((resp) => {
          // console.log(resp.data);
          this.setState(
            {
              active: 2,
              secondaryCategories: resp.data.data.offline_order,
              totalCount: resp.data.total,
              venderCount: resp.data.vender,
              akCount: resp.data.ak_share,
              payButton: false,
              showSent: false,
              sendMail: false,
              showStatus: true,
              sentPayment: false,
            },
            () => {
              let gstTotal = 0;
              let taxTotal = 0;
              this.state.secondaryCategories.forEach((cat) => {
                gstTotal += Math.floor(
                  ((parseInt(cat.GRAND_TOTAL) -
                    parseInt(cat.vender_commission)) /
                    118) *
                    18
                );
                taxTotal += Math.floor(
                  (parseInt(cat.ak_commission) / 118) * 100
                );
              });
              this.setState({ gstTotal, taxTotal });
              let tablesArray = [];
              this.state.secondaryCategories.forEach((v) => {
                if (v.vender_list) {
                  tablesArray.push(v);
                }
              });
              //console.log(tablesArray);
              this.setState({ tablesArray });
            }
          );
        });
    }
  };

  sendEmail = () => {
    this.setState({ loading: true });
    axios
      .get(
        "https://admin.akclasses.in/api/email_vender_order_datevise?frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate +
          "&id=" +
          this.state.vendor
      )
      .then(() => {
        //console.log(resp.data);
        this.setState({
          loading: false,
          mailSent: true,
        });
        setTimeout(() => {
          this.setState({
            mailSent: false,
          });
          window.location.reload();
        }, 4000);
      });
  };

  moveToPending = (id) => {
    this.setState({ moving: true });
    axios
      .post(
        "https://admin.akclasses.in/api/movePayment_status_to_pending?id=" + id
      )
      .then(() => {
        this.setState({ moving: false, moved: true });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  };

  searchEmails = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/get_vender_order_datevise?frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate +
          "&id=" +
          this.state.vendor
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState(
          {
            secondaryCategories: resp.data.data.offline_order,
            penEmail: true,
            payButton: false,
            sendMail: true,
            totalCount: resp.data.total,
            venderCount: resp.data.vender,
            akCount: resp.data.ak_share,
          },
          () => {
            let gstTotal = 0;
            let taxTotal = 0;
            this.state.secondaryCategories.forEach((cat) => {
              gstTotal += Math.floor(
                ((parseInt(cat.GRAND_TOTAL) - parseInt(cat.vender_commission)) /
                  118) *
                  18
              );
              taxTotal += Math.floor((parseInt(cat.ak_commission) / 118) * 100);
            });
            this.setState({ gstTotal, taxTotal });
            let tablesArray = [];
            this.state.secondaryCategories.forEach((v) => {
              if (v.vender_list) {
                tablesArray.push(v);
              }
            });
            //console.log(tablesArray);
            this.setState({ tablesArray });
          }
        );
      });
  };

  handlePayment = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/get_vender_order_payment_datevise?frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate +
          "&id=" +
          this.state.vendor
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState(
          {
            secondaryCategories: resp.data.data.offline_order,
            sendMail: true,
            payButton: true,
            totalCount: resp.data.total,
            venderCount: resp.data.vender,
            akCount: resp.data.ak_share,
          },

          () => {
            let gstTotal = 0;
            let taxTotal = 0;
            this.state.secondaryCategories.forEach((cat) => {
              gstTotal += Math.floor(
                ((parseInt(cat.GRAND_TOTAL) - parseInt(cat.vender_commission)) /
                  118) *
                  18
              );
              taxTotal += Math.floor((parseInt(cat.ak_commission) / 118) * 100);
            });
            this.setState({ gstTotal, taxTotal });
            let tablesArray = [];
            this.state.secondaryCategories.forEach((v) => {
              if (v.vender_list) {
                tablesArray.push(v);
              }
            });
            //console.log(tablesArray);

            this.setState({ tablesArray });
          }
        );
      });
  };

  sendPaymentMail = () => {
    this.setState({ mailLoading: true });
    axios
      .get(
        "https://admin.akclasses.in/api/email_vender_order_payment_datevise?frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate +
          "&id=" +
          this.state.vendor
      )
      .then(() => {
        // console.log(resp.data);
        this.setState({
          mailLoading: false,
          paymentMailSent: true,
        });
        setTimeout(() => {
          this.setState({
            paymentMailSent: false,
          });
          window.location.reload();
        }, 4000);
      });
  };

  render() {
    return (
      <div>
        <GridContainer>
          {this.state.mailSent ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Invoices Sent Successfully!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          {this.state.moved ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Moved Successfully!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody
                style={{
                  position: "relative",
                }}
              >
                {/* {this.state.showStatus ? ( */}
                <div
                  style={{
                    position: "relative",
                    left: "5%",
                    marginTop: "50px",
                  }}
                >
                  <Button
                    onClick={this.showUnSent}
                    style={{
                      position: "relative",
                      bottom: "62px",
                      right: "50px",
                    }}
                    color={this.state.active === 0 ? "secondary" : "primary"}
                  >
                    Pending
                  </Button>

                  {this.state.sendMail ? (
                    <>
                      <a
                        href={
                          this.state.penEmail
                            ? "https://admin.akclasses.in/api/download_vender_order_datevise_pdf?frmdate=" +
                              this.state.frmDate +
                              "&todate=" +
                              this.state.toDate +
                              "&id=" +
                              this.state.vendor
                            : "https://admin.akclasses.in/api/download_vender_order_payment_pdf?frmdate=" +
                              this.state.frmDate +
                              "&todate=" +
                              this.state.toDate +
                              "&id=" +
                              this.state.vendor
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          style={{
                            position: "absolute",
                            right: "65px",
                            bottom: "60px",
                          }}
                          color="danger"
                        >
                          Download
                        </Button>
                      </a>
                      <a
                        href={
                          this.state.penEmail
                            ? "https://admin.akclasses.in/api/download_vender_order_datevise_pdf?frmdate=" +
                              this.state.frmDate +
                              "&todate=" +
                              this.state.toDate +
                              "&id=" +
                              this.state.vendor +
                              "&is_gst=true"
                            : "https://admin.akclasses.in/api/download_vender_order_payment_pdf?frmdate=" +
                              this.state.frmDate +
                              "&todate=" +
                              this.state.toDate +
                              "&id=" +
                              this.state.vendor
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          style={{
                            position: "absolute",
                            right: "65px",
                            bottom: "10px",
                          }}
                          color="danger"
                        >
                          Download with GST
                        </Button>
                      </a>
                    </>
                  ) : null}
                </div>
                {/* ) : null} */}
                <div style={{ position: "relative", top: "50px" }}>
                  <h3 style={{ position: "relative", top: "80px" }}>
                    Vendor Orders
                  </h3>
                  <div
                    style={{
                      position: "relative",
                      bottom: "63px",
                      left: "63%",
                    }}
                  >
                    <GridItem
                      style={{
                        position: "relative",
                        bottom: "17px",
                        right: "10px",
                      }}
                      xs={12}
                      sm={12}
                      md={3}
                    >
                      <FormControl
                        style={{ marginTop: "12px" }}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Search By Vendor
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.vendor}
                          inputProps={{
                            name: "vendor",
                            id: "simple-select",
                            onChange: (e) => {
                              this.setState({
                                vendor: e.target.value,
                              });
                            },
                          }}
                        >
                          {this.state.vendors.map((v, i) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              key={i}
                              value={v.id}
                            >
                              {v.institute_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <Button
                      onClick={this.searchUser}
                      style={{
                        position: "relative",
                        bottom: "62px",
                        left: "200px",
                      }}
                      color="success"
                    >
                      Search
                    </Button>
                  </div>
                  {this.state.showSent ? (
                    <GridContainer
                      style={{
                        position: "absolute",
                        bottom: "70px",
                        left: "188px",
                      }}
                    >
                      <GridItem xs={12} sm={12} md={3}>
                        <br />
                        <label
                          style={{
                            color: "black",
                          }}
                        >
                          Search In Pending Mails
                        </label>
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={(e) => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  frmDate: year + "/" + month + "/" + day,
                                },
                                () => {
                                  //console.log(this.state.frmDate);
                                }
                              );
                            }}
                            inputProps={{
                              placeholder: "From Date",
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <br />
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={(e) => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  toDate: year + "/" + month + "/" + day,
                                },
                                () => {
                                  //console.log(this.state.toDate);
                                }
                              );
                            }}
                            inputProps={{
                              placeholder: "To Date",
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <Button
                          style={{
                            position: "relative",
                            top: "13px",
                            right: "20px",
                          }}
                          onClick={this.searchEmails}
                          color="success"
                        >
                          Search
                        </Button>
                        {this.state.sendMail ? (
                          <Button
                            style={{
                              position: "relative",
                              top: "13px",
                              right: "20px",
                            }}
                            onClick={() => {
                              this.setState({ visible: true, pay: false });
                            }}
                            color="primary"
                          >
                            Send Invoices
                          </Button>
                        ) : null}
                      </GridItem>
                    </GridContainer>
                  ) : null}
                  {this.state.sentPayment ? (
                    <GridContainer
                      style={{
                        position: "absolute",
                        bottom: "70px",
                        left: "320px",
                      }}
                    >
                      <GridItem xs={12} sm={12} md={3}>
                        <br />
                        <label
                          style={{
                            color: "black",
                          }}
                        >
                          Search In Sent Mails
                        </label>
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={(e) => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  frmDate: year + "/" + month + "/" + day,
                                },
                                () => {
                                  //console.log(this.state.frmDate);
                                }
                              );
                            }}
                            inputProps={{
                              placeholder: "From Date",
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <br />
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={(e) => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  toDate: year + "/" + month + "/" + day,
                                },
                                () => {
                                  //console.log(this.state.toDate);
                                }
                              );
                            }}
                            inputProps={{
                              placeholder: "To Date",
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <Button
                          style={{
                            position: "relative",
                            top: "13px",
                            right: "20px",
                          }}
                          onClick={this.handlePayment}
                          color="success"
                        >
                          Search
                        </Button>
                      </GridItem>
                    </GridContainer>
                  ) : null}
                </div>
                {/* <Table
                  style={{ position: "relative" }}
                  tableHead={[
                    "Sr No.",
                    "Student Name",
                    "Order Date",
                    "Vendor's Name",
                    "Product Details",
                    "Grand Total",
                    "Vender Commission",
                    "AK Commission",
                    "Mail"
                  ]}
                  tableData={this.state.tableArray}
                /> */}
                <table
                  style={{
                    width: "100%",
                    border: "1px grey solid",
                    borderCollapse: "collapse",
                  }}
                >
                  <tr
                    style={{
                      border: "1px grey solid",
                      borderCollapse: "collapse",
                    }}
                  >
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      Sr No.
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      Student Name
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                        width: "100px",
                      }}
                    >
                      Order Date
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      Vendor Name
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      Product Details
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      Grand Total
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      Vendor Commission
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      taxable Value
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      GST@18%
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      Recivable
                    </th>
                    {this.state.active === 2 ? (
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        Payment Status
                      </th>
                    ) : null}
                    {/* <th
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                    >
                      Mail
                    </th> */}
                  </tr>
                  {this.state.tablesArray.map((sec, index) => (
                    <tr
                      style={{
                        border: "1px grey solid",
                        borderCollapse: "collapse",
                      }}
                      key={index}
                    >
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        {sec.NAME}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        {sec.ORDER_DATE}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        {sec.vender_list.institute_name}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        {sec.PRODUCT_DETAILS}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        ₹{sec.GRAND_TOTAL}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        ₹{sec.vender_commission}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        ₹{" "}
                        {Math.floor((parseInt(sec.ak_commission) / 118) * 100)}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        ₹{" "}
                        {Math.floor(
                          ((parseInt(sec.GRAND_TOTAL) -
                            parseInt(sec.vender_commission)) /
                            118) *
                            18
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        ₹ {sec.ak_commission}
                      </td>
                      {/* {this.state.active === 2 ? (
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px grey solid",
                            borderCollapse: "collapse",
                          }}
                        >
                          <Select
                            key={index}
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={sec.PAYMENT_STATUS}
                            inputProps={{
                              name: "status",
                              id: "simple-select",
                              onChange: () => {
                                this.moveToPending(sec.ID);
                              },
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value="Collected"
                            >
                              Collected
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value="Not_Collected"
                            >
                              Not Collected
                            </MenuItem>
                          </Select>
                          {sec.PAYMENT_STATUS === "Collected" ? (
                            <Button key={index} color="success">
                              Collected
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                this.moveToPending(sec.ID);
                              }}
                              key={index}
                              color="info"
                            >
                              {this.state.moving
                                ? "Moving..."
                                : "Move to Pending"}
                            </Button>
                          )}
                        </td>
                      ) : null} */}

                      {/* <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        {sec.email_for_vender_status === 1 ? (
                          <Button key={index} color="success">
                            Sent
                          </Button>
                        ) : sec.email_for_vender_status === 0 ? (
                          <Button key={index} color="primary">
                            Pending
                          </Button>
                        ) : sec.email_for_vender_status === 2 ? (
                          <Button key={index} color="warning">
                            Payment Recieved
                          </Button>
                        ) : null}
                      </td> */}
                    </tr>
                  ))}
                  {this.state.tablesArray.length > 0 ? (
                    <tr>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      ></td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      ></td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      ></td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      ></td>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        Totals
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        ₹{this.state.totalCount}
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        ₹{this.state.venderCount}
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        ₹{this.state.taxTotal}
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        ₹{this.state.gstTotal}
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        ₹{this.state.akCount}
                      </th>
                      {/* <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px grey solid",
                          borderCollapse: "collapse",
                        }}
                      >
                        {this.state.payButton ? (
                          <Button
                            onClick={() => {
                              this.setState({ pay: true, visible: true });
                            }}
                            color="warning"
                          >
                            Payment Recieved
                          </Button>
                        ) : null}
                      </td> */}
                    </tr>
                  ) : null}
                </table>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Modal
          visible={this.state.visible}
          width="1000"
          height="330"
          effect="fadeInUp"
          onClickAway={() => this.setState({ visible: false })}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  {this.state.pay ? (
                    <div>
                      <h3>Are You Sure You Have Recieved the Payment!</h3>
                      {this.state.paymentMailSent ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Payment Invoices Sent Successfully!
                          </span>
                        </div>
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <a
                            href={
                              "https://admin.akclasses.in/api/download_vender_order_payment_pdf?frmdate=" +
                              this.state.frmDate +
                              "&todate=" +
                              this.state.toDate +
                              "&id=" +
                              this.state.vendor
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              className=""
                              color="warning"
                              onClick={this.sendPaymentMail}
                            >
                              {this.state.mailLoading ? <Loader /> : "Yes"}
                            </Button>
                          </a>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            No
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : (
                    <div>
                      <h3>Are You Sure You Want to Send the Invoices!</h3>
                      {this.state.mailSent ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Invoices Sent Successfully!
                          </span>
                        </div>
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <a
                            href={
                              "https://admin.akclasses.in/api/download_vender_order_datevise_pdf?frmdate=" +
                              this.state.frmDate +
                              "&todate=" +
                              this.state.toDate +
                              "&id=" +
                              this.state.vendor
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              className=""
                              color="warning"
                              onClick={this.sendEmail}
                            >
                              {this.state.loading ? <Loader /> : "Yes"}
                            </Button>
                          </a>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            No
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Modal>
        {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : null}
      </div>
    );
  }
}

export default VendorOrders;
