import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
// import AddAlert from "@material-ui/icons/AddAlert";
// import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Constants from "variables/AppConstants";
import Modal from "react-awesome-modal";
import Loader from "components/Loader";
const classes = makeStyles(styles);

class FaceToFaceLevel extends Component {
  state = {
    levels: [],
    loader: true,
    noData: false,
    status: "",
    rowId: "",
    visibleDelete: false,
  };

  componentDidMount() {
    this.getLevels();
  }

  getLevels = () => {
    axios.get("https://admin.akclasses.in/api/faceToFaceLevel?per_page=100&type=COURSE").then(resp => {
      console.log(resp);
      this.setState({
        loader: false,
        levels: resp.data.data
      });
    });
  }
  handleStatus = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        const payload = {
          status: this.state.status
        };
        axios
          .put(
            "https://admin.akclasses.in/api/faceToFaceLevel/" + id,
            payload
          )
          .then(() => {
            //console.log(res);
            this.setState({
              notifyStatus: true
            });
            setTimeout(() => {
              this.setState(
                {
                  notifyStatus: false,
                  fromInc: 1,
                  active: 1,
                  toInc: 5
                },
                () => {
                  this.getLevels();
                }
              );
            }, 1000);
          });
      }
    );
  };

  handleModal = sec => {
    this.setState({
      rowId: sec.id,
      visibleDelete: true
    });
  };

  handleDelete = id => {
    this.setState({ delLoading: true });
    axios
      .delete(Constants.deleteUrls.deleteFaceLevel + id)
      .then(() => {
        this.setState({
          notifyDelete: true,
          delLoading: false,
        });
        setTimeout(() => {
          this.setState({
            visibleDelete: false,
          })
          this.getLevels();
        }, 2000);
      });
  };

  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <h3>Face To Face Levels</h3>
                <Link to="/admin/addfacetofacelevel">
                  <Button className="" color="warning">
                    Add Level
                  </Button>
                </Link>
                <Table
                  tableHead={["Sr No.", "Name", "Status", "Action","Delete"]}
                  tableData={this.state.levels.map((sec, index) => [
                    [index + 1],
                    [sec.name],
                    [
                      <Select
                        key={index}
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={sec.status}
                        inputProps={{
                          name: "status",
                          id: "simple-select",
                          onChange: e => {
                            this.handleStatus(e, sec.id);
                          }
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value="active"
                        >
                          ACTIVE
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value="inactive"
                        >
                          INACTIVE
                        </MenuItem>
                      </Select>
                    ],
                    [
                      <Link
                        key={index}
                        to={{
                          pathname: "/admin/updatefacetofacelevel/" + sec.id,
                          state: { categoryData: sec }
                        }}
                      >
                        <Button className="btn-round" color="info">
                          Update
                        </Button>
                      </Link>
                    ],
                    [
                      <Button
                        key={index}
                        onClick={() => {
                          this.handleModal(sec);
                        }}
                        className="btn-round"
                        color="danger"
                      >
                        Delete
                      </Button>
                    ]
                  ])}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.levels.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : null}

        <Modal
          visible={this.state.visibleDelete}
          width="600"
          height="100"
          effect="fadeInUp"
          onClickAway={() => this.setState({ visibleDelete: false })}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  <div>
                    <h3>Are You Sure You Want to Delete It?</h3>
                    {this.state.notifyDelete ? (
                      <div>
                        <span style={{ color: "green" }}>
                          Level Deleted Successfuly!
                        </span>
                      </div>
                    ) : null}
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <br />
                        <Button
                          className=""
                          color="warning"
                          onClick={() => {
                            this.handleDelete(this.state.rowId);
                          }}
                        >
                          {this.state.delLoading ? <Loader /> : "Yes"}
                        </Button>
                        <Button
                          className=""
                          color="danger"
                          onClick={() => {
                            this.setState({ visibleDelete: false });
                          }}
                        >
                          No
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Modal>

      </div>
    );
  }
}

export default FaceToFaceLevel;
