import React, { Component } from "react";
import "../scss/Batches.scss";
import ak_pic from "../images/ak_pic.png";
import amansirleft from "../images/productImg.jpg";
import amansirright from "../images/amansirright.png";
// import toofan from "../images/toofan.png";
import Books from "./Books";
import axios from "axios";
import Constants from "./common/Constants";

export default class Batches extends Component {
  state = {
    courses: [],
  };
  componentDidMount() {
    axios.get(Constants.getUrls.specialCourse).then((respCourse) => {
      // console.log(respCourse);
      if (respCourse.data.data.length > 0) {
        let businessMathCourse = respCourse.data.data.filter(
          (courseTitle) =>
            courseTitle.TITLE === "Business Mathematics Statistics & LR"
        );
        console.log(businessMathCourse);
        this.setState({
          courses: businessMathCourse,
        });
      }
    });
  }
  render() {
    return (
      <div className="container contain">
        <div className="row ">
          <div className="col-sm-6">
            <h4 className={"wingName1536"}>
              <span className="param">Toofan Batch: </span> Teaching Language
              Hindi
            </h4>
            <div className="imageAreaMain">
              <div
                className="imgWrapper row"
                style={{
                  backgroundImage: `url(https://admin.akclasses.in//storage/Courses/images/870206531.jpg)`,
                }}
              >
                <div className="col-sm-6 col-xs-6 paddingClass"></div>
              </div>
              <div className="daysArea">
                <div style={{ textAlign: "center" }}>
                  <h3 className="ttl">
                    Business Mathematics Statistics & LR - Toofan Batch
                  </h3>
                  <h4 className="registerEnd">
                    Registration Ends on 15th November 2022
                  </h4>
                </div>
                <div className="daysDiv">
                  <h4 className="days">
                    <i
                      class="fa-solid fa-check hexCode"
                      style={{ color: "#3f92ad" }}
                    ></i>
                    <span className="totalDays"> 100 Days</span>{" "}
                    <span>Target Strategy</span>
                  </h4>

                  <h4 className="days">
                    <i
                      class="fa-solid fa-check hexCode"
                      style={{ color: "#3f92ad" }}
                    ></i>
                    <span className="totalDays"> 15 Mock Test</span>{" "}
                    <span>Papers with Evaluation</span>
                  </h4>

                  <h4 className="days">
                    <i
                      class="fa-solid fa-check hexCode"
                      style={{ color: "#3f92ad" }}
                    ></i>
                    <span className="totalDays"> 20 Live</span>{" "}
                    <span>Revision Sessions</span>
                  </h4>

                  <h4 className="days">
                    <i
                      class="fa-solid fa-check hexCode"
                      style={{ color: "#3f92ad" }}
                    ></i>
                    <span className="totalDays"> 12 Live</span>{" "}
                    <span>Quiz Sessions</span>
                  </h4>

                  <h4 className="days">
                    <i
                      class="fa-solid fa-check hexCode"
                      style={{ color: "#3f92ad" }}
                    ></i>{" "}
                    Doubt Sessions
                    <span className="totalDays"> as per 100 Days</span>
                  </h4>
                </div>
              </div>
              <div className="enrollArea">
                <div className=" row">
                  <div
                    className="col-md-4 col-xs-4"
                    style={{ textAlign: "left" }}
                  >
                    <h3
                      className="fontSize hrMargin"
                      style={{ color: "#3f92ad" }}
                    >
                      <i
                        class="fa-regular fa-clock"
                        style={{ marginRight: "4px" }}
                      ></i>
                      20 Days
                    </h3>
                  </div>
                  <div
                    className="col-md-4 col-xs-4"
                    style={{ textAlign: "center" }}
                  >
                    <a
                      href="https://akclasses.in/course_details/1/4/1/38/226/45 "
                      style={{ textDecoration: "none" }}
                    >
                      <button
                        className="enrollBtn"
                        style={{ backgroundColor: "#3f92ad" }}
                      >
                        Enroll Now
                      </button>
                    </a>
                  </div>
                  <div className="col-md-4 col-xs-4">
                    <h3
                      className="priceMarg"
                      style={{
                        color: "#3f92ad",
                        textAlign: "right",
                        marginRight: "10px",
                      }}
                    >
                      499/-
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 ">
            <h4 className="wingName2">
              <span className="param2">Lakshya Batch: </span> Teaching Language
              English
            </h4>
            <div className="imageAreaMain">
              <div className="imgWrapper row">
                <div className="col-sm-6 col-xs-6"></div>
              </div>
              <div className="daysArea">
                <div style={{ textAlign: "center" }}>
                  <h3 className="ttl">
                    Business Mathematics Statistics & LR - Nov 22
                  </h3>
                  <h4 className="registerEnd">
                    Registration Ends on 31st August 2022
                  </h4>
                </div>
                <div className="daysDiv">
                  <h4 className="days">
                    <i class="fa-solid fa-check hexCode"></i>

                    <span className="totalDays"> 75 Days</span>
                    <span>Target Strategy</span>
                  </h4>

                  <h4 className="days">
                    <i class="fa-solid fa-check hexCode"></i>
                    <span className="totalDays"> 22 Mock Test</span>{" "}
                    <span>Papers with Evaluation</span>
                  </h4>

                  <h4 className="days">
                    <i class="fa-solid fa-check hexCode"></i>
                    <span className="totalDays"> 15 Live</span>{" "}
                    <span>Revision Sessions</span>
                  </h4>

                  <h4 className="days">
                    <i class="fa-solid fa-check hexCode"></i>
                    <span className="totalDays"> 10 Live</span>{" "}
                    <span>Quiz Sessions</span>
                  </h4>

                  <h4 className="days">
                    <i class="fa-solid fa-check hexCode"></i> Doubt Sessions
                    <span className="totalDays"> as per 75 Days</span>
                  </h4>
                </div>
              </div>

              <div className="enrollArea">
                <div className=" row">
                  <div
                    className="col-md-4 col-xs-4"
                    style={{ textAlign: "left", color: "green" }}
                  >
                    <h3 className="fontSize hrMargin">
                      <i
                        class="fa-regular fa-clock"
                        style={{ marginRight: "4px" }}
                      ></i>
                      150Hrs.
                    </h3>
                  </div>
                  <div
                    className="col-md-4 col-xs-4"
                    style={{ textAlign: "center" }}
                  >
                    <a
                      href="https://akclasses.in/course_details/1/4/1/35/213/45 "
                      style={{ textDecoration: "none" }}
                    >
                      <button className="enrollBtn">Enroll Now</button>
                    </a>
                  </div>
                  <div className="col-md-4 col-xs-4">
                    <h3
                      className="priceMarg"
                      style={{
                        color: "green",
                        textAlign: "right",
                        marginRight: "10px",
                      }}
                    >
                      6500/-
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
