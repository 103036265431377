import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import Modal from "react-awesome-modal";
import { MultiSelect } from "react-multi-select-component";
import renderHTML from "react-render-html";
// import editorConfiguration from "../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Loader from "components/Loader";
import Constants from "variables/Constants";
const classes = makeStyles(styles);

export default class Manual_Notification extends Component {
  state = {
    allNotifcation: [],
    per_page: "10",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    visible: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    rowId: "",
    notification: false,
    notifyDelete: false,
    visible: false,
    delLoading: false,
    message: "",
    is_delete: 0,
  };
  componentDidMount() {
    this.getAllNotification();
  }

  getAllNotification = () => {
    axios
      .get(
        Constants.getUrls.getManualNotification +
          "?is_delete=" +
          this.state.is_delete
      )
      .then((resp) => {
        // console.log(resp);
        this.setState({
          allNotifcation: resp.data.data.data,
        });
      });
  };

  handleDelete = (id) => {
    this.setState({ delLoading: true });
    axios
      .delete(Constants.postUrls.addManualNotification + "/" + id)
      .then((resp) => {
        console.log(resp);
        this.setState({
          notifyDelete: true,
          delLoading: false,
        });
        setTimeout(() => {
          // window.location.reload();
          this.setState(
            {
              notifyDelete: false,
              visible: false,
            },
            () => {
              this.getAllNotification();
            }
          );
        }, 2000);
      });
  };
  handleModal = (sec) => {
    console.log(sec);
    this.setState(
      {
        rowId: sec.id,
        visible: true,
      },
      () => {
        console.log(this.state.rowId);
      }
    );
  };

  handleSelect = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const payload = {
          status: this.state.status,
        };
        console.log(payload);
        axios
          .post(Constants.postUrls.addManualNotification + "/" + id, payload)
          .then((resp) => {
            console.log(resp);
            this.setState({
              notifyStatus: true,
            });
            setTimeout(() => {
              // window.location.reload();
              this.setState(
                {
                  notifyStatus: false,
                },
                () => {
                  this.getAllNotification();
                }
              );
            }, 1000);
          });
      }
    );
  };

  render() {
    return (
      <div>
        <GridContainer>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="danger">
                <Assignment />
              </CardIcon>
              <h4>Manual Notification</h4>
            </CardHeader>
            <CardBody
              style={{
                position: "relative",
              }}
            >
              <FormControl
                style={{
                  position: "absolute",
                  right: "5%",
                  width: "85px",
                }}
                className={classes.selectFormControl}
              >
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Per Page
                </InputLabel>
                <Select
                  // s
                  // key={1}
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={this.state.limit}
                  inputProps={{
                    name: "limit",
                    id: "simple-select",
                    onChange: (e) => {
                      this.handlePerPage(e);
                    },
                  }}
                >
                  {/* {perPages.map((v, i) => ( */}
                  <MenuItem value="25">25</MenuItem>
                  <MenuItem value="50">50</MenuItem>
                  <MenuItem value="100">100</MenuItem>
                  {/* ))} */}
                </Select>
              </FormControl>
              <Link to="/admin/addManualNotification">
                <Button className="" color="warning">
                  Add Notification
                </Button>
              </Link>
              <Table
                tableHead={[
                  "S.no",
                  "Heading",
                  "Image",
                  "Description",
                  "Project",
                  "Likes",
                  "Views",
                  "Shared",
                  "status",
                  "Actions",
                ]}
                tableData={this.state.allNotifcation.map((sec, index) => [
                  [index + 1],
                  [sec.heading ? sec.heading : "NA"],
                  [
                    sec.image ? (
                      <img
                        src={"https://admin.akclasses.in" + sec.image}
                        style={{ height: "100px", width: "100px" }}
                      />
                    ) : null,
                  ],
                  [sec.message ? renderHTML(sec.message) : "NA"],
                  [sec.project ? sec.project : "NA"],
                  [sec.like_count],
                  [sec.views_count],
                  [sec.share_count],
                  [
                    <Select
                      key={index}
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={sec.status}
                      inputProps={{
                        name: "status",
                        id: "simple-select",
                        onChange: (e) => {
                          this.handleSelect(e, sec?.id);
                        },
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="active"
                      >
                        ACTIVE
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="inactive"
                      >
                        INACTIVE
                      </MenuItem>
                    </Select>,
                  ],
                  [
                    <Link
                      key={index}
                      to={{
                        pathname: "/admin/updateManualNotification/" + sec.id,
                        state: sec,
                      }}
                    >
                      <Button className="btn-round" color="info">
                        Update
                      </Button>
                    </Link>,
                    <Button
                      key={index}
                      onClick={() => {
                        this.handleModal(sec);
                      }}
                      className="btn-round"
                      color="danger"
                    >
                      Delete
                    </Button>,
                  ],
                ])}
              />
            </CardBody>
          </Card>
          <Modal
            visible={this.state.visible}
            width="600"
            height="100"
            effect="fadeInUp"
            onClickAway={() => this.setState({ visible: false })}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  {/* <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                  <h4>Stacked Form</h4> 
                  </CardHeader> */}
                  <CardBody>
                    <div>
                      <h3>Are You Sure You Want to Delete It?</h3>
                      {this.state.notifyDelete ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Notification Deleted Successfuly!
                          </span>
                        </div>
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={() => {
                              this.handleDelete(this.state.rowId);
                            }}
                          >
                            {this.state.delLoading ? <Loader /> : "Yes"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            No
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Modal>
        </GridContainer>
      </div>
    );
  }
}
