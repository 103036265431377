import React, { Component } from "react";
import Constants from "../variables/Constants";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { MultiSelect } from "react-multi-select-component";

const classes = makeStyles(styles);
class AddFaceToFaceSubject extends Component {
  state = {
    type: "",
    pkey: "",
    category: "",
    ftof_batch_ids: [],
    allFaceBatches: [],
    selected_batch: [],
    courses: [],
    batch_wings: [],
    addedWings: [],
    batch_wings_id: [],
    combo_type: "",
    redirect: false,
    aAlert: false,
    bAlert: false,
    notification: false,
    loading: false,
    cAlert: false,
  };
  componentDidMount() {
    axios
      .get(
        "https://admin.akclasses.in/api/facetofaceStream?type=COURSE&per_page=10"
      )
      .then((resp) => {
        console.log(resp);
        this.setState({
          courses: resp.data.data,
        });
      });

    // Get Face to Face Batch

    axios.get(Constants.getUrls.faceToFaceBatch).then((resp) => {
      console.log(resp);
      resp.data.data.map((item) => {
        this.state.allFaceBatches.push({
          label: item?.batch_name,
          value: item.id,
        });
      });
      this.setState(
        {
          allFaceBatches: this.state.allFaceBatches,
        },
        () => {
          console.log(this.state.allFaceBatches);
        }
      );
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.state.selected_batch.map((v) => {
      this.state.ftof_batch_ids.push(v.value);
    });
    this.setState(
      {
        ftof_batch_ids: this.state.ftof_batch_ids,
      },
      () => {
        console.log(this.state.ftof_batch_ids);
      }
    );
    let payLoad = {
      stream_id: this.state.category,
      type: "Course",
      title: this.state.pkey,
      combo_type: this.state.combo_type,
      ftof_batch_ids: this.state.ftof_batch_ids,
    };
    if (this.state.category === "") {
      this.setState({
        bAlert: true,
      });
    } else if (this.state.ftof_batch_ids.length === 0) {
      this.setState({ cAlert: true });
      return;
    } else {
      this.setState({
        loading: true,
      });
      axios
        .post("https://admin.akclasses.in/api/facetofaceSubject", payLoad)
        .then((resp) => {
          console.log(resp);
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        });
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/facetofacesubjects"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Subject Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Face To Face Subject</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Stream
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.category}
                            inputProps={{
                              name: "category",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  category: e.target.value,
                                  bAlert: false,
                                });
                                console.log(e.target.value);
                              },
                            }}
                          >
                            {this.state.courses.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <label> Select Batches </label>
                        <br />
                        <MultiSelect
                          disableSearch
                          options={this.state.allFaceBatches}
                          name="test"
                          value={this.state.selected_batch}
                          onChange={(data) => {
                            // console.log(data);
                            this.setState({
                              selected_batch: data,
                            });
                          }}
                        />
                        {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Combo Type
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.combo_type}
                            inputProps={{
                              name: "combo_type",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  combo_type: e.target.value,
                                });
                                console.log(e.target.value);
                              },
                            }}
                          >
                            <MenuItem value="Normal">Normal</MenuItem>
                            <MenuItem value="Combo">Combo</MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Title"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ pkey: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <br />
                      <GridItem xs={12} sm={12} md={6}></GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Subject"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/facetofacesubjects",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddFaceToFaceSubject;
