/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Constants from "variables/Constants";
const classes = makeStyles(styles);

export default class UpdateScannerSubject extends Component {
  state = {
    redirect: false,
    notification: false,
    loading: false,
    level: "",
    levels: [],
    aAlert: false,
    bAlert: false,
    cAlert: false,
    title: "",
    description: "",
  };

  componentDidMount() {
    let levelData = this.props.location.state.levelData;
    axios.get(Constants.getUrls.getlevel_without_paginate).then((resp) => {
      console.log(resp);
      this.setState({
        levels: resp.data,
        level: levelData.scanner_level_id,
        title: levelData.title,
        description: levelData.description,
        status: levelData.status,
      });
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.level === "") {
      this.setState({
        aAlert: true,
      });
    } else if (this.state.title === "") {
      this.setState({
        bAlert: true,
      });
    } else if (this.state.description === "") {
      this.setState({
        cAlert: true,
      });
    } else {
      this.setState({
        loading: true,
      });

      let payLoad = {
        scanner_level_id: this.state.level,
        title: this.state.title,
        description: this.state.description,
        status: this.state.status,
      };

      let levelData = this.props.location.state.levelData;
      let levelId = levelData.id;

      axios
        .post(Constants.postUrls.updateScannerSubject + levelId, payLoad)
        .then((resp) => {
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        });
    }
  };

  handleSunEditorChange = (content) => {
    this.setState({
      description: content,
      cAlert: false,
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/scannersubject"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Subject Updated!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Scanner Subject</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Title"
                          id="float"
                          inputProps={{
                            value: this.state.title,
                            required: true,
                            onChange: (e) =>
                              this.setState({ title: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Level
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.level}
                            inputProps={{
                              name: "level",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  level: e.target.value,
                                  bAlert: false,
                                });
                              },
                            }}
                          >
                            {this.state.levels.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <br />
                        <label>Description</label>
                        <br />
                        <SunEditor
                          setOptions={{
                            buttonList: [
                              ["undo", "redo"],
                              ["bold", "underline", "italic", "strike"],
                              ["fontColor", "hiliteColor"],
                              ["indent", "outdent"],
                              ["align", "horizontalRule", "list", "table"],
                              ["link", "image", "video"],
                            ],
                          }}
                          onChange={this.handleSunEditorChange}
                          setContents={this.state.description}
                        />
                        {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Update Subject"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/scannersubject",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}
