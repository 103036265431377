import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import editorConfiguration from "../variables/EditorConfiguration";
import Constants from "variables/Constants";
import { MultiSelect } from "react-multi-select-component";

const classes = makeStyles(styles);

class AddBatchWings extends Component {
  state = {
    redirect: false,
    notification: false,
    loading: false,
    title: "",
    description: "",
    batches: [],
    batch: [],
    selectedBatch: [],
    // attempt_id: "",
    attempts: [],
    attempt_id: [],
  };

  componentDidMount() {
    this.getAttempts();
  }

  getAttempts = () => {
    axios
      .get("https://admin.akclasses.in/api/activeAttempts")
      .then((respattempt) => {
        console.log(respattempt);
        respattempt.data.forEach((val) => {
          this.state.attempts.push({
              label: val.title,
              value: val.id,
            });
          });
          this.setState({
            attempts: this.state.attempts,
          });
      });
  };

  getAttemptsBatches = (selectedAttempts) => {
    // Assuming selectedAttempts is an array of selected attempt IDs
    console.log(selectedAttempts);
  
    // Clear previous batches
    this.setState({ batches: [] });
  
    selectedAttempts.forEach((attemptId) => {
      axios
        .get(Constants.getUrls.getBatches + "?per_page=50")
        .then((resp) => {
          console.log(resp.data.data);
          resp.data.data.forEach((val) => {
            console.log(attemptId);
            console.log(val.attempt.id);
            if (attemptId.value === val.attempt.id) {
              this.setState((prevState) => ({
                batches: [
                  ...prevState.batches,
                  {
                    label: val.batch_name + " (" + val.attempt.title + ")",
                    value: val.id,
                  },
                ],
              }));
            }
          });
        })
        .catch((error) => {
          console.error("Error fetching batches:", error);
        });
    });
  };

  // getAttemptsBatches = (e) => {
  //   console.log(e.target.value);
  //   this.setState(
  //     {
  //       [e.target.name]: e.target.value,
  //     },
  //     () => {
  //       // console.log(this.state.attempt_id);
  //       axios
  //         .get(Constants.getUrls.getBatches + "?per_page=50")
  //         .then((resp) => {
  //           // console.log(resp.data.data);

  //           resp.data.data.forEach((val) => {
  //             if (this.state.attempt_id === val.attempt.id) {
  //               this.state.batches.push({
  //                 label: val.batch_name + " " + "(" + val.attempt.title + ")",
  //                 value: val.id,
  //               });
  //             }
  //           });
  //           this.setState({
  //             batches: this.state.batches,
  //           });
  //         });
  //     }
  //   );
  // };
  handleSubmit = (e) => {
    e.preventDefault();
  
    // Extracting values from the selected attempts
    const selectedAttemptsValues = this.state.attempt_id.map((attempt) => attempt.value);
  
    this.state.batch.forEach((v) => {
      this.state.selectedBatch.push({ value: v.value });
    });
  
    let payLoad = {
      title: this.state.title,
      attempt_id: selectedAttemptsValues, // Using extracted values
      description: this.state.description,
      batch_id: JSON.stringify(this.state.selectedBatch),
    };
  
    this.setState({
      loading: true,
    });
  
    axios.post(Constants.postUrls.addBatchWing, payLoad).then((resp) => {
      console.log(resp);
      setTimeout(() => {
        this.setState({
          redirect: true,
        });
      }, 1000);
      this.setState({
        notification: true,
        loading: false,
      });
    });
  };

  // handleSubmit = (e) => {
  //   e.preventDefault();
  //   this.state.batch.forEach((v) => {
  //     this.state.selectedBatch.push({ value: v.value });
  //   });
  //   let payLoad = {
  //     title: this.state.title,
  //     attempt_id: this.state.attempt_id,
  //     description: this.state.description,
  //     batch_id: JSON.stringify(this.state.selectedBatch),
  //   };
  //   this.setState({
  //     loading: true,
  //   });
  //   axios.post(Constants.postUrls.addBatchWing, payLoad).then((resp) => {
  //     console.log(resp);
  //     setTimeout(() => {
  //       this.setState({
  //         redirect: true,
  //       });
  //     }, 1000);
  //     this.setState({
  //       notification: true,
  //       loading: false,
  //     });
  //   });
  // };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/batchwings"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Batch Wing Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Batch Wing</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Title"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ title: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      {/* <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          required={true}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Select Attempt
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.attempt_id}
                            inputProps={{
                              required: true,
                              name: "attempt_id",
                              id: "simple-select",
                              onChange: this.getAttemptsBatches,
                            }}
                          >
                            {this.state.attempts.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem> */}

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{
                            zIndex: "100",
                            width: "100%",
                          }}
                        >
                          <span> Select Attempt </span>
                          <MultiSelect
                            disableSearch
                            options={this.state.attempts}
                            value={this.state.attempt_id}
                            onChange={(data) => {
                              this.setState({ attempt_id: data }, () =>
                                this.getAttemptsBatches(data)
                              );
                            }}
                            labelledBy={"Attempts"}
                          />
                        </FormControl>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{
                            zIndex: "100",
                            width: "100%",
                          }}
                        >
                          <span> Select Batches </span>
                          <MultiSelect
                            disableSearch
                            options={this.state.batches}
                            value={this.state.batch}
                            onChange={(data) => {
                              this.setState({ batch: data }, () =>
                                console.log(this.state.batch)
                              );
                            }}
                            labelledBy={"Batches"}
                          />
                        </FormControl>
                      </GridItem>

                      {/* <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{
                            zIndex: "100",
                            // marginTop: "10px",
                            width: "100%",
                          }}
                        >
                          <span> Select Batches </span>
                          <MultiSelect
                            disableSearch
                            options={this.state.batches}
                            value={this.state.batch}
                            onChange={(data) => {
                              this.setState({ batch: data }, () =>
                                console.log(this.state.batch)
                              );
                            }}
                            labelledBy={"Batches"}
                          />
                        </FormControl>
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={12}>
                        <br />
                        <label>Description</label>
                        <br />
                        <CKEditor
                          editor={ClassicEditor}
                          // config={editorConfiguration}
                          data={this.state.description}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            //console.log({ data });
                            this.setState({
                              description: data,
                              specificationAlert: false,
                            });
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Add Batch Wing"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/batchwings",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddBatchWings;
