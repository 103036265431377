/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
// import editorConfiguration from "../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AddAlert from "@material-ui/icons/AddAlert";
import Constants from "variables/AppConstants";
let ProgressBar = (props) => {
  return (
    <div className="progress-bar">
      <Filler percentage={props.percentage} />
    </div>
  );
};
let Filler = (props) => {
  return (
    <div className="filler" style={{ width: `${props.percentage}%` }}></div>
  );
};
let fd = new FormData();
class UpdateAd extends Component {
  state = {
    title: "",
    imgUrl: "",
    subtitle: "",
    description: "",
    redirect: false,
  };
  handleSubmit = (e) => {
    e.preventDefault();
    // console.log(this.state);

    fd.append("title", this.state.title);
    if (this.state.imgUrl) {
      fd.append("file", this.state.imgUrl);
    } else {
      return null;
    }
    fd.append("description", this.state.description);
    fd.append("subtitle", this.state.subtitle);
    const adData = this.props.location.state.adData;
    // console.log(adData);
    const adId = parseInt(adData.id);
    // console.log(adId);
    // for (var pair of fd.entries()) {
    // console.log(pair);
    // }
    axios.put(Constants.updateUrls.updateAds + adId, fd).then(() => {
      // console.log(resp);
      this.setState({
        redirect: true,
      });
    });
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };
  componentDidMount() {
    const adData = this.props.location.state.adData;
    // console.log(adData.title);
    this.setState({
      title: adData.title,
      imgUrl: adData.img_url,
      description: adData.description,
      subtitle: adData.subtitle,
    });
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/ads"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.show ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Advertisement Updated Successfully!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Update Ad</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Title"
                          id="float"
                          inputProps={{
                            required: true,
                            value: this.state.title,
                            onChange: (e) =>
                              this.setState({ title: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Sub Title"
                          id="float"
                          inputProps={{
                            required: true,
                            value: this.state.subtitle,
                            onChange: (e) =>
                              this.setState({ subtitle: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <label>Image</label>
                        <br />
                        <input
                          name="imgUrl"
                          onChange={this.handleFile}
                          type="file"
                          required={true}
                          accept="image/*"
                        />
                      </GridItem>
                      <label>Preview</label>
                      <br />
                      <img src={this.state.imgUrl} width="100px" alt="adv" />
                      <br />
                      <GridItem xs={12} sm={12} md={12}>
                        <label>Description</label>
                        <br />
                        <CKEditor
                          editor={ClassicEditor}
                          // config={editorConfiguration}
                          data={this.props.location.state.adData.description}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            //console.log({ data });
                            this.setState({
                              description: data,
                              specificationAlert: false,
                            });
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <br />
                        {this.state.mediaProgress ? (
                          <ProgressBar percentage={this.state.progress} />
                        ) : null}
                      </GridItem>
                      <br />
                      <GridItem xs={12} sm={12} md={4}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? <Loader /> : "Update Ad"}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/ads",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateAd;
