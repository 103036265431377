import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Constants from "variables/Constants";
import FormControl from "@material-ui/core/FormControl";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";

const classes = makeStyles(styles);
class AddSubjectFilter extends Component {
  state = {
    // type: "",
    category: "",
    // catData: ["COURSE", "TEST_SERIES"],
    redirect: false,
    notification: false,
    loading: false,
    status: "active",
    is_deleted: 0,
    Subjects: [],
    subject_id: 0,
  };
  componentDidMount() {
    this.getSubjects();
  }
  getSubjects = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/subject_master/paginate_index?per_page=100" +
          "&type=Course"
      )
      .then((res) => {
        console.log("subjects", res);
        this.setState(
          {
            Subjects: res.data.data,
          },
          () => {
            console.log(this.state.Subjects);
          }
        );
      });
  };
  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    let payLoad = {
      subject_id: this.state.subject_id,
      title: this.state.title,
      description: this.state.description,
      status: this.state.status,
      is_deleted: 0,
    };
    console.log(payLoad);
    axios.post(Constants.postUrls.AddSubjectFilter, payLoad).then((resp) => {
      console.log(resp);
      setTimeout(() => {
        this.setState({
          redirect: true,
        });
      }, 1000);
      this.setState({
        notification: true,
        loading: false,
      });
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/subjectsFilter"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Category Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Subject Filter</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Subject
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            required={true}
                            value={this.state.subject_id}
                            inputProps={{
                              required: true,
                              name: "subejct_id",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState(
                                  {
                                    subject_id: e.target.value,

                                    aAlert: false,
                                  },
                                  () => {
                                    console.log(this.state.subject_id);
                                  }
                                );
                              },
                            }}
                          >
                            {this.state.Subjects.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={5}>
                        <CustomInput
                          labelText="Title"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ title: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={5}>
                        <CustomInput
                          labelText="Description"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ description: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Button className="" color="warning" type="submit">
                          {this.state.loading ? (
                            <Loader />
                          ) : (
                            "Add Subject Filter"
                          )}
                        </Button>
                        <Link
                          to={{
                            pathname: "/admin/subjectsFilter",
                          }}
                        >
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddSubjectFilter;
