import React, { Component } from "react";
import "../../css/Poster.css";
// import "../scss/Poster.scss";
import img1 from "../images/home_ak.png";
import img2 from "../images/05.png";
import videoImg from "../images/01.png";
import Batches from "./Batches";
import axios, { Axios } from "axios";
import Constants from "./common/Constants";
import Modal from "react-awesome-modal";
import loader from "../images/loader.json";
import Lottie from "react-lottie";
import CheckoutRegitrationNotification from "./CheckoutRegitrationNotification";
export default class Poster extends Component {
  state = {
    attempts: [],
    attempt1: "",
    attempt2: "",
    respState: false,
  };

  componentDidMount() {
    if (window.innerWidth <= 360) {
      this.setState({ respState: true }, () => {
        console.log(this.state.respState);
      });
    }
    axios.get(Constants.getUrls.getAttempts).then((attResp) => {
      // console.log(attResp.data[0].title,attResp.data[1].title);
      if (attResp.data.length > 0) {
        this.setState({
          attempts: attResp.data,
          attempt1: attResp.data[0]?.title,
          attempt2: attResp.data[1]?.title,
        });
      }
    });
  }
  closeModal = () => {
    this.setState({ openModal: false });
  };
  onSubmit = (e) => {
    e.preventDefault();
    this.setState({
      btnLoading: true,
      showNotif: true,
      notiMessage: "Sending request...",
    });
    const payLoad = {
      full_name: this.state.full_name,
      subject: "Request Call back",
      mobile: this.state.mobile,
      email: this.state.email,
      // messages: this.state.messages,
    };
    console.log(payLoad);
    axios
      .post(Constants.postUrls.mail, payLoad)
      .then((res) => {
        console.log(res);
        if (res.data === "successfully send your request") {
          this.setState({
            btnLoading: false,
            showNotif: true,
            notiMessage: "Request Sent!",
          });
          setTimeout(() => {
            this.setState({ showNotif: false });
            window.location.reload();
          }, 2000);
        } else {
          alert("Request Send Failed");
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (
          err.response.status === 500 ||
          err.response.status === 400 ||
          err.response.status === 404
        ) {
          this.setState({
            showNotif: false,
            btnLoading: false,
            showError: true,
            errorMsg: "Something went wrong! Please try after some time",
          });
          setTimeout(() => {
            this.setState({ showError: false, openModal: false });
          }, 3000);
        }
      });
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    return (
      <div>
        <div className="posterBack">
          <div className="col-md-12 wraper1">
            <div className="col-md-6 leftPoster">
              <div>
                <h4 className="discover">Discover your learning journey</h4>
              </div>
              <div>
                <h3 className="cafoundation">
                  <span>CA Foundation</span>
                  <br />
                  <span>Math made easy with shortcuts</span>
                  <br />
                  <span>calculator tricks and conceptual clarity</span>
                  <br />
                  <br />
                </h3>

                <h4 className="learnAt">
                  <span>
                    Learn at your pace. We have Regular, Fast Track Batches.
                  </span>
                  <br />
                  <span>
                    Doubt Session Android App, Printed Study Material, Test
                    Series
                  </span>
                  <br />
                  <span>get all at one destination Akclasses.in</span>
                  <br />
                </h4>
              </div>
            </div>
            <div className="col-md-6 rightPoster">
              <img src={img1} alt="" className="homeImg1" />
            </div>
            <div className="col-md-6 rightPoster1">
              <img
                src={img2}
                alt=""
                className="homeImg2"
                height={200}
                width={200}
              />
            </div>
            <div className="blueBack">
              <div className="col-md-12 showBlueBack">
                <div
                  className="col-md-6"
                  style={{ textAlign: "left" }}
                  onClick={() => {
                    this.setState({ openModal: true }, () => {
                      console.log(this.state.openModal);
                    });
                  }}
                >
                  <button className="requestCallBack">Request Call Back</button>
                  <p className="requestPara">
                    Get Complete Details About the Classes
                  </p>
                </div>
                <div className="col-md-6" style={{ textAlign: "left" }}>
                  <button className="requestCallBack">
                    <img
                      src={videoImg}
                      alt="video Img"
                      style={{ marginRight: "8px" }}
                    />
                    Know the Best Approach to Study Maths
                  </button>
                  <p className="requestPara">
                    Get Complete Details About the Classes
                  </p>
                </div>
                {/* <div className="col-md-4" style={{ textAlign: "left" }}>
                  <button className="requestCallBack">Free Test Series</button>
                  <p className="requestPara">
                    Get Complete Details About the Classes
                  </p>
                </div> */}
              </div>
            </div>
          </div>

          <div className="blueBackCont">
            {" "}
            <div
              className="hiden"
              style={{ textAlign: "left" }}
              onClick={() => {
                this.setState({ openModal: true }, () => {
                  console.log(this.state.openModal);
                });
              }}
            >
              <button className="requestCallBack">Request Call Back</button>
            </div>
            <div className="hiden" style={{ textAlign: "left" }}>
              <button className="requestCallBack">
                {" "}
                <img src={videoImg} alt="icon" /> Best Maths Approach
              </button>
            </div>
          </div>
        </div>
        {this.state.respState ? (
          <div className="joinCa">
            <h2 className="join">
              Join CA Foundation Batch for
              <span className="may2022">
                &nbsp; {this.state.attempt1} | {this.state.attempt2}
              </span>
              &nbsp; Attempt{" "}
            </h2>
            <h3 className="batchAvail">
              Batch is also available in{" "}
              <span className="english">English for South Indian Students</span>{" "}
            </h3>
          </div>
        ) : (
          <div className="joinCa">
            <h2 className="join">
              Join CA Foundation Batch for
              <span className="may2022">
                &nbsp; {this.state.attempt1} | {this.state.attempt2}
              </span>
              &nbsp; Attempt{" "}
            </h2>
            <h3 className="batchAvail">
              Batch is also available in{" "}
              <span className="english">English for South Indian Students</span>{" "}
            </h3>
          </div>
        )}
        <Modal
          visible={this.state.openModal}
          width="400"
          height="250"
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
        >
          {/* <div className="modal-content quick-view-modal"> */}
          <div
            className="modal-body"
            style={{ padding: "16px ", zIndex: 9999 }}
          >
            <h2 style={{ textAlign: "center", color: "#0f66b9", fontSize: 18 }}>
              <i
                className="fa fa-question-circle"
                style={{ marginRight: "8px" }}
              />
              Request CallBack
            </h2>
            <form onSubmit={this.onSubmit}>
              <div className="footer-contant">
                <div className="theme-form">
                  <div className="form-row">
                    <input
                      type="text"
                      className="form-control"
                      //   id="name"
                      placeholder="Enter Your name"
                      required={true}
                      name="full_name"
                      style={{ marginBottom: 10 }}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />

                    <input
                      type="text"
                      className="form-control"
                      //   id="last-name"
                      placeholder="Enter your email"
                      required={true}
                      name="email"
                      style={{ marginBottom: 10 }}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />

                    <input
                      type="number"
                      className="form-control"
                      //   id="last-name"
                      placeholder="Enter 10 digit mobile number"
                      required={true}
                      name="mobile"
                      style={{ marginBottom: 10 }}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />

                    <div
                      style={{
                        textAlign: "center",
                        width: "100%",
                        marginTop: "20px",
                      }}
                    >
                      <button
                        className="btn btn-Footer"
                        type="submit"
                        style={{ backgroundColor: "#234f83", color: "#fff" }}
                      >
                        {this.state.btnLoading ? (
                          <Lottie
                            options={{
                              animationData: loader,
                            }}
                            style={{
                              width: "50px",
                              height: "30px",
                            }}
                          />
                        ) : (
                          "Send Message"
                        )}
                      </button>
                    </div>
                    {this.state.showError && (
                      <p style={{ textAlign: "center", color: "red" }}>
                        {this.state.errorMsg}{" "}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
          {/* </div> */}
        </Modal>
        {this.state.showNotif ? (
          <CheckoutRegitrationNotification message={this.state.notiMessage} />
        ) : null}
      </div>
    );
  }
}
