import React, { Component } from "react";
import Table from "components/Table/Table.js";
import axios from "axios";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import Loader from "components/Loader";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CardBody from "components/Card/CardBody.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import init from "Helper/WindowToken";
import Datetime from "react-datetime";
import Modal from "react-awesome-modal";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
const classes = makeStyles(styles);
const perPages = ["10", "25", "50", "100"];
class FacultyOnlineOrders extends Component {
  state = {
    notifyStatus: false,
    repButton: false,
    secondaryCategories: [],
    status: "",
    pKey: "",
    facultyData: {},
    student: "",
    orders: ["Order Placed", "Activated", "Shipped", "Cancelled"],
    osType: "",
    mediaType: "",
    per_page: "10",
    viewValidity: "",
    trackingNo: "",
    ordType: "Order Placed",
    act: 0,
    active: 1,
    frmDate: "",
    toDate: "",
    product: "",
    address: "",
    logic: "",
    btnIdArray: [],
    notification: false,
    newOrder: false,
    delModal: false,
    logDetail: false,
    delLoading: false,
    notifyDelete: false,
    pincode: "",
    ord: true,
    logistics: [],
    userSearch: false,
    visible: false,
    loading: false,
    courierCom: "",
    tracLink: "",
    proDetail: false,
    comment: false,
    notifyNote: false,
    searchOrderId: "",
    notes: "",
    delStatus: "",
    price: "",
    rowId: "",
    city: "",
    state: "",
    category: "",
    contact: "",
    print: false,
    printView: true,
    empty: false,
    payStatus: "",
    shippingDate: "",
    fromInc: 1,
    toInc: 5,
    filteredBtnIdArray: []
  };
  openModal() {
    this.setState({
      visible: true
    });
  }
  closeModal() {
    this.setState({
      visible: false,
      logDetail: false,
      print: false,
      delModal: false,
      proDetail: false,
      comment: false
    });
  }
  componentDidMount() {
    if (init() === "success") {
      axios.get("https://admin.akclasses.in/api/userdetail").then(resp => {
        // console.log(resp.data);
        this.setState({ facultyData: resp.data });
      });
    }
    let category = localStorage.getItem("category");
    this.setState({ category }, () => {
      axios
        .get(
          "https://admin.akclasses.in/api/faculty_new_order?order_type=" +
            this.state.category +
            "&per_page=" +
            this.state.per_page
        )
        .then(resp => {
          // console.log(resp.data);
          let btnIdArray = [];
          for (let i = 1; i <= resp.data.last_page; i++) {
            btnIdArray.push(i);
          }
          this.setState(
            {
              btnIdArray,
              newOrder: true,
              ord: false,
              userSearch: false,
              secondaryCategories: resp.data.data,
              total: resp.data.total,
              from: resp.data.from,
              to: resp.data.to
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                v => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({
                filteredBtnIdArray
              });
            }
          );
        });
    });
    axios.get("https://admin.akclasses.in/api/logistic").then(resp => {
      //console.log(resp.data.data);
      this.setState({
        logistics: resp.data.data
      });
    });
  }

  handlePage = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5
      },
      () => {
        if (this.state.ordType === "Order Placed") {
          axios
            .get(
              "https://admin.akclasses.in/api/faculty_new_order?order_type=" +
                this.state.category +
                "&per_page=" +
                this.state.per_page
            )
            .then(resp => {
              // console.log(resp.data);
              let btnIdArray = [];
              for (let i = 1; i <= resp.data.last_page; i++) {
                btnIdArray.push(i);
              }
              this.setState(
                {
                  btnIdArray,
                  newOrder: true,
                  userSearch: false,
                  ord: false,
                  secondaryCategories: resp.data.data,
                  total: resp.data.total,
                  from: resp.data.from,
                  to: resp.data.to
                },
                () => {
                  let filteredBtnIdArray = this.state.btnIdArray.filter(
                    v => v >= this.state.fromInc && v <= this.state.toInc
                  );
                  this.setState({
                    filteredBtnIdArray
                  });
                }
              );
            });
        } else {
          axios
            .get(
              "https://admin.akclasses.in/api/faculty_orders?status=" +
                this.state.ordType +
                "&order_type=" +
                this.state.category +
                "&per_page=" +
                this.state.per_page
            )
            .then(resp => {
              // console.log(resp.data);
              let btnIdArray = [];
              for (let i = 1; i <= resp.data.last_page; i++) {
                btnIdArray.push(i);
              }
              this.setState(
                {
                  btnIdArray,
                  empty: false,
                  newOrder: false,
                  ord: true,
                  userSearch: false,
                  secondaryCategories: resp.data.data,
                  total: resp.data.total,
                  from: resp.data.from,
                  to: resp.data.to
                },
                () => {
                  let filteredBtnIdArray = this.state.btnIdArray.filter(
                    v => v >= this.state.fromInc && v <= this.state.toInc
                  );
                  this.setState({
                    filteredBtnIdArray
                  });
                }
              );
            });
        }
      }
    );
  };
  handleCategory = e => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        if (this.state.category === "Extend_Validity") {
          this.setState({
            orders: ["Order Placed", "Extended", "Shipped", "Cancelled"]
          });
        } else {
          this.setState({
            orders: ["Order Placed", "Activated", "Shipped", "Cancelled"]
          });
        }
        axios
          .get(
            "https://admin.akclasses.in/api/faculty_new_order?order_type=" +
              this.state.category +
              "&per_page=" +
              this.state.per_page
          )
          .then(resp => {
            // console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                fromInc: 1,
                active: 1,
                toInc: 5,
                newOrder: true,
                ord: false,
                userSearch: false,
                act: 0,
                secondaryCategories: resp.data.data,
                total: resp.data.total,
                from: resp.data.from,
                to: resp.data.to
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray
                });
              }
            );
          });
      }
    );
  };

  searchReport = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/get_online_order_datevise_for_faculty?order_type=COURSE" +
          "&frmdate=" +
          this.state.frmDate +
          "&todate=" +
          this.state.toDate +
          "&per_page=" +
          this.state.per_page +
          "&status=Order Placed"
      )
      .then(resp => {
        console.log(resp);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            empty: false,
            repButton: true,
            fromInc: 1,
            active: 1,
            toInc: 5,
            ord: false,
            newOrder: false,
            btnIdArray,
            userSearch: false,
            secondaryCategories: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              v => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray
            });
          }
        );
      });
  };

  handleInput = e => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        //console.log(this.state.logic);
      }
    );
  };

  handleSelect = (e, ordId, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        //console.log(this.state.delStatus);
        axios
          .post(
            "https://admin.akclasses.in/api/orderstatus?ordId=" +
              ordId +
              "&status=" +
              this.state.delStatus +
              "&id=" +
              id
          )
          .then(() => {
            //console.log(res);
            this.setState({
              notifyStatus: true
            });
            setTimeout(() => {
              this.setState({
                notifyStatus: false
              });
            }, 2000);
          });
      }
    );
  };

  currentPage = v => {
    let commonUrl = this.state.ord
      ? "https://admin.akclasses.in/api/faculty_orders?status=" +
        this.state.ordType +
        "&order_type=" +
        this.state.category +
        "&per_page=" +
        this.state.per_page +
        "&page="
      : this.state.newOrder
      ? "https://admin.akclasses.in/api/faculty_new_order?order_type=" +
        this.state.category +
        "&per_page=" +
        this.state.per_page +
        "&page="
      : this.state.userSearch
      ? "https://admin.akclasses.in/api/online_faculty_show_order?name=" +
        this.state.searchOrderId +
        "&per_page=" +
        this.state.per_page +
        "&page="
      : "https://admin.akclasses.in/api/get_online_order_datevise_for_faculty?order_type=COURSE" +
        "&frmdate=" +
        this.state.frmDate +
        "&todate=" +
        this.state.toDate +
        "&per_page=" +
        this.state.per_page + 
        "&status=Order Placed" +
        "&page=";
    axios.get(commonUrl + v).then(resp => {
      // console.log(resp.data.data);
      this.setState({
        total: resp.data.total,
        from: resp.data.from,
        to: resp.data.to,
        secondaryCategories: resp.data.data,
        active: v
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          v => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                v => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          v => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                v => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  handleModal = sec => {
    this.setState({
      product: sec.course ? sec.course.TITLE : "",
      orderId: sec.ORDER_ID,
      rowId: sec.ID,
      osType: sec.variant ? sec.variant.os_type.os_type : "",
      mediaType: sec.variant ? sec.variant.media_type.media_type : "",
      viewValidity: sec.variant
        ? sec.variant.view_and_validity.view_and_validity
        : "",
      delStatus: sec.DELIVERY_STATUS ? sec.DELIVERY_STATUS : "",
      address: sec.ADDRESS ? sec.ADDRESS : sec.user ? sec.user.ADDRESS_1 : "",
      pKey: sec.PRODUCT_KEY ? sec.PRODUCT_KEY : "",
      price: sec.GRAND_TOTAL ? sec.GRAND_TOTAL : "",
      student: sec.FIRST_NAME
        ? (sec.FIRST_NAME ? sec.FIRST_NAME : "") +
          " " +
          (sec.LAST_NAME ? sec.LAST_NAME : "")
        : sec.user
        ? (sec.user.FIRST_NAME ? sec.user.FIRST_NAME : "") +
          " " +
          (sec.user.LAST_NAME ? sec.user.LAST_NAME : "")
        : "",
      trackingNo: sec.TRACKING_NO ? sec.TRACKING_NO : "",
      tracLink: sec.logistic ? sec.logistic.tracking_link : "",
      courierCom: sec.logistic ? sec.logistic.name : "",
      shippingDate: sec.SHIPPING_DATE ? sec.SHIPPING_DATE : "",
      orderDate: sec.ORDER_DATE,
      notes: sec.NOTES ? sec.NOTES : "",
      payStatus: sec.PAYMENT_STATUS ? sec.PAYMENT_STATUS : "",
      pincode: sec.PINCODE ? sec.PINCODE : "",
      city: sec.CITY ? sec.CITY : "",
      state: sec.STATE ? sec.STATE : "",
      contact: sec.CONTACT_NO
        ? sec.CONTACT_NO
        : sec.user
        ? sec.user.MOBILE
        : "",
      productId: sec.course ? sec.course.id : ""
    });
    this.openModal();
  };

  handleDelete = id => {
    this.setState({ delLoading: true });
    axios
      .post("https://admin.akclasses.in/api/online_order/delete/" + id)
      .then(() => {
        this.setState({
          notifyDelete: true,
          delLoading: false
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true });
    axios
      .post(
        "https://admin.akclasses.in/api/online/orderNote/" + this.state.rowId,
        {
          note: this.state.notes
        }
      )
      .then(() => {
        //console.log(resp);
        this.setState({
          notifyNote: true,
          loading: false
        });
        setTimeout(() => {
          this.setState({ notifyNote: false });
          window.location.reload();
        }, 2000);
      });
  };

  addShipping = () => {
    this.setState({ loading: true });
    let payLoad = {
      tracking_no: this.state.trackingNo,
      prod_key: this.state.pKey,
      logistic: this.state.logic
    };
    axios
      .post(
        "https://admin.akclasses.in/api/add_onlineshiping/" + this.state.rowId,
        payLoad
      )
      .then(() => {
        //console.log(resp);
        this.closeModal();
        this.setState({
          notification: true,
          loading: false
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  };

  handleOrders = (btn, index) => {
    if (btn === "Order Placed") {
      axios
        .get(
          "https://admin.akclasses.in/api/faculty_new_order?order_type=" +
            this.state.category +
            "&per_page=" +
            this.state.per_page
        )
        .then(resp => {
          // console.log(resp.data);
          let btnIdArray = [];
          for (let i = 1; i <= resp.data.last_page; i++) {
            btnIdArray.push(i);
          }
          this.setState(
            {
              btnIdArray,
              fromInc: 1,
              active: 1,
              toInc: 5,
              empty: false,
              newOrder: true,
              ord: false,
              userSearch: false,
              act: index,
              secondaryCategories: resp.data.data,
              total: resp.data.total,
              from: resp.data.from,
              to: resp.data.to
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                v => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({
                filteredBtnIdArray
              });
            }
          );
        });
    } else {
      let value = btn === "Activated" || btn === "Extended" ? "Packaged" : btn;
      axios
        .get(
          "https://admin.akclasses.in/api/faculty_orders?status=" +
            value +
            "&order_type=" +
            this.state.category +
            "&per_page=" +
            this.state.per_page
        )
        .then(resp => {
          // console.log(resp);
          let btnIdArray = [];
          for (let i = 1; i <= resp.data.last_page; i++) {
            btnIdArray.push(i);
          }
          this.setState(
            {
              empty: false,
              fromInc: 1,
              active: 1,
              toInc: 5,
              ordType: value,
              userSearch: false,
              newOrder: false,
              ord: true,
              act: index,
              btnIdArray,
              secondaryCategories: resp.data.data,
              total: resp.data.total,
              from: resp.data.from,
              to: resp.data.to
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                v => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({
                filteredBtnIdArray
              });
            }
          );
        });
    }
  };
  searchOrderId = () => {
    axios
      .get(
        "https://admin.akclasses.in/api/online_faculty_show_order?name=" +
          this.state.searchOrderId +
          "&per_page=" +
          this.state.per_page
      )
      .then(resp => {
        // console.log(resp.data);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            btnIdArray,
            userSearch: true,
            fromInc: 1,
            active: 1,
            toInc: 5,
            ord: false,
            newOrder: false,
            secondaryCategories: resp.data.data,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              v => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray
            });
          }
        );
        // Object.size = obj => {
        //   let size = 0,
        //     key;
        //   for (key in obj) {
        //     if (obj.hasOwnProperty(key)) size++;
        //   }
        //   return size;
        // };

        // // Get the size of an object
        // let size = Object.size(resp.data);
        // if (size > 0) {
        //   let array1 = [];
        //   array1.push(resp.data);
        //   this.setState({
        //     searchOrderId: "",
        //     empty: true,
        //     secondaryCategories: array1
        //   });
        // } else {
        //   this.setState({
        //     searchOrderId: "",
        //     empty: true,
        //     secondaryCategories: []
        //   });
        // }
      });
  };
  render() {
    return (
      <div>
        <GridContainer>
          {this.state.notification ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Shipping Added Successfully!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}

          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <FormControl
                  style={{
                    position: "absolute",
                    right: "10%",
                    width: "85px"
                  }}
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    s
                    key={1}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={this.state.per_page}
                    inputProps={{
                      name: "per_page",
                      id: "simple-select",
                      onChange: e => {
                        this.handlePage(e);
                      }
                    }}
                  >
                    {perPages.map((v, i) => (
                      <MenuItem
                        key={i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={v}
                      >
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <GridItem xs={12} sm={12} md={2}>
                  <FormControl
                    style={{ marginTop: "12px" }}
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Type
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={this.state.category}
                      inputProps={{
                        name: "category",
                        id: "simple-select",
                        onChange: e => {
                          this.handleCategory(e);
                        }
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="Course"
                      >
                        Course
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="Test_Series"
                      >
                        Test Series
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="Books"
                      >
                        Books
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="Extend_Validity"
                      >
                        Extension
                      </MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <br />
                <label
                  style={{
                    marginLeft: "12px",
                    color: "black",
                    fontSize: "17px"
                  }}
                >
                  Filter The Orders
                </label>
                <br />
                <br />
                <label
                  style={{ color: "black", position: "relative", left: "10px" }}
                >
                  By Attempt
                </label>
                <br />
                {this.state.orders.map((btn, index) => (
                  <Button
                    key={index}
                    id={btn}
                    style={{ marginRight: "10px", marginLeft: "10px" }}
                    color={this.state.act === index ? "danger " : "info"}
                    onClick={() => {
                      this.handleOrders(btn, index);
                    }}
                  >
                    {btn}
                  </Button>
                ))}
                <br />
                <GridContainer style={{ position: "relative", left: "10px" }}>
                  <GridItem xs={12} sm={12} md={3}>
                    <br />
                    <label
                      style={{
                        color: "black"
                      }}
                    >
                      By Date
                    </label>
                    <br />
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        onChange={e => {
                          let year = e._d.getFullYear().toString();
                          let month = (e._d.getMonth() + 101)
                            .toString()
                            .substring(1);
                          let day = (e._d.getDate() + 100)
                            .toString()
                            .substring(1);
                          this.setState(
                            {
                              frmDate: year + "/" + month + "/" + day
                            },
                            () => {
                              //console.log(this.state.frmDate);
                            }
                          );
                        }}
                        inputProps={{
                          placeholder: "From Date"
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <br />
                    <br />
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        onChange={e => {
                          let year = e._d.getFullYear().toString();
                          let month = (e._d.getMonth() + 101)
                            .toString()
                            .substring(1);
                          let day = (e._d.getDate() + 100)
                            .toString()
                            .substring(1);
                          this.setState(
                            {
                              toDate: year + "/" + month + "/" + day
                            },
                            () => {
                              //console.log(this.state.toDate);
                            }
                          );
                        }}
                        inputProps={{
                          placeholder: "To Date"
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <br />
                    <Button
                      style={{
                        position: "relative",
                        top: "13px",
                        right: "20px"
                      }}
                      onClick={this.searchReport}
                      color="success"
                    >
                      Search
                    </Button>
                  </GridItem>
                  {this.state.repButton ? (
                    <GridItem xs={12} sm={12} md={2}>
                      <br />
                      <a
                        href={
                          "https://admin.akclasses.in/api/order_excel_report?frmdate=" +
                          this.state.frmDate +
                          "&todate=" +
                          this.state.toDate
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          style={{
                            position: "relative",
                            top: "13px",
                            right: "60px"
                          }}
                          onClick={() => {
                            this.setState({ repButton: false });
                          }}
                          color="danger"
                        >
                          Download
                        </Button>
                      </a>
                    </GridItem>
                  ) : null}
                </GridContainer>
                <GridContainer style={{ position: "relative", left: "10px" }}>
                  <GridItem xs={12} sm={12} md={3}>
                    <br />
                    <label
                      style={{
                        color: "black"
                      }}
                    >
                      By User Name
                    </label>
                    <br />
                    <CustomInput
                      style={{
                        marginLeft: "30px"
                      }}
                      labelText="User Name"
                      id="float"
                      inputProps={{
                        required: true,
                        value: this.state.searchOrderId,
                        onChange: e =>
                          this.setState({ searchOrderId: e.target.value })
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <br />
                    <Button
                      style={{
                        position: "relative",
                        top: "35px",
                        right: "15px"
                      }}
                      onClick={this.searchOrderId}
                      color="success"
                    >
                      Search
                    </Button>
                  </GridItem>
                </GridContainer>
                <h3>Online Orders</h3>
                <Table
                  tableHead={
                    this.state.category === "Extend_Validity"
                      ? [
                          "Sr No.",
                          "Order No",
                          <div key={1} style={{ width: "70px" }}>
                            Order Date
                          </div>,
                          <div key={1} style={{ width: "150px" }}>
                            Product Details
                          </div>,
                          "Student Details",
                          "Grand Total",
                          "Shipping Details",
                          "Actions"
                        ]
                      : [
                          "Sr No.",
                          "Order No",
                          <div key={1} style={{ width: "70px" }}>
                            Order Date
                          </div>,
                          <div key={1} style={{ width: "150px" }}>
                            Product Details
                          </div>,
                          "Student Details",
                          "Grand Total",
                          "Product Key Extended",
                          "Shipping Details",
                          "Actions"
                        ]
                  }
                  tableData={
                    this.state.category === "Extend_Validity"
                      ? this.state.secondaryCategories.map((sec, index) => [
                          [index + 1],
                          [sec.ORDER_ID],
                          [
                            sec.ADDED_DATE.slice(8, 10) +
                              "-" +
                              sec.ADDED_DATE.slice(5, 7) +
                              "-" +
                              sec.ADDED_DATE.slice(0, 4)
                          ],
                          [sec.course ? sec.course.TITLE : "NA"],
                          [
                            <div key={index}>
                              <span>
                                {sec.FIRST_NAME
                                  ? (sec.FIRST_NAME ? sec.FIRST_NAME : "") +
                                    " " +
                                    (sec.LAST_NAME ? sec.LAST_NAME : "")
                                  : sec.user
                                  ? (sec.user.FIRST_NAME
                                      ? sec.user.FIRST_NAME
                                      : "") +
                                    " " +
                                    (sec.user.LAST_NAME
                                      ? sec.user.LAST_NAME
                                      : "")
                                  : "NA"}
                                ,
                              </span>
                              <br />
                              <span>
                                {sec.EMAIL
                                  ? sec.EMAIL
                                  : sec.user
                                  ? sec.user.email
                                  : "NA"}
                                ,
                              </span>
                              <br />
                              <span>
                                {sec.CONTACT_NO
                                  ? sec.CONTACT_NO
                                  : sec.user
                                  ? sec.user.MOBILE
                                  : "NA"}
                                ,
                              </span>
                              <br />
                              <span>
                                {sec.ADDRESS
                                  ? sec.ADDRESS
                                  : sec.user
                                  ? sec.user.ADDRESS_1
                                  : "NA"}
                              </span>
                            </div>
                          ],
                          [sec.GRAND_TOTAL ? sec.GRAND_TOTAL : "NA"],
                          [
                            sec.DELIVERY_STATUS === "Order Placed" ? (
                              <Button
                                key={index}
                                color="danger"
                                onClick={() => {
                                  this.handleModal(sec);
                                }}
                              >
                                <i
                                  style={{
                                    color: "white",
                                    fontSize: "18px",
                                    margin: "0px"
                                  }}
                                  className="material-icons"
                                >
                                  &#xe558;
                                </i>
                              </Button>
                            ) : sec.DELIVERY_STATUS === "Shipped" ? (
                              <Button
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    proDetail: false,
                                    logDetail: true,
                                    print: false,
                                    delModal: false,
                                    comment: false
                                  });
                                }}
                                key={index}
                                color="success"
                              >
                                Shipped
                              </Button>
                            ) : sec.DELIVERY_STATUS === "Packaged" ? (
                              <Button
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                }}
                                key={index}
                                color="success"
                              >
                                {this.state.category === "Extend_Validity"
                                  ? "Extended"
                                  : "Activated"}
                              </Button>
                            ) : (
                              <Button
                                key={index}
                                color={
                                  sec.DELIVERY_STATUS === "Cancelled"
                                    ? "danger"
                                    : "success"
                                }
                              >
                                {sec.DELIVERY_STATUS}
                              </Button>
                            )
                          ],
                          [
                            <span key={1}>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    logDetail: false,
                                    print: false,
                                    delModal: false,
                                    proDetail: true,
                                    comment: false
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe417;</i>
                              </span>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    comment: true,
                                    delModal: false,
                                    logDetail: false,
                                    print: false,
                                    proDetail: false
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe0b9;</i>
                              </span>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    comment: false,
                                    logDetail: false,
                                    delModal: false,
                                    print: true,
                                    proDetail: false
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe8ad;</i>
                              </span>
                              <span
                                style={{ cursor: "pointer", color: "red" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    comment: false,
                                    print: false,
                                    logDetail: false,
                                    delModal: true,
                                    proDetail: false
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe5c9;</i>
                              </span>
                            </span>
                          ]
                        ])
                      : this.state.secondaryCategories.map((sec, index) => [
                          [index + 1],
                          [sec.ORDER_ID],
                          [
                            sec.ADDED_DATE.slice(8, 10) +
                              "-" +
                              sec.ADDED_DATE.slice(5, 7) +
                              "-" +
                              sec.ADDED_DATE.slice(0, 4)
                          ],
                          [sec.course ? sec.course.TITLE : "NA"],
                          [
                            <div key={index}>
                              <span>
                                {sec.FIRST_NAME
                                  ? (sec.FIRST_NAME ? sec.FIRST_NAME : "") +
                                    " " +
                                    (sec.LAST_NAME ? sec.LAST_NAME : "")
                                  : sec.user
                                  ? (sec.user.FIRST_NAME
                                      ? sec.user.FIRST_NAME
                                      : "") +
                                    " " +
                                    (sec.user.LAST_NAME
                                      ? sec.user.LAST_NAME
                                      : "")
                                  : "NA"}
                                ,
                              </span>
                              <br />
                              <span>
                                {sec.EMAIL
                                  ? sec.EMAIL
                                  : sec.user
                                  ? sec.user.email
                                  : "NA"}
                                ,
                              </span>
                              <br />
                              <span>
                                {sec.CONTACT_NO
                                  ? sec.CONTACT_NO
                                  : sec.user
                                  ? sec.user.MOBILE
                                  : "NA"}
                                ,
                              </span>
                              <br />
                              <span>
                                {sec.ADDRESS
                                  ? sec.ADDRESS
                                  : sec.user
                                  ? sec.user.ADDRESS_1
                                  : "NA"}
                              </span>
                            </div>
                          ],
                          [sec.GRAND_TOTAL ? sec.GRAND_TOTAL : "NA"],
                          [sec.PRODUCT_EXTENDED],
                          [
                            sec.DELIVERY_STATUS === "Order Placed" ? (
                              <Button
                                key={index}
                                color="danger"
                                onClick={() => {
                                  this.handleModal(sec);
                                }}
                              >
                                <i
                                  style={{
                                    color: "white",
                                    fontSize: "18px",
                                    margin: "0px"
                                  }}
                                  className="material-icons"
                                >
                                  &#xe558;
                                </i>
                              </Button>
                            ) : sec.DELIVERY_STATUS === "Shipped" ? (
                              <Button
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    proDetail: false,
                                    logDetail: true,
                                    print: false,
                                    delModal: false,
                                    comment: false
                                  });
                                }}
                                key={index}
                                color="success"
                              >
                                Shipped
                              </Button>
                            ) : sec.DELIVERY_STATUS === "Packaged" ? (
                              <Button
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                }}
                                key={index}
                                color="success"
                              >
                                {this.state.category === "Extend_Validity"
                                  ? "Extended"
                                  : "Activated"}
                              </Button>
                            ) : (
                              <Button
                                key={index}
                                color={
                                  sec.DELIVERY_STATUS === "Cancelled"
                                    ? "danger"
                                    : "success"
                                }
                              >
                                {sec.DELIVERY_STATUS}
                              </Button>
                            )
                          ],
                          [
                            <span key={1}>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    logDetail: false,
                                    print: false,
                                    delModal: false,
                                    proDetail: true,
                                    comment: false
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe417;</i>
                              </span>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    comment: true,
                                    delModal: false,
                                    logDetail: false,
                                    print: false,
                                    proDetail: false
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe0b9;</i>
                              </span>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    comment: false,
                                    logDetail: false,
                                    delModal: false,
                                    print: true,
                                    proDetail: false
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe8ad;</i>
                              </span>
                              <span
                                style={{ cursor: "pointer", color: "red" }}
                                onClick={() => {
                                  this.handleModal(sec);
                                  this.setState({
                                    visible: true,
                                    comment: false,
                                    print: false,
                                    logDetail: false,
                                    delModal: true,
                                    proDetail: false
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe5c9;</i>
                              </span>
                            </span>
                          ]
                        ])
                  }
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Modal
          visible={this.state.visible}
          width="1000"
          height="330"
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                {/* <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4>Stacked Form</h4>
                </CardHeader> */}
                <CardBody>
                  {this.state.proDetail ? (
                    <div>
                      <h3>Product Details</h3>
                      <Table
                        tableHead={[
                          "Product Name",
                          "Price",
                          "Product Key",
                          "OS Type",
                          "Media Type",
                          "Views & Validity",
                          // "Tracking No.",
                          // "Shipping Date",
                          "Payment Status",
                          "Delivery Status"
                        ]}
                        tableData={[
                          [
                            this.state.product,
                            this.state.price,
                            this.state.pKey,
                            this.state.osType,
                            this.state.mediaType,
                            this.state.viewValidity,
                            // this.state.trackingNo,
                            // this.state.shippingDate,
                            this.state.payStatus,
                            this.state.delStatus
                          ]
                        ]}
                      />
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.print ? (
                    <div>
                      <div>
                        <h6 style={{ marginBottom: "0px" }}>To,</h6>
                        <span>{this.state.student}</span>
                        <br />
                        <span>{this.state.address}</span>
                        <br />
                        <span>
                          {this.state.city},{this.state.state},
                          {this.state.pincode}
                        </span>
                        <br />
                        <span>{this.state.contact}</span>
                      </div>
                      <br />
                      <div>
                        <h6 style={{ marginBottom: "0px" }}>From,</h6>
                        <span>
                          {this.state.facultyData.FIRST_NAME}
                          ,
                          <br />
                          {this.state.facultyData.ADDRESS_1}
                        </span>
                        <br />
                        <span>{this.state.facultyData.ADDRESS_2}</span>
                        <br />
                        <span>Mobile: {this.state.facultyData.MOBILE}</span>
                      </div>
                      {this.state.printView ? (
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({ printView: false });
                                setTimeout(() => {
                                  window.print();
                                }, 1);
                                setTimeout(() => {
                                  this.setState({ printView: true });
                                }, 2);
                              }}
                            >
                              Print
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.closeModal();
                              }}
                            >
                              Cancel
                            </Button>
                          </GridItem>
                        </GridContainer>
                      ) : null}
                    </div>
                  ) : this.state.comment ? (
                    <div>
                      <h3>Add Note</h3>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Note"
                            id="float"
                            inputProps={{
                              required: true,
                              value: this.state.notes,
                              onChange: e =>
                                this.setState({ notes: e.target.value })
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                          {this.state.notifyNote ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Note Added Successfuly!
                              </span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={this.handleSubmit}
                          >
                            {this.state.loading ? <Loader /> : "Add"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.delModal ? (
                    <div>
                      <h3>Are You Sure You Want to Delete It?</h3>
                      {this.state.notifyDelete ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Order Deleted Successfuly!
                          </span>
                        </div>
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={() => {
                              this.handleDelete(this.state.rowId);
                            }}
                          >
                            {this.state.delLoading ? <Loader /> : "Yes"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            No
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.logDetail ? (
                    <div>
                      <h3>Shipping Details</h3>
                      <Table
                        tableHead={[
                          "Courier Company",
                          "Tracking No.",
                          "Track Now"
                        ]}
                        tableData={[
                          [
                            this.state.courierCom,
                            this.state.trackingNo,
                            <a
                              key={1}
                              href={this.state.tracLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button color="danger">
                                <i
                                  style={{
                                    color: "white",
                                    fontSize: "18px",
                                    margin: "0px"
                                  }}
                                  className="material-icons"
                                >
                                  &#xe558;
                                </i>
                              </Button>
                            </a>
                          ]
                        ]}
                      />
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : (
                    <form onSubmit={this.handleSubmit}>
                      <h4 style={{ textAlign: "center" }}>
                        Order ID:{this.state.orderId}
                      </h4>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Product Name :" + this.state.product}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={6} sm={12} md={6}>
                          <CustomInput
                            labelText={"Student Name :" + this.state.student}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Address :" + this.state.address}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Producy Key :" + this.state.pKey}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl
                            // style={{ marginTop: "12px" }}
                            fullWidth
                            // className={classes.selectFormControl}
                          >
                            <label
                            // htmlFor="simple-select"
                            // className={classes.selectLabel}
                            >
                              Logistics
                            </label>
                            <select
                              style={{ padding: "11px" }}
                              name="logic"
                              onChange={this.handleInput}
                            >
                              <option value="">Select</option>
                              {this.state.logistics.map((v, i) => (
                                <option key={i} value={v.id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <label>Order Status</label>
                            <select
                              style={{ padding: "11px" }}
                              name="delStatus"
                              value={this.state.delStatus}
                              onChange={e => {
                                this.handleSelect(
                                  e,
                                  this.state.orderId,
                                  this.state.rowId
                                );
                              }}
                            >
                              <option value="">Select</option>
                              {this.state.orders.map((v, i) => (
                                <option
                                  key={i}
                                  value={
                                    v === "Activated" || v === "Extended"
                                      ? "Packaged"
                                      : v
                                  }
                                >
                                  {v}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                          {this.state.notifyStatus ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Status Changed Successfuly!
                              </span>
                            </div>
                          ) : null}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Tracking ID"
                            id="float"
                            inputProps={{
                              disabled: false,
                              onChange: e =>
                                this.setState({ trackingNo: e.target.value })
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Product Key"
                            id="float"
                            inputProps={{
                              disabled: false,
                              onChange: e =>
                                this.setState({ pKey: e.target.value })
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <Button
                            className=""
                            color="warning"
                            onClick={this.addShipping}
                          >
                            {this.state.loading ? (
                              <Loader />
                            ) : (
                              <i
                                style={{
                                  color: "white",
                                  fontSize: "18px",
                                  margin: "0px"
                                }}
                                className="material-icons"
                              >
                                &#xe558;
                              </i>
                            )}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            <i
                              style={{
                                color: "white",
                                fontSize: "18px",
                                margin: "0px"
                              }}
                              className="material-icons"
                            >
                              &#xe5c9;
                            </i>
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </form>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Modal>
        {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : this.state.empty === false ? (
          <div>
            <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div>
            <div style={{ float: "right", border: "1px solid grey" }}>
              <div
                onClick={this.prevPages}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey"
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div>
              {this.state.filteredBtnIdArray.map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(v);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === v ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === v ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black"
                  }}
                >
                  {v}
                </div>
              ))}
              <div
                onClick={this.nextPages}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.filteredBtnIdArray.length === 2 ||
                    this.state.filteredBtnIdArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey"
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c8;
                </i>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default FacultyOnlineOrders;
