/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
// @material-ui/core components
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Constants from "variables/Constants";
const classes = makeStyles(styles);
let fd = new FormData();

class UpdateVendorOfflineOrder extends Component {
  state = {
    tran: "",
    cusName: "",
    orderDate: "",
    contact: "",
    email: "",
    add1: "",
    add2: "",
    level: "",
    stream: "",
    levels: [],
    categories: [],
    category: "",
    streams: [],
    city: "",
    orderId: "",
    state: "",
    exMode: "",
    country: "India",
    pincode: "",
    delStatus: "",
    payStatus: "",
    akShare: 0,
    shipDate: "",
    ordType: "",
    products: [],
    exModes: [],
    variants: [],
    mediaTypes: [],
    osTypes: [],
    views: [],
    delivery: ["Order Placed", "Packaged", "Shipped", "Delivered", "Cancelled"],
    payment: ["Collected", "Not_Collected"],
    vendors: [],
    trackingNo: "",
    total: "",
    productId: "",
    mediaId: "",
    osId: "",
    viewId: "",
    reference: "",
    max: false,
    variantId: "",
    redirect: false,
    showVariant: false,
    showProduct: false,
    notification: false,
    aAlert: false,
    bAlert: false,
    cAlert: false,
    dAlert: false,
    eAlert: false,
    fAlert: false,
    gAlert: false,
    hAlert: false,
    loading: false
  };
  componentDidMount() {
    let categoryData = this.props.location.state.categoryData;
    // console.log(categoryData);
      axios
        // .get("https://admin.akclasses.in/api/coursesR?type=COURSES")
        .get(Constants.getUrls.get_product_by_stream + "?per_page=1000")
        .then(resp => {
          // console.log(resp.data);
          this.setState(
            {
              exMode: categoryData.MODE_ID,
              products: resp.data.data,
              ordType: "COURSE",
              showProduct: true,
              showVariant: true,
              tran: categoryData.TRANSACTION_ID,
              cusName: categoryData.NAME,
              orderDate: categoryData.ORDER_DATE,
              contact: categoryData.CONTACT_NO,
              email: categoryData.EMAIL,
              akShare: categoryData.AK_SHARE,
              add1: categoryData.ADDRESS,
              add2: categoryData.ADDRESS_2,
              city: categoryData.CITY,
              orderId: categoryData.ORDER_ID,
              state: categoryData.STATE,
              country: categoryData.COUNTRY,
              pincode: categoryData.PINCODE,
              delStatus: categoryData.DELIVERY_STATUS,
              payStatus: categoryData.PAYMENT_STATUS,
              shipDate: categoryData.SHIPPING_DATE,
              trackingNo: categoryData.TRACKING_NO,
              variantId: categoryData.variant ? categoryData.variant.id : "",
              total: categoryData.GRAND_TOTAL ? categoryData.GRAND_TOTAL : "",
              osId: categoryData.variant ? categoryData.variant.os_type_id : "",
              mediaId: categoryData.variant ? categoryData.variant.media_type_id : "",
              viewId: categoryData.variant ? categoryData.variant.view_and_validity_id : "",
              productId: categoryData.course ? categoryData.course.ID : "",
              category: categoryData.course.CATEGORY_ID,
              level: categoryData.course.LEVEL_ID,
              stream: categoryData.course.STREAM_ID,
              reference: categoryData.vender_list
                ? categoryData.vender_list.id
                : ""
            },
            () => {
              if (this.state.productId !== "") {
                axios
                  .get(
                    "https://admin.akclasses.in/api/course/" +
                    this.state.productId
                  )
                  .then(resp => {
                    // console.log(resp.data);
                    this.setState(
                      {
                        variants: resp.data.product.variant
                      },
                      () => {
                        let osIds = [];
                        let osTypes = [];
                        this.state.variants.forEach(v => {
                          if (osIds.includes(v.os_type.id) === false) {
                            osIds.push(v.os_type.id);
                            osTypes.push(v.os_type);
                          }
                        });
                        //console.log(osTypes);
                        this.setState({ osTypes });
                        let mediaTypes = [];
                        let mediaIds = [];
                        this.state.variants.forEach(v => {
                          if (mediaIds.includes(v.media_type.id) === false) {
                            if (v.os_type_id === this.state.osId) {
                              mediaIds.push(v.media_type.id);
                              mediaTypes.push(v.media_type);
                            }
                          }
                        });
                        //console.log(mediaTypes);
                        this.setState({ mediaTypes });
                        let viewIds = [];
                        let views = [];
                        this.state.variants.forEach(v => {
                          if (
                            viewIds.includes(v.view_and_validity.id) === false
                          ) {
                            if (
                              v.os_type_id === this.state.osId &&
                              v.media_type_id === this.state.mediaId
                            ) {
                              viewIds.push(v.view_and_validity.id);
                              views.push(v.view_and_validity);
                            }
                          }
                        });
                        //console.log(views);
                        this.setState({ views });
                      }
                    );
                  });
              }
            }
          );
        });
  }
  handleLevel = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        streams: []
      },
      () => {
        //console.log(this.state.category);
        axios
          .get(
            "https://admin.akclasses.in/api/showCategory_with_level/" +
            this.state.category
          )
          .then(resp => {
            //console.log(resp.data);
            this.setState({
              levels: resp.data.level,
              level: ""
            });
          });
      }
    );
  };
  searchRecord = () => {
    axios
      .get(
        // "https://admin.akclasses.in/api/get_by_cate_levl_strm?per_page=1000&category_id=" +
        Constants.getUrls.get_product_by_stream + "?per_page=1000&category_id=" +
        this.state.category +
        "&level_id=" +
        this.state.level +
        "&stream_id=" +
        this.state.stream
      )
      .then(resp => {
        // console.log(resp.data.data);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
        let sequenceArray = [];
        resp.data.data.forEach(v => {
          sequenceArray.push(parseInt(v.SEQUENCE));
        });
        let arr = [];
        resp.data.data.forEach(v => {
          arr.push({ course: v });
        });
        this.setState(
          {
            ord: false,
            btnIdArray,
            sequenceArray,
            products: arr,
            fromInc: 1,
            active: 1,
            toInc: 5,
            // total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              v => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray
            });
          }
        );
      });
  };
  handleStream = e => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        //console.log(this.state.level);
        axios
          .get("https://admin.akclasses.in/api/level/" + this.state.level)
          .then(resp => {
            //console.log(resp.data);
            this.setState({
              streams: resp.data.stream,
              stream: ""
            });
          });
      }
    );
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.max === true) {
      this.setState({ max: true });
    } else {
      fd.append("tx_id", "");
      fd.append("name", this.state.cusName);
      fd.append("contact_no", this.state.contact);
      fd.append("email", this.state.email);
      fd.append("address", this.state.add1);
      fd.append("address_2", "");
      fd.append("city", this.state.city);
      fd.append("state", this.state.state);
      fd.append("country", "");
      fd.append("pincode", this.state.pincode);
      //   fd.append("delivery_status", this.state.delStatus);
      //   fd.append("payment_status", this.state.payStatus);
      fd.append("shipping_date", "");
      fd.append("product_id", this.state.productId);
      //   fd.append("vender", this.state.reference);
      fd.append("shipping", "");
      fd.append("grand_total", this.state.total);
      fd.append("ordID", this.state.orderId);
      fd.append("order_type", this.state.ordType);
      fd.append("exam_given", "no");
      fd.append("order_date", this.state.orderDate);
      fd.append("media_type_id", this.state.mediaId);
      fd.append("os_type_id", this.state.osId);
      //   fd.append("ak_share", this.state.akShare);
      //   fd.append("mode", this.state.exMode);
      fd.append("variant_id", this.state.variantId);
      fd.append("view_and_validity_id", this.state.viewId);
      //   if (this.state.delStatus === "") {
      //     this.setState({
      //       aAlert: true
      //     });
      //   } else if (this.state.payStatus === "") {
      //     this.setState({
      //       bAlert: true
      //     });
      //   } else
      if (this.state.productId === "") {
        this.setState({
          cAlert: true
        });
      }
      //    else if (this.state.mediaId === "") {
      //     this.setState({
      //       dAlert: true
      //     });
      //   } else if (this.state.osId === "") {
      //     this.setState({
      //       eAlert: true
      //     });
      //   } else if (this.state.viewId === "") {
      //     this.setState({
      //       fAlert: true
      //     });
      //   } else if (this.state.reference === "") {
      //     this.setState({
      //       gAlert: true
      //     });
      //   } else if (this.state.exMode === "") {
      //     this.setState({
      //       hAlert: true
      //     });
      //   }
      else {
        this.setState({
          loading: true
        });
        let categoryData = this.props.location.state.categoryData;
        axios
          .post(
            "https://admin.akclasses.in/api/update_vendor_offline_add/" +
            categoryData.id,
            fd
          )
          .then(() => {
            // console.log(resp);
            // localStorage.setItem("category", "Course");
            setTimeout(() => {
              this.setState({
                redirect: true
              });
            }, 1000);
            this.setState({
              notification: true,
              loading: false
            });
          });
      }
    }
  };
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleProducts = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        cAlert: false
      },
      () => {
        axios
          .get("https://admin.akclasses.in/api/course/" + this.state.productId)
          .then(resp => {
            // console.log(resp.data);
            this.setState(
              {
                variants: resp.data.product.variant
              },
              () => {
                this.setState(
                  {
                    mediaId: this.state.variants[0]
                      ? this.state.variants[0].media_type_id
                      : "",
                    osId: this.state.variants[0]
                      ? this.state.variants[0].os_type_id
                      : "",
                    viewId: this.state.variants[0]
                      ? this.state.variants[0].view_and_validity_id
                      : "",
                    variantId: this.state.variants[0]
                      ? this.state.variants[0].id
                      : "",
                    total: this.state.variants[0]
                      ? this.state.variants[0].offer_price
                      : 0
                  },
                  () => {
                    let osIds = [];
                    let osTypes = [];
                    this.state.variants.forEach(v => {
                      if (osIds.includes(v.os_type.id) === false) {
                        osIds.push(v.os_type.id);
                        osTypes.push(v.os_type);
                      }
                    });
                    //console.log(osTypes);
                    this.setState({ osTypes });
                    let mediaTypes = [];
                    let mediaIds = [];
                    this.state.variants.forEach(v => {
                      if (mediaIds.includes(v.media_type.id) === false) {
                        if (v.os_type_id === this.state.osId) {
                          mediaIds.push(v.media_type.id);
                          mediaTypes.push(v.media_type);
                        }
                      }
                    });
                    //console.log(mediaTypes);
                    this.setState({ mediaTypes });
                    let viewIds = [];
                    let views = [];
                    this.state.variants.forEach(v => {
                      if (viewIds.includes(v.view_and_validity.id) === false) {
                        if (
                          v.os_type_id === this.state.osId &&
                          v.media_type_id === this.state.mediaId
                        ) {
                          viewIds.push(v.view_and_validity.id);
                          views.push(v.view_and_validity);
                        }
                      }
                    });
                    //console.log(views);
                    this.setState({ views });
                  }
                );
              }
            );
          });
      }
    );
    // //console.log(e.target.value);
  };
  handleOsType = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        eAlert: false
      },
      () => {
        let mediaTypes = [];
        let mediaIds = [];
        this.state.variants.forEach(v => {
          if (mediaIds.includes(v.media_type.id) === false) {
            if (v.os_type_id === this.state.osId) {
              mediaIds.push(v.media_type.id);
              mediaTypes.push(v.media_type);
            }
          }
        });
        //console.log(mediaTypes);
        this.setState({ mediaTypes }, () => {
          this.setState(
            {
              mediaId: this.state.mediaTypes[0]
                ? this.state.mediaTypes[0].id
                : ""
            },
            () => {
              let viewIds = [];
              let views = [];
              this.state.variants.forEach(v => {
                if (viewIds.includes(v.view_and_validity.id) === false) {
                  if (
                    v.os_type_id === this.state.osId &&
                    v.media_type_id === this.state.mediaId
                  ) {
                    viewIds.push(v.view_and_validity.id);
                    views.push(v.view_and_validity);
                  }
                }
              });
              //console.log(views);
              this.setState({ views }, () => {
                this.setState(
                  {
                    viewId: this.state.views[0] ? this.state.views[0].id : ""
                  },
                  () => {
                    let requiredVariant = this.state.variants.find(
                      v =>
                        v.os_type_id === this.state.osId &&
                        v.media_type_id === this.state.mediaId &&
                        v.view_and_validity_id === this.state.viewId
                    );
                    // console.log(requiredVariant);
                    this.setState({
                      variantId: requiredVariant ? requiredVariant.id : "",
                      total: requiredVariant ? requiredVariant.offer_price : 0
                    });
                  }
                );
              });
            }
          );
        });
      }
    );
  };
  handleMediaType = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        dAlert: false
      },
      () => {
        let viewIds = [];
        let views = [];
        this.state.variants.forEach(v => {
          if (viewIds.includes(v.view_and_validity.id) === false) {
            if (
              v.os_type_id === this.state.osId &&
              v.media_type_id === this.state.mediaId
            ) {
              viewIds.push(v.view_and_validity.id);
              views.push(v.view_and_validity);
            }
          }
        });
        //console.log(views);
        this.setState({ views }, () => {
          this.setState(
            {
              viewId: this.state.views[0] ? this.state.views[0].id : ""
            },
            () => {
              let requiredVariant = this.state.variants.find(
                v =>
                  v.os_type_id === this.state.osId &&
                  v.media_type_id === this.state.mediaId &&
                  v.view_and_validity_id === this.state.viewId
              );
              // console.log(requiredVariant);
              this.setState({
                variantId: requiredVariant ? requiredVariant.id : "",
                total: requiredVariant ? requiredVariant.offer_price : 0
              });
            }
          );
        });
      }
    );
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/vendorofflineorders"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Order Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Vendor Offline Order</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText={"Order ID: " + this.state.orderId}
                          id="disabled"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true
                          }}
                        />
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Transaction Details"
                          id="float"
                          inputProps={{
                            value: this.state.tran,
                            onChange: e =>
                              this.setState({ tran: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={6}>
                        <label>Order Date</label>
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={e => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  orderDate: year + "/" + month + "/" + day
                                },
                                () => {
                                  //console.log(this.state.orderDate);
                                }
                              );
                            }}
                            inputProps={{
                              value: this.state.orderDate,
                              required: true,
                              placeholder: "Order Date"
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Customer Name"
                          id="float"
                          inputProps={{
                            value: this.state.cusName,
                            required: true,
                            onChange: e =>
                              this.setState({ cusName: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Customer Contact#"
                          id="float"
                          inputProps={{
                            type: "number",
                            value: this.state.contact,
                            required: true,
                            onChange: e =>
                              this.setState({ contact: e.target.value }, () => {
                                if (this.state.contact.length > 10) {
                                  this.setState({ max: true });
                                } else {
                                  this.setState({ max: false });
                                }
                              })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                        {this.state.max ? (
                          <div>
                            <span style={{ color: "red" }}>
                              Max Allowed Characters : 10
                            </span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Customer Email"
                          id="float"
                          inputProps={{
                            type: "email",
                            value: this.state.email,
                            required: true,
                            onChange: e =>
                              this.setState({ email: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Street Address(House number and Street name*)"
                          id="float"
                          inputProps={{
                            required: true,
                            value: this.state.add1,
                            onChange: e =>
                              this.setState({ add1: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      {/*  <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Street Address(optional)"
                          id="float"
                          inputProps={{
                            onChange: e =>
                              this.setState({ add2: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="City"
                          id="float"
                          inputProps={{
                            value: this.state.city,
                            required: true,
                            onChange: e =>
                              this.setState({ city: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="State"
                          id="float"
                          inputProps={{
                            required: true,
                            value: this.state.state,
                            onChange: e =>
                              this.setState({ state: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Ak Share"
                          id="float"
                          inputProps={{
                            required: true,
                            type: "number",
                            onChange: e =>
                              this.setState({ akShare: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Postcode/ZIP"
                          id="float"
                          inputProps={{
                            value: this.state.pincode,
                            required: true,
                            onChange: e =>
                              this.setState({ pincode: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={3}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Type
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.ordType}
                            inputProps={{
                              name: "ordType",
                              id: "simple-select",
                              onChange: e => {
                                this.setState(
                                  {
                                    ordType: e.target.value
                                  },
                                  () => {
                                    if (this.state.ordType === "") {
                                      this.setState({
                                        showProduct: false,
                                        showVariant: false
                                      });
                                    } else if (
                                      this.state.ordType === "COURSE"
                                    ) {
                                      axios
                                        .get(
                                          "https://admin.akclasses.in/api/courseCategoriesR"
                                        )
                                        .then(resp => {
                                          // console.log(resp);
                                          this.setState({
                                            categories: resp.data,
                                            levels: [],
                                            level: "",
                                            total: "",
                                            streams: [],
                                            stream: ""
                                          });
                                        });
                                      axios
                                        .get(
                                          "https://admin.akclasses.in/api/coursesR?type=COURSES"
                                        )
                                        .then(resp => {
                                          // console.log(resp.data);
                                          // let filtered = resp.data.filter(v=> v)
                                          this.setState({
                                            products: resp.data,
                                            showProduct: true,
                                            showVariant: true
                                          });
                                        });
                                    } else if (
                                      this.state.ordType === "TEST_SERIES"
                                    ) {
                                      axios
                                        .get(
                                          "https://admin.akclasses.in/api/testCategoriesR"
                                        )
                                        .then(resp => {
                                          //console.log(resp.data);
                                          this.setState({
                                            categories: resp.data,
                                            levels: [],
                                            level: "",
                                            streams: [],
                                            stream: ""
                                          });
                                        });
                                      axios
                                        .get(
                                          "https://admin.akclasses.in/api/coursesR?type=" +
                                          this.state.ordType
                                        )
                                        .then(resp => {
                                          // console.log(resp.data);
                                          // let filtered = resp.data.filter(v=> v)
                                          this.setState({
                                            products: resp.data,
                                            showProduct: true,
                                            showVariant: false
                                          });
                                        });
                                    } else {
                                      axios
                                        .get(
                                          "https://admin.akclasses.in/api/index_book_pagi?per_page=1000"
                                        )
                                        .then(resp => {
                                          // console.log(resp.data);
                                          this.setState({
                                            categories: resp.data.data,
                                            levels: [],
                                            level: "",
                                            streams: [],
                                            stream: ""
                                          });
                                        });
                                      axios
                                        .get(
                                          "https://admin.akclasses.in/api/coursesR?type=" +
                                          this.state.ordType
                                        )
                                        .then(resp => {
                                          // console.log(resp.data);
                                          // let filtered = resp.data.filter(v=> v)
                                          this.setState({
                                            products: resp.data,
                                            showProduct: true,
                                            showVariant: false
                                          });
                                        });
                                    }
                                  }
                                );
                                // //console.log(e.target.value);
                              }
                            }}
                          >
                            {["COURSE", "TEST_SERIES", "BOOKS"].map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          required={true}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Category
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.category}
                            inputProps={{
                              name: "category",
                              id: "simple-select",
                              onChange: this.handleLevel
                            }}
                          >
                            {this.state.categories.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.ID}
                              >
                                {v.CATEGORY}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Level
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.level}
                            inputProps={{
                              required: true,
                              name: "level",
                              id: "simple-select",
                              onChange: this.handleStream
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value=""
                            >
                              Select Category First
                            </MenuItem>
                            {this.state.levels.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Stream
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.stream}
                            inputProps={{
                              required: true,
                              name: "stream",
                              id: "simple-select",
                              onChange: e => {
                                this.setState(
                                  {
                                    stream: e.target.value
                                  },
                                  () => {
                                    this.searchRecord();
                                  }
                                );
                              }
                              // //console.log(e.target.value);
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value=""
                            >
                              Select Level First
                            </MenuItem>
                            {this.state.streams.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem> */}
                      {/*     <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Payment Status
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.payStatus}
                            inputProps={{
                              name: "payStatus",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  payStatus: e.target.value,
                                  bAlert: false
                                });
                                // //console.log(e.target.value);
                              }
                            }}
                          >
                            {this.state.payment.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem> */}
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={e => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  shipDate: year + "/" + month + "/" + day
                                },
                                () => {
                                  //console.log(this.state.shipDate);
                                }
                              );
                            }}
                            inputProps={{
                              placeholder: "Shipping Date"
                            }}
                          />
                        </FormControl>
                      </GridItem> */}
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Tracking No.(if any)"
                          id="float"
                          inputProps={{
                            onChange: e =>
                              this.setState({ trackingNo: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      {/* {this.state.showProduct ? ( */}
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <GridItem xs={12} sm={12} md={6}>
                              <FormControl
                                style={{ marginTop: "12px" }}
                                fullWidth
                                className={classes.selectFormControl}
                              >
                                <InputLabel
                                  htmlFor="simple-select"
                                  className={classes.selectLabel}
                                >
                                  Products
                                </InputLabel>
                                <Select
                                  MenuProps={{
                                    className: classes.selectMenu
                                  }}
                                  classes={{
                                    select: classes.select
                                  }}
                                  value={this.state.productId}
                                  inputProps={{
                                    name: "productId",
                                    id: "simple-select",
                                    onChange: e => {
                                      this.handleProducts(e);
                                    }
                                  }}
                                >
                                  {this.state.products.map((v, i) => (
                                    <MenuItem
                                      classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                      }}
                                      key={i}
                                      value={v.course.ID}
                                    >
                                      {v.course.TITLE}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {this.state.cAlert ? (
                                <div>
                                  <span style={{ color: "red" }}>
                                    *required
                                  </span>
                                </div>
                              ) : null}
                            </GridItem>
                            {this.state.showVariant ? (
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                  <GridItem xs={12} sm={12} md={6}>
                                    <FormControl
                                      style={{ marginTop: "12px" }}
                                      fullWidth
                                      className={classes.selectFormControl}
                                    >
                                      <InputLabel
                                        htmlFor="simple-select"
                                        className={classes.selectLabel}
                                      >
                                        OS Type
                                      </InputLabel>
                                      <Select
                                        MenuProps={{
                                          className: classes.selectMenu
                                        }}
                                        classes={{
                                          select: classes.select
                                        }}
                                        value={this.state.osId}
                                        inputProps={{
                                          name: "osId",
                                          id: "simple-select",
                                          onChange: e => {
                                            this.handleOsType(e);
                                          }
                                        }}
                                      >
                                        {this.state.osTypes.map((v, i) => (
                                          <MenuItem
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected:
                                                classes.selectMenuItemSelected
                                            }}
                                            key={i}
                                            value={v.id}
                                          >
                                            {v.os_type}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                    {this.state.eAlert ? (
                                      <div>
                                        <span style={{ color: "red" }}>
                                          *required
                                        </span>
                                      </div>
                                    ) : null}
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={6}>
                                    <FormControl
                                      style={{ marginTop: "12px" }}
                                      fullWidth
                                      className={classes.selectFormControl}
                                    >
                                      <InputLabel
                                        htmlFor="simple-select"
                                        className={classes.selectLabel}
                                      >
                                        Media Type
                                      </InputLabel>
                                      <Select
                                        MenuProps={{
                                          className: classes.selectMenu
                                        }}
                                        classes={{
                                          select: classes.select
                                        }}
                                        value={this.state.mediaId}
                                        inputProps={{
                                          name: "mediaId",
                                          id: "simple-select",
                                          onChange: e => {
                                            this.handleMediaType(e);
                                          }
                                        }}
                                      >
                                        {this.state.mediaTypes.map((v, i) => (
                                          <MenuItem
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected:
                                                classes.selectMenuItemSelected
                                            }}
                                            key={i}
                                            value={v.id}
                                          >
                                            {v.media_type}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                    {this.state.dAlert ? (
                                      <div>
                                        <span style={{ color: "red" }}>
                                          *required
                                        </span>
                                      </div>
                                    ) : null}
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={6}>
                                    <FormControl
                                      style={{ marginTop: "12px" }}
                                      fullWidth
                                      className={classes.selectFormControl}
                                    >
                                      <InputLabel
                                        htmlFor="simple-select"
                                        className={classes.selectLabel}
                                      >
                                        Views & Validity
                                      </InputLabel>
                                      <Select
                                        MenuProps={{
                                          className: classes.selectMenu
                                        }}
                                        classes={{
                                          select: classes.select
                                        }}
                                        value={this.state.viewId}
                                        inputProps={{
                                          name: "viewId",
                                          id: "simple-select",
                                          onChange: e => {
                                            this.setState(
                                              {
                                                viewId: e.target.value,
                                                fAlert: false
                                              },
                                              () => {
                                                let requiredVariant = this.state.variants.find(
                                                  v =>
                                                    v.os_type_id ===
                                                    this.state.osId &&
                                                    v.media_type_id ===
                                                    this.state.mediaId &&
                                                    v.view_and_validity_id ===
                                                    this.state.viewId
                                                );
                                                // console.log(requiredVariant);
                                                this.setState({
                                                  variantId: requiredVariant
                                                    ? requiredVariant.id
                                                    : "",
                                                  total: requiredVariant
                                                    ? requiredVariant.offer_price
                                                    : 0
                                                });
                                              }
                                            );
                                          }
                                        }}
                                      >
                                        {this.state.views.map((v, i) => (
                                          <MenuItem
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected:
                                                classes.selectMenuItemSelected
                                            }}
                                            key={i}
                                            value={v.id}
                                          >
                                            {v.view_and_validity}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                    {this.state.fAlert ? (
                                      <div>
                                        <span style={{ color: "red" }}>
                                          *required
                                        </span>
                                      </div>
                                    ) : null}
                                  </GridItem>
                                </GridItem>
                              </GridContainer>
                            ) : null}
                          </GridItem>
                        </GridContainer>
                      {/* ) : null} */}
                      <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                          labelText="Grand Total"
                          id="float"
                          inputProps={{
                            value: this.state.total,
                            // disabled: true,
                            onChange: e =>
                              this.setState({ total: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Reference By
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.reference}
                            inputProps={{
                              name: "reference",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  reference: e.target.value,
                                  gAlert: false
                                });
                              }
                            }}
                          >
                            {this.state.vendors.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.institute_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.gAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Income Mode
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.exMode}
                            inputProps={{
                              name: "exMode",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  exMode: e.target.value,
                                  hAlert: false
                                });
                                // //console.log(e.target.value);
                              }
                            }}
                          >
                            {this.state.exModes.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v.ID}
                              >
                                {v.CATEGORY}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.hAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem> */}
                    </GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <Button className="" color="warning" type="submit">
                        {this.state.loading ? (
                          <Loader />
                        ) : (
                          "Update Vendor Offline Order"
                        )}
                      </Button>
                      <Link
                        to={{
                          pathname: "/admin/vendorofflineorders"
                        }}
                      >
                        <Button className="btn-round" color="danger">
                          Cancel
                        </Button>
                      </Link>
                    </GridItem>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateVendorOfflineOrder;
