import React from 'react'
import {useLocation} from 'react-router-dom'
import Footer from '../common/Footer';
import Header from '../common/Header';
import Checkout from "./Checkout"


export default function FunctionalCheckout(props) {
    // let id = useParams();
    console.log(props)
    // console.log(this.props.location.state);
    const location = useLocation();
    console.log(location.state);
  return (
    <div>
      <Header/>
        <Checkout {...location.state}/>
        {/* <Footer/> z */}
    </div>
  )
}
