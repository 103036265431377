import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
// import AddAlert from "@material-ui/icons/AddAlert";
// import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Constants from "variables/AppConstants";
const classes = makeStyles(styles);

class Levels extends Component {
  state = {
    levels: [],
    loader: true,
    noData: false,
    status: false
  };

  componentDidMount() {
    axios.get(Constants.getUrls.levels + "all=true").then(resp => {
      this.setState({
        loader: false,
        levels: resp.data.levels
      });
    });
  }
  handleStatus = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        const statusPayload = {
          active: this.state.status === "false" ? false : true
        };
        axios
          .put(Constants.updateUrls.updateLevels + id, statusPayload)
          .then(() => {
            axios.get(Constants.getUrls.levels + "all=true").then(resp => {
              this.setState({
                levels: resp.data.levels
              });
            });
            // window.location.reload();
          });
      }
    );
  };

  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <h3>Levels</h3>
                <Link to="/admin/addlevel">
                  <Button className="" color="warning">
                    Add Level
                  </Button>
                </Link>
                <Table
                  tableHead={["Sr No.", "Name", "Status", "Action"]}
                  tableData={this.state.levels.map((sec, index) => [
                    [index + 1],
                    [sec.name],
                    [
                      <Select
                        key={index}
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={sec.active}
                        inputProps={{
                          name: "status",
                          id: "simple-select",
                          onChange: e => {
                            this.handleStatus(e, sec.id);
                          }
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value="true"
                        >
                          ACTIVE
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value="false"
                        >
                          INACTIVE
                        </MenuItem>
                      </Select>
                    ],
                    [
                      <Link
                        key={index}
                        to={{
                          pathname: "/admin/updatelevel/" + sec.id,
                          state: { levelData: sec }
                        }}
                      >
                        <Button className="btn-round" color="info">
                          Update
                        </Button>
                      </Link>
                    ]
                  ])}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.levels.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Levels;
