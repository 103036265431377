import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
// @material-ui/core components
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Constants from "variables/Constants";
const classes = makeStyles(styles);
const courses = [
  // "9th Standard",
  // "10th Standard",
  "CA Foundation",
  "CA Inter",
  "CA Final"
];

class AddFaceToFaceOfflineOrder extends Component {
  state = {
    tran: "",
    cusName: "",
    orderDate: "",
    orderTime: "",
    facultyCommission: 0,
    vendorCommission: 0,
    contact: "",
    email: "",
    faculties: [],
    add1: "",
    data: "",
    add2: "",
    city: "",
    orderId: "",
    state: "",
    exMode: "",
    attempt: "",
    country: "India",
    pincode: "",
    delStatus: "",
    payStatus: "",
    akShare: 100,
    shipDate: "",
    products: [],
    exModes: [],
    variants: [],
    mediaTypes: [],
    osTypes: [],
    views: [],
    delivery: ["Order Placed", "Packaged", "Shipped", "Delivered", "Cancelled"],
    payment: ["Collected", "Pending"],
    vendors: [],
    attempts: [],
    trackingNo: "",
    total: "",
    productId: "",
    mediaId: "",
    osId: "",
    viewId: "",
    reference: "",
    max: false,
    view: false,
    vendorSelected: false,
    warning: false,
    variantId: "",
    redirect: false,
    notification: false,
    aAlert: false,
    bAlert: false,
    cAlert: false,
    dAlert: false,
    eAlert: false,
    fAlert: false,
    gAlert: false,
    hAlert: false,
    iAlert: false,
    jAlert: false,
    kAlert: false,
    loading: false,
    send_mail_to_user:"",
    send_key:"",
    levels: [],
    streams: [],
    subjects: [],
    courses: [],
    category:"",
    level:"",
    stream:"",
    facetoface:"",
    subject:"",
    catAlert: false,
    levelAlert: false,
    streamAlert: false,
    subjectAlert: false,
    staticCourses: "",
    batchwings: "",
    batch_wings: [],
    batch: "",
    batches: [],
    batch_id: [],
    facetofaces: [],
  };
  componentDidMount() {
    axios.get("https://admin.akclasses.in/api/generate_orderId").then(resp => {
      //console.log(resp.data);
      this.setState({
        orderId: resp.data
      });
    });
   
    axios.get("https://admin.akclasses.in/api/getfaceCats").then(resp => {
      console.log(resp);
      this.setState({
        courses: resp.data.data
      });
    });
    
  }
  handleSelect = e => {
      this.setState({
        [e.target.name]: e.target.value,
      })
  }
  findFaculty = data => {
    let obj = this.state.faculties.find(v => v.id === data[0].faculty_id);
    if (obj !== undefined) {
      return obj.name;
    }
  };
  handleSubmit = e => {
    e.preventDefault();
    // if (this.state.max === true) {
    //   this.setState({ max: true });
    // } else if (this.state.delStatus === "") {
    //   this.setState({
    //     aAlert: true
    //   });
    // } else if (this.state.payStatus === "") {
    //   this.setState({
    //     bAlert: true
    //   });
    // } else if (this.state.productId === "") {
    //   this.setState({
    //     cAlert: true
    //   });
    // } else if (this.state.mediaId === "") {
    //   this.setState({
    //     dAlert: true
    //   });
    // } else if (this.state.osId === "") {
    //   this.setState({
    //     eAlert: true
    //   });
    // } else if (this.state.viewId === "") {
    //   this.setState({
    //     fAlert: true
    //   });
    // } else if (this.state.reference === "") {
    //   this.setState({
    //     gAlert: true
    //   });
    // }  else if (this.state.send_mail_to_user === "") {
    //   this.setState({
    //     jAlert: true
    //   });
    // }  else if (this.state.send_key === "") {
    //   this.setState({
    //     kAlert: true
    //   });
    // } else if (this.state.warning === true) {
    //   this.setState({
    //     warning: true
    //   });
    //  } else {
      
    let fd = new FormData();
    fd.append("transaction_id", this.state.tran);
      fd.append("user_name", this.state.cusName);
      fd.append("contact", this.state.contact);
      fd.append("email", this.state.email);
      fd.append("address", this.state.add1);
      fd.append("city", this.state.city);
      fd.append("state", this.state.state);
      fd.append("country", "India");
      fd.append("pincode", this.state.pincode);
      fd.append("delivery_status", this.state.delStatus);
      fd.append("payment_status", this.state.payStatus);
      fd.append("grand_total", this.state.total);
      fd.append("order_id", this.state.orderId);
      fd.append("order_type", "COURSE");
      fd.append("order_date", this.state.orderDate);
      fd.append("order_time", this.state.orderTime);
      fd.append("category_id", this.state.category);
      fd.append("level_id", this.state.level);
      fd.append("stream_id", this.state.stream);
      fd.append("subject_id", this.state.subject);
      fd.append("facetoface_id", this.state.facetoface);
      this.setState({
        loading: true
      });
      axios
        .post("https://admin.akclasses.in/api/facetofaceOfflineOrders", fd)
        // .post(Constants.postUrls.createOfflineOrder, fd)
        .then(resp => {
          console.log(resp);
          localStorage.setItem("category", "Course");
          setTimeout(() => {
            this.setState({
              redirect: true
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false
          });
        });
    // }
  };
  handleLevel = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        aAlert: false
      },
      () => {
        //console.log(this.state.category);
        axios
          .get(
            "https://admin.akclasses.in/api/getCategyLevels?category_id=" + this.state.category + "&type=COURSE"
          )
          .then(resp => {
            console.log(resp);
            this.setState({
              levels: resp.data
            });
          });
      }
    );
  };
  handleStream = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        cAlert: false
      },
      () => {
        //console.log(this.state.level);
        axios
          .get("https://admin.akclasses.in/api/getLevelsStream?level_id=" + this.state.level + "&type=COURSE")
          .then(resp => {
            //console.log(resp.data);
            this.setState({
              streams: resp.data
            });
          });
      }
    );
  };
  handleSubject = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        dAlert: false
      },
      () => {
        // console.log(this.state.stream);
        axios
          .get("https://admin.akclasses.in/api/getFaceSubjects?stream_id=" + this.state.stream)
          .then(resp => {
            console.log(resp);
            this.setState({
              subjects: resp.data.data
            });
          });
      }
    );
  };

  handleFaceToFace = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        dAlert: false
      },
      () => {
        // console.log(this.state.stream);
        axios
          .get("https://admin.akclasses.in/api/getFaceToFace?category_id=" + this.state.category + "&level_id=" + this.state.level + "&stream_id=" + this.state.stream + "&subject_id=" + this.state.subject)
          .then(resp => {
            console.log(resp);
            this.setState({
              facetofaces: resp.data.data
            });
          });
      }
    );
  };

  handleWings = e =>{
    this.setState({
      [e.target.name]: e.target.value,
      fAlert: false
    }, ()=>{
      // console.log(this.state.subject);
      axios
        .get("https://admin.akclasses.in/api/getBatchCourses/" + this.state.subject)
        .then(resp => {
          console.log(resp);
          this.setState({
            products: resp.data.data
          });
        });
      axios.get("https://admin.akclasses.in/api/subjectWings/" + this.state.subject)
      .then((resp)=>{
        // console.log(resp.data.wings)
        this.setState({
          batch_wings: resp.data.wings
        })
      })
    })
  }
  handleBatch = e=>{
    this.setState({
      [e.target.name]: e.target.value,
      fAlert: false
    },()=>{
      // console.log(this.state.batchwings);
      axios.get("https://admin.akclasses.in/api/wingBatch/" + this.state.batchwings)
      .then((resp)=>{
        // console.log(resp)
        this.setState({
          batches: []
        })
        resp.data.data.map((v)=>{
          if(v.batch)
          {
            this.state.batches.push({
              label: v.batch ? v.batch.batch_name : "",
              value: v.batch ? v.batch.id : "",
            })
          }
        })
        this.setState({
          batches: this.state.batches
        }, ()=>{ console.log(this.state.batches)})
      })
    })
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/facetofaceofflineorder"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Order Added!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Face To Face Offline Order</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText={"Order ID: " + this.state.orderId}
                          id="disabled"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true
                          }}
                        />
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Transaction Details"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ tran: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={6}>
                        <label>Order Date</label>
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={e => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              let time = new Date(); // for now
                              let hour = time.getHours();
                              let minute = time.getMinutes();
                              let second = time.getSeconds();

                              this.setState(
                                {
                                  orderDate: year + "/" + month + "/" + day,
                                  orderTime: hour + ":" + minute + ":" + second,
                                },
                                () => {
                                  // console.log(this.state.orderDate);
                                  // console.log(this.state.orderTime);
                                }
                              );
                            }}
                            inputProps={{
                              required: true,
                              placeholder: "Order Date"
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Customer Name"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ cusName: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Customer Contact#"
                          id="float"
                          inputProps={{
                            type: "number",
                            required: true,
                            onChange: e =>
                              this.setState({ contact: e.target.value }, () => {
                                if (this.state.contact.length > 10) {
                                  this.setState({ max: true });
                                } else {
                                  this.setState({ max: false });
                                }
                              })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                        {this.state.max ? (
                          <div>
                            <span style={{ color: "red" }}>
                              Max Allowed Characters : 10
                            </span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Customer Email"
                          id="float"
                          inputProps={{
                            type: "email",
                            required: true,
                            onChange: e =>
                              this.setState({ email: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Street Address(House number and Street name*)"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ add1: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      {/*  <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Street Address(optional)"
                          id="float"
                          inputProps={{
                            onChange: e =>
                              this.setState({ add2: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="City"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ city: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="State"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ state: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Postcode/ZIP"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ pincode: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Delivery Status
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.delStatus}
                            inputProps={{
                              name: "delStatus",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  delStatus: e.target.value,
                                  aAlert: false
                                });
                                // //console.log(e.target.value);
                              }
                            }}
                          >
                            {this.state.delivery.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Payment Status
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={this.state.payStatus}
                            inputProps={{
                              name: "payStatus",
                              id: "simple-select",
                              onChange: e => {
                                this.setState({
                                  payStatus: e.target.value,
                                  bAlert: false
                                });
                                // //console.log(e.target.value);
                              }
                            }}
                          >
                            {this.state.payment.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                key={i}
                                value={v}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            required={true}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Category
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              value={this.state.category}
                              inputProps={{
                                name: "category",
                                id: "simple-select",
                                onChange: this.handleLevel
                              }}
                            >
                              {this.state.courses.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  key={i}
                                  value={v.id}
                                >
                                  {v.category}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.aAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Level
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              value={this.state.level}
                              inputProps={{
                                required: true,
                                name: "level",
                                id: "simple-select",
                                onChange: this.handleStream
                              }}
                            >
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value=""
                              >
                                Select Category First
                              </MenuItem>
                              {this.state.levels.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  key={i}
                                  value={v.id}
                                >
                                  {v.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.cAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Stream
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              value={this.state.stream}
                              inputProps={{
                                required: true,
                                name: "stream",
                                id: "simple-select",
                                onChange: this.handleSubject
                                // //console.log(e.target.value);
                              }}
                            >
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value=""
                              >
                                Select Level First
                              </MenuItem>
                              {this.state.streams.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  key={i}
                                  value={v.id}
                                >
                                  {v.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.dAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Subject
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              value={this.state.subject}
                              inputProps={{
                                required: true,
                                name: "subject",
                                id: "simple-select",
                                onChange: this.handleFaceToFace
                                // //console.log(e.target.value);
                              }}
                            >
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value=""
                              >
                                Select Stream First
                              </MenuItem>
                              {this.state.subjects.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  key={i}
                                  value={v.id}
                                >
                                  {v.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.dAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl
                            style={{ marginTop: "12px" }}
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Face To Face
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              value={this.state.facetoface}
                              inputProps={{
                                required: true,
                                name: "facetoface",
                                id: "simple-select",
                                onChange: this.handleSelect
                                // //console.log(e.target.value);
                              }}
                            >
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value=""
                              >
                                Select Subject First
                              </MenuItem>
                              {this.state.facetofaces.map((v, i) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  key={i}
                                  value={v.id}
                                >
                                  {v.city}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {this.state.dAlert ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Grand Total"
                          id="float"
                          inputProps={{
                            value: this.state.total,
                            // disabled: true,
                            onChange: e =>
                              this.setState({ total: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>

                    </GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <Button className="" color="warning" type="submit" disabled={this.state.loading}>
                        {this.state.loading ? (
                          <Loader />
                        ) : (
                          "Add Face To Face Offline Order"
                        )}
                      </Button>
                      <Link
                        to={{
                          pathname: "/admin/facetofaceofflineorder"
                        }}
                      >
                        <Button className="btn-round" color="danger">
                          Cancel
                        </Button>
                      </Link>
                    </GridItem>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddFaceToFaceOfflineOrder;
