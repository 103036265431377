import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
// @material-ui/core components
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Constants from "variables/Constants";
const classes = makeStyles(styles);
const courses = [
  // "9th Standard",
  // "10th Standard",
  "CA Foundation",
  "CA Inter",
  "CA Final",
];

class AddOfflineOrder extends Component {
  state = {
    tran: "",
    cusName: "",
    orderDate: "",
    orderTime: "",
    facultyCommission: 0,
    vendorCommission: 0,
    contact: "",
    email: "",
    faculties: [],
    add1: "",
    data: "",
    add2: "",
    city: "",
    orderId: "",
    state: "",
    exMode: "",
    attempt: "",
    country: "India",
    pincode: "",
    delStatus: "",
    payStatus: "",
    akShare: 100,
    shipDate: "",
    products: [],
    exModes: [],
    variants: [],
    mediaTypes: [],
    osTypes: [],
    views: [],
    delivery: ["Order Placed", "Packaged", "Shipped", "Delivered", "Cancelled"],
    payment: ["Collected", "Not_Collected"],
    vendors: [],
    attempts: [],
    trackingNo: "",
    total: "",
    productId: "",
    mediaId: "",
    osId: "",
    viewId: "",
    reference: "",
    max: false,
    view: false,
    vendorSelected: false,
    warning: false,
    variantId: "",
    redirect: false,
    notification: false,
    aAlert: false,
    bAlert: false,
    cAlert: false,
    dAlert: false,
    eAlert: false,
    fAlert: false,
    gAlert: false,
    hAlert: false,
    iAlert: false,
    jAlert: false,
    kAlert: false,
    lAlert: false,
    loading: false,
    send_mail_to_user: "",
    send_key: "",
    levels: [],
    streams: [],
    subjects: [],
    courses: [],
    category: "",
    level: "",
    stream: "",
    subject: "",
    catAlert: false,
    levelAlert: false,
    streamAlert: false,
    subjectAlert: false,
    staticCourses: "",
    batchwings: "",
    batch_wings: [],
    batch: "",
    batches: [],
    batch_id: [],
    product_sku: "",
    sku: "",
    message: "",
    // mailTemplate: "",
    templates: [],
    skuError: false,
    errorSkuMsg: "",
  };
  componentDidMount() {
    // axios
    //   .get("https://admin.akclasses.in/api/coursesR?type=COURSES")
    //   .then(resp => {
    //     // console.log(resp.data);
    //     this.setState({
    //       products: resp.data
    //     });
    //   });
    // axios.get("https://admin.akclasses.in/api/mailTemplate").then((resp) => {
    //   console.log(resp.data);
    //   this.setState({
    //     templates: resp.data,
    //   }, () => {
    //     console.log(this.state.templates);
    //   });
    // });

    axios.get("https://admin.akclasses.in/api/attempt").then((resp) => {
      console.log(resp.data);
      resp.data.map((val) => {
        if (val.status === "ACTIVE") {
          this.state.attempts.push(val);
        }
      });
      // this.setState({
      //   attempts: resp.data
      // });
    });
    axios.get("https://admin.akclasses.in/api/venderR").then((resp) => {
      // console.log(resp.data);
      this.setState({
        vendors: resp.data,
      });
    });
    axios
      .get("https://admin.akclasses.in/api/generate_orderId")
      .then((resp) => {
        //console.log(resp.data);
        this.setState({
          orderId: resp.data,
        });
      });
    axios
      .get("https://admin.akclasses.in/api/expenseCategoriesR")
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          exModes: resp.data,
        });
      });
    axios.get("https://admin.akclasses.in/api/faculty").then((resp) => {
      // console.log(resp.data);
      this.setState({
        faculties: resp.data,
      });
    });
    axios
      .get("https://admin.akclasses.in/api/courseCategoriesR")
      .then((resp) => {
        //console.log(resp.data);
        this.setState({
          courses: resp.data,
        });
      });

    // axios.get("https://admin.akclasses.in/api/indexall_view").then(resp => {
    //   //console.log(resp.data);
    //   this.setState({
    //     views: resp.data
    //   });
    // });
  }
  findFaculty = (data) => {
    let obj = this.state.faculties.find((v) => v.id === data[0].faculty_id);
    if (obj !== undefined) {
      return obj.name;
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.max === true) {
      this.setState({ max: true });
    } else if (this.state.delStatus === "") {
      this.setState({
        aAlert: true,
      });
    } else if (this.state.payStatus === "") {
      this.setState({
        bAlert: true,
      });
    } else if (this.state.productId === "") {
      this.setState({
        cAlert: true,
      });
    } else if (this.state.mediaId === "") {
      this.setState({
        dAlert: true,
      });
    } else if (this.state.osId === "") {
      this.setState({
        eAlert: true,
      });
    } else if (this.state.viewId === "") {
      this.setState({
        fAlert: true,
      });
    } else if (this.state.reference === "") {
      this.setState({
        gAlert: true,
      });
    } else if (this.state.send_mail_to_user === "") {
      this.setState({
        jAlert: true,
      });
    } else if (this.state.send_key === "") {
      this.setState({
        kAlert: true,
      });
    } 
    // else if (this.state.mailTemplate === "") {
    //   this.setState({
    //     lAlert: true,
    //   });
    // } 
    else if (this.state.warning === true) {
      this.setState({
        warning: true,
      });
    } else {
      // this.state.batch.map((v)=>{
      //   this.state.batch_id.push({ "value": v.value})
      // })
      // this.setState({
      //   batch_id: this.state.batch_id
      // })
      let fd = new FormData();
      fd.append("tx_id", this.state.tran);
      fd.append("name", this.state.cusName);
      fd.append("contact_no", this.state.contact);
      fd.append("email", this.state.email);
      fd.append("address", this.state.add1);
      fd.append("address_2", "");
      fd.append("city", this.state.city);
      fd.append("state", this.state.state);
      fd.append("country", "");
      fd.append("pincode", this.state.pincode);
      fd.append("delivery_status", this.state.delStatus);
      fd.append("payment_status", this.state.payStatus);
      fd.append("shipping_date", "");
      fd.append("product_details", this.state.productId);
      fd.append("vender", this.state.reference);
      fd.append("shipping", "");
      fd.append("grand_total", this.state.total);
      fd.append("ordID", this.state.orderId);
      fd.append("order_type", "COURSE");
      fd.append("exam_given", "no");
      fd.append("order_date", this.state.orderDate);
      fd.append("order_time", this.state.orderTime);
      fd.append("media_type_id", this.state.mediaId);
      fd.append("os_type_id", this.state.osId);
      fd.append("ak_share", this.state.akShare);
      fd.append("attempt", this.state.attempt);
      fd.append("mode", this.state.exMode);
      fd.append("variant_id", this.state.variantId);
      fd.append("view_and_validity_id", this.state.viewId);
      fd.append("send_mail_to_user", this.state.send_mail_to_user);
      fd.append("send_key", this.state.send_key);
      fd.append("course", this.state.staticCourses);
      fd.append("batch_wings_id", this.state.batchwings);
      fd.append("batch_id", JSON.stringify(this.state.batch));
      fd.append("sku", this.state.sku);
      fd.append("template", "");

      this.setState({
        loading: true,
      });
      console.log(fd);
      // return
      axios
        .post("https://admin.akclasses.in/api/storeOfflineOrder", fd)
        // .post(Constants.postUrls.createOfflineOrder, fd)
        .then((resp) => {
          console.log(resp);
          localStorage.setItem("category", "Course");
          if (resp.data.status === "success") {
            setTimeout(() => {
              this.setState({
                redirect: true,
              });
            }, 1000);
            this.setState({
              notification: true,
              loading: false,
            });
          }
          if (resp.data.status === "failed") {
            this.setState({
              loading: false,
              notification: true,
              message: resp.data.message,
              skuError: true,
              errorSkuMsg: resp.data.message
            });
            setTimeout(() => {
              this.setState({
                notification: false,
                skuError: false,
              });
            }, 4000);
          }
        });
    }
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleProducts = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        aAlert: false,
      },
      () => {
        console.log(this.state.batch);
        // axios
        // .get("https://admin.akclasses.in/api/coursesR?type=COURSES")
        // .get("https://admin.akclasses.in/api/selectedProducts?type=COURSES" +
        //   "&category_id=" + this.state.category +
        //   "&level_id=" + this.state.level +
        //   "&stream_id=" + this.state.stream +
        //   "&subject_id=" + this.state.subject +
        //   "&batch_wings_id=" + this.state.batchwings +
        //   "&batch_id=" + this.state.batch
        //   )
        // axios
        // .get("https://admin.akclasses.in/api/getBatchCourses/" + this.state.subject)
        // .then(resp => {
        //   console.log(resp);
        //   this.setState({
        //     products: resp.data.data
        //   });
        // });
      }
    );
  };
  handleVariants = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        cAlert: false,
      },
      () => {
        axios
          // .get("https://admin.akclasses.in/api/getCoursesActiveVarient/" + this.state.productId)
          .get(Constants.getUrls.getCoursesActiveVarient + this.state.productId)
          .then((resp) => {
            console.log(resp);
            this.setState(
              {
                variants: resp.data.product.variant,
                product_sku: resp.data?.product.SKU,
              },
              () => {
                this.setState(
                  {
                    mediaId: this.state.variants[0]
                      ? this.state.variants[0].media_type_id
                      : "",
                    osId: this.state.variants[0]
                      ? this.state.variants[0].os_type_id
                      : "",
                    viewId: this.state.variants[0]
                      ? this.state.variants[0].view_and_validity_id
                      : "",
                    variantId: this.state.variants[0]
                      ? this.state.variants[0].id
                      : "",
                    total: this.state.variants[0]
                      ? this.state.variants[0].offer_price
                      : 0,
                  },
                  () => {
                    let osIds = [];
                    let osTypes = [];
                    this.state.variants.forEach((v) => {
                      if (osIds.includes(v.os_type.id) === false) {
                        osIds.push(v.os_type.id);
                        osTypes.push(v.os_type);
                      }
                    });
                    //console.log(osTypes);
                    this.setState({ osTypes });
                    let mediaTypes = [];
                    let mediaIds = [];
                    this.state.variants.forEach((v) => {
                      if (mediaIds.includes(v.media_type.id) === false) {
                        if (v.os_type_id === this.state.osId) {
                          mediaIds.push(v.media_type.id);
                          mediaTypes.push(v.media_type);
                        }
                      }
                    });
                    //console.log(mediaTypes);
                    this.setState({ mediaTypes });
                    let viewIds = [];
                    let views = [];
                    this.state.variants.forEach((v) => {
                      if (v.view_and_validity) {
                        if (
                          viewIds.includes(v.view_and_validity.id) === false
                        ) {
                          if (
                            v.os_type_id === this.state.osId &&
                            v.media_type_id === this.state.mediaId
                          ) {
                            viewIds.push(v.view_and_validity.id);
                            views.push(v.view_and_validity);
                          }
                        }
                      }
                    });
                    //console.log(views);
                    this.setState({ views });
                  }
                );
              }
            );
            let facultyCommission = 0;
            resp.data.product.faculty.forEach((v) => {
              facultyCommission += v.faulty_share;
            });
            if (this.state.vendorSelected) {
              axios
                .get(
                  "https://admin.akclasses.in/api/get_vendor_product/" +
                    this.state.reference +
                    "?product_id=" +
                    this.state.productId
                )
                .then((resp) => {
                  console.log(resp.data);
                  if (resp.data.length > 0) {
                    let vendorCommission = resp.data.find(
                      (v) => v.product_id === parseInt(this.state.productId)
                    ).commission_percent;
                    this.setState(
                      {
                        vendorCommission,
                        warning: false,
                      },
                      () => {
                        // console.log(vendorCommission);
                        let total =
                          facultyCommission +
                          parseInt(this.state.vendorCommission);
                        this.setState({
                          akShare: 100 - total,
                        });
                      }
                    );
                  } else {
                    this.setState({
                      warning: true,
                      akShare: 100 - facultyCommission,
                    });
                  }
                });
            } else {
              this.setState({
                akShare: 100 - facultyCommission,
                facultyCommission,
              });
            }
          });
      }
    );
    // //console.log(e.target.value);
  };
  handleOsType = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        eAlert: false,
      },
      () => {
        let mediaTypes = [];
        let mediaIds = [];
        this.state.variants.forEach((v) => {
          if (mediaIds.includes(v.media_type.id) === false) {
            if (v.os_type_id === this.state.osId) {
              mediaIds.push(v.media_type.id);
              mediaTypes.push(v.media_type);
            }
          }
        });
        //console.log(mediaTypes);
        this.setState({ mediaTypes }, () => {
          this.setState(
            {
              mediaId: this.state.mediaTypes[0]
                ? this.state.mediaTypes[0].id
                : "",
            },
            () => {
              let viewIds = [];
              let views = [];
              this.state.variants.forEach((v) => {
                if (viewIds.includes(v.view_and_validity.id) === false) {
                  if (
                    v.os_type_id === this.state.osId &&
                    v.media_type_id === this.state.mediaId
                  ) {
                    viewIds.push(v.view_and_validity.id);
                    views.push(v.view_and_validity);
                  }
                }
              });
              //console.log(views);
              this.setState({ views }, () => {
                this.setState(
                  {
                    viewId: this.state.views[0] ? this.state.views[0].id : "",
                  },
                  () => {
                    let requiredVariant = this.state.variants.find(
                      (v) =>
                        v.os_type_id === this.state.osId &&
                        v.media_type_id === this.state.mediaId &&
                        v.view_and_validity_id === this.state.viewId
                    );
                    // console.log(requiredVariant);
                    this.setState({
                      variantId: requiredVariant ? requiredVariant.id : "",
                      total: requiredVariant ? requiredVariant.offer_price : 0,
                    });
                  }
                );
              });
            }
          );
        });
      }
    );
  };
  handleMediaType = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        dAlert: false,
      },
      () => {
        let viewIds = [];
        let views = [];
        this.state.variants.forEach((v) => {
          if (viewIds.includes(v.view_and_validity.id) === false) {
            if (
              v.os_type_id === this.state.osId &&
              v.media_type_id === this.state.mediaId
            ) {
              viewIds.push(v.view_and_validity.id);
              views.push(v.view_and_validity);
            }
          }
        });
        //console.log(views);
        this.setState({ views }, () => {
          this.setState(
            {
              viewId: this.state.views[0] ? this.state.views[0].id : "",
            },
            () => {
              let requiredVariant = this.state.variants.find(
                (v) =>
                  v.os_type_id === this.state.osId &&
                  v.media_type_id === this.state.mediaId &&
                  v.view_and_validity_id === this.state.viewId
              );
              // console.log(requiredVariant);
              this.setState({
                variantId: requiredVariant ? requiredVariant.id : "",
                total: requiredVariant ? requiredVariant.offer_price : 0,
              });
            }
          );
        });
      }
    );
  };
  handleLevel = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        aAlert: false,
      },
      () => {
        //console.log(this.state.category);
        axios
          .get(
            "https://admin.akclasses.in/api/showCategory_with_level/" +
              this.state.category
          )
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              levels: resp.data.level,
            });
          });
      }
    );
  };
  handleStream = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        cAlert: false,
      },
      () => {
        //console.log(this.state.level);
        axios
          .get("https://admin.akclasses.in/api/level/" + this.state.level)
          .then((resp) => {
            //console.log(resp.data);
            this.setState({
              streams: resp.data.stream,
            });
          });
      }
    );
  };
  handleSubject = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        dAlert: false,
      },
      () => {
        // console.log(this.state.stream);
        axios
          .get("https://admin.akclasses.in/api/stream/" + this.state.stream)
          .then((resp) => {
            // console.log(resp.data);
            this.setState({
              subjects: resp.data.stream.subject_masters,
            });
          });
      }
    );
  };
  handleWings = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fAlert: false,
      },
      () => {
        // console.log(this.state.subject);
        axios
          .get(
            "https://admin.akclasses.in/api/getBatchCourses/" +
              this.state.subject
          )
          .then((resp) => {
            console.log(resp);
            this.setState({
              products: resp.data.data,
            });
          });
        axios
          .get(
            "https://admin.akclasses.in/api/subjectWings/" + this.state.subject
          )
          .then((resp) => {
            // console.log(resp.data.wings)
            this.setState({
              batch_wings: resp.data.wings,
            });
          });
      }
    );
  };
  handleBatch = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fAlert: false,
      },
      () => {
        console.log(this.state.batchwings);
        axios
          .get(
            "https://admin.akclasses.in/api/wingBatch/" + this.state.batchwings
          )
          .then((resp) => {
            console.log(resp);
            this.setState({
              batches: [],
            });
            resp.data.data.map((v) => {
              if (v.batch) {
                this.state.batches.push({
                  label: v.batch
                    ? v.batch.batch_name
                      ? v.batch.batch_name
                      : ""
                    : "",
                  value: v.batch ? (v.batch.id ? v.batch.id : "") : "",
                });
              }
            });
            this.setState(
              {
                batches: this.state.batches,
              },
              () => {
                console.log(this.state.batches);
              }
            );
          });
      }
    );
  };
  render() {
    if (this.state.redirect) {
      // return console.log("submitted")
      return <Redirect to={"/admin/offlineorders"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.notification ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={this.state.message}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Course Offline Order</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText={"Order ID: " + this.state.orderId}
                          id="disabled"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: true,
                          }}
                        />
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Transaction Details"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: e =>
                              this.setState({ tran: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={6}>
                        <label>Order Date</label>
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={(e) => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              let time = new Date(); // for now
                              let hour = time.getHours();
                              let minute = time.getMinutes();
                              let second = time.getSeconds();

                              this.setState(
                                {
                                  orderDate: year + "/" + month + "/" + day,
                                  orderTime: hour + ":" + minute + ":" + second,
                                },
                                () => {
                                  // console.log(this.state.orderDate);
                                  // console.log(this.state.orderTime);
                                }
                              );
                            }}
                            inputProps={{
                              required: true,
                              placeholder: "Order Date",
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Customer Name"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ cusName: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Customer Contact#"
                          id="float"
                          inputProps={{
                            type: "number",
                            required: true,
                            onChange: (e) =>
                              this.setState({ contact: e.target.value }, () => {
                                if (this.state.contact.length > 10) {
                                  this.setState({ max: true });
                                } else {
                                  this.setState({ max: false });
                                }
                              }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        {this.state.max ? (
                          <div>
                            <span style={{ color: "red" }}>
                              Max Allowed Characters : 10
                            </span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Customer Email"
                          id="float"
                          inputProps={{
                            type: "email",
                            required: true,
                            onChange: (e) =>
                              this.setState({ email: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Street Address(House number and Street name*)"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ add1: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      {/*  <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Street Address(optional)"
                          id="float"
                          inputProps={{
                            onChange: e =>
                              this.setState({ add2: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="City"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ city: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="State"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ state: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Ak Share"
                          id="float"
                          inputProps={{
                            disabled: true,
                            type: "float",
                            value: this.state.akShare,
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Postcode/ZIP"
                          id="float"
                          inputProps={{
                            required: true,
                            onChange: (e) =>
                              this.setState({ pincode: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Course
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.staticCourses}
                            inputProps={{
                              name: "staticCourses",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  staticCourses: e.target.value,
                                  // aAlert: false
                                });
                                // //console.log(e.target.value);
                              },
                            }}
                          >
                            {courses.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null} */}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Delivery Status
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.delStatus}
                            inputProps={{
                              name: "delStatus",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  delStatus: e.target.value,
                                  aAlert: false,
                                });
                                // //console.log(e.target.value);
                              },
                            }}
                          >
                            {this.state.delivery.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Payment Status
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.payStatus}
                            inputProps={{
                              name: "payStatus",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  payStatus: e.target.value,
                                  bAlert: false,
                                });
                                // //console.log(e.target.value);
                              },
                            }}
                          >
                            {this.state.payment.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.bAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            onChange={e => {
                              let year = e._d.getFullYear().toString();
                              let month = (e._d.getMonth() + 101)
                                .toString()
                                .substring(1);
                              let day = (e._d.getDate() + 100)
                                .toString()
                                .substring(1);
                              this.setState(
                                {
                                  shipDate: year + "/" + month + "/" + day
                                },
                                () => {
                                  //console.log(this.state.shipDate);
                                }
                              );
                            }}
                            inputProps={{
                              placeholder: "Shipping Date"
                            }}
                          />
                        </FormControl>
                      </GridItem> */}
                      {/* <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Tracking No.(if any)"
                          id="float"
                          inputProps={{
                            onChange: e =>
                              this.setState({ trackingNo: e.target.value })
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem> */}
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          required={true}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Category
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.category}
                            inputProps={{
                              name: "category",
                              id: "simple-select",
                              onChange: this.handleLevel,
                            }}
                          >
                            {this.state.courses.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.ID}
                              >
                                {v.CATEGORY}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.aAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Level
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.level}
                            inputProps={{
                              required: true,
                              name: "level",
                              id: "simple-select",
                              onChange: this.handleStream,
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Category First
                            </MenuItem>
                            {this.state.levels.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Stream
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.stream}
                            inputProps={{
                              required: true,
                              name: "stream",
                              id: "simple-select",
                              onChange: this.handleSubject,
                              // //console.log(e.target.value);
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Level First
                            </MenuItem>
                            {this.state.streams.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.dAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Subject
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.subject}
                            inputProps={{
                              required: true,
                              name: "subject",
                              id: "simple-select",
                              onChange: this.handleWings,
                              // onChange: e => {
                              //   this.setState({
                              //     fAlert: false,
                              //     subject: e.target.value
                              //   });
                              // }
                              // //console.log(e.target.value);
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Stream First
                            </MenuItem>
                            {this.state.subjects.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.subject_master.id}
                              >
                                {v.subject_master.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.fAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Batch Wings
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.batchwings}
                            inputProps={{
                              required: true,
                              name: "batchwings",
                              id: "simple-select",
                              onChange: this.handleBatch,

                              // e => {
                              //   this.setState({
                              //     fAlert: false,
                              //     selected_wing: e.target.value
                              //   });
                              // }
                              // //console.log(e.target.value);
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Subject First
                            </MenuItem>
                            {this.state.batch_wings.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v?.batch_wing?.id}
                              >
                                {v?.batch_wing?.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.fAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Batches
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.batch}
                            inputProps={{
                              required: true,
                              name: "batch",
                              id: "simple-select",
                              onChange: this.handleProducts,

                              // e => {
                              //   this.setState({
                              //     fAlert: false,
                              //     selected_wing: e.target.value
                              //   });
                              // }
                              // //console.log(e.target.value);
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Batch Wing First
                            </MenuItem>
                            {this.state.batches.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.value}
                              >
                                {v.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.fAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Products
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.productId}
                            inputProps={{
                              name: "productId",
                              id: "simple-select",
                              onChange: (e) => {
                                this.handleVariants(e);
                              },
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value=""
                            >
                              Select Batch First
                            </MenuItem>
                            {this.state.products.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.ID}
                              >
                                {
                                  <span>
                                    {v.TITLE}(
                                    {v.faculties === null
                                      ? "AK Team"
                                      : v.faculties.name}
                                    )
                                  </span>
                                }
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.cAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            OS Type
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.osId}
                            inputProps={{
                              name: "osId",
                              id: "simple-select",
                              onChange: (e) => {
                                this.handleOsType(e);
                              },
                            }}
                          >
                            {this.state.osTypes.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.os_type}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.eAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Media Type
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.mediaId}
                            inputProps={{
                              name: "mediaId",
                              id: "simple-select",
                              onChange: (e) => {
                                this.handleMediaType(e);
                              },
                            }}
                          >
                            {this.state.mediaTypes.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.media_type}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.dAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Views & Validity
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.viewId}
                            inputProps={{
                              name: "viewId",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState(
                                  {
                                    viewId: e.target.value,
                                    fAlert: false,
                                  },
                                  () => {
                                    let requiredVariant = this.state.variants.find(
                                      (v) =>
                                        v.os_type_id === this.state.osId &&
                                        v.media_type_id ===
                                          this.state.mediaId &&
                                        v.view_and_validity_id ===
                                          this.state.viewId
                                    );
                                    // console.log(requiredVariant);
                                    this.setState({
                                      variantId: requiredVariant
                                        ? requiredVariant.id
                                        : "",
                                      total: requiredVariant
                                        ? requiredVariant.offer_price
                                        : 0,
                                    });
                                  }
                                );
                              },
                            }}
                          >
                            {this.state.views.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.view_and_validity}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.fAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Grand Total"
                          id="float"
                          inputProps={{
                            value: this.state.total,
                            // disabled: true,
                            onChange: (e) =>
                              this.setState({ total: e.target.value }),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Reference By
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.reference}
                            inputProps={{
                              name: "reference",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState(
                                  {
                                    reference: e.target.value,
                                    vendorSelected: true,
                                    gAlert: false,
                                  },
                                  () => {
                                    axios
                                      .get(
                                        "https://admin.akclasses.in/api/get_vendor_product/" +
                                          this.state.reference +
                                          "?product_id=" +
                                          this.state.productId
                                      )
                                      .then((resp) => {
                                        console.log(resp.data);
                                        if (resp.data.length > 0) {
                                          let vendorCommission = resp.data.find(
                                            (v) =>
                                              v.product_id ===
                                              parseInt(this.state.productId)
                                          ).commission_percent;
                                          this.setState(
                                            {
                                              vendorCommission,
                                              warning: false,
                                            },
                                            () => {
                                              let total =
                                                this.state.facultyCommission +
                                                parseInt(
                                                  this.state.vendorCommission
                                                );
                                              this.setState({
                                                akShare: 100 - total,
                                              });
                                            }
                                          );
                                        } else {
                                          this.setState({ warning: true });
                                        }
                                      });
                                  }
                                );
                              },
                            }}
                          >
                            {this.state.vendors.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.institute_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.gAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                        {this.state.warning ? (
                          <div>
                            <span style={{ color: "red" }}>
                              This Vendor is Not Available for Selected
                              Product!!!
                            </span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Income Mode
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.exMode}
                            inputProps={{
                              name: "exMode",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  exMode: e.target.value,
                                  hAlert: false,
                                });
                                // //console.log(e.target.value);
                              },
                            }}
                          >
                            {this.state.exModes.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.ID}
                              >
                                {v.CATEGORY}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.hAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Attempt
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.attempt}
                            inputProps={{
                              name: "attempt",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  attempt: e.target.value,
                                  iAlert: false,
                                });
                                // //console.log(e.target.value);
                              },
                            }}
                          >
                            {this.state.attempts.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.state.iAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Send Mail To User
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.send_mail_to_user}
                            inputProps={{
                              name: "send_mail_to_user",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  send_mail_to_user: e.target.value,
                                  jAlert: false,
                                });
                                // //console.log(e.target.value);
                              },
                            }}
                          >
                            {/* {this.state.attempts.map((v, i) => ( */}
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              // key={i}
                              value="Yes"
                            >
                              Yes
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              // key={i}
                              value="No"
                            >
                              No
                            </MenuItem>
                            {/* ))} */}
                          </Select>
                        </FormControl>
                        {this.state.jAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Send Product Key
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.send_key}
                            inputProps={{
                              name: "send_key",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  send_key: e.target.value,
                                  kAlert: false,
                                });
                                if (e.target.value === "Yes") {
                                  this.setState({
                                    sku: this.state.product_sku,
                                  });
                                }
                                // //console.log(e.target.value);
                              },
                            }}
                          >
                            {/* {this.state.attempts.map((v, i) => ( */}
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              // key={i}
                              value="Yes"
                            >
                              Yes
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              // key={i}
                              value="No"
                            >
                              No
                            </MenuItem>
                            {/* ))} */}
                          </Select>
                        </FormControl>
                        {this.state.kAlert ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                      </GridItem>

                      {/* <GridItem xs={12} sm={12} md={6}>
                        <FormControl
                          style={{ marginTop: "12px" }}
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Mail Template
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={this.state.mailTemplate}
                            inputProps={{
                              name: "mailTemplate",
                              id: "simple-select",
                              onChange: (e) => {
                                this.setState({
                                  mailTemplate: e.target.value,
                                });
                              },
                            }}
                          >
                            {this.state.templates.map((v, i) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                key={i}
                                value={v.id}
                              >
                                {v.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem> */}

                    </GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <Button
                        className=""
                        color="warning"
                        type="submit"
                        disabled={this.state.loading}
                      >
                        {this.state.loading ? (
                          <Loader />
                        ) : (
                          "Add Course Offline Order"
                        )}
                      </Button>
                      <Link
                        to={{
                          pathname: "/admin/offlineorders",
                        }}
                      >
                        <Button className="btn-round" color="danger">
                          Cancel
                        </Button>
                      </Link>
                      <span style={{color:"red", fontSize:"14px", marginLeft:"7px", fontWeight:"500"}}>
                        {
                          this.state.skuError ?
                            this.state.errorSkuMsg
                          : ""
                        }
                      </span>
                    </GridItem>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default AddOfflineOrder;
