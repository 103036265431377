import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
// import AddAlert from "@material-ui/icons/AddAlert";
// import SnackbarContent from "components/Snackbar/SnackbarContent.js";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from "@material-ui/core/FormControl";
import Constants from "variables/AppConstants";

class Chapters extends Component {
  state = {
    subjects: [],
    status: false,
    chapters: [],
    loader: true,
    noData: false
  };
  componentDidMount() {
    this.getChapters();
  }
  getChapters = () => {
    axios.get(Constants.getUrls.subjects + "all=true").then(resp => {
      // console.log(resp);
      this.setState(
        {
          loader: false,
          subjects: resp.data.subjects
        },
        () => {
          const chapters = [];
          this.state.subjects.forEach(subject => {
            subject.chapters.forEach(chapter => {
              chapters.push({
                id: chapter.id,
                name: chapter.name,
                subject: subject.name,
                subject_id: subject.id
              });
            });
          });
          this.setState({ chapters });
        }
      );
      if (this.state.subjects.length < 1) {
        this.setState({
          noData: true
        });
      }
    });
  };
  handleStatus = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        // console.log(this.state.status);
        const statusPayload = {
          active: this.state.status === "false" ? false : true
        };
        axios
          .put(Constants.updateUrls.updateSubject + id, statusPayload)
          .then(() => {
            // window.location.reload();
            this.getChapters();
          });
      }
    );
  };

  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <h3>Chapters</h3>
                {/* <Link to="/admin/addlevel">
                  <Button className="" color="warning">
                    Add Level
                  </Button>
                </Link> */}
                <Table
                  tableHead={["Sr No.", "Subject", "Chapter", "Action"]}
                  tableData={this.state.chapters.map((sec, index) => [
                    [index + 1],
                    [sec.subject],
                    [sec.name],
                    [
                      <Link
                        key={index}
                        to={{
                          pathname: "/admin/updatechapter/" + sec.id,
                          state: {
                            chapterData: sec,
                            subject_id: sec.subject_id
                          }
                        }}
                      >
                        <Button className="btn-round" color="info">
                          Update
                        </Button>
                      </Link>
                    ]
                  ])}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.chapters.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Chapters;
