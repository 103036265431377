/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Redirect, Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import Loader from "components/Loader";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Constants from "variables/AppConstants";
import "react-tagsinput/react-tagsinput.css";
import TagsInput from "react-tagsinput";
class UpdateAppSubject extends Component {
  state = {
    name: "",
    levelId: "",
    chapters: [],
    // levels: [],
    redirect: false,
    show: false,
    loading: false
    //  initialTags: []
  };
  handleChange = chapters => {
    this.setState({ chapters });
  };
  // handleSelect = e => {
  //   this.setState(
  //     {
  //       [e.target.name]: e.target.value
  //     },
  //     () => {
  //       this.setState({
  //         levelId: this.state.levelId
  //       });
  //     }
  //   );
  // };
  handleSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true });
    let payLoad = {
      name: this.state.name,
      // chapters: this.state.chapters
      _method: "put"
      // levelId: this.state.levelId
    };
    const subjectData = this.props.location.state.subjectData;
    const subId = parseInt(subjectData.id);
    axios.post(Constants.updateUrls.updateSubject + subId, payLoad).then(() => {
      // console.log(resp);
      if (this.state.chapters.length !== 0) {
        const chapters = {
          chapters: this.state.chapters
        };
        axios
          .post(
            Constants.updateUrls.updateSubject + subId + "/chapters/add",
            chapters
          )
          .then(() => {
            // console.log(res);
            setTimeout(() => {
              this.setState({
                redirect: true
              });
            }, 2000);
            this.setState({
              show: true,
              loading: false
            });
          });
      } else {
        setTimeout(() => {
          this.setState({
            redirect: true
          });
        }, 2000);
        this.setState({
          show: true,
          loading: false
        });
      }
    });

    // const subjectPayLoad = {
    //   name: this.state.name
    // };
    // console.log(subjectPayLoad);
    // console.log(this.state.initialTags, this.state.tags);
    // const array1 = this.state.initialTags;
    // const array2 = this.state.tags;
    // if (array1.length > array2.length) {
    //   var array3 = array1.filter(function(obj) {
    //     return array2.indexOf(obj) === -1;
    //   });
    //   console.log(array3);
    //   const chaptersPayload = {
    //     chapters: this.state.tags
    //   };
    //   if (array3.length > 0) {
    //     const payload = {
    //       chapters: array3
    //     };
    //     axios
    //       .post(
    //         Constants.postUrls.postSubject +
    //           "/" +
    //           parseInt(subId) +
    //           "/chapters/remove",
    //         payload
    //       )
    //       .then(res => {
    //         console.log(res);
    //       });
    //     // console.log(array3);
    //   }
    // } else {
    //   var array3 = array2.filter(function(obj) {
    //     return array1.indexOf(obj) === -1;
    //   });
    //   console.log(array3);
    //   const payload = {
    //     chapters: array3
    //   };
    //   axios
    //     .post(
    //       Constants.postUrls.postSubject +
    //         "/" +
    //         parseInt(subId) +
    //         "/chapters/add",
    //       payload
    //     )
    //     .then(res => {
    //       console.log(res);
    //     });
    // }
    // axios.put(Constants.updateUrls.postSubject, subjectPayLoad).then(resp => {
    //   console.log(resp);
    //   // console.log(chaptersPayload);
    //   this.setState({
    //     redirect: true
    //   });
    // });
  };
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleChange = chapters => {
    this.setState({ chapters });
  };
  componentDidMount() {
    const subjectData = this.props.location.state.subjectData;
    // console.log(subjectData);

    //   const chapterArray = subjectData.chapters;
    //   let filteredChapters = [];
    //   chapterArray.filter(chapter => {
    //     filteredChapters.push(chapter.name);
    //   });
    // axios.get(Constants.getUrls.levels).then(resp => {
    //   this.setState({
    //     levels: resp.data.levels
    //   });
    // });
    this.setState({
      name: subjectData.name,
      levelId: subjectData.level_id
      //     tags: filteredChapters,
      //     initialTags: filteredChapters
    });
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/appsubjects"} />;
    } else {
      return (
        <div>
          <GridContainer>
            {this.state.show ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Subject Updated Successfully!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <EditIcon />
                  </CardIcon>
                  <h4 style={{ color: "black" }}>Update Subject</h4>
                </CardHeader>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    {/* <GridContainer> */}
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Name"
                        id="float"
                        inputProps={{
                          required: true,
                          value: this.state.name,
                          onChange: e => this.setState({ name: e.target.value })
                        }}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <label>Chapters</label>
                      <br />
                      <TagsInput
                        className="tagStyling"
                        value={this.state.chapters}
                        onChange={this.handleChange}
                        required={true}
                        onlyUnique
                        addOnBlur
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button className="" color="warning" type="submit">
                        {this.state.loading ? <Loader /> : "Update Subject"}
                      </Button>
                      <Link
                        to={{
                          pathname: "/admin/appsubjects"
                        }}
                      >
                        <Button className="btn-round" color="danger">
                          Cancel
                        </Button>
                      </Link>
                    </GridItem>
                    {/* </GridContainer> */}
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

export default UpdateAppSubject;
