import React, { Component } from "react";
import subject_bg from "../images/sub_back.png";
import "../scss/Subjects.scss";
import axios from "axios";
import Constants from "./common/Constants";
import { Link } from "react-router-dom";
import Header from "./common/Header";
import Footer from "./common/Footer";
import notFound from "../images/77703-no-data-found.json";
import Lottie from "react-lottie";
export default class Subjects extends Component {
  state = {
    subjects: [],
    streamName: "",
    streamId: window.location.pathname.split("/")[4],
    attempts: [],
    attempt: "",
    attemptId: "",
    attemptShow: false,
    attemptName: "",
    colorCodes: ["#2f65b4", "#df7504", "#279b14", "#6f1561"],
    comboSub: [],
  };
  componentDidMount() {
    console.log(this.state.streamId);
    axios.get(Constants.getUrls.getAttempts).then((res) => {
      console.log(res);
      this.setState(
        {
          attempts: res.data,
          attemptId: res.data[0].id,
        },
        () => {
          this.getSingleAttempt(this.state.attemptId);
        }
      );
    });

    axios
      .get(
        Constants.getUrls.getStreamsSubject +
          "?stream_id=" +
          this.state.streamId
      )
      .then((resp) => {
        console.log("subjects", resp);
        if (resp.data.data.length > 0) {
          const NormalSubjects = resp.data.data.filter(
            (item) => item.combo_type === "Normal"
          );
          const ComboSubjects = resp.data.data.filter(
            (item) => item.combo_type === "Combo"
          );
          this.setState({
            subjects: NormalSubjects,
            comboSub: ComboSubjects,
            streamName: resp.data.data[0].stream.name,
          });
        }
      })
      .catch((error) => {
        console.log("subjectserr", error.response);
      });
  }

  getSingleAttempt = (id) => {
    axios.get(Constants.getUrls.attempts + "/" + id).then((resp) => {
      console.log(resp);
      if (resp.data.status === "suceess") {
        this.setState({
          attemptName: resp.data.data.title,
        });
      }
    });
  };

  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: notFound,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div>
        <Header />
        <section className="section1">
          <h1 className="fonts"> {this.state.streamName}</h1>
        </section>
        <div className=" paddingContainer">
          <section className="section2 ">
            <div
              className="row"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                width: "90vw",
              }}
            >
              <div
                style={{
                  width: "35%",
                  marginLeft: "-100px",
                }}
              >
                <h3>
                  Showing Courses for{" "}
                  <span style={{ color: "#077c07" }}>
                    {this.state.attemptName}
                  </span>{" "}
                </h3>
              </div>
              <div
                style={{
                  width: "50%",
                  marginLeft: "-170px",
                }}
              >
                <div
                  className="selectTab"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                    widht: "700px",
                  }}
                >
                  <span> Select Your CA Foundation Attempt</span>
                  <span>
                    {" "}
                    <select
                      name="attempt"
                      id=""
                      style={{ color: "#747474", width: "11rem" }}
                      className="selectAttSub"
                      value={this.state.attemptId}
                      onChange={(e) => {
                        this.setState(
                          {
                            attemptShow: true,
                            attemptId: e.target.value,
                          },
                          () => {
                            this.getSingleAttempt(this.state.attemptId);
                          }
                        );
                      }}
                    >
                      <option value="">Select Attempt</option>
                      {this.state.attempts.map((attempt) => (
                        <option value={attempt.id}>{attempt.title}</option>
                      ))}
                    </select>
                  </span>
                </div>
              </div>
            </div>
          </section>
          <section className="section3">
            <div
              className="row"
              style={{
                display: "grid",
                gridTemplateColumns: "25% 25% 25% 25%",
                // flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              {this.state.subjects.length > 0 ? (
                <>
                  {this.state.subjects.map((subject, index) => (
                    <div
                      className="col-md-3"
                      key={index}
                      style={{ marginTop: "8px" }}
                    >
                      <Link
                        style={{ textDecoration: "none" }}
                        to={
                          "/admin/akWebPortal/course_landing/" +
                          this.state.streamId +
                          "/" +
                          this.state.attemptId +
                          "/" +
                          subject.id
                        }
                      >
                        <div className="cardMain">
                          <div
                            className={
                              window.innerWidth === 1396
                                ? "courseCard13961"
                                : "courseCard1"
                            }
                            style={{
                              backgroundColor: this.state.colorCodes.at(
                                index % 4
                              ),
                            }}
                          >
                            <h4 className="courseTitle"> {subject.title}</h4>
                            <div className="cardSecPart">
                              <h5>Smart Classroom - live + Recorded</h5>
                              <h5 className="doubtSolving">
                                24 × 7 Doubt Solving Portal
                              </h5>
                              <h5 className="personalized">
                                Personalized Course Completion Strategy
                              </h5>
                              <h5 className="printedNotes">Printed Notes</h5>
                              <h5 className="liveQuiz">
                                Live Quiz & Regular Test Series
                              </h5>
                              <button className="getDetails">
                                Get Details
                              </button>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </>
              ) : (
                <div
                  className="regiBatches"
                  style={{
                    textAlign: "center",
                    marginTop: "5%",
                    marginLeft: "8%",
                    fontSize: "24px",
                    fontFamily: "raleway",
                  }}
                >
                  <Lottie
                    options={defaultOptions}
                    height={200}
                    width={300}
                    isStopped={this.state.isStopped}
                    isPaused={this.state.isPaused}
                  />
                </div>
              )}
            </div>
          </section>
          <section className="section4">
            <div className="container-fluid container-fluid-Hidden">
              <div className="row combosArea">
                <div
                  className="col-md-12"
                  style={{
                    marginTop: "23px",
                    display: "grid",
                    gridTemplateColumns: "25% 25% 25% 25%",
                    justifyContent: "space-around",
                    alignItems: "center",
                    fontWeight: "bold",
                  }}
                >
                  <div className="col-md-3">
                    <h1 className="subCom">
                      Subject <br /> Combos
                    </h1>
                  </div>
                  <div className="col-md-3">
                    <h5 className="smartClass">
                      Smart Classroom - live + Recorded
                    </h5>
                    <h5 className="doubtSolvingCom">
                      24 × 7 Doubt Solving Portal
                    </h5>
                    <h5 className="personalizedCom">
                      Personalized Course Completion Strategy
                    </h5>
                  </div>
                  <div className="col-md-3">
                    <h5 className="smartClass">Printed Notes</h5>
                    <h5 className="doubtSolvingCom">
                      Live Quiz & Regular Test Series
                    </h5>
                    <h5 className="personalizedCom"></h5>
                  </div>
                  <div className="col-md-3">
                    {this.state.comboSub?.length > 0 ? (
                      this.state.comboSub?.map((item) => (
                        <button className="getDetailsCom">
                          <Link
                            to={
                              "/admin/akWebPortal/combo_subjects/" +
                              this.state.streamId +
                              "/" +
                              this.state.attemptId +
                              "/" +
                              item.id
                            }
                          >
                            Get Details
                          </Link>
                        </button>
                      ))
                    ) : (
                      <button className="getDetailsCom">
                        <Link
                          to={
                            "/admin/akWebPortal/combo_subjects/" +
                            this.state.streamId +
                            "/" +
                            this.state.attemptId
                          }
                        >
                          Get Details
                        </Link>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid divClass">
              <div className="row combosArea1">
                <div className="col-md-12 subTitle">
                  <div className="col-md-3">
                    {this.state.comboSub?.length > 0 ? (
                      this.state.comboSub?.map((item) => (
                        <h5 className="subCom">
                          <Link
                            to={
                              "/admin/akWebPortal/combo_subjects/" +
                              this.state.streamId +
                              "/" +
                              this.state.attemptId +
                              "/" +
                              item.id
                            }
                          >
                            Subject Combos
                          </Link>
                        </h5>
                      ))
                    ) : (
                      <h5 className="subCom">
                        <Link
                          to={
                            "/admin/akWebPortal/combo_subjects/" +
                            this.state.streamId +
                            "/" +
                            this.state.attemptId
                          }
                        >
                          Subject Combos
                        </Link>
                      </h5>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section5">
            <div className="container-fluid">
              <div className="row clickSubject">
                <h4 className="clickParagraph">
                  Click on any of the subjects above or Combo Package to view
                  all the options available with us for particular subject. You
                  need to select attempt so that we... Click on any of the
                  subjects above or Combo Package to view all the options
                  available with us for particular subject. You need to select
                  attempt so that we ca
                </h4>
              </div>
            </div>
          </section>
          <section className="section6">
            <div className="container-fluid">
              <div className="row bottomText">
                <h1 className="marathiText">तैयारी आपकी...जिम्मेदारी हमारी</h1>
              </div>
            </div>
          </section>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}
