/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Constants from "variables/Constants";
// import { makeStyles } from "@material-ui/core/styles";
// import Select from "@material-ui/core/Select";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from "@material-ui/core/FormControl";
// import MenuItem from "@material-ui/core/MenuItem";
// import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
// const classes = makeStyles(styles);

// const perPages = ["10", "25", "50", "100"];
class Monitor_User_Days_Dashboard extends Component {
  state = {
    notifyStatus: false,
    empty: true,
    secondaryCategories: [],
    strategyData: [],
    status: "",
    user: "",
    strategy: "",
    active: 1,
    per_page: "10",
    currentPage: 1,
    average: 0,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    addedStrategies: [],
    id: 0,
    strategyDays: [],
    name: [],
    test_paper: 0,
    batch_id: "",
    secondaryArray: [],
    secondArray: [],
    user_id: "",
    strategy_id: "",
    user_details: {},
    strategy_details: "",
    lecture: "",
    revision: "",
    total: "",
    delayed_by: "",
    complete: "",
    average: "",
    batch_details: {},
    strategy_start_date: "",
    test_paper_completed: "",
    pending_test_ppr: [],
    batch_name: "",
    message: "",
  };

  componentDidMount() {
    // console.log(this.props.location.state.strategyDays.length);
    this.setState(
      {
        // strategy_start_date: this.props.location.state.strategy_data,
        // secondaryCategories: this.props.location.state.strategyDays
        //   ? this.props.location.state.strategyDays.reverse()
        //   : [],
        user_id: this.props.location.state.user_details.ID,
        strategy_id: this.props.location.state.id,
        user_details: this.props.location.state.user_details,
        batch_id: this.props.location.state.batch_id,
        mobile_no: this.props.location.state.user_details.MOBILE,
      },
      () => {
        console.log(this.state.strategy_id);
        let strategy_load = {
          user_id: this.state.user_id,
          strategy_id: this.state.strategy_id,
        };
        axios
          .post(
            "https://admin.akclasses.in/api/monitoring/get-users-strategy",
            strategy_load
          )
          .then((resp) => {
            console.log(resp);
            this.setState({
              secondaryCategories: resp.data.student_strategy_days
                ? resp.data.student_strategy_days
                : [],
              strategy_start_date:
                resp.data.student_strategy_days[0].strategy_day_start_date,
            });
          });

        axios.get(Constants.getUrls.getBatches).then((res) => {
          // console.log(res);
          // // this.setState({ catchNull: res.data.data})
          res.data.data.map((item) => {
            if (item.id === this.state.batch_id) {
              this.setState({
                batch_name: item.batch_name,
              });
            }
          });
          this.setState({
            batches: res.data.data,
          });
        });

        axios
          .post(
            "https://admin.akclasses.in/api/monitoring/get-average",
            strategy_load
          )
          .then((resp) => {
            console.log(resp, "resp");
            if (resp.data.status === "failed") {
              this.setState({
                strategy_details: resp.data.message,
              });
              return;
            }
            this.setState({
              strategy_details: resp.data,
              delayed_by: resp.data?.running_delay_by,
              revision: resp.data?.revision,
              lecture: resp.data?.lecture,
              completed: resp.data?.complete,
              average: resp.data?.average,
              test_paper_completed: resp.data?.test_paper_completed,
              pending_test_ppr: resp.data?.test_paper_not_completed,
            });
          });
      }
    );
  }

  markComplete = (sec) => {
    // console.log(sec);
    let payload = {
      strategy_day_status: "complete",
      strategy_days_id: sec?.strategy_days_id,
      mobile_no: this.state.mobile_no,
    };
    // console.log(payload);
    axios
      .post("https://admin.akclasses.in/api/noval/day_strategy", payload, {
        withCredentials: true,
        headers: {
          NoauthKey:
            "lIgcNQQlOnNyAdeCRrnyNwVyeZHEQm0ylJ80YH12GiVJihMQouDMkarPclkM",
        },
      })
      .then((resp) => {
        // console.log(resp);
        if (resp.data.status === "success") {
          this.setState({
            message: resp.data.message,
            notifyStatus: true,
          });
          setTimeout(() => {
            this.setState({
              notifyStatus: false,
            });
          }, 3000);
          window.location.reload();
        } else {
          this.setState({
            message: resp.data.message,
            notifyStatus: true,
          });
          setTimeout(() => {
            this.setState({
              notifyStatus: false,
            });
          }, 3000);
        }
      });
  };

  render() {
    return (
      <div>
        <GridContainer>
          {this.state.notifyStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={this.state.message}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}

          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <h3>
                  {this.state.user} Days Stats for {this.state.strategy}
                </h3>
                <div>
                  <Link
                    to={{
                      pathname: "/admin/monitorUserStrategy/" + this.state.id,

                      state: {
                        strategy_data: this.state.addedStrategies,
                        id: this.state.id,
                        average: this.state.average,
                      },
                    }}
                  >
                    <Button className="" color="danger">
                      Go Back
                    </Button>
                  </Link>
                  ;
                </div>

                {this.state.strategy_details ===
                "Strategy data not found.   " ? (
                  "No strategy details found"
                ) : (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "33.33% 33.33% 33.33%",
                      justifyContent: "space-around",
                      alignItems: "center",
                      border: "2px solid #d6d6c2",
                      // margin: "10px",
                      marginBottom: "20px",
                      // width: "auto"
                    }}
                  >
                    <div style={{ marginLeft: "5px" }}>
                      <h5 style={{ textAlign: "left", fontWeight: "bold" }}>
                        Student Name:
                        <span style={{ color: "blue" }}>
                          {" "}
                          {this.state.user_details?.FIRST_NAME}
                        </span>
                      </h5>
                      <h5 style={{ fontWeight: "bold" }}>
                        Batch Name:{" "}
                        <span style={{ color: "blue" }}>
                          {" "}
                          {this.state.batch_name}{" "}
                        </span>
                      </h5>
                    </div>

                    <div style={{ marginLeft: "5px", textAlign: "center" }}>
                      <h5 style={{ fontWeight: "bold" }}>
                        <span style={{ color: "red" }}> Overall Progress:</span>
                        <span style={{ color: "blue" }}>
                          {" "}
                          {this.state.average}
                        </span>
                      </h5>
                      <h5 style={{ fontWeight: "bold" }}>
                        Start Date:{" "}
                        <span style={{ color: "blue" }}>
                          {" "}
                          {this.state.strategy_start_date}{" "}
                        </span>
                      </h5>
                    </div>
                    <div style={{ marginRight: "10px", textAlign: "right" }}>
                      {this.state.pending_test_ppr.length > 0 ? (
                        <h5 style={{ fontWeight: "bold", marginRight: "5px" }}>
                          Pending Test Papers:{" "}
                          <select
                            style={{
                              height: "30px",
                              width: "100px",
                              fontWeight: "300",
                            }}
                          >
                            {this.state.pending_test_ppr.map((val) => (
                              <option> {val?.paper?.TITLE}</option>
                            ))}
                          </select>
                        </h5>
                      ) : (
                        <h5>No Data for Testpaper</h5>
                      )}
                      <h5 style={{ fontWeight: "bold", marginRight: "5px" }}>
                        Running Delay by:{" "}
                        <span style={{ color: "red" }}>
                          {this.state.delayed_by} Days
                        </span>
                      </h5>
                    </div>

                    <div
                      style={{
                        textAlign: "center",
                        marginRight: "5px",
                        border: "2px solid black",
                        height: "50px",
                        width: "170px",
                      }}
                    >
                      <h5 style={{ fontWeight: "bold", marginLeft: "5px" }}>
                        Lectures:{" "}
                        <span style={{ color: "blue" }}>
                          {this.state.lecture}
                        </span>
                      </h5>{" "}
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        margin: "auto",
                        border: "2px solid black",
                        height: "50px",
                        width: "170px",
                      }}
                    >
                      <h5
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          marginRight: "5px",
                        }}
                      >
                        Revision:{" "}
                        <span style={{ color: "blue" }}>
                          {" "}
                          {this.state.revision}
                        </span>
                      </h5>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        margin: "auto",
                        border: "2px solid black",
                        height: "50px",
                        width: "170px",
                        marginRight: "0px",
                      }}
                    >
                      <h5
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          marginRight: "5px",
                        }}
                      >
                        Test Paper:{" "}
                        <span style={{ color: "blue" }}>
                          {" "}
                          {this.state.test_paper_completed}
                        </span>
                      </h5>
                    </div>
                  </div>
                )}

                {this.state.secondaryCategories.length > 0 ? (
                  <Table
                    tableHead={[
                      "Sr No.",
                      "Topic",
                      "Strategy Type",
                      "Day",
                      "Status",
                      "Action",
                    ]}
                    tableData={this.state.secondaryCategories.map(
                      (sec, index) => [
                        [index + 1],
                        [sec.topic ? sec.topic?.name : sec?.paper?.TITLE],
                        [sec?.type],
                        ["Day " + (index + 1)],
                        [
                          <span
                            key={index}
                            style={{
                              color:
                                sec?.strategy_day_status === "complete"
                                  ? "#367E18"
                                  : sec?.strategy_day_status === "postponed"
                                  ? "#FFDE00"
                                  : "red",
                            }}
                          >
                            <h5>
                              {sec?.strategy_day_status === "not-complete"
                                ? "Not Completed"
                                : sec?.strategy_day_status === "postponed"
                                ? "Postponed"
                                : "Completed"}
                            </h5>
                          </span>,
                        ],
                        [
                          sec?.strategy_day_status === "complete" ? (
                            <div>
                              <Button color="grey">Mark Complete  </Button>
                            </div>
                          ) : (
                            <div>
                              <Button
                                onClick={() => this.markComplete(sec)}
                                color="success"
                              >
                                Mark Complete
                              </Button>
                            </div>
                          ),
                        ],
                      ]
                    )}
                  />
                ) : (
                  "Staretegy day not available "
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {/* {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : this.state.empty ? null : (
          <div>
            <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div>
            <div style={{ float: "right", border: "1px solid grey" }}>
              <div
                onClick={this.prevPages}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div>
              {this.state.filteredBtnIdArray.map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(v);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === v ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === v ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black",
                  }}
                >
                  {v}
                </div>
              ))}
              <div
                onClick={this.nextPages}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.filteredBtnIdArray.length === 2 ||
                    this.state.filteredBtnIdArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c8;
                </i>
              </div>
            </div>
          </div>
        )} */}
      </div>
    );
  }
}

export default Monitor_User_Days_Dashboard;
