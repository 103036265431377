import React, { Component } from "react";
import axios from "axios";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Constants from "variables/AppConstants";
import Constant from "variables/Constants";
// import Loader from "components/Loader";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
// import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import MenuItem from "@material-ui/core/MenuItem";
import fileDownload from "js-file-download";
import Modal from "react-awesome-modal";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { Link } from "react-router-dom";
import Loader from "components/Loader";
const classes = makeStyles(styles);

const perPages = ["10", "25", "50", "100"];
class Queries extends Component {
  state = {
    queries: [],
    allQueries: [],
    subjectId: "null",
    levelId: "null",
    chapterId: "null",
    frmDate: "null",
    toDate: "null",
    ord: true,
    lord: false,
    empty: false,
    levels: [],
    filteredSubjects: [],
    filteredChapters: [],
    subjects: [],
    sortedQueries: [],
    filteredQueries: [],
    status: "",
    queryStatus: "null",
    show: false,
    loading: false,
    activeOpen: false,
    activeClosed: false,
    activeApproved: false,
    per_page: "10",
    loader: true,
    noData: false,
    active: 1,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    user: "",
    users: [],
    name: "",
    filteredBtnIdArray: [],
    visible: false,
    file: "",
    img_url: "",
    imgPath: "",
    id: "",
    queryFacultyAnswer: "",
    visibleDelete: false,
    rowId: "",
  };
  componentDidMount() {
    axios
      .get(Constants.getUrls.queries + "status=open,closed,approved,rejected")
      .then(resp => {
        // console.log(resp);
        this.setState({ allQueries: resp.data.queries });
      });
    this.getQueries();
    axios.get(Constants.getUrls.levels).then(resp => {
      this.setState({
        levels: resp.data.levels
      });
    });
  }
  getQueries = () => {
    axios
      .get(
        Constants.getUrls.queries +
        "status=open,approved,rejected,closed&perPage=" +
        this.state.per_page
      )
      .then(resp => {
        console.log(resp);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.queries.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            // queries: resp.data.queries.data,
            // filteredQueries: resp.data.queries.data,
            sortedQueries: resp.data.queries.data,
            btnIdArray,
            total: resp.data.queries.total,
            from: resp.data.queries.from,
            to: resp.data.queries.to
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              v => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray
            });
          }
        );
      });
  };
  handlePage = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        ord: false,
        toInc: 5
      },
      () => {
        axios
          .get(
            "https://core.akclasses.in/api/filter_by_date?" +
            "status=" +
            this.state.queryStatus +
            "&frmdate=" +
            this.state.frmDate +
            "&todate=" +
            this.state.toDate +
            "&level_id=" +
            this.state.levelId +
            "&subject_id=" +
            this.state.subjectId +
            "&chapter_id=" +
            this.state.chapterId +
            "&perPage=" +
            this.state.per_page
          )
          .then(resp => {
            // console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.queries.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                empty: false,
                // queries: resp.data.queries.data,
                // filteredQueries: resp.data.queries.data,
                sortedQueries: resp.data.queries.data,
                btnIdArray,
                total: resp.data.queries.total,
                from: resp.data.queries.from,
                to: resp.data.queries.to
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray
                });
              }
            );
          });
      }
    );
  };
  currentPage = v => {
    let commonUrl = this.state.ord
      ? "https://core.akclasses.in/api/queries?status=open,approved,rejected,closed&perPage=" +
      this.state.per_page +
      "&page="
      : this.state.lord
        // ? "https://core.akclasses.in/api/searchUser?name=" +
        ? "https://core.akclasses.in/api/searchByUserName?q=" +
        this.state.user +
        "&perPage=" +
        this.state.per_page +
        "&page="
        : "https://core.akclasses.in/api/filter_by_date?" +
        "status=" +
        this.state.queryStatus +
        "&frmdate=" +
        this.state.frmDate +
        "&todate=" +
        this.state.toDate +
        "&level_id=" +
        this.state.levelId +
        "&subject_id=" +
        this.state.subjectId +
        "&chapter_id=" +
        this.state.chapterId +
        "&perPage=" +
        this.state.per_page +
        "&page=";
    axios.get(commonUrl + v).then(resp => {
      console.log(resp);
      if (this.state.lord) {
        this.setState({
          total: resp.data.queries.total,
          from: resp.data.queries.from,
          to: resp.data.queries.to,
          sortedQueries: resp.data.queries.data,
          active: v
        });
      } else {
        this.setState({
          total: resp.data.queries.total,
          from: resp.data.queries.from,
          to: resp.data.queries.to,
          sortedQueries: resp.data.queries.data,
          active: v
        });
      }
      // console.log(resp.data.data);
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          v => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                v => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          v => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                v => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  handleDelete = id => {
    this.setState({ delLoading: true });
    axios.delete("https://core.akclasses.in/api/queries/" + id).then(() => {
      this.setState({
        notifyDelete: true,
        delLoading: false,
        visibleDelete: false
      });
      setTimeout(() => {
        this.getQueries();
      }, 2000);
    });
  };

  handleLevel = e => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        const singleLevel = this.state.levels.find(
          level => level.id === this.state.levelId
        );
        // console.log(singleLevel);

        this.setState({
          filteredSubjects: singleLevel.subjects
        });
      }
    );
  };
  handleSubject = e => {
    if (e.target.value !== "") {
      this.setState(
        {
          [e.target.name]: e.target.value
        },
        () => {
          const singleSubject = this.state.filteredSubjects.find(
            subject => subject.id === this.state.subjectId
          );
          // console.log(singleSubject);

          this.setState({
            filteredChapters: singleSubject.chapters
          });
        }
      );
    }
  };
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    const filteredQueries = [];
    this.state.allQueries.forEach(query => {
      if (
        query.level_id === this.state.levelId &&
        query.subject_id === this.state.subjectId &&
        query.chapter_id === this.state.chapterId
      ) {
        filteredQueries.push(query);
      }
    });
    // console.log(filteredQueries);
    this.setState({
      empty: true,
      filteredQueries,
      sortedQueries: filteredQueries,
      activeOpen: false,
      activeApproved: false,
      activeRejected: false
    });
  };
  statusChange = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        // alert(this.state.status + " " + id);
        const payload = {
          status: this.state.status
        };
        axios
          .put(Constants.updateUrls.updateQueryStatus + id, payload)
          .then(() => {
            // console.log(res);
            this.setState({
              show: true
            });
            setTimeout(() => {
              this.getQueries();
              // window.location.reload();
            }, 2000);
          });
      }
    );
  };
  searchuser = () => {
    axios
      .get(
        // "https://core.akclasses.in/api/searchUser?name=" +
        "https://core.akclasses.in/api/searchByUserName?q=" +
        this.state.user +
        "&perPage=" +
        this.state.per_page
      )
      .then(resp => {
        console.log(resp);
        if (resp.data.status == "success") {
          let btnIdArray = [];
          for (let i = 1; i <= resp.data.queries.last_page; i++) {
            btnIdArray.push(i);
          }
          // console.log(resp.data);
          this.setState(
            {
              active: 1,
              fromInc: 1,
              lord: true,
              ord: false,
              toInc: 5,
              total: resp.data.queries.total,
              from: resp.data.queries.from,
              to: resp.data.queries.to,
              btnIdArray,
              sortedQueries: resp.data.queries.data
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                v => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({
                filteredBtnIdArray
              });
            }
          );
        }
        // else
        // {

        // }
      });
  };
  showOpen = () => {
    this.setState(
      {
        queryStatus: "open",
        activeOpen: true,
        activeClosed: false,
        activeRejected: false,
        activeApproved: false
      },
      () => {
        this.submitFilter();
      }
    );
    // this.setState({ queryStatus: "open" });
    // const openQueries = [];
    // this.state.allQueries.forEach(question => {
    //   if (question.status == "open") {
    //     openQueries.push(question);
    //   }
    // });
    // console.log(openQueries);
    // this.setState({
    //   empty: true,
    //   sortedQueries: openQueries,
    //   activeRejected: false,
    //   activeOpen: true,
    //   activeApproved: false
    // });
  };
  showClosed = () => {
    this.setState(
      {
        queryStatus: "closed",
        activeClosed: true,
        activeOpen: false,
        activeRejected: false,
        activeApproved: false
      },
      () => {
        this.submitFilter();
      }
    );
  };
  showApproved = () => {
    this.setState(
      {
        queryStatus: "approved",
        activeOpen: false,
        activeClosed: false,
        activeRejected: false,
        activeApproved: true
      },
      () => {
        this.submitFilter();
      }
    );
    // this.setState({ queryStatus: "approved" });
    // const approvedQueries = [];
    // this.state.allQueries.forEach(question => {
    //   if (question.status == "approved") {
    //     approvedQueries.push(question);
    //   }
    // });
    // console.log(approvedQueries);

    // this.setState({
    //   empty: true,
    //   sortedQueries: approvedQueries,
    //   activeRejected: false,
    //   activeOpen: false,
    //   activeApproved: true
    // });
  };

  showRejected = () => {
    this.setState(
      {
        queryStatus: "rejected",
        activeOpen: false,
        activeClosed: false,
        activeRejected: true,
        activeApproved: false
      },
      () => {
        this.submitFilter();
      }
    );
    // const closedQuestions = [];
    // this.state.allQueries.forEach(question => {
    //   if (question.status == "rejected") {
    //     closedQuestions.push(question);
    //   }
    // });

    // this.setState({
    //   empty: true,
    //   sortedQueries: closedQuestions,
    //   activeOpen: false,
    //   activeRejected: true,
    //   activeApproved: false
    // });
  };
  dateConverter = date => {
    let year = date.getFullYear().toString();
    let month = (date.getMonth() + 101).toString().substring(1);
    let day = (date.getDate() + 100).toString().substring(1);
    return year + "/" + month + "/" + day;
  };
  searchByDate = () => {
    let commonUrl =
      "https://core.akclasses.in/api/filter_by_date?frmdate=" +
      this.state.frmDate +
      "&todate=" +
      this.state.toDate;
    axios.get(commonUrl).then(resp => {
      // console.log(resp);
      let btnIdArray = [];
      for (let i = 1; i <= resp.data.queries.last_page; i++) {
        btnIdArray.push(i);
      }
      this.setState(
        {
          empty: true,
          ord: false,
          lord: false,
          active: 1,
          fromInc: 1,
          toInc: 5,
          total: resp.data.queries.total,
          from: resp.data.queries.from,
          to: resp.data.queries.to,
          btnIdArray,
          queries: resp.data.queries.data,
          filteredQueries: resp.data.queries.data,
          sortedQueries: resp.data.queries.data
        },
        () => {
          let filteredBtnIdArray = this.state.btnIdArray.filter(
            v => v >= this.state.fromInc && v <= this.state.toInc
          );
          this.setState({
            filteredBtnIdArray
          });
        }
      );
    });
  };
  readFile = (file) => {
    console.log(file);
    this.setState({
      file: file
    }, () => {
      this.setState({
        visible: true
      })
    })
  };
  submitFilter = () => {
    axios
      .get(
        "https://core.akclasses.in/api/filter_by_date?" +
        "status=" +
        this.state.queryStatus +
        "&frmdate=" +
        this.state.frmDate +
        "&todate=" +
        this.state.toDate +
        "&level_id=" +
        this.state.levelId +
        "&subject_id=" +
        this.state.subjectId +
        "&chapter_id=" +
        this.state.chapterId +
        "&perPage=" +
        this.state.per_page
      )
      .then(resp => {
        // console.log(resp);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.queries.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            ord: false,
            active: 1,
            fromInc: 1,
            toInc: 5,
            total: resp.data.queries.total,
            from: resp.data.queries.from,
            to: resp.data.queries.to,
            btnIdArray,
            // queries: resp.data.queries.data,
            // filteredQueries: resp.data.queries.data,
            sortedQueries: resp.data.queries.data
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              v => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray
            });
          }
        );
      });
  };

  // downloadFile = (id) => {
  //   console.log(id);
  //   axios.get(Constants.getUrls.getSingleQuery + id).then(resp => {
  //     console.log(resp);
  //     this.setState({
  //       img_url: resp.data.query.img_url,
  //     });
  //     const payLoad = {
  //       path: this.state.img_url,
  //       id: id
  //     }
  //     axios
  //       .post(Constants.postUrls.downloadQueryFile, payLoad , { responseType: "blob" })
  //       .then((res) => {
  //         console.log(res);
  //         fileDownload(res.data, "");
  //       });
  //   });
  // }

  // downloadFile = (uri, name) => {
  //   console.log(uri);
  //   console.log(name);
  //     var link = document.createElement("a");
  //     link.download = name;
  //     link.href = uri;
  //     link.click();
  // }


  // downloadFile = (fileUrl, fileName, index) => {
  //   console.log("hi");
  //   var a = document.createElement("a");
  //   a.href = fileUrl;
  //   a.setAttribute("download", 'Query_'+ index +'.png');
  //   a.click();
  // }


  downloadFile = (uri, name, index) => {
    var ext = uri.substr(uri.lastIndexOf('.') + 1);
    axios({
      url: uri,
      method: 'GET',
      responseType: 'blob',
      headers: { "Access-Control-Allow-Origin": "*" }
    })
      .then((response) => {
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Query_' + index + '.' + ext);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch((error) => {
        console.log(error.response);
      })
  }



  render() {
    return (
      <>
        <div>
          <GridContainer>
            {this.state.show ? (
              <div style={{ position: "relative", left: "600px" }}>
                <SnackbarContent
                  message={"Status Changed!"}
                  icon={AddAlert}
                  color="success"
                />
              </div>
            ) : null}
            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="danger">
                    <Assignment />
                  </CardIcon>
                  <h4>Simple Table</h4>
                </CardHeader>
                <CardBody style={{ position: "relative" }}>
                  <FormControl
                    style={{
                      position: "absolute",
                      right: "10%",
                      width: "85px"
                    }}
                    className={classes.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Per Page
                    </InputLabel>
                    <Select
                      s
                      key={1}
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={this.state.per_page}
                      inputProps={{
                        name: "per_page",
                        id: "simple-select",
                        onChange: e => {
                          this.handlePage(e);
                        }
                      }}
                    >
                      {perPages.map((v, i) => (
                        <MenuItem
                          key={i}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={v}
                        >
                          {v}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <h6 stylle={{ marginBottom: "0px" }}>Filter</h6>
                      <FormControl
                        style={{ marginTop: "12px" }}
                        required={true}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Level
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.levelId}
                          inputProps={{
                            name: "levelId",
                            id: "simple-select",
                            onChange: this.handleLevel
                          }}
                        >
                          {this.state.levels.map((v, i) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              key={i}
                              value={v.id}
                            >
                              {v.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {this.state.aAlert ? (
                        <div>
                          <span style={{ color: "red" }}>*required</span>
                        </div>
                      ) : null}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <FormControl
                        style={{ marginTop: "48px" }}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Subject
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.subjectId}
                          inputProps={{
                            required: true,
                            name: "subjectId",
                            id: "simple-select",
                            onChange: this.handleSubject
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value=""
                          >
                            Select Level First
                          </MenuItem>
                          {this.state.filteredSubjects.map((v, i) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              key={i}
                              value={v.id}
                            >
                              {v.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {this.state.bAlert ? (
                        <div>
                          <span style={{ color: "red" }}>*required</span>
                        </div>
                      ) : null}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <FormControl
                        style={{ marginTop: "48px" }}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Chapter
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.chapterId}
                          inputProps={{
                            required: true,
                            name: "chapterId",
                            id: "simple-select",
                            onChange: e => {
                              this.setState({
                                cAlert: false,
                                chapterId: e.target.value
                              });
                            }
                            // //console.log(e.target.value);
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value=""
                          >
                            Select Subject First
                          </MenuItem>
                          {this.state.filteredChapters.map((v, i) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              key={i}
                              value={v.id}
                            >
                              {v.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {this.state.cAlert ? (
                        <div>
                          <span style={{ color: "red" }}>*required</span>
                        </div>
                      ) : null}
                    </GridItem>
                    <Button
                      onClick={this.submitFilter}
                      style={{
                        marginTop: "55px"
                      }}
                      color="success"
                    >
                      Search
                    </Button>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <Button
                            color={
                              this.state.activeOpen === true
                                ? "info"
                                : "secondary"
                            }
                            onClick={this.showOpen}
                          >
                            Open
                          </Button>
                          <Button
                            color={
                              this.state.activeClosed === true
                                ? "info"
                                : "secondary"
                            }
                            onClick={this.showClosed}
                          >
                            Closed
                          </Button>
                          <Button
                            color={
                              this.state.activeApproved === true
                                ? "info"
                                : "secondary"
                            }
                            onClick={this.showApproved}
                          >
                            Approved
                          </Button>
                          <Button
                            color={
                              this.state.activeRejected === true
                                ? "info"
                                : "secondary"
                            }
                            onClick={this.showRejected}
                          >
                            Rejected
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                          <br />
                          <label
                            style={{
                              color: "black"
                            }}
                          >
                            Search By Date
                          </label>
                          <br />
                          <FormControl fullWidth>
                            <Datetime
                              timeFormat={false}
                              onChange={e => {
                                let year = e._d.getFullYear().toString();
                                let month = (e._d.getMonth() + 101)
                                  .toString()
                                  .substring(1);
                                let day = (e._d.getDate() + 100)
                                  .toString()
                                  .substring(1);
                                this.setState(
                                  {
                                    frmDate: year + "/" + month + "/" + day
                                  },
                                  () => {
                                    //console.log(this.state.frmDate);
                                  }
                                );
                              }}
                              inputProps={{
                                placeholder: "From Date"
                              }}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                          <br />
                          <br />
                          <FormControl fullWidth>
                            <Datetime
                              timeFormat={false}
                              onChange={e => {
                                let year = e._d.getFullYear().toString();
                                let month = (e._d.getMonth() + 101)
                                  .toString()
                                  .substring(1);
                                let day = (e._d.getDate() + 100)
                                  .toString()
                                  .substring(1);
                                this.setState(
                                  {
                                    toDate: year + "/" + month + "/" + day
                                  },
                                  () => {
                                    //console.log(this.state.toDate);
                                  }
                                );
                              }}
                              inputProps={{
                                placeholder: "To Date"
                              }}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <br />
                          <Button
                            style={{
                              position: "relative",
                              top: "10px",
                              right: "20px"
                            }}
                            onClick={this.submitFilter}
                            color="success"
                          >
                            Search
                          </Button>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText={"Search"}
                            id="float"
                            inputProps={{
                              onChange: e =>
                                this.setState({ user: e.target.value })
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                          <Button
                            onClick={this.searchuser}
                            style={{
                              position: "relative",
                              bottom: "62px",
                              left: "210px"
                            }}
                            color="success"
                          >
                            Search
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <h3>Queries</h3>
                  {/* <Link to="/admin/addschedule">
                  <Button className="" color="warning">
                    Add Schedule
                  </Button>
                </Link> */}
                  <Table
                    tableHead={["Sr No.", "Title", "Download", "Answer", "Status", "Action"]}
                    tableData={this.state.sortedQueries.map((sec, index) => [
                      // [index + 1],
                      [sec.id],
                      [sec.text === "null" ? "Title Not Available" : sec.text],
                      [
                        sec.path ? (
                          <>
                            <a
                              key={index}
                              rel="noopener noreferrer"
                              onClick={() => this.downloadFile(sec.img_url, "Query_", sec.id)}
                            >
                              <Button color="danger">Download File</Button>
                            </a>

                            {/* <a
                          key={index}
                          href={sec.img_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                          >
                            <Button color="danger">Download File</Button>
                        </a> */}
                            <a
                              key={index}
                              // href={sec.img_url}
                              // target="_blank"
                              rel="noopener noreferrer"
                              onClick={() => {
                                this.readFile(sec.img_url)
                              }}
                            >
                              <Button color="danger">Read File</Button>
                            </a>

                          </>
                        ) : (
                          "Media Not Available"
                        )
                      ],
                      [
                        <Link
                          key={index}
                          to={{
                            pathname: "/admin/answerquery/" + sec.id,
                            state: { questionData: sec, queryFacultyAnswer: sec.faculty_answers.length > 0 ? sec.faculty_answers[0].answer : null }
                          }}
                        >
                          <Button
                            onClick={() => {
                              localStorage.setItem("id", 1);
                              localStorage.setItem("width", 852);
                              localStorage.setItem("height", 480);
                            }}
                            className="btn-round"
                            color="info"
                          >
                            Upload An Answer
                          </Button>
                        </Link>
                      ],
                      [
                        <Select
                          key={index}
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={sec.status}
                          inputProps={{
                            name: "status",
                            id: "simple-select",
                            onChange: e => {
                              this.statusChange(e, sec.id);
                            }
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="approved"
                          >
                            Approved
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="open"
                          >
                            Open
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="closed"
                          >
                            Closed
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="rejected"
                          >
                            Rejected
                          </MenuItem>
                        </Select>
                      ],
                      [
                        <Button
                          onClick={() => {
                            this.setState({
                              visibleDelete: true,
                              rowId: sec.id
                            })
                          }}
                          className="btn-round"
                          color="danger"
                        >
                          Delete
                        </Button>
                      ]
                    ])}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          {this.state.sortedQueries.length === 0 ? (
            <div>
              <h6 style={{ textAlign: "center" }}>No Data Found</h6>
            </div>
          ) : this.state.empty ? null : (
            <div>
              <div style={{ float: "left" }}>
                Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
                entries.
              </div>
              <div style={{ float: "right", border: "1px solid grey" }}>
                <div
                  onClick={this.prevPages}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    padding: "4px 0px 4px 0px",
                    backgroundColor: "transparent",
                    fontWeight: "bold",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "grey"
                  }}
                >
                  <i
                    style={{ fontSize: "16px", position: "relative", top: "3px" }}
                    className="material-icons"
                  >
                    &#xe5c4;
                  </i>
                </div>
                {this.state.filteredBtnIdArray.map((v, i) => (
                  <div
                    key={i}
                    onClick={() => {
                      this.currentPage(v);
                    }}
                    style={{
                      width: "34px",
                      textAlign: "center",
                      borderLeft:
                        (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                          (v % 2 === 0 && i === 4)
                          ? ""
                          : "1px solid grey",
                      borderRight:
                        (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                          (v % 2 === 0 && i === 0)
                          ? ""
                          : "1px solid grey",
                      backgroundColor:
                        this.state.active === v ? "skyblue" : "transparent",
                      padding: "5px 0px 5px 0px",
                      fontWeight: this.state.active === v ? "bold" : "",
                      display: "inline-block",
                      cursor: "pointer",
                      color: "black"
                    }}
                  >
                    {v}
                  </div>
                ))}
                <div
                  onClick={this.nextPages}
                  style={{
                    width: "34px",
                    borderLeft:
                      this.state.filteredBtnIdArray.length === 2 ||
                        this.state.filteredBtnIdArray.length === 4
                        ? "1px solid grey"
                        : "",
                    padding: "4px 0px 4px 0px",
                    backgroundColor: "transparent",
                    textAlign: "center",
                    fontWeight: "bold",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "grey"
                  }}
                >
                  <i
                    style={{ fontSize: "16px", position: "relative", top: "3px" }}
                    className="material-icons"
                  >
                    &#xe5c8;
                  </i>
                </div>
              </div>
            </div>
          )}
        </div>
        <Modal
          visible={this.state.visible}
          width="800"
          // height="600"
          scrollable={true}
          effect="fadeInUp"
          onClickAway={() => this.setState({ visible: false })}
          style={{ overflowY: "auto" }}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  <div>
                    <Button color="danger" onClick={() => {
                      this.setState({
                        visible: false
                      })
                    }}>Close</Button>
                    <img src={this.state.file} width="700px" />
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Modal>

        <Modal
          visible={this.state.visibleDelete}
          width="600"
          height="100"
          effect="fadeInUp"
          onClickAway={() => this.setState({ visibleDelete: false })}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  <div>
                    <h3>Are You Sure You Want to Delete It?</h3>
                    {this.state.notifyDelete ? (
                      <div>
                        <span style={{ color: "green" }}>
                          Query Deleted Successfuly!
                        </span>
                      </div>
                    ) : null}
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <br />
                        <Button
                          className=""
                          color="warning"
                          onClick={() => {
                            this.handleDelete(this.state.rowId);
                          }}
                        >
                          {this.state.delLoading ? <Loader /> : "Yes"}
                        </Button>
                        <Button
                          className=""
                          color="danger"
                          onClick={() => {
                            this.setState({ visibleDelete: false });
                          }}
                        >
                          No
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Modal>
      </>
    );



  }
}

export default Queries;
